import { createAsyncThunk } from "@reduxjs/toolkit";
import { cloudChiprApi } from "../../../services/cloudchipr.api";
import { enqueueSnackbarErrorAlert } from "../../../components/pages/common/snackbar-alert/EnqueueSnackbarErrorAlert";

export const getOrganizationsForCommitmentsThunk = createAsyncThunk(
  "commitments/getOrganizationsForCommitments",
  async (_: void, { dispatch }) => {
    const { getUsersMeOrganisationsCurrentProviderOrganisations } =
      cloudChiprApi.endpoints;

    try {
      await dispatch(
        getUsersMeOrganisationsCurrentProviderOrganisations.initiate(),
      ).unwrap();
    } catch (e) {
      // @ts-expect-error todo: fix api spec;
      enqueueSnackbarErrorAlert(e?.data?.message);

      return null;
    }
  },
);
