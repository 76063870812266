import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../../../../store";
import { cloudChiprApi } from "../../../../../services/cloudchipr.api";
import { commitmentUtilizationWidgetSetupDataPayloadSelector } from "../../../selectors/widgets/commitment-utilization/setup/commitmentUtilizationWidgetSetupDataPayloadSelector";
import { widgetDataNotFoundSelector } from "../../../selectors/widgets/common/widgetDataNotFoundSelector";
import { enqueueSnackbarErrorAlert } from "../../../../../components/pages/common/snackbar-alert/EnqueueSnackbarErrorAlert";

export const getCommitmentUtilizationWidgetSetupDataThunk = createAsyncThunk(
  "dashboards/getCommitmentUtilizationWidgetSetupData",
  async (_, { dispatch, getState }) => {
    const state = getState() as RootState;

    const { getUsersMeOrganisationsCurrentWidgetsCommitmentsUtilization } =
      cloudChiprApi.endpoints;

    const widgetNotFound = widgetDataNotFoundSelector(
      state,
      "",
      "commitments_utilization",
    );

    const payload = commitmentUtilizationWidgetSetupDataPayloadSelector(state);

    if (!payload) {
      return;
    }
    try {
      await dispatch(
        getUsersMeOrganisationsCurrentWidgetsCommitmentsUtilization.initiate(
          payload,
          {
            forceRefetch: true,
          },
        ),
      );
    } catch (e) {
      if (!widgetNotFound) {
        // @ts-expect-error todo: fix api spec;
        enqueueSnackbarErrorAlert(e?.data?.message);
      }
    }
  },
);
