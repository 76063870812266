import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../../../../../store";
import { cloudChiprApi } from "../../../../../../services/cloudchipr.api";
import { recommendationRestoreByResourceFixedCacheKey } from "../../../../utils/constants/fixedCacheKeys";
import { refetchDataAfterActionThunk } from "../../../refetchDataAfterActionThunk";
import { offHoursRecommendationsDismissedResourcesAccountIdsByResourceIdSelector } from "../../../../selectors/off-hours/dismissed-resources/offHoursRecommendationsDismissedResourcesAccountIdsByResourceIdSelector";
import { enqueueSnackbarErrorAlert } from "../../../../../../components/pages/common/snackbar-alert/EnqueueSnackbarErrorAlert";

export const restoreOffHoursRecommendationResourceThunk = createAsyncThunk(
  "recommendations/restoreOffHoursRecommendationResourceThunk",
  async (_: void, { dispatch, getState }) => {
    const state = getState() as RootState;

    const {
      patchUsersMeOrganisationsCurrentRecommendationsDailyOffHoursByResource,
      patchUsersMeOrganisationsCurrentRecommendationsWeeklyOffHoursByResource,
    } = cloudChiprApi.endpoints;

    const selectedIds =
      offHoursRecommendationsDismissedResourcesAccountIdsByResourceIdSelector(
        state,
      );

    if (!selectedIds) {
      return;
    }

    try {
      await Promise.all([
        await dispatch(
          patchUsersMeOrganisationsCurrentRecommendationsDailyOffHoursByResource.initiate(
            { restoreRecommendationByResourceRequest: selectedIds },
            { fixedCacheKey: recommendationRestoreByResourceFixedCacheKey },
          ),
        ),
        await dispatch(
          patchUsersMeOrganisationsCurrentRecommendationsWeeklyOffHoursByResource.initiate(
            { restoreRecommendationByResourceRequest: selectedIds },
            { fixedCacheKey: recommendationRestoreByResourceFixedCacheKey },
          ),
        ),
      ]);

      await dispatch(
        refetchDataAfterActionThunk({
          dbDaily: true,
          dbWeekly: true,
          dbDismissedResources: true,

          computeDaly: true,
          computeWeekly: true,
          computeDismissedResources: true,
        }),
      );
    } catch (e: any) {
      enqueueSnackbarErrorAlert(e?.data?.message);
    }
  },
);
