import { FC, useEffect } from "react";
import { List } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { useFlag } from "@unleash/proxy-client-react";
import { LiveUsageResourceNavItem } from "./components/LiveUsageResourceNavItem";
import { LiveUsageAllResourcesNavbarItem } from "./LiveUsageAllResourcesNavbarItem";
import { LiveUsageMgmtServicesCosts } from "./LiveUsageMgmtServicesCosts";
import { resourceTypeSearchParam } from "../../../../utils/constants/searchParams";
import { useAppSelector } from "../../../../../../../store/hooks";
import { resourceGroupsByProvider } from "../../../../../../../utils/constants/resources/resourceGroupsByProvider";
import { liveUsageMgmtProviderSelector } from "../../../../../../../store/live-usage-mgmt/selectors/store-selectors/liveUsageMgmtProviderSelector";
import {
  LiveUsageNavigationResourceType,
  useLiveUsageMgmtPageNavigate,
} from "../../utils/hooks/useLiveUsageMgmtPageNavigate.hook";
import { allResourcesResourceType } from "../../../../utils/constants/common";
import { getIsResourceTypeHiddenByFeatureToggle } from "../../../../utils/helpers/getIsResourceTypeHiddenByFeatureToggle";

interface LiveUsageSidebarNavigationProps {
  collapsed: boolean;
}

export const LiveUsageSidebarNavigation: FC<
  LiveUsageSidebarNavigationProps
> = ({ collapsed }) => {
  const [searchParams] = useSearchParams();

  const enableAllResourcesLiveUsageTab = useFlag(
    "EnableAllResourcesLiveUsageTab",
  );

  const provider = useAppSelector(liveUsageMgmtProviderSelector);
  const setResourceTypeInRouter = useLiveUsageMgmtPageNavigate();

  const searchParamsResourceType = searchParams.get(
    resourceTypeSearchParam,
  ) as LiveUsageNavigationResourceType;

  const resourceGroups = resourceGroupsByProvider.get(provider ?? "aws");

  const defaultResourceType = enableAllResourcesLiveUsageTab
    ? allResourcesResourceType
    : resourceGroups?.at(0)?.value;

  useEffect(() => {
    if (searchParamsResourceType || !defaultResourceType) {
      return;
    }

    setResourceTypeInRouter(defaultResourceType);
  }, [searchParamsResourceType, setResourceTypeInRouter, defaultResourceType]);

  if (!provider) {
    return null;
  }

  if (!searchParamsResourceType) {
    return null;
  }

  return (
    <List dense>
      {enableAllResourcesLiveUsageTab ? (
        <LiveUsageAllResourcesNavbarItem
          collapsed={collapsed}
          selected={searchParamsResourceType === allResourcesResourceType}
        />
      ) : (
        <LiveUsageMgmtServicesCosts />
      )}

      {resourceGroups?.map((rg) => {
        if (getIsResourceTypeHiddenByFeatureToggle(rg.value)) {
          return null;
        }

        return (
          <LiveUsageResourceNavItem
            key={rg.value}
            provider={provider}
            selectedResourceType={searchParamsResourceType}
            sidebarCollapsed={collapsed}
            {...rg}
          />
        );
      })}
    </List>
  );
};
