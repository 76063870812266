import { FC, memo, Ref, useCallback, useRef } from "react";
import {
  FormControl,
  InputLabel,
  Select,
  FormHelperText,
  ListItemIcon,
  ListItemText,
  MenuItem,
  PopoverActions,
} from "@mui/material";
import { ScheduleResourcesSelectListItem } from "./ScheduleResourcesSelectListItem";
import { resourceGroupsByProvider } from "../../../../../../../../../utils/constants/resources/resourceGroupsByProvider";
import {
  ProviderType,
  ResourceType,
  ResourceFiltersWithAction,
} from "../../../../../../../../../services/cloudchipr.api";
import { useAllResourcesVisibility } from "../../../../../../common/utils/hooks/useAllResourcesVisibility.hook";
import { getResourceTypeName } from "../../../../../../../../../utils/helpers/resources/getResourceTypeName";
import { isScheduleResourceTypeVisible } from "../../../../../../common/utils/helpers/isScheduleResourceTypeVisible";
import { AutomationType } from "../../../../../../../../../store/automations/utils/types/common";
import { IconByResourceType } from "../../../../../../../live-usage-mgmt/components/navigation/IconByResourceType";

interface ScheduleResourceSelectProps {
  value: ResourceType;
  provider: ProviderType;
  resources: ResourceFiltersWithAction[];
  accountIds: string[];
  onChange(rt: ResourceType): void;
  error?: string | boolean;
  disabled?: boolean;
  scheduleType: AutomationType;
}

export const ScheduleResourceSelect: FC<ScheduleResourceSelectProps> = memo(
  ({
    accountIds,
    value,
    onChange,
    error,
    disabled,
    provider,
    resources,
    scheduleType,
  }) => {
    const popoverActions = useRef<PopoverActions>();

    const resourcesVisibility = useAllResourcesVisibility({
      accountIds: accountIds.join(", "),
      provider,
    });

    const isResourceTypeVisibleHandler = useCallback(
      (resourceType: ResourceType) => {
        return isScheduleResourceTypeVisible({
          resourceType,
          resourcesVisibility,
          scheduleType,
        });
      },
      [resourcesVisibility, scheduleType],
    );

    const isResourceTypeDisabled = useCallback(
      (resourceType: ResourceType) => {
        return resources.some(({ filter }) => filter.type === resourceType);
      },
      [resources],
    );

    const renderValue = useCallback(
      (rt: any) => getResourceTypeName(rt as ResourceType, { type: "long" }),
      [],
    );

    const recalculateMenuPosition = useCallback(() => {
      popoverActions?.current?.updatePosition();
    }, []);

    return (
      <FormControl size="small" fullWidth>
        <InputLabel>Select Service*</InputLabel>
        <Select
          size="small"
          disabled={disabled}
          value={value}
          label="Select Service*"
          error={!!error}
          renderValue={renderValue}
          MenuProps={{ action: popoverActions as Ref<PopoverActions> }}
        >
          {resourceGroupsByProvider.get(provider)?.map((rg) => {
            if (
              rg.actionableResourceTypes.every(
                (item) => !isResourceTypeVisibleHandler(item),
              )
            ) {
              return null;
            }
            if (rg.actionableResourceTypes.length > 1) {
              const allNestedResourcesSelected =
                rg.actionableResourceTypes.every(
                  (rt) =>
                    !isResourceTypeVisibleHandler(rt) ||
                    resources.some(({ filter }) => filter.type === rt),
                );

              return (
                <ScheduleResourcesSelectListItem
                  key={rg.value}
                  resourcesGroup={rg}
                  onChange={onChange}
                  selectedValue={value}
                  isResourceTypeVisible={isResourceTypeVisibleHandler}
                  isResourceTypeDisabled={isResourceTypeDisabled}
                  recalculateMenuPosition={recalculateMenuPosition}
                  allNestedResourcesSelected={allNestedResourcesSelected}
                />
              );
            }

            const resourceType = rg.actionableResourceTypes.at(0);
            const selected = value === resourceType;

            if (!resourceType || !isResourceTypeVisibleHandler(resourceType)) {
              return null;
            }

            return (
              <MenuItem
                className={resourceType === value ? "Mui-selected" : ""}
                key={resourceType}
                disabled={
                  isResourceTypeDisabled(resourceType) && resourceType !== value
                }
                onClick={onChange.bind(null, resourceType)}
              >
                <ListItemIcon
                  sx={{
                    filter: selected ? "none" : "grayscale(1)",
                  }}
                >
                  <IconByResourceType resourceType={rg.value} />
                </ListItemIcon>

                <ListItemText>
                  {getResourceTypeName(resourceType, {
                    type: "long",
                  })}
                </ListItemText>
              </MenuItem>
            );
          })}
        </Select>

        {error && <FormHelperText error>{error}</FormHelperText>}
      </FormControl>
    );
  },
);
