import { FC, useCallback } from "react";
import { LiveUsageFiltersNavigationItem } from "./LiveUsageFiltersNavigationItem";
import { useAppSelector } from "../../../../../../../../store/hooks";
import { liveUsageMgmtAppliedFiltersByResourceTypeSelector } from "../../../../../../../../store/live-usage-mgmt/selectors/liveUsageMgmtAppliedFiltersByResourceTypeSelector";
import { getResourceTypeName } from "../../../../../../../../utils/helpers/resources/getResourceTypeName";
import { ResourceType } from "../../../../../../../../services/cloudchipr.api";

interface LiveUsageFiltersNavigationItemByResourceTypeProps {
  resourceType: ResourceType;
  resourceRef: HTMLElement | null;
  active: boolean;
  setActiveItem(rt: ResourceType): void;
}

export const LiveUsageFiltersNavigationItemByResourceType: FC<
  LiveUsageFiltersNavigationItemByResourceTypeProps
> = ({ resourceType, resourceRef, active, setActiveItem }) => {
  const filter = useAppSelector((state) =>
    liveUsageMgmtAppliedFiltersByResourceTypeSelector(state, resourceType),
  );

  const handleClick = useCallback(() => {
    resourceRef?.scrollIntoView({ behavior: "smooth" });
    setActiveItem(resourceType);
  }, [resourceRef, resourceType, setActiveItem]);

  return (
    <LiveUsageFiltersNavigationItem
      active={active}
      onClick={handleClick}
      title={getResourceTypeName(resourceType, { type: "long" })}
      filtersCount={filter?.filter_items?.length ?? 0}
    />
  );
};
