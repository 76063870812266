import moment from "moment/moment";

export const dueDateCellFilterFunction = (
  filterLocalDateAtMidnight: Date,
  cellValue: string,
) => {
  if (!cellValue) {
    return -1;
  }

  const current = moment(filterLocalDateAtMidnight);
  const next = moment(cellValue);

  if (current.isBefore(next)) {
    return -1;
  }

  if (current.isAfter(next)) {
    return 1;
  }

  return 0;
};
