import { FC, useCallback } from "react";
import { Button, Tooltip } from "@mui/material";
import AvTimerIcon from "@mui/icons-material/AvTimer";
import { useSearchParams } from "react-router-dom";
import { useAppAbility } from "../../../../../../services/permissions";
import { useAppDispatch, useAppSelector } from "../../../../../../store/hooks";
import { setWorkflowCreationDrawerOpen } from "../../../../../../store/schedules/schedulesSlice";
import { resourceTypeSearchParam } from "../../../utils/constants/searchParams";
import { useCreateWorkflowV2 } from "../../../utils/hooks/useCreateWorkflowV2.hook";
import { liveUsageMgmtAccountsWorkflowDataSelector } from "../../../../../../store/live-usage-mgmt/selectors/accounts-data/liveUsageMgmtAccountsWorkflowDataSelector";

export const CreateWorkflowButton: FC = () => {
  const dispatch = useAppDispatch();
  const { cannot } = useAppAbility();
  const canNotCreateSchedule = cannot("create", "schedule");

  const [searchParams] = useSearchParams();
  const resourceType = searchParams.get(resourceTypeSearchParam);

  const accountsWorkflowData = useAppSelector((state) =>
    liveUsageMgmtAccountsWorkflowDataSelector(state, resourceType),
  );

  const createWorkflow = useCreateWorkflowV2(accountsWorkflowData);

  const createHandler = useCallback(() => {
    createWorkflow();
    dispatch(setWorkflowCreationDrawerOpen(true));
  }, [dispatch, createWorkflow]);

  return (
    <Tooltip title="Create Workflow" arrow placement="top">
      <Button
        onClick={createHandler}
        variant="outlined"
        color="primary"
        disabled={canNotCreateSchedule}
        sx={buttonSx}
      >
        <AvTimerIcon
          fontSize="small"
          sx={{
            color: "primary.main",
          }}
        />
      </Button>
    </Tooltip>
  );
};
const buttonSx = {
  minWidth: 36,
  minHeight: 36,
  px: 0.5,
};
