import { createAsyncThunk } from "@reduxjs/toolkit";
import { enqueueSnackbar } from "notistack";
import { updateTaskEnvironmentPropertiesThunk } from "./updateTaskEnvironmentPropertiesThunk";
import { updateTaskActionPropertiesThunk } from "./updateTaskActionPropertiesThunk";
import { updateTaskStatusPropertiesThunk } from "./updateTaskStatusPropertiesThunk";
import { updateTaskPriorityPropertiesThunk } from "./updateTaskPriorityPropertiesThunk";
import { getTaskAvailablePropertiesThunk } from "./getTaskAvailablePropertiesThunk";
import { GetUsersMeOrganisationsCurrentTasksPropertiesApiResponse } from "../../../../services/cloudchipr.api";
import { enqueueSnackbarErrorAlert } from "../../../../components/pages/common/snackbar-alert/EnqueueSnackbarErrorAlert";

export const updateTaskPropertiesThunk = createAsyncThunk(
  "taskManagement/updateTaskProperties",
  async (
    fetchOnly:
      | (keyof GetUsersMeOrganisationsCurrentTasksPropertiesApiResponse)[]
      | void,
    { dispatch },
  ) => {
    const promises = [];

    if (needToFetch("environments", fetchOnly)) {
      promises.push(dispatch(updateTaskEnvironmentPropertiesThunk()).unwrap());
    }

    if (needToFetch("actions", fetchOnly)) {
      promises.push(dispatch(updateTaskActionPropertiesThunk()).unwrap());
    }

    if (needToFetch("statuses", fetchOnly)) {
      promises.push(dispatch(updateTaskStatusPropertiesThunk()).unwrap());
    }

    if (needToFetch("priorities", fetchOnly)) {
      promises.push(dispatch(updateTaskPriorityPropertiesThunk()).unwrap());
    }

    try {
      await Promise.all(promises);
      await dispatch(getTaskAvailablePropertiesThunk(true));

      enqueueSnackbar("Task properties successfully updated.", {
        variant: "snackbarAlert",
        AlertSnackBarProps: { severity: "success" },
      });
    } catch (e) {
      // @ts-expect-error todo: fix api spec;
      enqueueSnackbarErrorAlert(e?.data?.message);
    }
  },
);

const needToFetch = (
  type: keyof GetUsersMeOrganisationsCurrentTasksPropertiesApiResponse,
  fetchOnly:
    | (keyof GetUsersMeOrganisationsCurrentTasksPropertiesApiResponse)[]
    | void,
) => !fetchOnly?.length || fetchOnly?.includes(type);
