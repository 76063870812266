import { FC, Fragment, useCallback, MouseEvent } from "react";
import BarChartOutlinedIcon from "@mui/icons-material/BarChartOutlined";
import { IconButton, PopoverOrigin } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useLocation, useNavigate } from "react-router-dom";
import { useFlag } from "@unleash/proxy-client-react";
import { ResourceExplorerV2NavigationAddActionMenu } from "./components/ResourceExplorerV2NavigationAddActionMenu";
import { ResourceExplorerV2Visibility } from "./components/visibility-types/ResourceExplorerV2Visibility";
import { NavItem } from "../nav-item/NavItem";
import { useAppAbility } from "../../../../services/permissions";
import { useAppSelector } from "../../../../store/hooks";
import { navigationCollapsedSelector } from "../../../../store/common/selectors/navigation/navigationCollapsedSelector";
import { useMenuHook } from "../../../../utils/hooks/useMenu.hook";

export const ResourceExplorerV2Navigation: FC = () => {
  const navigate = useNavigate();

  const navigationCollapsed = useAppSelector(navigationCollapsedSelector);

  const { anchor, openMenu, closeMenu, open } = useMenuHook();

  const { can } = useAppAbility();
  const canCreateView = can("create", "resource-explorer");

  const enableHierarchySeparatePages = useFlag("EnableHierarchySeparatePages");

  const location = useLocation();
  const resourceExplorerSelected =
    location.pathname.startsWith("/resource-explorer");

  const handleTextClick = useCallback(
    (_: MouseEvent<HTMLDivElement>) => {
      navigate("/resource-explorer");
    },
    [navigate],
  );

  return (
    <Fragment>
      <NavItem
        primary="Billing Explorer"
        icon={BarChartOutlinedIcon}
        selected={resourceExplorerSelected}
        secondaryAction={
          <IconButton size="small" onClick={openMenu} disabled={!canCreateView}>
            <AddIcon fontSize="inherit" />
          </IconButton>
        }
        onTextClick={enableHierarchySeparatePages ? handleTextClick : undefined}
        navCollapsed={navigationCollapsed}
      >
        {[<ResourceExplorerV2Visibility key="resource-explorer-hierarchy" />]}
      </NavItem>

      <ResourceExplorerV2NavigationAddActionMenu
        open={open}
        onCloseMenu={closeMenu}
        anchorEl={anchor}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
      />
    </Fragment>
  );
};

const anchorOrigin: PopoverOrigin = {
  vertical: "top",
  horizontal: "right",
};

const transformOrigin: PopoverOrigin = {
  vertical: "top",
  horizontal: "right",
};
