import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  setTaskManagementDrawerOpen,
  setTaskManagementTask,
} from "../../taskManagementSlice";
import { taskAvailablePropertiesSelector } from "../../selectors/properties/taskAvailablePropertiesSelector";
import { RootState } from "../../../store";

export const taskManagementInitiateEmptyTaskThunk = createAsyncThunk(
  "taskManagement/taskManagementInitiateEmptyTask",
  async (_, { dispatch, getState }) => {
    const state = getState() as RootState;

    const properties = taskAvailablePropertiesSelector(state);

    const status_id = properties?.statuses?.at(0)?.id ?? "";
    const priority_id = properties?.priorities?.at(0)?.id ?? "";
    const action_id = properties?.actions?.at(0)?.id ?? "";

    dispatch(
      setTaskManagementTask({
        status_id,
        priority_id,
        action_id,
        environment_id: null,
        description: "",
        assignees: null,
        due_date: null,
      }),
    );

    dispatch(setTaskManagementDrawerOpen(true));
  },
);
