import { SvgIcon } from "@mui/material";

const DiskSvgIcon = () => {
  //TODO: add colors

  return (
    <SvgIcon>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M13.4754 11.3945V15.2112H3.40039V18.3362H16.6004V11.3945H13.4754Z"
          fill="#757575"
        />
        <path
          d="M6.52539 8.60964V4.79297H16.6004V1.66797H3.40039V8.60964H6.52539Z"
          fill="#BDBDBD"
        />
        <path
          d="M8.26706 6.52734V10.3523H3.40039V13.4773H11.7337V9.65234H16.6004V6.52734H8.26706Z"
          fill="#9E9E9E"
        />
      </svg>
    </SvgIcon>
  );
};

DiskSvgIcon.muiName = "DiskSvgIcon";

export default DiskSvgIcon;
