import { FC } from "react";
import Stack from "@mui/material/Stack";
import { useSearchParams } from "react-router-dom";
import { useAppSelector } from "../../../../../store/hooks";
import { resourceTypeSearchParam } from "../../utils/constants/searchParams";
import { liveUsageMgmtAccountIdsSelector } from "../../../../../store/live-usage-mgmt/selectors/store-selectors/liveUsageMgmtAccountIdsSelector";
import { liveUsageMgmtProviderSelector } from "../../../../../store/live-usage-mgmt/selectors/store-selectors/liveUsageMgmtProviderSelector";
import { LiveUsageMgmtResourceCard } from "../resource-card/LiveUsageMgmtResourceCard";
import { LiveUsageNavigationResourceType } from "../navigation/utils/hooks/useLiveUsageMgmtPageNavigate.hook";
import { LiveUsageAllResources } from "../resource-card/LiveUsageAllResources";
import { isStringResourceType } from "../../../../../utils/helpers/resources/isStringResourceType";
import { allResourcesResourceType } from "../../utils/constants/common";

export const LiveUsageResourcesArea: FC = () => {
  const [searchParams] = useSearchParams();
  const activeResourceType = searchParams.get(
    resourceTypeSearchParam,
  ) as LiveUsageNavigationResourceType;

  const accountIds = useAppSelector(liveUsageMgmtAccountIdsSelector);
  const provider = useAppSelector(liveUsageMgmtProviderSelector);

  if (!accountIds || !provider || !activeResourceType) {
    return null;
  }

  if (activeResourceType === allResourcesResourceType) {
    return <LiveUsageAllResources provider={provider} />;
  }

  if (!isStringResourceType(activeResourceType)) {
    return null;
  }

  return (
    <Stack
      direction="row"
      overflow="auto"
      height="100%"
      bgcolor="white"
      key={activeResourceType}
    >
      <LiveUsageMgmtResourceCard singleView resourceType={activeResourceType} />
    </Stack>
  );
};
