import { ListItemText, Stack } from "@mui/material";
import { GroupingOptionIcon } from "../GroupingOptionIcon";
import {
  resourceExplorerEKSGroupings,
  resourcesExplorerGroupingSingularLabels,
} from "../../../../resource-explorer/components/resource-explorer-card/utils/constants/groupings";
import { TypographyWithTooltip } from "../../../../../common/TypographyWithTooltip";
import { DropdownSelectOption } from "../../../../../common/select/dropdown-select/utils/types/types";
import EKSSvgIcon from "../../../../../../assets/images/logos/providers/EKSSvgIcon";

export const eksGroupingOptions: DropdownSelectOption = {
  value: "eks_groupings",
  disableSelection: true,
  label: (
    <Stack direction="row" spacing={1} alignItems="center">
      <EKSSvgIcon fontSize="small" />
      <ListItemText
        primary="EKS (SCAD)"
        primaryTypographyProps={{
          sx: { width: "fit-content" },
        }}
        sx={{ flexGrow: 0 }}
      />
    </Stack>
  ),
  rawValue: { search: "eks skad" },
  nestedOptions: resourceExplorerEKSGroupings.map((option) => ({
    value: option ?? "eks",
    rawValue: { search: `${option}eks skad` },
    label: (
      <Stack direction="row" spacing={1} alignItems="center" pl={2}>
        <GroupingOptionIcon grouping={option} />
        <TypographyWithTooltip
          variant="body2"
          title={resourcesExplorerGroupingSingularLabels.get(option) ?? option}
        />
      </Stack>
    ),
  })),
};
