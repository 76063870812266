import { FC, MouseEvent, ReactElement, useCallback } from "react";
import { Chip, IconButton } from "@mui/material";
import HorizontalRuleIcon from "@mui/icons-material/HorizontalRule";
import CloseIcon from "@mui/icons-material/Close";
import { FilterTriggerComponentProps } from "../../../../../../../common/select/dropdown-select/utils/types/filterTriggerComponentProps";
import { useAppSelector } from "../../../../../../../../store/hooks";
import { taskAvailablePropertiesSelector } from "../../../../../../../../store/task-management/selectors/properties/taskAvailablePropertiesSelector";
import { GetUsersMeOrganisationsCurrentTasksPropertiesApiResponse } from "../../../../../../../../services/cloudchipr.api";
import { useHover } from "../../../../../../../../utils/hooks/useHover.hook";
import { TaskPropertyTriggerWrapper } from "../TaskPropertyTriggerWrapper";

interface TaskPropertySelectionTriggerProps
  extends FilterTriggerComponentProps {
  optionAvatar?: ReactElement;
  value?: string | null;
  inCell?: boolean;
  optionsKey: keyof GetUsersMeOrganisationsCurrentTasksPropertiesApiResponse;
  removable: boolean;
}

export const TaskPropertySelectionTrigger: FC<
  TaskPropertySelectionTriggerProps
> = ({
  optionAvatar,
  optionsKey,
  removable,
  value,
  onClick,
  onCloseAndSubmit,
  disabled,
  inCell,
}) => {
  const { ref, hovered } = useHover();

  const data = useAppSelector(taskAvailablePropertiesSelector);
  const options = data?.[optionsKey];
  const selectedOption = options?.find((option) => option.id === value);

  const removeHandler = useCallback(
    (e: MouseEvent) => {
      e.stopPropagation();

      onCloseAndSubmit([]);
    },
    [onCloseAndSubmit],
  );

  return (
    <TaskPropertyTriggerWrapper
      inCell={inCell}
      refToPass={ref}
      hovered={hovered}
      onClick={onClick}
      disabled={disabled}
    >
      {selectedOption ? (
        <Chip
          size="small"
          variant="filled"
          avatar={optionAvatar}
          label={selectedOption.name}
          onClick={disabled ? undefined : onClick}
          sx={{
            mr: 0.5,
            color: "white",
            overflow: "hidden",
            bgcolor: selectedOption.color,
            "&:hover": { bgcolor: selectedOption.color },
          }}
        />
      ) : (
        <HorizontalRuleIcon color="action" fontSize="small" />
      )}

      {!disabled && removable && selectedOption && hovered && (
        <IconButton size="small" sx={{ p: 0.25 }} onClick={removeHandler}>
          <CloseIcon fontSize="small" />
        </IconButton>
      )}
    </TaskPropertyTriggerWrapper>
  );
};
