import { FC } from "react";
import { Stack, Tooltip } from "@mui/material";
import { PersonOffOutlined } from "@mui/icons-material";
import { CloudChiprCell } from "./CloudChiprCell";
import { TypographyWithTooltip } from "../TypographyWithTooltip";
import { formatDate } from "../../../utils/helpers/date-time/datetime-format";

interface ResourceExplorerCreatedByCellProps {
  name?: string;
  date?: string;
  byCloudchipr?: boolean;
  deleted?: boolean;
}

export const UserActivityCell: FC<ResourceExplorerCreatedByCellProps> = ({
  name,
  byCloudchipr,
  deleted,
  date,
}) => {
  if (byCloudchipr) {
    return <CloudChiprCell />;
  }

  if (!name) {
    return "-";
  }

  return (
    <Stack direction="row" alignItems="center" spacing={1}>
      {deleted && (
        <Tooltip arrow placement="top" title="Deleted user">
          {/* todo: Change this to be an avatar after adding user avatars */}
          <PersonOffOutlined fontSize="small" />
        </Tooltip>
      )}
      <Stack spacing={0.25}>
        <TypographyWithTooltip variant="body2" title={name} />
        {date && (
          <TypographyWithTooltip
            variant="caption"
            sx={{ fontSize: 10 }}
            color="text.secondary"
            title={`${formatDate(date, { type: "dateTime" })} (UTC)`}
          />
        )}
      </Stack>
    </Stack>
  );
};
