import { FC, memo, useCallback, useMemo } from "react";
import { Stack } from "@mui/material";
import { useFlag } from "@unleash/proxy-client-react";
import { useEffectOnceWhen } from "rooks";
import { useSearchParams } from "react-router-dom";
import moment from "moment";
import { Payload } from "recharts/types/component/DefaultTooltipContent";
import { ResourceExplorerChartBaseSelect } from "./components/ResourceExplorerChartBaseSelect";
import { ResourceExplorerChartBase } from "./utils/types";
import { useResourceExplorerPreferences } from "../../../../utils/hooks/useResourceExplorerPreferences.hook";
import { MultiTypeChart } from "../../../../../../../storybook/charts/multi-type-chart/MultiTypeChart";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../store/hooks";
import { resourceExplorerWidgetDataLoadingSelector } from "../../../../../../../store/resource-explorer/selectors/loading/resourceExplorerWidgetDataLoadingSelector";
import { moneyFormatter } from "../../../../../../../utils/numeral/moneyFormatter";
import { budgetDataOnChartSelector } from "../../../../../../../store/resource-explorer/selectors/budget/budgetDataOnChartSelector";
import { formatDate } from "../../../../../../../utils/helpers/date-time/datetime-format";
import { resourceExplorerDataPointSelector } from "../../../../../../../store/resource-explorer/selectors/current-resource-explorer/data/resourceExplorerDataPointSelector";
import { resourceExplorerShowBudgetLineSelector } from "../../../../../../../store/resource-explorer/selectors/current-resource-explorer/data/resourceExplorerShowBudgetLineSelector";
import { useMultiTypeChartContext } from "../../../../../../../storybook/charts/multi-type-chart/MultiTypeChartProvider";
import { urlToObject } from "../../../../../../utils/helpers/dataToUrlToDataConvertors";
import { resourceExplorerGroupingSelector } from "../../../../../../../store/resource-explorer/selectors/current-resource-explorer/data/resourceExplorerGroupingSelector";
import { ZoomChangeFunction } from "../../../../../../../storybook/charts/multi-type-chart/utils/hooks/useChartZoom.hook";
import { changeResourceExplorerDateThunk } from "../../../../../../../store/resource-explorer/thunks/resource-explorer/changeResourceExplorerDateThunk";
import {
  ChartDataType,
  ChartType,
} from "../../../../../../../storybook/charts/multi-type-chart/utils/types/types";
import { resourceExplorerPossibleChartTypes } from "../../../../../../../store/resource-explorer/utils/types/resourceExplorer";
import { ChartTypeSelect } from "../../../../../../../storybook/charts/multi-type-chart/components/common/ChartTypeSelect";
import { resourceExplorerCombinedChartDataSelector } from "../../../../../../../store/resource-explorer/selectors/current-resource-explorer/chart/resourceExplorerCombinedChartDataSelector";
import { money } from "../../../../../../../utils/numeral/money";
import { resourceExplorerChartBaseSelector } from "../../../../../../../store/resource-explorer/selectors/resourceExplorerChartBaseSelector";
import { setResourceExplorerChartBase } from "../../../../../../../store/resource-explorer/resourceExplorerSlice";
import { DataPointSelector } from "../../../app-bar/components/resource-explorer-toolbar/components/data-point-selector/DataPointSelector";

interface ResourceExplorerChartProps {
  viewId: string;
}

export const ResourceExplorerChart: FC<ResourceExplorerChartProps> = memo(
  ({ viewId }) => {
    const dispatch = useAppDispatch();
    const chartBase = useAppSelector(resourceExplorerChartBaseSelector);
    const budgetsEnabled = useFlag("EnableResourcesExplorerBudgets");
    const [searchParams] = useSearchParams();
    const combinedChartData = useAppSelector(
      resourceExplorerCombinedChartDataSelector,
    );

    const budgetLineData = useAppSelector(budgetDataOnChartSelector);
    const showBudgetLine = useAppSelector(
      resourceExplorerShowBudgetLineSelector,
    );
    const loading = useAppSelector(resourceExplorerWidgetDataLoadingSelector);
    const dataPoint = useAppSelector(resourceExplorerDataPointSelector);
    const grouping = useAppSelector(resourceExplorerGroupingSelector);

    const { changeChartPreferences, chartPreferences } =
      useResourceExplorerPreferences(viewId);
    const { chartType, zoomAvailable } = useMultiTypeChartContext();

    const referenceLines = useMemo(() => {
      return budgetLineData && showBudgetLine && budgetsEnabled
        ? budgetLineData
        : [];
    }, [budgetLineData, showBudgetLine, budgetsEnabled]);

    const yAxisFormatter = useCallback(
      (value: number | string) => {
        return chartBase === "percent"
          ? percentFormater(value)
          : moneyFormatter(+value);
      },
      [chartBase],
    );

    const chartZoomHandler = useCallback<ZoomChangeFunction>(
      (start, end) => {
        const dateTo = formatDate(moment(end), {
          type: "yearMonthDay",
        });
        const dateFrom = formatDate(moment(start), {
          type: "yearMonthDay",
        });

        if (!dateTo || !dateFrom) {
          return;
        }

        dispatch(
          changeResourceExplorerDateThunk({
            label: null,
            from: dateFrom,
            to: dateTo,
          }),
        );
      },
      [dispatch],
    );

    const path = searchParams.get("path") as string;
    const chartTypeFromPath = urlToObject(path)?.at(-1)?.chartType;

    const chartBaseChangeHandler = useCallback(
      (base: ResourceExplorerChartBase) => {
        dispatch(setResourceExplorerChartBase(base));
        changeChartPreferences("base", base);
      },
      [changeChartPreferences, dispatch],
    );

    const chartTypeChangeHandler = useCallback(
      (type: ChartType) => {
        changeChartPreferences("type", type);

        if (type !== "stack") {
          chartBaseChangeHandler("cost");
        }
      },
      [changeChartPreferences, chartBaseChangeHandler],
    );

    const tooltipValueFormatter = useCallback(
      (payload: Payload<any, any>) => {
        const dateKey = payload.dataKey;
        const identifier = payload.payload.recharts_chart_data_point_unique_key;
        const values = combinedChartData?.[identifier]?.values?.[dateKey ?? ""];

        return `${money(values?.cost)} (${values?.percent}%)`;
      },
      [combinedChartData],
    );

    const tooltipTotalCostFormatter = useCallback(
      (data?: ChartDataType) => {
        const identifier = data?.recharts_chart_data_point_unique_key;

        return money(combinedChartData?.[identifier ?? ""]?.totalCost);
      },
      [combinedChartData],
    );

    const isPathChartTypeCorrect = resourceExplorerPossibleChartTypes.includes(
      chartTypeFromPath ?? "",
    );

    useEffectOnceWhen(() => {
      chartType?.onChange(chartTypeFromPath);
    }, !!chartTypeFromPath && isPathChartTypeCorrect);

    useEffectOnceWhen(
      () => {
        chartType?.onChange(chartPreferences.type);
      },
      !!chartPreferences?.type &&
        (!chartTypeFromPath || !isPathChartTypeCorrect),
    );

    useEffectOnceWhen(() => {
      if (chartPreferences.base !== chartBase) {
        dispatch(setResourceExplorerChartBase(chartPreferences.base));
      }
    }, !!chartPreferences?.base);

    return (
      <Stack>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={1}
        >
          <Stack direction="row" alignItems="center" spacing={1}>
            {chartType.type === "stack" && (
              <ResourceExplorerChartBaseSelect
                base={chartBase}
                onChartBaseChange={chartBaseChangeHandler}
              />
            )}

            <DataPointSelector />
          </Stack>

          <ChartTypeSelect
            selectionType="select"
            size="xsmall"
            availableTypes={resourceExplorerPossibleChartTypes}
            onChartTypeChange={chartTypeChangeHandler}
          />
        </Stack>

        <MultiTypeChart
          zoom={zoomAvailable}
          highlight
          selectable
          loading={loading}
          showLoader={false}
          tooltipProps={{
            showTotal: grouping !== "none",
            showReferenceLinesInTooltip: true,
            tooltipLabelFormatter:
              dataPoint === "daily"
                ? (label: string) => formatDate(label, { type: "weekDayDate" })
                : undefined,
            tooltipValueFormatter,
            tooltipTotalCostFormatter,
          }}
          referenceLines={referenceLines}
          yAxisFormatter={yAxisFormatter}
          chartBase={chartBase}
          yAxisProps={
            chartBase === "percent" ? { domain: [0, 100] } : undefined
          }
          xAxisTickProps={budgetLineData ? xAxisTickProps : undefined}
          emptyText="No data by your selected date range and filters."
          onZoomChange={chartZoomHandler}
        />
      </Stack>
    );
  },
);

const xAxisTickProps = { parentPadding: { left: 50 } };
const percentFormater = (value: number | string) => `${(+value).toFixed(0)}%`;
