import { FC, useCallback, useState } from "react";
import { GridApi } from "ag-grid-community";
import { Box, Stack } from "@mui/material";
import { useEffectOnceWhen } from "rooks";
import { ReportsTableComponent } from "../../reports/components/table/components/ReportsTableComponent";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { getAllReportsLoadingSelector } from "../../../../store/reports/selector/getAllReportsLoadingSelector";
import { ReportsTableNoData } from "../../reports/components/table/components/ReportsTableNoData";
import { ReportsSearch } from "../../reports/components/table/components/toolbar/ReportsSearch";
import { ManageAllReportsButton } from "../../reports/components/table/components/toolbar/ManageAllReportsButton";
import { CreateReportButton } from "../../reports/components/CreateReportButton";
import { setReportsData } from "../../../../store/reports/reportsSlice";
import { reportsTableFilteredDataBySourceSelector } from "../../../../store/reports/selector/reportsTableFilteredDataBySourceSelector";

interface ResourceExplorerReportsDrawerContentProps {
  sourceId: string;
}

export const ReportsBySourceDrawerContent: FC<
  ResourceExplorerReportsDrawerContentProps
> = ({ sourceId }) => {
  const dispatch = useAppDispatch();
  const loading = useAppSelector(getAllReportsLoadingSelector);
  const reports = useAppSelector(reportsTableFilteredDataBySourceSelector);
  const [gridApi, setGridApi] = useState<GridApi>();

  const applyOnClick = useCallback(() => {
    dispatch(
      setReportsData({
        source_id: sourceId,
      }),
    );
  }, [sourceId, dispatch]);

  useEffectOnceWhen(() => {
    gridApi?.setColumnsVisible(["sourceName"], false);
  }, !!gridApi && !!reports?.length);

  if (!reports?.length && !loading) {
    return (
      <ReportsTableNoData
        manageAllButton
        applyOnCreateButtonClick={applyOnClick}
      />
    );
  }

  return (
    <Stack height="100%" p={2} spacing={2}>
      <Stack direction="row" justifyContent="space-between">
        <ReportsSearch gridApi={gridApi} size="small" />

        <Stack direction="row" spacing={1}>
          <ManageAllReportsButton />
          <CreateReportButton icon={false} applyOnClick={applyOnClick} />
        </Stack>
      </Stack>

      <Box border={1} borderColor="grey.300" borderRadius={1} overflow="hidden">
        <ReportsTableComponent
          domLayout="autoHeight"
          setGridApi={setGridApi}
          reports={reports}
        />
      </Box>
    </Stack>
  );
};
