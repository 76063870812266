import { resourcesNotIncludedInScheduleSelect } from "../constants/resourcesNotIncludedInScheduleSelect";
import { ResourceType } from "../../../../../../services/cloudchipr.api";
import { HookResourcesVisibilityReturnedDataType } from "../hooks/useAllResourcesVisibility.hook";
import { offHoursAvailableResourceTypes } from "../constants/common";
import { AutomationType } from "../../../../../../store/automations/utils/types/common";
import { getIsResourceTypeHiddenByFeatureToggle } from "../../../../live-usage-mgmt/utils/helpers/getIsResourceTypeHiddenByFeatureToggle";

export const isScheduleResourceTypeVisible = ({
  resourceType,
  resourcesVisibility,
  scheduleType,
}: {
  resourceType: ResourceType;
  resourcesVisibility: HookResourcesVisibilityReturnedDataType;
  scheduleType: AutomationType;
}) => {
  const isVisible =
    Object.values(resourcesVisibility).every((visibility) => {
      return visibility?.[resourceType]?.show;
    }) &&
    !getIsResourceTypeHiddenByFeatureToggle(resourceType) &&
    !resourcesNotIncludedInScheduleSelect.has(resourceType);

  return scheduleType === "offHours"
    ? isVisible && offHoursAvailableResourceTypes.includes(resourceType)
    : isVisible;
};
