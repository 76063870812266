import { resourceGroupsByProvider } from "../../constants/resources/resourceGroupsByProvider";
import { ProviderType, ResourceType } from "../../../services/cloudchipr.api";

export const resourceTypesByProvider = (
  provider: ProviderType,
): ResourceType[] => {
  const providersResourceGroups = resourceGroupsByProvider.get(provider);

  return (
    providersResourceGroups?.reduce((acc, resourceGroup) => {
      acc.push(...resourceGroup.resourceTypes);

      return acc;
    }, [] as ResourceType[]) ?? []
  );
};
