import { FC } from "react";
import { Stack, Table, TableBody, Typography } from "@mui/material";
import { TargetSingleResourceTableRow } from "./TargetSingleResourceTableRow";
import { TargetResourceTableIdentifierRow } from "../row-items/TargetResourceTableIdentifierRow";
import { TargetResourceTableStateRow } from "../row-items/TargetResourceTableStateRow";
import { useAppSelector } from "../../../../../../../../../../store/hooks";
import { taskManagementTargetResourcesSelector } from "../../../../../../../../../../store/task-management/selectors/form/target/resources/taskManagementTargetResourcesSelector";
import { TagCell } from "../../../../../../../../../common/data-grid-cells/tag-cell/TagCell";
import { money } from "../../../../../../../../../../utils/numeral/money";
import { resourceIdentifierLabels } from "../../../../../../../../../../utils/constants/resources/resourceTypeNames/resourceIdentifierLabels";
import { getResourceTypeName } from "../../../../../../../../../../utils/helpers/resources/getResourceTypeName";
import { CreatedByCell } from "../../../../../../../../live-usage-mgmt/components/resource-card/resource-card-content/resource-card-data/resource-card-grid/resouce-card-data-grid/cells/created-by-cell/CreatedByCell";
import { isTaskManagementDrawerEditModeSelector } from "../../../../../../../../../../store/task-management/selectors/form/options/isTaskManagementDrawerEditModeSelector";
import { AccountInfoData } from "../../../../../../../../../common/AccountInfoData";
import { TargetResourceTableRegionRow } from "../row-items/TargetResourceTableRegionRow";
import { ProviderIcon } from "../../../../../../../../../common/ProviderIcon";
import { getProviderName } from "../../../../../../../../../../utils/helpers/providers/getProviderName";

export const TargetSingleResource: FC = () => {
  const resources = useAppSelector(taskManagementTargetResourcesSelector);
  const targetResource = resources?.at(0);
  const edit = useAppSelector(isTaskManagementDrawerEditModeSelector);

  if (!targetResource) {
    return null;
  }

  return (
    <Table sx={{ tableLayout: "fixed" }}>
      <TableBody>
        <TargetSingleResourceTableRow
          highlight
          label={
            resourceIdentifierLabels.get(targetResource.resource_type) ??
            "Instance ID"
          }
        >
          <TargetResourceTableIdentifierRow
            cloudLink={edit}
            liveUsageLink={edit}
            resource={targetResource}
          />
        </TargetSingleResourceTableRow>

        <TargetSingleResourceTableRow label="Resource Type">
          {getResourceTypeName(targetResource.resource_type, {
            singular: true,
            type: "long",
          })}
        </TargetSingleResourceTableRow>

        <TargetSingleResourceTableRow label="Cloud Provider" highlight>
          <Stack direction="row" spacing={0.5}>
            <ProviderIcon provider={targetResource.account.provider} />
            <Typography variant="body2">
              {getProviderName(targetResource.account.provider, {
                title: true,
              })}
            </Typography>
          </Stack>
        </TargetSingleResourceTableRow>

        <TargetSingleResourceTableRow label="Account">
          <AccountInfoData
            nameIdCopiable
            nameTypographyProps={{ variant: "body2" }}
            idTypographyProps={{ variant: "tiny" }}
            accountId={targetResource.account.provider_account_id}
            accountName={targetResource.account.provider_account_name}
          />
        </TargetSingleResourceTableRow>

        <TargetSingleResourceTableRow label="Monthly Price" highlight>
          <Typography variant="body2">
            {money(targetResource.price_per_month)}
          </Typography>
        </TargetSingleResourceTableRow>

        <TargetSingleResourceTableRow label="Region">
          <TargetResourceTableRegionRow region={targetResource.region} />
        </TargetSingleResourceTableRow>

        <TargetSingleResourceTableRow highlight label="Created By">
          <CreatedByCell data={targetResource.creation_data} copy={false} />
        </TargetSingleResourceTableRow>

        <TargetSingleResourceTableRow label="Resource State">
          <TargetResourceTableStateRow state={targetResource.state} />
        </TargetSingleResourceTableRow>

        <TargetSingleResourceTableRow highlight label="Tags">
          {targetResource.tags?.length ? (
            <TagCell tags={targetResource.tags} />
          ) : (
            "-"
          )}
        </TargetSingleResourceTableRow>
      </TableBody>
    </Table>
  );
};
