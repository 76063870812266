import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  cloudChiprApi,
  RightSizingRecommendation,
} from "../../../../../services/cloudchipr.api";
import { RootState } from "../../../../store";
import { recommendationDismissByResourceFixedCacheKey } from "../../../utils/constants/fixedCacheKeys";
import { rightsizingRecommendationsDataSelector } from "../../../selectors/rightsizing/data/rightsizingRecommendationsDataSelector";
import { refetchDataAfterActionThunk } from "../../refetchDataAfterActionThunk";
import { enqueueSnackbarErrorAlert } from "../../../../../components/pages/common/snackbar-alert/EnqueueSnackbarErrorAlert";

export const dismissRightsizingRecommendationResourceThunk = createAsyncThunk(
  "recommendations/dismissRightsizingRecommendationResourceThunk",
  async (recommendationId: string, { dispatch, getState }) => {
    const state = getState() as RootState;

    const { patchUsersMeResourcesRightSizingRecommendations } =
      cloudChiprApi.endpoints;

    const recommendations = rightsizingRecommendationsDataSelector(state);

    const recommendation = recommendations?.find(
      ({ id }) => id === recommendationId,
    ) as RightSizingRecommendation;

    if (!recommendation) {
      return;
    }

    try {
      await dispatch(
        patchUsersMeResourcesRightSizingRecommendations.initiate(
          {
            body: {
              resource_identifier: recommendation.resource_identifier,
              account_id: recommendation.account.id,
            },
          },
          {
            fixedCacheKey: recommendationDismissByResourceFixedCacheKey,
          },
        ),
      ).unwrap();

      await dispatch(
        refetchDataAfterActionThunk({
          rightsizing: true,
          rightsizingDismissedResources: true,
        }),
      );
    } catch (e: any) {
      enqueueSnackbarErrorAlert(e?.data?.message);
    }
  },
);
