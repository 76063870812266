import { createAsyncThunk } from "@reduxjs/toolkit";
import { cloudChiprApi } from "../../../../services/cloudchipr.api";
import { setCurrentDashboardId } from "../../dashboardsSlice";
import { enqueueSnackbarErrorAlert } from "../../../../components/pages/common/snackbar-alert/EnqueueSnackbarErrorAlert";

export const getDashboardByIdThunk = createAsyncThunk(
  "dashboards/getDashboardById",
  async (dashboardId: string, { dispatch }) => {
    const { getUsersMeOrganisationsCurrentDashboardsByDashboardId } =
      cloudChiprApi.endpoints;

    try {
      const response = await dispatch(
        getUsersMeOrganisationsCurrentDashboardsByDashboardId.initiate(
          { dashboardId },
          { forceRefetch: true },
        ),
      ).unwrap();

      dispatch(setCurrentDashboardId(response.id));
    } catch (e) {
      // @ts-expect-error todo: fix api spec;
      enqueueSnackbarErrorAlert(e?.data?.message);
    }
  },
);
