import { FC, useCallback, useRef, useState } from "react";
import { ImperativePanelHandle, Panel } from "react-resizable-panels";
import { Box, Button, IconButton } from "@mui/material";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import { useOutletContext } from "react-router-dom";
import { LiveUsageSidebarNavigation } from "./navigation/components/sidebar-navigation/LiveUsageSidebarNavigation";
import { OutletContextType } from "../../../layout/MainLayout";
import { useLayoutConfigHook } from "../../../navigation/utils/hooks/useLayoutConfigHook";

export const LiveUsageSidebarPanel: FC = () => {
  const sideBarRef = useRef<ImperativePanelHandle>(null);

  const [collapsed, setCollapsed] = useState<boolean>();

  const handleCollapseToggle = useCallback(() => {
    if (sideBarRef.current?.isExpanded()) {
      sideBarRef.current?.collapse();
    } else {
      sideBarRef.current?.expand();
    }
  }, []);

  const handleSideBarCollapse = useCallback(() => {
    setCollapsed(true);
  }, []);

  const handleSideBarExpand = useCallback(() => {
    setCollapsed(false);
  }, []);

  const { layoutSizes } = useOutletContext<OutletContextType>();

  const { min, max, collapsedSize } = useLayoutConfigHook(
    52,
    220,
    320,
    layoutSizes?.[1],
  );

  return (
    <Panel
      collapsible
      minSize={min}
      maxSize={max}
      collapsedSize={collapsedSize}
      ref={sideBarRef}
      onCollapse={handleSideBarCollapse}
      onExpand={handleSideBarExpand}
      style={{ overflowY: "auto" }}
    >
      {collapsed !== undefined && (
        <Box bgcolor="white" height="100%">
          {collapsed ? (
            <IconButton
              color="primary"
              size="small"
              onClick={handleCollapseToggle}
              sx={{ borderRadius: 2, m: 1 }}
            >
              <MenuOpenIcon sx={{ transform: "rotate(180deg)" }} />
            </IconButton>
          ) : (
            <Button
              variant="text"
              startIcon={<MenuOpenIcon />}
              onClick={handleCollapseToggle}
              sx={{ m: 1, textTransform: "initial" }}
            >
              Collapse Services
            </Button>
          )}

          <LiveUsageSidebarNavigation collapsed={collapsed} />
        </Box>
      )}
    </Panel>
  );
};
