import { FC } from "react";
import { CustomCellRendererProps } from "ag-grid-react";
import { Stack } from "@mui/material";
import { Role } from "../../../../../../../services/cloudchipr.api";
import c8rLogo from "../../../../../../../assets/images/logos/c8r-logo.svg";
import { TypographyWithTooltip } from "../../../../../../common/TypographyWithTooltip";
import { isCreatedByCloudchipr } from "../../../../../../../utils/helpers/isCreatedByCloudchipr";

export const RolesGridNameCell: FC<CustomCellRendererProps<Role>> = ({
  data: role,
}) => {
  if (!role) {
    return null;
  }

  return (
    <Stack direction="row" spacing={1} overflow="hidden">
      {isCreatedByCloudchipr(role.created_by.name) && (
        <img src={c8rLogo} alt="C8R-logo" style={{ width: 20 }} />
      )}
      <Stack overflow="hidden">
        <TypographyWithTooltip title={role.name.trim()} variant="body2" />

        <TypographyWithTooltip
          variant="caption"
          color="text.secondary"
          title={role.description}
        />
      </Stack>
    </Stack>
  );
};
