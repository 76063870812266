import { FC, useMemo } from "react";
import { Box } from "@mui/material";
import { useAppSelector } from "../../../../../../../../../../../store/hooks";
import { commitmentUtilizationWidgetSetupDataSelector } from "../../../../../../../../../../../store/dashboards/selectors/widgets/commitment-utilization/setup/commitmentUtilizationWidgetSetupDataSelector";
import { getCommitmentUtilizationTableViewColumnDefs } from "../../../utils/helpers/getCommitmentUtilizationTableViewColumnDefs";
import { commitmentUtilizationSetupPropertyByKeySelector } from "../../../../../../../../../../../store/dashboards/selectors/setups/commitment-utilization/commitmentUtilizationSetupPropertyByKeySelector";
import { AgDataGrid } from "../../../../../../../../../common/ag-grid/AgDataGrid";

export const CommitmentUtilizationWidgetSetupTableView: FC = () => {
  const data = useAppSelector(commitmentUtilizationWidgetSetupDataSelector);
  const { groups } = data ?? {};
  const visualization = useAppSelector(
    commitmentUtilizationSetupPropertyByKeySelector("visualization"),
  );
  const columnDefs = useMemo(
    () => getCommitmentUtilizationTableViewColumnDefs(visualization),
    [visualization],
  );

  return (
    <Box height="100%" p={2}>
      <AgDataGrid
        rowHeight={40}
        headerHeight={44}
        rowData={groups ?? []}
        columnDefs={columnDefs}
      />
    </Box>
  );
};
