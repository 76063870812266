import { createAsyncThunk } from "@reduxjs/toolkit";
import { closeSnackbar, enqueueSnackbar, SnackbarKey } from "notistack";
import { cloudChiprApi } from "../../../../services/cloudchipr.api";
import { getResourceExplorerVisibilityHierarchyThunk } from "../resource-explorer-hierarchy-visibility/getResourceExplorerVisibilityHierarchyThunk";
import { enqueueSnackbarErrorAlert } from "../../../../components/pages/common/snackbar-alert/EnqueueSnackbarErrorAlert";

export const updateResourceExplorerFolderNameThunkFixedCacheKey =
  "updateResourceExplorerFolderName-fixedCacheKey";

interface UpdateResourceExplorerFolderNameThunkArg {
  id: string;
  name: string;
}
export const updateResourceExplorerFolderNameThunk = createAsyncThunk(
  "resourceExplorer/updateResourceExplorerFolderName",
  async (
    { name, id }: UpdateResourceExplorerFolderNameThunkArg,
    { dispatch },
  ) => {
    const {
      patchV2UsersMeOrganisationsCurrentResourceExplorerFoldersByResourceExplorerFolderId,
    } = cloudChiprApi.endpoints;

    try {
      await dispatch(
        patchV2UsersMeOrganisationsCurrentResourceExplorerFoldersByResourceExplorerFolderId.initiate(
          {
            resourceExplorerFolderId: id,
            body: { name },
          },
          {
            fixedCacheKey: updateResourceExplorerFolderNameThunkFixedCacheKey,
          },
        ),
      ).unwrap();
      dispatch(getResourceExplorerVisibilityHierarchyThunk());

      const snackbarId: SnackbarKey = enqueueSnackbar(
        "Folder name successfully updated.",
        {
          variant: "snackbarAlert",
          AlertSnackBarProps: {
            severity: "success",
            onClose: () => closeSnackbar(snackbarId),
          },
        },
      );
    } catch (e) {
      // @ts-expect-error todo: fix api spec;
      enqueueSnackbarErrorAlert(e?.data?.message);
    }
  },
);
