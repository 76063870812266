import { FC } from "react";
import { Button, Stack, Typography } from "@mui/material";
import { PlayCircleOutlineOutlined } from "@mui/icons-material";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { Link } from "react-router-dom";
import EmptyTasks from "../../../../assets/images/empty_tasks.png";
import { useAppSelector } from "../../../../store/hooks";
import { liveUsageFirstOrgAllAccountsPathSelector } from "../../../../store/accounts/selectors/all-providers/liveUsageFirstOrgAllAccountsPathSelector";

export const TasksEmptyPage: FC = () => {
  const path = useAppSelector(liveUsageFirstOrgAllAccountsPathSelector);

  return (
    <Stack alignItems="center" pt={10} spacing={4}>
      <img src={EmptyTasks} alt="Empty Tasks" width={416} />

      <Stack alignItems="center" spacing={2}>
        <Typography variant="h6" fontWeight="bold">
          There aren’t any tasks created yet.
        </Typography>

        <Stack alignItems="center" spacing={1}>
          <Stack direction="row" spacing={0.5} alignItems="center">
            <Typography variant="body1" textAlign="center">
              To get started, go to
            </Typography>
            <PlayCircleOutlineOutlined fontSize="small" />
            <Typography variant="body1" textAlign="center">
              <Typography
                variant="inherit"
                fontWeight="bold"
                component="span"
                color="text.secondary"
              >
                Live Usage & Management
              </Typography>
              , select resource(s), and create task.
            </Typography>
          </Stack>
          <Typography variant="body2" color="text.secondary" textAlign="center">
            💡You can create tasks directly from Live Resources, assign owners,
            set priorities, and track progress,
            <br /> ensuring that no saving opportunities or resources are
            overlooked. 🔍
          </Typography>
        </Stack>

        <Button
          variant="contained"
          endIcon={<OpenInNewIcon />}
          component={Link}
          target="_blank"
          to={path}
          sx={{ textTransform: "none" }}
        >
          Go To Live Usage & Management
        </Button>
      </Stack>
    </Stack>
  );
};
