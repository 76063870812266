import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../../../store";
import { cloudChiprApi } from "../../../../services/cloudchipr.api";
import { commitmentsDataGridPayloadDataSelector } from "../../selectors/common/payload/commitmentsDataGridPayloadDataSelector";
import { awsBillingStatusNotAvailableSelector } from "../../selectors/organisations/awsBillingStatusNotAvailableSelector";
import { enqueueSnackbarErrorAlert } from "../../../../components/pages/common/snackbar-alert/EnqueueSnackbarErrorAlert";

export const getCommitmentsOverviewDataThunk = createAsyncThunk(
  "commitments/getCommitmentsOverviewForDataGrid",
  async (_: void, { dispatch, getState }) => {
    const state = getState() as RootState;

    const { getUsersMeOrganisationsCurrentCommitments } =
      cloudChiprApi.endpoints;

    const payload = commitmentsDataGridPayloadDataSelector(state);

    const billingStatusNotAvailable =
      awsBillingStatusNotAvailableSelector(state);

    try {
      await dispatch(
        getUsersMeOrganisationsCurrentCommitments.initiate(payload),
      ).unwrap();
    } catch (e) {
      if (billingStatusNotAvailable) {
        return;
      }

      // @ts-expect-error todo: fix api spec;
      enqueueSnackbarErrorAlert(e?.data?.message);

      return null;
    }
  },
);
