import { FC, Fragment, useCallback, useEffect, useState } from "react";
import { WorkflowsListToolbar } from "./grid-options/WorkflowsListToolbar";
import { WorkflowsDataGridComponent } from "./WorkflowsDataGridComponent";
import { useAppSelector } from "../../../../../../store/hooks";
import { workflowsFilteredDataSelector } from "../../../../../../store/automations/selectros/workflow/list-data/workflowsFilteredDataSelector";
import { Schedule } from "../../../../../../services/cloudchipr.api";
import { workflowsExistSelector } from "../../../../../../store/automations/selectros/workflow/list-data/workflowsExistSelector";
import type { FilterChangedEvent, GridApi } from "ag-grid-community";

export const WorkflowsDataGrid: FC = () => {
  const data = useAppSelector(workflowsFilteredDataSelector);
  const exist = useAppSelector(workflowsExistSelector);

  const [displayedDataCount, setDisplayedDataCount] = useState(0);
  const [gridApi, setGridApi] = useState<GridApi | null>(null);

  const handleNoRowsOverlayVisibility = useCallback(
    (gridApi: GridApi | null) => {
      if (!gridApi) {
        return;
      }

      if (!gridApi.getDisplayedRowCount()) {
        gridApi?.showNoRowsOverlay();
      } else {
        gridApi?.hideOverlay();
      }
    },
    [],
  );

  const filterChangeHandler = useCallback(
    (event: FilterChangedEvent<Schedule>) => {
      handleNoRowsOverlayVisibility(event.api);
      const count = event.api?.getDisplayedRowCount();
      setDisplayedDataCount(count ?? 0);
    },
    [handleNoRowsOverlayVisibility],
  );

  useEffect(() => {
    handleNoRowsOverlayVisibility(gridApi);
    setDisplayedDataCount(gridApi?.getDisplayedRowCount() ?? 0);
  }, [data?.length, gridApi, handleNoRowsOverlayVisibility]);

  if (!exist) {
    return;
  }

  return (
    <Fragment>
      <WorkflowsListToolbar dataCount={displayedDataCount} gridApi={gridApi} />

      <WorkflowsDataGridComponent
        data={data}
        setGridApi={setGridApi}
        onFilterChanged={filterChangeHandler}
      />
    </Fragment>
  );
};
