import { FC, Fragment } from "react";
import { IconButton } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { ResourceExplorerActionsMenu } from "./ResourceExplorerActionsMenu";
import { useMenuHook } from "../../../../../../../utils/hooks/useMenu.hook";
import { useAppAbility } from "../../../../../../../services/permissions";
import { useAppSelector } from "../../../../../../../store/hooks";
import { NavigationItemsVisibilityType } from "../../../../../../../store/navigation/utils/types";
import { resourceExplorerHierarchyVisibilityIsDefaultSelector } from "../../../../../../../store/resource-explorer/selectors/views-hierarchy-visibility/resourceExplorerHierarchyVisibilityIsDefaultSelector";
import {
  EmbeddedUser,
  ProtectionDetails,
} from "../../../../../../../services/cloudchipr.api";

interface ResourceExplorerViewActionButtonProps {
  id: string;
  name: string;
  folderId?: string;
  size?: "small" | "medium" | "large";
  visibility: NavigationItemsVisibilityType;
  protectedBy?: EmbeddedUser;
  protectionDetails?: ProtectionDetails;
}

export const ResourceExplorerActionButton: FC<
  ResourceExplorerViewActionButtonProps
> = ({
  id,
  name,
  folderId,
  visibility,
  size,
  protectionDetails,
  protectedBy,
}) => {
  const { cannot } = useAppAbility();
  const cannotDeleteResourceExplorer = cannot("delete", "resource-explorer");
  const isVisibilityItemDefault = useAppSelector((state) =>
    resourceExplorerHierarchyVisibilityIsDefaultSelector(state, id),
  );

  const { anchor, open, openMenu, closeMenu } = useMenuHook();

  if (cannotDeleteResourceExplorer || isVisibilityItemDefault) {
    return null;
  }

  return (
    <Fragment>
      <IconButton
        size="small"
        onClick={openMenu}
        sx={open ? openedStyles : undefined}
      >
        <MoreVertIcon fontSize={size ?? "inherit"} />
      </IconButton>

      <ResourceExplorerActionsMenu
        id={id}
        name={name}
        open={open}
        anchor={anchor}
        closeMenu={closeMenu}
        folderId={folderId}
        visibility={visibility}
        protectedBy={protectedBy}
        protectionDetails={protectionDetails}
      />
    </Fragment>
  );
};

//TODO: add to all NavItemExpanded secondary actions
const openedStyles = {
  visibility: "visible !important",
  bgcolor: "grey.200",
};
