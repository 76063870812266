import { FC, useCallback, useState } from "react";
import { Stack } from "@mui/material";
import { GridApi } from "ag-grid-community";
import { SavingsOpportunitiesTableCsvDownloader } from "./SavingsOpportunitiesTableCSVDownloader";
import { GlobalFilter } from "../../../../../storybook/data-grid/toolbar/GlobalFilter";
import { AGGridColumnsToolPanel } from "../../../common/ag-grid/components/AGGridColumnsToolPanel";

interface SavingsOpportunitiesTableToolbarProps {
  gridApi: GridApi | null;
}

export const SavingsOpportunitiesTableToolbar: FC<
  SavingsOpportunitiesTableToolbarProps
> = ({ gridApi }) => {
  const [searchValue, setSearchValue] = useState("");

  const searchChangeHandler = useCallback(
    (keyword: string) => {
      gridApi?.setGridOption("quickFilterText", keyword);
      setSearchValue(keyword);
    },
    [gridApi],
  );

  return (
    <Stack
      p={2}
      pt={1}
      top={0}
      spacing={2}
      bgcolor="white"
      zIndex="appBar"
      borderBottom={1}
      position="sticky"
      borderColor="grey.300"
    >
      <Stack
        direction="row"
        justifyContent="end"
        alignItems="center"
        spacing={2}
      >
        <GlobalFilter
          globalFilter={searchValue}
          setGlobalFilter={searchChangeHandler}
          size="xsmall"
        />

        <SavingsOpportunitiesTableCsvDownloader />

        <AGGridColumnsToolPanel gridApi={gridApi} />
      </Stack>
    </Stack>
  );
};
