import { createAsyncThunk } from "@reduxjs/toolkit";
import { cloudChiprApi } from "../../../../services/cloudchipr.api";
import { enqueueSnackbarErrorAlert } from "../../../../components/pages/common/snackbar-alert/EnqueueSnackbarErrorAlert";

export const getDimensionsThunk = createAsyncThunk(
  "dimensions/getDimensions",
  async (forceRefetch: boolean | void, { dispatch }) => {
    const { getUsersMeOrganisationsCurrentDimensions } =
      cloudChiprApi.endpoints;

    try {
      return await dispatch(
        getUsersMeOrganisationsCurrentDimensions.initiate(undefined, {
          forceRefetch: !!forceRefetch,
        }),
      ).unwrap();
    } catch (e) {
      // @ts-expect-error todo: fix api spec;
      enqueueSnackbarErrorAlert(e?.data?.message);
    }
  },
);
