import { FC, RefObject, useCallback, useMemo } from "react";
import { AgGridReact } from "ag-grid-react";
import { getRolesDataGridColumns } from "./utils/getRolesDataGridColumns";
import { getOrgCurrentRolesThunk } from "../../../../../../store/user/thunks/roles/getOrgCurrentRolesThunk";
import { useAppDispatch, useAppSelector } from "../../../../../../store/hooks";
import { userOrgCurrentRolesSelector } from "../../../../../../store/user/selectors/roles/userOrgCurrentRolesSelector";
import { AgDataGrid } from "../../../../common/ag-grid/AgDataGrid";

interface RolesDataGridProps {
  refToPassGrid: RefObject<AgGridReact>;
}

export const RolesDataGrid: FC<RolesDataGridProps> = ({ refToPassGrid }) => {
  const dispatch = useAppDispatch();

  const data = useAppSelector(userOrgCurrentRolesSelector);
  const gridReadyHandler = useCallback(async () => {
    dispatch(getOrgCurrentRolesThunk());
  }, [dispatch]);

  const columns = useMemo(() => {
    return getRolesDataGridColumns(refToPassGrid);
  }, [refToPassGrid]);

  return (
    <AgDataGrid
      ref={refToPassGrid}
      rowHeight={58}
      headerHeight={56}
      rowData={data}
      columnDefs={columns}
      onGridReady={gridReadyHandler}
    />
  );
};
