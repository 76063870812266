import { FC } from "react";
import { CustomCellRendererProps } from "ag-grid-react";
import { Typography } from "@mui/material";
import { SavingsOpportunity } from "../../../../../services/cloudchipr.api";

export const SavingsOpportunityBasedOnPastCellRenderer: FC<
  CustomCellRendererProps<SavingsOpportunity>
> = (cell) => {
  const data = cell.data as SavingsOpportunity;

  if (!data?.based_on_past) {
    return "-";
  }

  return <Typography variant="body2">{data.based_on_past} days</Typography>;
};
