import { FC, useCallback } from "react";
import { Stack, Typography } from "@mui/material";
import InsertChartOutlinedOutlinedIcon from "@mui/icons-material/InsertChartOutlinedOutlined";
import LaunchIcon from "@mui/icons-material/Launch";

export interface SourceDataChipProps {
  title: string;
  url: string;
}

export const SourceDataChip: FC<SourceDataChipProps> = ({ url, title }) => {
  const clickHandler = useCallback(() => window.open(url, "_blank"), [url]);

  if (!title) {
    return null;
  }

  return (
    <Stack
      px={0.5}
      spacing={0.5}
      direction="row"
      borderRadius={1}
      onClick={clickHandler}
      alignItems="center"
      whiteSpace="nowrap"
      sx={{ cursor: "pointer" }}
      bgcolor={(theme) => theme.palette.primary.light}
    >
      <InsertChartOutlinedOutlinedIcon sx={{ fontSize: 16 }} color="primary" />

      <Typography
        variant="body2"
        color="primary"
        sx={{ textDecoration: "underline" }}
      >
        {title}
      </Typography>

      <LaunchIcon sx={{ fontSize: 16, ml: 1 }} color="primary" />
    </Stack>
  );
};
