import { FC } from "react";
import { Stack } from "@mui/material";
import { TaskCreationPropertiesSection } from "./properties-section/TaskCreationPropertiesSection";
import { TaskCreationDescription } from "./TaskCreationDescription";
import { TaskCreationTargetSection } from "./target-section/TaskCreationTargetSection";
import { TaskCreationMultiTaskingAlertMessage } from "./TaskCreationMultiTaskingAlertMessage";
import { TaskCreationName } from "./TaskCreationName";
import { TaskCreationDuplicatedResourcesAlertMessage } from "./TaskCreationDuplicatedResourcesAlertMessage";

export const TaskCreationDrawerContent: FC = () => {
  return (
    <Stack p={2} spacing={3} overflow="auto" height="100%">
      <TaskCreationName />

      <TaskCreationMultiTaskingAlertMessage />

      <TaskCreationDuplicatedResourcesAlertMessage />

      <TaskCreationPropertiesSection />

      <TaskCreationDescription />

      <TaskCreationTargetSection />
    </Stack>
  );
};
