import { FC } from "react";
import { useAppSelector } from "../../../../../../../../../store/hooks";
import { costAndUsageSumSetupPropertyByKeySelector } from "../../../../../../../../../store/dashboards/selectors/setups/cost-and-usage/costAndUsageSumSetupPropertyByKeySelector";
import { widgetViewNameByViewIdIdSelector } from "../../../../../../../../../store/dashboards/selectors/widgets/common/widgetViewNameByViewIdIdSelector";
import { objectToUrl } from "../../../../../../../../utils/helpers/dataToUrlToDataConvertors";
import { WidgetSetupSubHeader } from "../../common/WidgetSetupSubHeader";

export const CostAndUsageSumWidgetSetupHeader: FC = () => {
  const name = useAppSelector(
    costAndUsageSumSetupPropertyByKeySelector("name"),
  );
  const dates = useAppSelector(
    costAndUsageSumSetupPropertyByKeySelector("dates"),
  );
  const viewId = useAppSelector(
    costAndUsageSumSetupPropertyByKeySelector("viewId"),
  );

  const viewName = useAppSelector((state) =>
    widgetViewNameByViewIdIdSelector(state, viewId),
  );

  const path = objectToUrl([{ dates }]);

  return (
    <WidgetSetupSubHeader
      dates={dates}
      name={name}
      title={viewName ?? ""}
      url={`/resource-explorer/${viewId}?path=${path}`}
    />
  );
};
