import { createAsyncThunk } from "@reduxjs/toolkit";
import { enqueueSnackbar } from "notistack";
import { RootState } from "../../store";
import { reportsDataSelector } from "../selector/reportsDataSelector";
import { cloudChiprApi } from "../../../services/cloudchipr.api";
import { editReportFixedCacheKey } from "../utils/constants/fixedCacheKeys";
import { formatNotificationsFromHtmlToSlack } from "../../../components/utils/helpers/formatNotificationsFromHtmlToSlack";
import { enqueueSnackbarErrorAlert } from "../../../components/pages/common/snackbar-alert/EnqueueSnackbarErrorAlert";

export const editReportThunk = createAsyncThunk(
  "reports/editReport",
  async (_, { dispatch, getState }) => {
    const state = getState() as RootState;
    const report = reportsDataSelector(state);

    const { putUsersMeOrganisationsCurrentReportsByReportId } =
      cloudChiprApi.endpoints;

    if (!report.id) {
      return;
    }

    try {
      const response = await dispatch(
        putUsersMeOrganisationsCurrentReportsByReportId.initiate(
          {
            reportId: report.id,
            body: {
              ...report,
              notifications:
                formatNotificationsFromHtmlToSlack(report.notifications) ??
                null,
              name: report.name.trim(),
            },
          },
          {
            fixedCacheKey: editReportFixedCacheKey,
          },
        ),
      ).unwrap();

      enqueueSnackbar("Subscription updated.", {
        variant: "snackbarAlert",
        AlertSnackBarProps: {
          severity: "success",
        },
      });

      return response;
    } catch (e) {
      // @ts-expect-error todo: fix api spec;
      enqueueSnackbarErrorAlert(e?.data?.message);
    }
  },
);
