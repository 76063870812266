import { createAsyncThunk } from "@reduxjs/toolkit";
import { closeSnackbar, enqueueSnackbar, SnackbarKey } from "notistack";

import { getDashboardsVisibilityHierarchyThunk } from "../dashboard-hierarchy-visibility/getDashboardsVisibilityHierarchyThunk";
import { cloudChiprApi } from "../../../../services/cloudchipr.api";
import { enqueueSnackbarErrorAlert } from "../../../../components/pages/common/snackbar-alert/EnqueueSnackbarErrorAlert";

export const deleteDashboardHierarchyItemThunkFixedCacheKey =
  "deleteDashboardItemThunk-fixedCacheKey";

export const deleteDashboardItemThunk = createAsyncThunk(
  "dashboard/deleteDashboardItem",
  async (id: string, { dispatch }) => {
    const { deleteV2UsersMeOrganisationsCurrentDashboardsByDashboardId } =
      cloudChiprApi.endpoints;

    try {
      await dispatch(
        deleteV2UsersMeOrganisationsCurrentDashboardsByDashboardId.initiate(
          {
            dashboardId: id,
          },
          {
            fixedCacheKey: deleteDashboardHierarchyItemThunkFixedCacheKey,
          },
        ),
      ).unwrap();
      dispatch(getDashboardsVisibilityHierarchyThunk());

      const snackbarId: SnackbarKey = enqueueSnackbar(
        "Dashboard successfully deleted.",
        {
          variant: "snackbarAlert",
          AlertSnackBarProps: {
            severity: "success",
            onClose: () => closeSnackbar(snackbarId),
          },
        },
      );
    } catch (e) {
      // @ts-expect-error todo: fix api spec;
      enqueueSnackbarErrorAlert(e?.data?.message);
    }
  },
);
