import { useFlag } from "@unleash/proxy-client-react";
import { isResourceTypeHiddenByFeatureToggleStorageKey } from "../helpers/getIsResourceTypeHiddenByFeatureToggle";

export const useInitiateHiddenResourceTypesByFeatureToggle = () => {
  const enableEbsSnapshot = useFlag("EnableEBSSnapshot");
  const enableRdsSnapshot = useFlag("EnableRDSSnapshot");
  const enableDynamoDBTable = useFlag("EnableDynamoTable");
  const enableDynamoDBBackup = useFlag("EnableDynamoDBBackup");
  const enableDaxCluster = useFlag("EnableDAXCluster");
  const enableNatGateway = useFlag("EnableNatGateway");
  const enableEcs = useFlag("EnableEcs");

  const data = {
    ebs_snapshot: !enableEbsSnapshot,
    rds_snapshot: !enableRdsSnapshot,
    dynamo_db_table: !enableDynamoDBTable,
    dynamo_db_backup: !enableDynamoDBBackup,
    dax_cluster: !enableDaxCluster,
    nat_gateway: !enableNatGateway,
    ecs: !enableEcs,
  };

  sessionStorage.setItem(
    isResourceTypeHiddenByFeatureToggleStorageKey,
    JSON.stringify(data),
  );
};
