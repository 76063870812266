import { FC, useCallback } from "react";
import {
  Box,
  Button,
  capitalize,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  IconButton,
  Stack,
} from "@mui/material";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { DialogTitleClosable } from "../../../../../../../common/dialog-utils/DialogTitleClosable";
import { useDialog } from "../../../../../../../../utils/hooks/useDialog.hook";
import { GetUsersMeOrganisationsCurrentTasksPropertiesApiResponse } from "../../../../../../../../services/cloudchipr.api";
import { TaskStatusPropertyEditSection } from "../../properties-edit/TaskStatusPropertyEditSection";
import { TaskPropertyEditSection } from "../../properties-edit/TaskPropertyEditSection";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../store/hooks";
import { taskAvailablePropertiesSelector } from "../../../../../../../../store/task-management/selectors/properties/taskAvailablePropertiesSelector";
import { setTaskManagementProperties } from "../../../../../../../../store/task-management/taskManagementSlice";
import { TaskPropertiesSettingsDrawerActions } from "../../../../../../task-management/components/toolbar/components/properties-settings/components/TaskPropertiesSettingsDrawerActions";

export interface TaskPropertySelectionEditItemProps {
  inGroup?: boolean;
  propertyType: keyof GetUsersMeOrganisationsCurrentTasksPropertiesApiResponse;
}

export const TaskPropertySelectionEditItem: FC<
  TaskPropertySelectionEditItemProps
> = ({ propertyType, inGroup }) => {
  const dispatch = useAppDispatch();

  const availableProperties = useAppSelector(taskAvailablePropertiesSelector);

  const { open, openDialog, closeDialog } = useDialog();

  const closeHandler = useCallback(() => {
    if (availableProperties) {
      dispatch(setTaskManagementProperties(availableProperties));
    }

    closeDialog();
  }, [dispatch, closeDialog, availableProperties]);

  const title = `Edit ${capitalize(propertyType)}`;

  return (
    <Stack justifyContent="center">
      {!inGroup && <Divider />}

      {inGroup ? (
        <IconButton size="small" onClick={openDialog}>
          <MoreVertIcon fontSize="small" />
        </IconButton>
      ) : (
        <Box p={1}>
          <Button
            size="small"
            color="tertiary"
            onClick={openDialog}
            sx={{ alignSelf: "start", textTransform: "none" }}
            startIcon={<EditOutlinedIcon fontSize="small" />}
          >
            {title}
          </Button>
        </Box>
      )}

      <Dialog fullWidth open={open} maxWidth="sm" onClose={closeHandler}>
        <DialogTitleClosable
          title={title}
          onClose={closeHandler}
          TitleProps={{ sx: { p: 2 } }}
        />

        <DialogContent dividers>
          {propertyType === "statuses" ? (
            <TaskStatusPropertyEditSection />
          ) : (
            <TaskPropertyEditSection type={propertyType} />
          )}
        </DialogContent>

        <DialogActions sx={{ p: 2 }}>
          <Button size="small" color="tertiary" onClick={closeHandler}>
            Cancel
          </Button>

          <TaskPropertiesSettingsDrawerActions onClose={closeDialog} />
        </DialogActions>
      </Dialog>
    </Stack>
  );
};
