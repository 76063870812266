import { FC, useMemo } from "react";
import { Box, Stack } from "@mui/material";
import DashboardCustomizeOutlinedIcon from "@mui/icons-material/DashboardCustomizeOutlined";
import GridViewOutlinedIcon from "@mui/icons-material/GridViewOutlined";
import BarChartOutlinedIcon from "@mui/icons-material/BarChartOutlined";
import InsertChartOutlinedOutlinedIcon from "@mui/icons-material/InsertChartOutlinedOutlined";
import { Breadcrumbs } from "../../../pages/common/breadcrumbs/Breadcrumbs";

interface InternalDashboardHeaderProps {
  name: string;
  type: "dashboard" | "view";
}

export const InternalPageHeader: FC<InternalDashboardHeaderProps> = ({
  name,
  type,
}) => {
  const breadcrumbs = useMemo(() => getBreadcrumbs(name, type), [name, type]);

  return (
    <Box>
      <Stack
        justifyContent="center"
        spacing={1}
        borderBottom={1}
        borderColor="grey.300"
        height={52}
        px={1}
      >
        <Breadcrumbs breadcrumbs={breadcrumbs} />
      </Stack>
    </Box>
  );
};

const getBreadcrumbs = (name: string, type: "dashboard" | "view") => [
  {
    title: type === "dashboard" ? "Dashboards" : "billing Explorer",
    icon:
      type === "dashboard" ? (
        <DashboardCustomizeOutlinedIcon fontSize="small" />
      ) : (
        <BarChartOutlinedIcon fontSize="small" />
      ),
  },
  {
    title: name,
    icon:
      type === "dashboard" ? (
        <GridViewOutlinedIcon fontSize="small" />
      ) : (
        <InsertChartOutlinedOutlinedIcon fontSize="small" />
      ),
  },
];
