import { FC, Fragment } from "react";
import { useSearchParams } from "react-router-dom";
import { Button, Theme } from "@mui/material";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import { SxProps } from "@mui/system";
import { LiveUsageFiltersPopover } from "./components/LiveUsageFiltersPopover";
import { useAppSelector } from "../../../../../../store/hooks";
import { liveUsageMgmtProviderSelector } from "../../../../../../store/live-usage-mgmt/selectors/store-selectors/liveUsageMgmtProviderSelector";
import { LiveUsageNavigationResourceType } from "../../navigation/utils/hooks/useLiveUsageMgmtPageNavigate.hook";
import { liveUsageMgmtAppliedFiltersCountSelector } from "../../../../../../store/live-usage-mgmt/selectors/store-selectors/liveUsageMgmtAppliedFiltersCountSelector";
import { useMenuHook } from "../../../../../../utils/hooks/useMenu.hook";
import { resourceTypeSearchParam } from "../../../utils/constants/searchParams";

export const LiveUsageAccountFilters: FC = () => {
  const [searchParams] = useSearchParams();
  const activeResourceType = searchParams.get(
    resourceTypeSearchParam,
  ) as LiveUsageNavigationResourceType;

  const provider = useAppSelector(liveUsageMgmtProviderSelector);
  const filtersCount = useAppSelector(liveUsageMgmtAppliedFiltersCountSelector);
  const { anchor, open, openMenu, closeMenu } = useMenuHook();

  if (!activeResourceType || !provider) {
    return null;
  }

  return (
    <Fragment>
      <Button
        disableElevation
        size="small"
        onClick={openMenu}
        startIcon={<FilterAltOutlinedIcon />}
        endIcon={<KeyboardArrowDownOutlinedIcon />}
        sx={filterSourceButtonStyles(!!filtersCount)}
        variant={filtersCount ? "contained" : "outlined"}
      >
        Filters ({filtersCount})
      </Button>

      <LiveUsageFiltersPopover
        open={open}
        anchor={anchor}
        onClose={closeMenu}
      />
    </Fragment>
  );
};

const filterSourceButtonStyles = (active: boolean): SxProps<Theme> => ({
  py: 0.5,
  height: 32,
  borderRadius: 4,
  textTransform: "none",
  whiteSpace: "nowrap",
  minWidth: "auto",
  bgcolor: active ? "grey.100" : "tertiary",
  color: ({ palette }) =>
    active ? palette.primary.main : palette.tertiary.main,
  borderColor: ({ palette }) =>
    active ? palette.primary.main : palette.tertiary.main,
  "&:hover": {
    bgcolor: ({ palette }) => (active ? palette.primary.light : "tertiary"),
    borderColor: ({ palette }) =>
      active ? palette.primary.main : palette.tertiary.main,
  },
});
