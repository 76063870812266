import { GetQuickFilterTextParams } from "ag-grid-community";
import { Role } from "../../../../../../../../services/cloudchipr.api";
import { formatDate } from "../../../../../../../../utils/helpers/date-time/datetime-format";

export const getQuickFilterCreatedAtText = (
  params: GetQuickFilterTextParams<Role>,
) => {
  const role = params.data;

  const createdAt = role.created_at;
  return formatDate(createdAt || "", { type: "date" });
};
