import { createAsyncThunk } from "@reduxjs/toolkit";
import { cloudChiprApi } from "../../../../../services/cloudchipr.api";
import { RootState } from "../../../../store";
import { recommendationRestoreFixedCacheKey } from "../../../utils/constants/fixedCacheKeys";
import { recommendationComputeDismissedDataSelector } from "../../../selectors/off-hours/data/compute/recommendationComputeDismissedDataSelector";
import { recommendationDbDismissedDataSelector } from "../../../selectors/off-hours/data/database/recommendationDbDismissedDataSelector";
import { enqueueSnackbarErrorAlert } from "../../../../../components/pages/common/snackbar-alert/EnqueueSnackbarErrorAlert";

export const restoreWeeklyOffHoursRecommendationThunk = createAsyncThunk(
  "recommendations/restoreWeeklyOffHoursRecommendationThunk",
  async (ids: string[], { dispatch, getState }) => {
    const state = getState() as RootState;

    const { patchUsersMeOrganisationsCurrentRecommendationsWeeklyOffHours } =
      cloudChiprApi.endpoints;

    const dismissedCompute = recommendationComputeDismissedDataSelector(state);
    const dismissedDb = recommendationDbDismissedDataSelector(state);

    const accountIds = ids.map((recommendationId) => {
      let recommendation = dismissedCompute?.find(
        ({ id }) => id === recommendationId,
      );

      if (!recommendation) {
        recommendation = dismissedDb?.find(({ id }) => id === recommendationId);
      }

      return recommendation?.account?.id ?? "";
    });

    try {
      return await dispatch(
        patchUsersMeOrganisationsCurrentRecommendationsWeeklyOffHours.initiate(
          {
            restoreRecommendationRequest: {
              account_ids: accountIds,
              recommendation_ids: ids,
            },
          },
          {
            fixedCacheKey: recommendationRestoreFixedCacheKey,
          },
        ),
      ).unwrap();
    } catch (e: any) {
      enqueueSnackbarErrorAlert(e?.data?.message);
    }
  },
);
