import { createAsyncThunk } from "@reduxjs/toolkit";
import { closeSnackbar, enqueueSnackbar, SnackbarKey } from "notistack";

import { cloudChiprApi } from "../../../../services/cloudchipr.api";
import { getDashboardsVisibilityHierarchyThunk } from "../dashboard-hierarchy-visibility/getDashboardsVisibilityHierarchyThunk";
import { enqueueSnackbarErrorAlert } from "../../../../components/pages/common/snackbar-alert/EnqueueSnackbarErrorAlert";

export const deleteDashboardHierarchyFolderThunkFixedCacheKey =
  "deleteDashboardFolderThunk-fixedCacheKey";

export const deleteDashboardFolderThunk = createAsyncThunk(
  "dashboard/deleteDashboardFolder",
  async (id: string, { dispatch }) => {
    const { deleteV2UsersMeOrganisationsCurrentDashboardsFoldersByFolderId } =
      cloudChiprApi.endpoints;

    try {
      await dispatch(
        deleteV2UsersMeOrganisationsCurrentDashboardsFoldersByFolderId.initiate(
          {
            folderId: id,
          },
          {
            fixedCacheKey: deleteDashboardHierarchyFolderThunkFixedCacheKey,
          },
        ),
      ).unwrap();
      dispatch(getDashboardsVisibilityHierarchyThunk());

      const snackbarId: SnackbarKey = enqueueSnackbar(
        "Folder successfully deleted.",
        {
          variant: "snackbarAlert",
          AlertSnackBarProps: {
            severity: "success",
            onClose: () => closeSnackbar(snackbarId),
          },
        },
      );
    } catch (e) {
      // @ts-expect-error todo: fix api spec;
      enqueueSnackbarErrorAlert(e?.data?.message);
    }
  },
);
