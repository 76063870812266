import { useNavigate } from "react-router-dom";
import { FC, Fragment, useCallback, useEffect, useMemo, useState } from "react";
import { useFormik } from "formik";
import { Card, CardContent, Container } from "@mui/material";
import { useSnackbar } from "notistack";
import { useEffectOnceWhen, useWillUnmount } from "rooks";
import { ScheduleComponent } from "./form/ScheduleComponent";
import { OffHoursPreview } from "./preview/OffHoursPreview";
import { OffHoursActionsStep } from "./form/action/OffHoursActionsStep";
import { SchedulesResourcesList } from "./form/resources/SchedulesResourcesList";
import { OffHoursStepper } from "./form/tools/OffHoursStepper";
import {
  OffHoursEnvironment,
  ScheduleEnvironmentType,
} from "./form/environment/OffHoursEnvironment";
import { NextPrevious } from "./form/tools/NextPrevious";
import { automationValidationSchema } from "../../../common/utils/constants/validation";
import {
  OffHoursSchedule,
  useGetProvidersByProviderRegionsQuery,
  useGetUsersMeOffHoursSchedulesByScheduleIdQuery,
  usePatchUsersMeOffHoursSchedulesByScheduleIdMutation,
  usePostUsersMeOffHoursSchedulesMutation,
} from "../../../../../../services/cloudchipr.api";
import {
  generateOffHoursSteps,
  generateOffHoursEditingData,
  getOffHoursCreatePayloadData,
  getOffHoursUpdatePayloadData,
} from "../../utils/helpers/helpers";
import { useAppDispatch, useAppSelector } from "../../../../../../store/hooks";
import { resetSchedulesState } from "../../../../../../store/schedules/schedulesSlice";
import { currentOffHoursSelector } from "../../../../../../store/schedules/selectors/currentOffHoursSelector";
import { offHourDefaultProviderSelector } from "../../../../../../store/schedules/selectors/offHourDefaultProviderSelector";
import { offHoursInitialData } from "../../utils/constants/constants";
import {
  OffHoursFormData,
  OffHoursSchedulerData,
} from "../../utils/types/types";
import { enqueueSnackbarErrorAlert } from "../../../../common/snackbar-alert/EnqueueSnackbarErrorAlert";

const initialValues: OffHoursFormData = offHoursInitialData;

const validationSchema = automationValidationSchema;

interface OffHoursFormProps {
  schedule?: OffHoursSchedule;
}

export const OffHoursForm: FC<OffHoursFormProps> = ({ schedule }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { enqueueSnackbar } = useSnackbar();

  const defaultProvider = useAppSelector(offHourDefaultProviderSelector);
  const scheduleFromState = useAppSelector(currentOffHoursSelector);

  const [activeStep, setActiveStep] = useState(scheduleFromState ? 4 : 0);

  const { data: awsRegions } = useGetProvidersByProviderRegionsQuery({
    provider: "aws",
  });
  const { data: gcpRegions } = useGetProvidersByProviderRegionsQuery({
    provider: "gcp",
  });
  const { data: azureRegions } = useGetProvidersByProviderRegionsQuery({
    provider: "azure",
  });

  const [createSchedule, { isLoading: isCreating }] =
    usePostUsersMeOffHoursSchedulesMutation();
  const [updateSchedule, { isLoading: isUpdating }] =
    usePatchUsersMeOffHoursSchedulesByScheduleIdMutation();
  const { refetch } = useGetUsersMeOffHoursSchedulesByScheduleIdQuery(
    { scheduleId: schedule?.id || "" },
    { skip: !schedule?.id },
  );

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      try {
        if (values.id) {
          await updateSchedule(getOffHoursUpdatePayloadData(values)).unwrap();
          enqueueSnackbar("Off Hours successfully updated.", {
            variant: "snackbarAlert",
            AlertSnackBarProps: {
              severity: "success",
            },
          });
          refetch();
        } else {
          await createSchedule(getOffHoursCreatePayloadData(values)).unwrap();
          enqueueSnackbar("Off Hours successfully created.", {
            variant: "snackbarAlert",
            AlertSnackBarProps: {
              severity: "success",
            },
          });
        }

        routerBackHandler();
      } catch (e) {
        // @ts-expect-error todo: fix api spec;
        enqueueSnackbarErrorAlert(e?.data?.message);
      }
    },
  });

  const {
    values,
    touched,
    setFieldValue,
    submitForm,
    errors,
    setValues,
    resetForm,
    dirty,
  } = formik;

  const routerBackHandler = useCallback(
    () => navigate("/schedule/off-hours"),
    [navigate],
  );

  const steps = useMemo(() => {
    return generateOffHoursSteps(values);
  }, [values]);

  const nextStepHandler = useCallback(() => {
    setActiveStep((step) => step + 1);
  }, []);

  const previousStepHandler = useCallback(() => {
    setActiveStep((step) => step - 1);
  }, []);

  const setScheduleDatesData = useCallback(
    ({
      startCron,
      stopCron,
      endDate,
      startDate,
      timeZone,
    }: OffHoursSchedulerData) => {
      setFieldValue("start_cron", startCron);
      setFieldValue("stop_cron", stopCron);
      setFieldValue("end_date_time", endDate);
      setFieldValue("start_date_time", startDate);
      setFieldValue("time_zone", timeZone);

      nextStepHandler();
    },
    [setFieldValue, nextStepHandler],
  );
  const setEnvironmentHandler = useCallback(
    (data: ScheduleEnvironmentType) => {
      setValues((values) => ({ ...values, ...data }));
      nextStepHandler();
    },
    [setValues, nextStepHandler],
  );

  useEffectOnceWhen(
    () => {
      if (!defaultProvider) {
        return;
      }

      const regions = {
        aws: awsRegions,
        gcp: gcpRegions,
        azure: azureRegions,
      };

      resetForm({
        values: {
          ...initialValues,
          regions: regions[defaultProvider]?.map((region) => region.key) || [],
          provider: defaultProvider,
        },
      });
    },
    !!defaultProvider &&
      !!awsRegions &&
      !!azureRegions &&
      !!gcpRegions &&
      !schedule &&
      !scheduleFromState,
  );

  useEffectOnceWhen(() => {
    if (scheduleFromState) {
      resetForm({ values: generateOffHoursEditingData(scheduleFromState) });
    }
  }, !!scheduleFromState);

  useEffect(() => {
    if (schedule) {
      const values = generateOffHoursEditingData(schedule);
      resetForm({ values });
    }
  }, [schedule, resetForm]);

  useWillUnmount(() => {
    dispatch(resetSchedulesState());
  });

  useEffectOnceWhen(() => {
    if (
      schedule?.accounts?.every((account) => account.status !== "connected")
    ) {
      routerBackHandler();
    }
  }, !!schedule);

  if ((schedule?.id && !values.id) || !values.provider) {
    return null;
  }

  return (
    <Container maxWidth="md" sx={{ p: 2 }} disableGutters>
      <Card variant="outlined">
        <CardContent>
          <OffHoursStepper
            steps={steps}
            activeStep={activeStep}
            changeStep={setActiveStep}
          />

          <Fragment>
            {activeStep === 0 && (
              <OffHoursEnvironment
                {...values}
                parentScheduleType="offHours"
                onFieldChange={setFieldValue}
                onNext={setEnvironmentHandler}
              />
            )}

            {activeStep === 1 && values.filter && (
              <Fragment>
                <SchedulesResourcesList
                  gracePeriodEnabled={false}
                  scheduleId={values.id}
                  scheduleAction={values.action}
                  resources={values.filter}
                  parentScheduleType="offHours"
                  setFieldValue={setFieldValue}
                  disabled={!values.regions.length}
                  provider={values.provider}
                  accountIds={values.account_ids}
                  errors={touched.filter && errors.filter}
                  regions={values.regions}
                />
                <NextPrevious
                  onNext={nextStepHandler}
                  onPrevious={previousStepHandler}
                  isNextDisabled={!steps[activeStep].completed}
                  isDirty={dirty}
                />
              </Fragment>
            )}

            {activeStep === 2 && (
              <OffHoursActionsStep
                emails={values.emails}
                notifications={values.notifications}
                action={values.action}
                onNext={nextStepHandler}
                onFieldChange={setFieldValue}
                onPrevious={previousStepHandler}
                isDirty={dirty}
                minThreshold={values.min_threshold}
              />
            )}

            {activeStep === 3 && (
              <ScheduleComponent
                id={values.id}
                stopCron={values.stop_cron}
                timeZone={values.time_zone}
                startCron={values.start_cron}
                endDate={values.end_date_time}
                onPreviousStep={previousStepHandler}
                startDate={values.start_date_time || null}
                setScheduleDatesData={setScheduleDatesData}
                isDirty={dirty}
              />
            )}

            {activeStep === 4 && (
              <OffHoursPreview
                {...values}
                isLoading={isCreating || isUpdating}
                onSubmit={submitForm}
                onPrevious={previousStepHandler}
                onFieldChange={setFieldValue}
              />
            )}
          </Fragment>
        </CardContent>
      </Card>
    </Container>
  );
};
