import { FC, useCallback } from "react";
import { Button, Tooltip } from "@mui/material";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../store/hooks";
import { useAppAbility } from "../../../../../../../../services/permissions";
import { WidgetType } from "../../../../../../../../services/cloudchipr.api";
import { editWidgetThunk } from "../../../../../../../../store/dashboards/thunks/widgets/common/editWidgetThunk";
import { currentDashboardProtectedSelector } from "../../../../../../../../store/dashboards/selectors/dashboard-hierarchy-visibility/currentDashboardProtectedSelector";

interface WidgetEditButtonProps {
  widgetId: string;
  widgetType: WidgetType;
}

export const WidgetEditButton: FC<WidgetEditButtonProps> = ({
  widgetType,
  widgetId,
}) => {
  const dispatch = useAppDispatch();
  const { cannot } = useAppAbility();
  const isProtected = useAppSelector(currentDashboardProtectedSelector);
  const canNotEditWidget = cannot("edit", "dashboard");

  const editWidgetHandler = useCallback(() => {
    dispatch(
      editWidgetThunk({
        widgetId,
        widgetType,
      }),
    );
  }, [dispatch, widgetId, widgetType]);

  return (
    <Tooltip
      title={isProtected ? "Protected dashboards cannot be modified." : ""}
      arrow
      placement="top"
    >
      <span>
        <Button
          size="small"
          variant="outlined"
          sx={{ whiteSpace: "nowrap" }}
          onClick={editWidgetHandler}
          disabled={canNotEditWidget || isProtected}
          startIcon={<EditOutlinedIcon fontSize="small" />}
        >
          Edit Widget
        </Button>
      </span>
    </Tooltip>
  );
};
