import { FC } from "react";
import AppsIcon from "@mui/icons-material/Apps";
import CloudOutlinedIcon from "@mui/icons-material/CloudOutlined";
import BrokenImageOutlinedIcon from "@mui/icons-material/BrokenImageOutlined";
import ListIcon from "@mui/icons-material/List";
import {
  ProviderType,
  ResourceExplorerFilterProvider,
} from "../../services/cloudchipr.api";
import AwsLogo from "../../assets/images/logos/providers/aws.svg";
import GcpLogo from "../../assets/images/logos/providers/gcp.svg";
import AzureLogo from "../../assets/images/logos/providers/azure.svg";
import { isStringProviderType } from "../../utils/helpers/providers/isStringProviderType";
import confluent from "../../assets/images/icons/confluent.svg";
import EKSSvgIcon from "../../assets/images/logos/providers/EKSSvgIcon";
import MongoSvgIcon from "../../assets/images/logos/providers/MongoSvgIcon";

interface ProviderIconProps {
  provider: ResourceExplorerFilterProvider | "all";
  width?: string | number;
}

export const ProviderIcon: FC<ProviderIconProps> = ({
  provider,
  width = 20,
}) => {
  if (isStringProviderType(provider)) {
    return <ProviderLogo provider={provider} width={width} />;
  }

  if (provider === "all") {
    return <AppsIcon color="primary" fontSize="small" />;
  }

  if (provider === "dimensions") {
    return <ListIcon color="primary" fontSize="small" />;
  }

  if (provider === "confluent") {
    return <img src={confluent} alt="confluent" />;
  }

  if (provider === "global") {
    return <CloudOutlinedIcon />;
  }

  if (provider === "eks") {
    return <EKSSvgIcon fontSize="small" color="primary" />;
  }

  if (provider === "mongo") {
    return <MongoSvgIcon fontSize="small" color="primary" />;
  }

  return <BrokenImageOutlinedIcon color="primary" fontSize="small" />;
};

const logos = { aws: AwsLogo, gcp: GcpLogo, azure: AzureLogo };
const ProviderLogo: FC<{
  provider: ProviderType;
  width?: string | number;
}> = ({ provider, width = 20 }) => {
  return <img alt={provider} src={logos[provider]} width={width} />;
};
