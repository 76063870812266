import { closeSnackbar, enqueueSnackbar } from "notistack";
import { Typography } from "@mui/material";
import React from "react";

export const enqueueSendReportSnackbar = (message: string) => {
  const key = enqueueSnackbar(message, {
    variant: "snackbarAlert",
    AlertSnackBarProps: {
      severity: "success",
      description: (
        <Typography variant="caption" color="text.secondary" px={4}>
          We'll send your document(s) shortly!
        </Typography>
      ),
      onClose: () => closeSnackbar(key),
    },
  });
};
