import { FC, useCallback, useMemo } from "react";
import { CustomCellRendererProps } from "ag-grid-react";
import { Task } from "../../../../../../services/cloudchipr.api";
import { AssigneeSelection } from "../../../../common/task-management/components/common/properties/assignee/AssigneeSelection";
import { useAppDispatch } from "../../../../../../store/hooks";
import { taskManagementUpdateTaskThunk } from "../../../../../../store/task-management/thunks/actions/taskManagementUpdateTaskThunk";
import { autoGroupColumnId } from "../auto-group-column/getAutoGroupColumnDef";

export const AssigneeCellRenderer: FC<CustomCellRendererProps<Task>> = ({
  data,
  colDef,
  column,
}) => {
  const dispatch = useAppDispatch();
  const colId = column?.getColId() || colDef?.colId;

  const assignees = useMemo(() => {
    return data?.assignees?.map(({ id }) => id) ?? null;
  }, [data]);
  const assigneesAsString = assignees?.join("");

  const changeHandler = useCallback(
    (value: string[] | null) => {
      if (!data?.id || assigneesAsString === value?.join("")) {
        return null;
      }

      dispatch(
        taskManagementUpdateTaskThunk({
          task_ids: [data.id],
          assignees: { value },
        }),
      );
    },
    [dispatch, data?.id, assigneesAsString],
  );

  if (!data) {
    return null;
  }

  return (
    <AssigneeSelection
      inCell
      values={assignees}
      onChange={changeHandler}
      disableSelection={colId === autoGroupColumnId}
    />
  );
};
