import { ColDef } from "ag-grid-community";

export const commitmentsSoringFn: ColDef["comparator"] = (
  valueA,
  valueB,
  nodeA,
  nodeB,
) => {
  const currentUtilization = Number(nodeA.data?.utilization?.amount);
  const currentWaste = Number(nodeA.data?.waste?.amount);
  const currentCommitment = currentWaste + currentUtilization;

  const nextUtilization = Number(nodeB.data?.utilization?.amount);
  const nextWaste = Number(nodeB.data?.waste?.amount);
  const nextCommitment = nextWaste + nextUtilization;

  return currentCommitment > nextCommitment ? -1 : 1;
};
