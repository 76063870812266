import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  cloudChiprApi,
  DeleteUsersMeOrganisationsCurrentDimensionsByDimensionIdCategoriesAndCategoryIdApiArg,
} from "../../../../services/cloudchipr.api";
import { enqueueSnackbarErrorAlert } from "../../../../components/pages/common/snackbar-alert/EnqueueSnackbarErrorAlert";

export const categoryDeleteFixedCacheKey =
  "deleteUsersMeOrganisationsCurrentDimensionsByDimensionIdCategoriesAndCategoryId-fixed-cache-key";

export const deleteCategoryByIdThunk = createAsyncThunk(
  "dimensions/deleteCategoryById",
  async (
    {
      dimensionId,
      categoryId,
    }: DeleteUsersMeOrganisationsCurrentDimensionsByDimensionIdCategoriesAndCategoryIdApiArg,
    { dispatch },
  ) => {
    const {
      deleteUsersMeOrganisationsCurrentDimensionsByDimensionIdCategoriesAndCategoryId,
    } = cloudChiprApi.endpoints;

    try {
      await dispatch(
        deleteUsersMeOrganisationsCurrentDimensionsByDimensionIdCategoriesAndCategoryId.initiate(
          { dimensionId, categoryId },
          { fixedCacheKey: categoryDeleteFixedCacheKey },
        ),
      ).unwrap();
    } catch (e) {
      // @ts-expect-error todo: fix api spec;
      enqueueSnackbarErrorAlert(e?.data?.message);
    }
  },
);
