import { FC, useCallback } from "react";
import { WithResourceType } from "../../../utils/types";
import { FilterSourceButton } from "../../../../common/filters/filters-selection/utils/types/types";
import { useAppDispatch, useAppSelector } from "../../../../../../store/hooks";
import { AccountResourceFilter2 } from "../../../../../../services/cloudchipr.api";
import { FiltersSourceButton } from "../../../../common/filters/filters-selection/FiltersSourceButton";
import { liveUsageMgmtAccountIdsSelector } from "../../../../../../store/live-usage-mgmt/selectors/store-selectors/liveUsageMgmtAccountIdsSelector";
import {
  setLiveUsageMgmtAppliedFilters,
  setLiveUsageMgmtFilterChangedManually,
  setLiveUsageMgmtViewWithoutFiltersResourceTypes,
} from "../../../../../../store/live-usage-mgmt/liveUsageMgmtSlice";
import { getLiveUsageMgmtResourcesWithFiltersByProviderThunk } from "../../../../../../store/live-usage-mgmt/thunks/resources/getLiveUsageMgmtResourcesWithFiltersByProviderThunk";
import { liveUsageMgmtApplyingFilterTemplateFiltersByResourceTypeSelector } from "../../../../../../store/live-usage-mgmt/selectors/liveUsageMgmtApplyingFilterTemplateFiltersByResourceTypeSelector";
import { liveUsageMgmtAppliedFiltersByResourceTypeSelector } from "../../../../../../store/live-usage-mgmt/selectors/filter-template/liveUsageMgmtAppliedFiltersByResourceTypeSelector";

interface LiveUsageResourceFilterProps extends WithResourceType {
  SourceButton?: FilterSourceButton;
}

export const LiveUsageResourceFilter: FC<LiveUsageResourceFilterProps> = ({
  resourceType,
  SourceButton,
}) => {
  const dispatch = useAppDispatch();

  const accountIds = useAppSelector(liveUsageMgmtAccountIdsSelector);
  const appliedFilters = useAppSelector((state) =>
    liveUsageMgmtAppliedFiltersByResourceTypeSelector(state, resourceType),
  );
  const accountApplyingFilters = useAppSelector((state) =>
    liveUsageMgmtApplyingFilterTemplateFiltersByResourceTypeSelector(
      state,
      resourceType,
    ),
  );

  const submitHandler = useCallback(
    (values: AccountResourceFilter2) => {
      if (!accountIds) {
        return;
      }

      dispatch(setLiveUsageMgmtAppliedFilters(values));
      dispatch(setLiveUsageMgmtFilterChangedManually());
      dispatch(
        setLiveUsageMgmtViewWithoutFiltersResourceTypes({
          resourceType,
          value: false,
        }),
      );

      dispatch(
        getLiveUsageMgmtResourcesWithFiltersByProviderThunk(resourceType),
      );
    },
    [dispatch, accountIds, resourceType],
  );

  if (!accountIds || !appliedFilters || !accountApplyingFilters) {
    return null;
  }

  return (
    <FiltersSourceButton
      defaultFilters={accountApplyingFilters}
      accountIds={accountIds}
      onSubmit={submitHandler}
      resourceType={resourceType}
      filters={appliedFilters}
      SourceButton={SourceButton}
    />
  );
};
