import { largestCostChangesSetupPropertyByKeySelector } from "./largestCostChangesSetupPropertyByKeySelector";
import { RootState } from "../../../../store";
import { widgetViewNameByViewIdIdSelector } from "../../widgets/common/widgetViewNameByViewIdIdSelector";
import { objectToUrl } from "../../../../../components/utils/helpers/dataToUrlToDataConvertors";
import { WidgetTitleProps } from "../../../../../components/pages/dashboard/utils/types/types";

export const largestCostChangesSetupWidgetTitleSelector = (
  state: RootState,
): WidgetTitleProps => {
  const grouping =
    largestCostChangesSetupPropertyByKeySelector("grouping")(state);
  const groupingValues =
    largestCostChangesSetupPropertyByKeySelector("group_values")(state);

  const widgetName =
    largestCostChangesSetupPropertyByKeySelector("name")(state);

  const dates = largestCostChangesSetupPropertyByKeySelector("dates")(state);
  const viewId = largestCostChangesSetupPropertyByKeySelector("viewId")(state);
  const viewName = widgetViewNameByViewIdIdSelector(state, viewId);

  const path = objectToUrl([
    {
      groupedBy: grouping,
      groupValues: groupingValues,
      dates: dates,
      filterTree: undefined,
    },
  ]);

  return {
    groupBy: grouping,
    groupValues: groupingValues,
    widgetName,
    dates: dates,
    sourceData: {
      title: viewName ?? "",
      url: `/resource-explorer/${viewId}?path=${path}`,
    },
  };
};
