import { FC, useMemo } from "react";
import { Stack } from "@mui/material";
import { SlackDefaultConversationTrigger } from "./SlackDefaultConversationTrigger";
import { DropdownSelect } from "../../../../../../../../common/select/dropdown-select/DropdownSelect";
import { SlackConversationResponse } from "../../../../../../../../../services/cloudchipr.api";
import { slackSelectionOptionsFilter } from "../../utils/helpers/helpers";
import { SlackConversationLabel } from "../SlackConversationLabel";
import { SlackConnectionTestMessage } from "../SlackConnectionTestMessage";

interface SlackDefaultConversationSelectProps {
  integrationId: string;
  selectedValue?: string;
  onChange(values: string[]): void;
  options?: SlackConversationResponse[] | null;
}

export const SlackDefaultConversationSelect: FC<
  SlackDefaultConversationSelectProps
> = ({ selectedValue, options, onChange, integrationId }) => {
  const dropdownOptions = useMemo(() => {
    return (
      options?.map((item) => {
        return {
          value: item.id,
          rawValue: item,
          label: <SlackConversationLabel type={item.type} name={item.name} />,
        };
      }) ?? []
    );
  }, [options]);

  const selectedConversation = useMemo(() => {
    return options?.find((option) => option.id === selectedValue);
  }, [options, selectedValue]);

  return (
    <Stack bgcolor="grey.100" p={1} spacing={2}>
      <DropdownSelect
        singleSelect={{ enablePropertyUnselect: true }}
        options={dropdownOptions}
        wrapperVariant="dropdown"
        noItemsLabel={noItemsLabel}
        submitHandlerOnClose={onChange}
        filterFn={slackSelectionOptionsFilter}
        label="Default Conversation (optional)"
        values={selectedValue ? [selectedValue] : []}
        TriggerComponent={SlackDefaultConversationTrigger}
      />

      <SlackConnectionTestMessage
        integrationId={integrationId}
        conversation={selectedConversation}
      />
    </Stack>
  );
};

const noItemsLabel = () => "No Conversation";
