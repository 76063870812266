import { FC, ReactNode } from "react";
import { Stack } from "@mui/material";
import {
  TaskPropertySelectionEditItem,
  TaskPropertySelectionEditItemProps,
} from "../../../../common/task-management/components/common/properties/task-property-selection/TaskPropertySelectionEditItem";

interface TaskPropCellRendererWrapperProps
  extends TaskPropertySelectionEditItemProps {
  children: ReactNode;
}

export const TaskPropCellRendererWrapper: FC<
  TaskPropCellRendererWrapperProps
> = ({ propertyType, inGroup, children }) => {
  return (
    <Stack direction="row">
      {children}

      {inGroup && (
        <TaskPropertySelectionEditItem inGroup propertyType={propertyType} />
      )}
    </Stack>
  );
};
