import { liveUsageMgmtResourceTypeDataSelector } from "./liveUsageMgmtResourceTypeDataSelector";
import { liveUsageMgmtResourceViewIsWithoutFiltersSelector } from "./liveUsageMgmtResourceViewIsWithoutFiltersSelector";
import { RootState } from "../../../store";
import { ResourceType } from "../../../../services/cloudchipr.api";
import { liveUsagePageStateSelector } from "../store-selectors/liveUsagePageStateSelector";
import { liveUsageMgmtFilterTemplatesEnabledSelector } from "../store-selectors/liveUsageMgmtFilterTemplatesEnabledSelector";
import { getResourceTypeDataWithoutFiltersFixedCacheKey } from "../../thunks/resources/getLiveUsageMgmtResourcesWithOutFiltersByProviderThunk";

export const liveUsageMgmtResourceTypeDataSpendingSelector = (
  state: RootState,
  resourceType: ResourceType,
) => {
  const pageState = liveUsagePageStateSelector(state);

  if (pageState === "pending") {
    return 0;
  }
  const withoutFilters = liveUsageMgmtResourceViewIsWithoutFiltersSelector(
    state,
    resourceType,
  );

  const filterTemplatesEnabled =
    liveUsageMgmtFilterTemplatesEnabledSelector(state);

  const fixedCacheKey =
    withoutFilters || !filterTemplatesEnabled
      ? getResourceTypeDataWithoutFiltersFixedCacheKey(resourceType)
      : resourceType;

  return (
    liveUsageMgmtResourceTypeDataSelector(state, fixedCacheKey)
      ?.total_monthly_spending ?? 0
  );
};
