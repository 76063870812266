import { FC, Fragment, useCallback } from "react";
import { Button, Divider, Stack, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

interface NoAvailableViewInBudgetsProps {
  defaultViewId: string;
}

export const NoAvailableViewInBudgets: FC<NoAvailableViewInBudgetsProps> = ({
  defaultViewId,
}) => {
  const navigate = useNavigate();

  const toResourceExplorerHandler = useCallback(() => {
    navigate(`/resource-explorer/${defaultViewId}`);
  }, [navigate, defaultViewId]);

  return (
    <Fragment>
      <Divider />
      <Stack alignItems="center" spacing={0.5} py={1}>
        <Typography textAlign="center" variant="body2" width="100%">
          Please add view in Billing Report to create budget
        </Typography>

        <Button
          sx={{ textTransform: "none" }}
          onClick={toResourceExplorerHandler}
        >
          Go to Billing Explorer
        </Button>
      </Stack>
    </Fragment>
  );
};
