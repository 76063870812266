import { SvgIcon, SvgIconProps, useTheme } from "@mui/material";

export const SendSvgIcon = ({ color }: SvgIconProps) => {
  const { palette } = useTheme();

  const fillColor =
    color === "primary" ? palette.primary.main : palette.action.active;

  return (
    <SvgIcon>
      <svg
        width="20"
        height="20"
        viewBox="-4 -4 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9.66667 0.166992V3.50033C3.83333 4.33366 1.33333 8.50033 0.5 12.667C2.58333 9.75033 5.5 8.41699 9.66667 8.41699V11.8337L15.5 6.00033L9.66667 0.166992ZM11.3333 4.19199L13.1417 6.00033L11.3333 7.80866V6.75033H9.66667C7.94167 6.75033 6.39167 7.06699 4.95 7.54199C6.11667 6.38366 7.61667 5.47533 9.9 5.16699L11.3333 4.94199V4.19199Z"
          fill={fillColor}
        />
      </svg>
    </SvgIcon>
  );
};

SendSvgIcon.muiName = "SendSvgIcon";

export default SendSvgIcon;
