import { createDraftSafeSelector } from "@reduxjs/toolkit";
import { accountsByProviderAndOrgIdSelector } from "./accountsByProviderAndOrgIdSelector";
import { activeAccountStatuses } from "../../../utils/constants/accounts";
import { AccountsPageTabs } from "../../../components/pages/accounts-group/utils/types/types";
import { Account } from "../../../services/cloudchipr.api";

export const accountsDividedToStatusesByOrgIdSelector = createDraftSafeSelector(
  [accountsByProviderAndOrgIdSelector],
  (accounts) => {
    if (!accounts) {
      return null;
    }

    return accounts.reduce(
      (result, account) => {
        const type = activeAccountStatuses.has(account.status)
          ? "active"
          : "inactive";

        result[type].push(account);

        return result;
      },
      { active: [], inactive: [] } as Record<AccountsPageTabs, Account[]>,
    );
  },
);
