import { FC, MouseEvent, ReactNode, useCallback, useMemo } from "react";
import { ListItem } from "@mui/material";
import { FilterListItemDisabledReasonTooltip } from "./FilterListItemDisabledReasonTooltip";
import { FilterListItemButton } from "./FilterListItemButton";
import { useHover } from "../../../../../utils/hooks/useHover.hook";
import { DropdownSelectOption } from "../utils/types/types";

export interface FilterListItemProps {
  isSelected?: boolean;
  option: DropdownSelectOption;
  singleSelect: boolean;
  isIndeterminate?: boolean;
  selectedValues: string[];
  onClick?(value?: string): void;
  lastSelectedOptionDisabled: boolean;
  onOnlyClick?(value?: string): void;
  renderOption?(option: DropdownSelectOption): ReactNode;
}

export const FilterListItem: FC<FilterListItemProps> = ({
  option: initialOption,
  isSelected,
  onClick,
  singleSelect,
  isIndeterminate,
  onOnlyClick,
  selectedValues,
  renderOption,
  lastSelectedOptionDisabled,
}) => {
  const { value, disabled, hideOnly } = initialOption;
  const { ref, hovered } = useHover();

  const option: DropdownSelectOption = useMemo(() => {
    if (!lastSelectedOptionDisabled || selectedValues?.length !== 1) {
      return initialOption;
    }

    const disabled = selectedValues.at(0) === initialOption.value;

    return {
      ...initialOption,
      disabled,
      disableSelection: disabled,
    };
  }, [selectedValues, initialOption, lastSelectedOptionDisabled]);

  const handleOnlyClick = useCallback(
    (e: MouseEvent) => {
      if (disabled) {
        return;
      }

      e.stopPropagation();
      onOnlyClick?.(value);
    },
    [disabled, value, onOnlyClick],
  );

  const handleToggleClick = useCallback(() => {
    if (disabled) {
      return;
    }

    onClick?.(value);
  }, [disabled, value, onClick]);

  const selected = isSelected || !!selectedValues?.includes(value);

  const showOnly =
    hovered && !singleSelect && !disabled && onOnlyClick && !hideOnly;

  const clickHandler = singleSelect ? handleOnlyClick : handleToggleClick;

  return (
    <ListItem
      ref={ref}
      disablePadding
      sx={{ mx: 0, position: "relative" }}
      className={singleSelect && selected ? "Mui-selected" : ""}
      onClick={option.disableSelection ? undefined : clickHandler}
    >
      <FilterListItemButton
        option={option}
        showOnly={!!showOnly}
        selected={selected}
        onOnlyClick={handleOnlyClick}
        singleSelect={singleSelect}
        renderOption={renderOption}
        isIndeterminate={isIndeterminate}
        wrappedWithButton={!option.disableSelection}
      />

      <FilterListItemDisabledReasonTooltip disabled={disabled} />
    </ListItem>
  );
};
