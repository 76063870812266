import { FC, Fragment, ReactNode, useMemo } from "react";
import {
  Collapse,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { useToggle } from "rooks";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { GroupsOutlined } from "@mui/icons-material";
import { useHover } from "../../../../../utils/hooks/useHover.hook";
import { visibilityTitleByVisibility } from "../../../../pages/common/hierarchy-all-items/utils/constants/constants";
import { TypographyWithTooltip } from "../../../../common/TypographyWithTooltip";

interface NavigationVisibilitySectionProps {
  children?: ReactNode;
  selected?: boolean;
  expanded?: boolean;
  visibility: "visible_only_to_me" | "visible_to_everyone";
}

export const NavigationVisibilitySection: FC<
  NavigationVisibilitySectionProps
> = ({ visibility, children, selected, expanded }) => {
  const { ref, hovered } = useHover();
  const [stateExpandedState, toggleExpandedState] = useToggle(
    expanded || selected,
  );
  const expandIcon = useMemo(
    () =>
      stateExpandedState ? (
        <KeyboardArrowDownIcon fontSize="small" color="action" />
      ) : (
        <KeyboardArrowRightIcon fontSize="small" color="action" />
      ),
    [stateExpandedState],
  );

  return (
    <Fragment>
      <ListItem sx={listItemSx} ref={ref}>
        <ListItemButton onClick={toggleExpandedState} sx={ListItemButtonSx}>
          <ListItemIcon sx={listItemIconSx}>
            {hovered ? expandIcon : iconByVisibility.get(visibility)}
          </ListItemIcon>

          <ListItemText
            primary={
              <TypographyWithTooltip
                title={visibilityTitleByVisibility.get(visibility)}
                variant="inherit"
              />
            }
          />
        </ListItemButton>
      </ListItem>

      <Collapse in={stateExpandedState} sx={{ px: 0.5 }}>
        {children}
      </Collapse>
    </Fragment>
  );
};

const iconByVisibility = new Map<
  NavigationVisibilitySectionProps["visibility"],
  ReactNode
>([
  [
    "visible_only_to_me",
    <LockOutlinedIcon
      key="visible_only_to_me"
      fontSize="small"
      color="action"
    />,
  ],
  [
    "visible_to_everyone",
    <GroupsOutlined
      key="visible_to_everyone"
      fontSize="small"
      color="action"
    />,
  ],
]);

const ListItemButtonSx = {
  borderRadius: 2,
};

const listItemSx = { px: 1.5, py: 0.25 };

const listItemIconSx = {
  minWidth: 32,
};
