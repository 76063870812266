import { FC } from "react";
import { CostBreakdownWidgetChartView } from "./chart/CostBreakdownWidgetChartView";
import { CostBreakdownWidgetTableView } from "./chart/table/CostBreakdownWidgetTableView";
import { CostBreakdownWidgetNumeralView } from "./chart/numeral/CostBreakdownWidgetNumeralView";
import { useAppSelector } from "../../../../../../../store/hooks";
import { DashboardEmptyWidgetState } from "../../common/DashboardEmptyWidgetState";
import { ChartType } from "../../../../../../../storybook/charts/multi-type-chart/utils/types/types";
import { DashboardNotFoundWidget } from "../../common/DashboardNotFoundWidget";
import { costBreakdownWidgetNotFoundSelector } from "../../../../../../../store/dashboards/selectors/widgets/cost-breakdown/widget-view/costBreakdownWidgetNotFoundSelector";
import { costBreakdownWidgetByIdSelector } from "../../../../../../../store/dashboards/selectors/widgets/cost-breakdown/widget-view/costBreakdownWidgetByIdSelector";

interface CostBreakdownWidgetContentProps {
  widgetId: string;
  viewName?: string;
}

export const CostBreakdownWidgetContent: FC<
  CostBreakdownWidgetContentProps
> = ({ widgetId, viewName }) => {
  const widgetDataNotFound = useAppSelector((state) =>
    costBreakdownWidgetNotFoundSelector(state, widgetId),
  );

  const widget = useAppSelector((state) =>
    costBreakdownWidgetByIdSelector(state, widgetId),
  );

  if (widgetDataNotFound) {
    return (
      <DashboardNotFoundWidget name={viewName ?? ""} label="billing report" />
    );
  }

  if (!widget) {
    return <DashboardEmptyWidgetState infoCards={infoCards} />;
  }

  if (widget.chart_type === "table") {
    return (
      <CostBreakdownWidgetTableView
        widgetId={widgetId}
        dateLabel={widget.dates?.label}
      />
    );
  }

  if (widget.chart_type === "numeral") {
    return (
      <CostBreakdownWidgetNumeralView
        widgetId={widgetId}
        dateLabel={widget.dates?.label}
      />
    );
  }

  return (
    <CostBreakdownWidgetChartView
      widgetId={widgetId}
      dateDataPoint={widget.date_granularity}
      dateLabel={widget.dates?.label}
      chartType={widget.chart_type as ChartType}
    />
  );
};

const infoCards = [
  {
    value: "-",
    title: "Total Cost",
  },
  {
    value: "-",
    title: "Filtered Resources",
  },
];
