import { FC, useCallback, useMemo } from "react";
import { Grid } from "@mui/material";
import { FilterItemNodeWithId } from "../../../../../utils/types/common";
import { FilterKeySelect, FilterWithKey } from "../filter-key/FilterKeySelect";
import { FilterValue } from "../filter-value/FilterValue";
import {
  KeyValuePair,
  useGetUsersMeOrganisationsCurrentResourceExplorerFiltersByFilterProviderAndFilterTypeValuesQuery as useValuesQuery,
} from "../../../../../../../../../services/cloudchipr.api";

interface DimensionFilterItemProps {
  index: number;
  filter: FilterItemNodeWithId;
  onFilterChange(filter: FilterItemNodeWithId, index: number): void;
}

export const DimensionFilterItem: FC<DimensionFilterItemProps> = ({
  filter,
  onFilterChange,
  index,
}) => {
  const filterValueKeyObject = useMemo(() => {
    return (filter.value ?? {
      key: "",
      value: "",
    }) as KeyValuePair;
  }, [filter.value]);

  const { data } = useValuesQuery(
    {
      filterProvider: filter.filter_provider,
      filterType: filter.type,
      key: filterValueKeyObject.key,
    },
    { skip: !filterValueKeyObject.key },
  );

  const filterValueChangeHandler = useCallback(
    (value: FilterItemNodeWithId["value"]) => {
      onFilterChange({ ...filter, value }, index);
    },
    [index, onFilterChange, filter],
  );

  const cleanedFilter = useMemo(() => {
    if (!data) {
      return filter;
    }

    if (typeof filterValueKeyObject.value === "string") {
      return filter;
    }

    return {
      ...filter,
      value: {
        ...filterValueKeyObject,
        value: filterValueKeyObject.value.filter((id) =>
          data.some(({ value }) => value === id),
        ),
      },
    };
  }, [filter, filterValueKeyObject, data]);

  return (
    <Grid container columnSpacing={0.5}>
      <Grid item xs={6}>
        <FilterKeySelect
          index={index}
          label="Select Dimension"
          filter={cleanedFilter as FilterWithKey}
          onFilterChange={onFilterChange}
        />
      </Grid>

      <Grid item xs={6}>
        <FilterValue
          label="Category"
          filter={cleanedFilter}
          onChange={filterValueChangeHandler}
        />
      </Grid>
    </Grid>
  );
};
