import { RootState } from "../../../store";
import {
  ResourceFiltersWithAction,
  ResourceType,
} from "../../../../services/cloudchipr.api";
import { allResourcesByProvider } from "../../../../utils/constants/resources/resourceGroupsByProvider";
import { getFirstParentInHierarchyResourceType } from "../../../../utils/helpers/resources/getFirstParentInHierarchyResourceType";
import { liveUsageMgmtProviderSelector } from "../store-selectors/liveUsageMgmtProviderSelector";
import { liveUsageMgmtRegionsSelector } from "../store-selectors/liveUsageMgmtRegionsSelector";
import { liveUsageMgmtAccountIdsSelector } from "../store-selectors/liveUsageMgmtAccountIdsSelector";
import { liveUsageMgmtAppliedFiltersByResourceTypeSelector } from "../liveUsageMgmtAppliedFiltersByResourceTypeSelector";
import { allResourcesResourceType } from "../../../../components/pages/live-usage-mgmt/utils/constants/common";

export const liveUsageMgmtAccountsWorkflowDataSelector = (
  state: RootState,
  resourceType: string | null,
) => {
  const accountIds = liveUsageMgmtAccountIdsSelector(state);
  const provider = liveUsageMgmtProviderSelector(state);
  const regions = liveUsageMgmtRegionsSelector(state);

  if (!provider || !accountIds) {
    return null;
  }

  const parentResourceType =
    getFirstParentInHierarchyResourceType(resourceType as ResourceType) ??
    resourceType;

  const resourceTypes =
    resourceType === allResourcesResourceType
      ? allResourcesByProvider.get(provider) ?? []
      : [parentResourceType as ResourceType];

  const filters: ResourceFiltersWithAction[] = resourceTypes.map((rt) => {
    const emptyFilterTemplate = {
      type: rt,
      filter_items: [],
      filter_groups: [],
      operators: [],
    };

    return {
      filter:
        liveUsageMgmtAppliedFiltersByResourceTypeSelector(state, rt) ??
        emptyFilterTemplate,
    };
  });

  return {
    provider,
    filters,
    resourceType: parentResourceType as ResourceType,
    accountIds: accountIds,
    regions: regions ?? [],
  };
};
