import { ColDef } from "ag-grid-community";

export const cloudProviderComparatorFn: ColDef["comparator"] = (
  _,
  __,
  nodeA,
  nodeB,
) => {
  const current = nodeA.data?.target?.account?.provider ?? "";
  const next = nodeB.data?.target?.account?.provider ?? "";

  return current.localeCompare(next);
};
