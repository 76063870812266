import { FC, Fragment, useCallback } from "react";
import { CostAndUsageSumWidgetSetupContent } from "./content/CostAndUsageSumWidgetSetupContent";
import { CostAndUsageDateRangeSelect } from "./app-bar/CostAndUsageDateRangeSelect";
import { WidgetSetupHeader } from "../common/WidgetSetupHeader";
import { WidgetSetupLayout } from "../common/WidgetSetupLayout";
import { WidgetsViewsHierarchySelect } from "../common/widgets-views-hierarchy-select/WidgetsViewsHierarchySelect";
import { scrollPageToBottom } from "../../../../../../utils/helpers/page-scrolling-functions";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../store/hooks";
import { costAndUsageSumREViewChangeThunk } from "../../../../../../../../store/dashboards/thunks/widgets/cost-and-usage/widget-change/costAndUsageSumREViewChangeThunk";
import { costAndUsageSumSetupPropertyByKeySelector } from "../../../../../../../../store/dashboards/selectors/setups/cost-and-usage/costAndUsageSumSetupPropertyByKeySelector";
import { buildCostAndUsageSumWidgetThunk } from "../../../../../../../../store/dashboards/thunks/widgets/cost-and-usage/actions/buildCostAndUsageSumWidgetThunk";
import { costAndUsageSubmitButtonDisabledSelector } from "../../../../../../../../store/dashboards/selectors/setups/cost-and-usage/costAndUsageSubmitButtonDisabledSelector";

interface CostAndUsageSetupProps {
  onClose(): void;
  onSubmit(): void;
}

export const CostAndUsageSumWidgetSetup: FC<CostAndUsageSetupProps> = ({
  onClose,
  onSubmit,
}) => {
  const dispatch = useAppDispatch();

  const viewId = useAppSelector(
    costAndUsageSumSetupPropertyByKeySelector("viewId"),
  );

  const setupId = useAppSelector(
    costAndUsageSumSetupPropertyByKeySelector("id"),
  );
  const disabled = useAppSelector(costAndUsageSubmitButtonDisabledSelector);

  const viewChangeHandler = useCallback(
    (id: string) => {
      dispatch(costAndUsageSumREViewChangeThunk(id));
    },
    [dispatch],
  );

  const submitHandler = useCallback(async () => {
    const responseId = await dispatch(
      buildCostAndUsageSumWidgetThunk(),
    ).unwrap();
    onSubmit();

    if (responseId && !setupId) {
      scrollPageToBottom();
    }
  }, [dispatch, setupId, onSubmit]);

  return (
    <Fragment>
      <WidgetSetupHeader
        disabled={disabled}
        onAdd={submitHandler}
        onCancel={onClose}
      />

      <WidgetSetupLayout
        empty={!viewId ? "Select Billing Report to get started." : ""}
        appBar={
          <Fragment>
            <WidgetsViewsHierarchySelect
              selectedItemId={viewId}
              onChange={viewChangeHandler}
            />

            <CostAndUsageDateRangeSelect />
          </Fragment>
        }
      >
        <CostAndUsageSumWidgetSetupContent />
      </WidgetSetupLayout>
    </Fragment>
  );
};
