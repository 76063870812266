import { FC, Fragment, useCallback } from "react";
import {
  ListItemIcon,
  MenuItem,
  Theme,
  Tooltip,
  Typography,
  SxProps,
} from "@mui/material";
import { GppBadOutlined, VerifiedUserOutlined } from "@mui/icons-material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { ProtectActionDialog } from "./components/ProtectActionDialog";
import { useAppAbility } from "../../../../../services/permissions";
import { useDialog } from "../../../../../utils/hooks/useDialog.hook";
import {
  EmbeddedUser,
  ProtectionDetails,
} from "../../../../../services/cloudchipr.api";

interface ProtectActionProps {
  id: string;
  onClose(): void;
  source: "view" | "dashboard";
  iconSx?: SxProps<Theme>;
  protectedBy?: EmbeddedUser;
  menuItemSx?: SxProps<Theme>;
  protectionDetails?: ProtectionDetails;
}

export const ProtectAction: FC<ProtectActionProps> = ({
  id,
  iconSx,
  source,
  onClose,
  menuItemSx,
  protectedBy,
  protectionDetails,
}) => {
  const { cannot } = useAppAbility();
  const canNotEditResourceExplorer = cannot("edit", "resource-explorer");
  const { open, openDialog, closeDialog } = useDialog();

  const closeMenusHandler = useCallback(() => {
    onClose();
    closeDialog();
  }, [onClose, closeDialog]);

  return (
    <Fragment>
      <MenuItem
        onClick={openDialog}
        disabled={canNotEditResourceExplorer}
        sx={menuItemSx}
      >
        <ListItemIcon>
          {protectionDetails?.is_protected ? (
            <GppBadOutlined fontSize="small" sx={iconSx} />
          ) : (
            <VerifiedUserOutlined fontSize="small" sx={iconSx} />
          )}
        </ListItemIcon>
        <Typography variant="body2">
          {protectionDetails?.is_protected ? "Unprotect" : "Protect"}
        </Typography>
        <ListItemIcon sx={{ justifyContent: "flex-end" }}>
          <Tooltip
            arrow
            placement="right"
            title={
              protectionDetails?.is_protected
                ? unprotectTooltipTitle
                : protectTooltipTitle
            }
          >
            <InfoOutlinedIcon fontSize="small" />
          </Tooltip>
        </ListItemIcon>
      </MenuItem>

      <ProtectActionDialog
        id={id}
        open={open}
        source={source}
        protectedBy={protectedBy}
        closeMenu={closeMenusHandler}
        protectionDetails={protectionDetails}
      />
    </Fragment>
  );
};

const protectTooltipTitle =
  "Protecting report prevents its settings and preferences from being changed.";
const unprotectTooltipTitle =
  "Unprotect the report to save changes or choose to revert any temporary adjustments.";
