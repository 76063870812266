import { createAsyncThunk } from "@reduxjs/toolkit";
import { cloudChiprApi } from "../../../../services/cloudchipr.api";
import { connectedProvidersSelector } from "../../selectors/connectedProvidersSelector";
import { RootState } from "../../../store";
import { enqueueSnackbarErrorAlert } from "../../../../components/pages/common/snackbar-alert/EnqueueSnackbarErrorAlert";

export const getAllProvidersBillingStatusThunk = createAsyncThunk(
  "resourceExplorer/getAllProvidersBillingStatus",
  async (_, { dispatch, getState }) => {
    const { getV2UsersMeOrganisationsCurrentProvidersByProviderBillingStatus } =
      cloudChiprApi.endpoints;

    const state = getState() as RootState;
    const providers = connectedProvidersSelector(state);

    try {
      const promises = providers.map((provider) => {
        return dispatch(
          getV2UsersMeOrganisationsCurrentProvidersByProviderBillingStatus.initiate(
            {
              provider,
            },
          ),
        ).unwrap();
      });

      Promise.all(promises);
    } catch (e) {
      // @ts-expect-error todo: fix api spec;
      enqueueSnackbarErrorAlert(e?.data?.message);
    }
  },
);
