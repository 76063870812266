import { GridState, StateUpdatedEvent } from "ag-grid-community";
import { useCallback, useEffect, useState } from "react";
import { Task } from "../../../../../../services/cloudchipr.api";
import { ConfigToRememberStateInStorage } from "../types/gridProps";

const disabledSources: StateUpdatedEvent["sources"] = [
  "gridInitializing",
  "focusedCell",
  "scroll",
];

export const useGridStateMemoization = (
  args?: ConfigToRememberStateInStorage,
) => {
  const { stateByDefault, uniqueNameAsKey } = args ?? {};

  const key = `c8r:${uniqueNameAsKey}-tasks-grid-state`;

  const gridStateByDefault = JSON.stringify(stateByDefault ?? {});
  const itemFromStorage = localStorage.getItem(key) ?? gridStateByDefault;
  const initialState = JSON.parse(itemFromStorage) as GridState;

  const [currentState, setCurrentState] = useState<GridState>();

  const onStateUpdated = useCallback((params: StateUpdatedEvent<Task>) => {
    const noNeedForUpdate = disabledSources.some((source) =>
      params.sources.includes(source),
    );

    if (noNeedForUpdate) {
      return;
    }

    setCurrentState(params.state);
  }, []);

  useEffect(() => {
    if (!uniqueNameAsKey) {
      return;
    }

    if (currentState) {
      localStorage.setItem(key, JSON.stringify(currentState));
    }
  }, [key, uniqueNameAsKey, currentState]);

  if (!uniqueNameAsKey) {
    return {
      onStateUpdated: undefined,
      initialState: undefined,
    };
  }

  return {
    onStateUpdated,
    initialState,
  };
};
