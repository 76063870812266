import { FC, useMemo } from "react";
import { useDidMount } from "rooks";
import { AutomationPreviewTable } from "../../../common/components/form/helper-components/AutomationPreviewTable";
import { useAppDispatch, useAppSelector } from "../../../../../../store/hooks";
import { automationFrequencySelector } from "../../../../../../store/automations/selectros/common/automationFrequencySelector";
import { automationStartDateSelector } from "../../../../../../store/automations/selectros/common/fields/automationStartDateSelector";
import { automationEndDateSelector } from "../../../../../../store/automations/selectros/common/fields/automationEndDateSelector";
import { automationTimeZoneSelector } from "../../../../../../store/automations/selectros/common/fields/automationTimeZoneSelector";
import { getTimeZoneObject } from "../../../../../../utils/helpers/date-time/timeZones";
import { fetchWorkflowNextRunsThunk } from "../../../../../../store/automations/thunks/wokrflows/fetchWorkflowNextRunsThunk";
import { AutomationSectionCard } from "../../../common/components/form/helper-components/AutomationSectionCard";
import { offHoursSchedulerSelector } from "../../../../../../store/automations/selectros/off-hours/offHoursSchedulerSelector";
import { useOffHoursNextRunDates } from "../../../../schedule/off-hours/utils/hooks/useOffHoursNextRunDates.hook";
import { useOffHoursPreviewDatesInfoHook } from "../../../../schedule/off-hours/utils/hooks/useOffHoursPreviewDatesInfo.hook";
import { generateCronForPayload } from "../../../../../../store/automations/utils/helpers/cron/generateCronForPayload";
import { getAutomationPreviewFormatDate } from "../../../../schedule/common/utils/helpers/common";

export const OffHoursSchedulePreview: FC = () => {
  const dispatch = useAppDispatch();
  const timeZone = useAppSelector(automationTimeZoneSelector);
  const startDate = useAppSelector(automationStartDateSelector);
  const endDate = useAppSelector(automationEndDateSelector);
  const automationFrequency = useAppSelector(automationFrequencySelector);
  const scheduler = useAppSelector(offHoursSchedulerSelector);

  const stopCron = generateCronForPayload(
    automationFrequency,
    scheduler?.stopTime ?? null,
    scheduler?.repeatValue,
    scheduler?.stopWeekDays,
  );
  const startCron = generateCronForPayload(
    automationFrequency,
    scheduler?.startTime ?? null,
    scheduler?.repeatValue,
    scheduler?.startWeekDays,
  );

  const startRunDate =
    automationFrequency === "once" ? scheduler?.startTime : startDate;
  const endRunDate =
    automationFrequency === "once" ? scheduler?.stopTime : endDate;

  const { nextRunStartDate: nextStartRun, nextRunStopDate: nextStopRun } =
    useOffHoursNextRunDates({
      endDate: endRunDate ?? "",
      startDate: startRunDate ?? "",
      startCron,
      stopCron,
      timeZone,
    });

  const { nextRunStartDate, nextRunStopDate, frequency } =
    useOffHoursPreviewDatesInfoHook({
      timeZone,
      startCron,
      stopCron,
      nextStartRun,
      nextStopRun,
    });

  const instanceStop = frequency?.at(0)?.tooltip;
  const instanceStart = frequency?.at(1)?.tooltip;

  const rows = useMemo(() => {
    const rows = [];

    if (instanceStart && instanceStop) {
      rows.push(
        {
          title: "Instance STOP",
          label: instanceStop,
        },
        {
          title: "Instance START",
          label: instanceStart,
        },
      );
    }

    rows.push(
      { title: "Next stop date", label: nextRunStopDate },
      { title: "Next start date", label: nextRunStartDate },
      {
        title: "Begins",
        label: startDate ? getAutomationPreviewFormatDate(startDate) : "now",
      },
      {
        title: "Ends",
        label: endDate ? getAutomationPreviewFormatDate(endDate) : "never",
      },
      {
        title: "Time zone",
        label: getTimeZoneObject(timeZone).name,
      },
    );

    return rows;
  }, [
    endDate,
    startDate,
    nextRunStartDate,
    nextRunStopDate,
    timeZone,
    instanceStart,
    instanceStop,
  ]);

  useDidMount(() => {
    dispatch(fetchWorkflowNextRunsThunk());
  });

  return (
    <AutomationSectionCard title="Set Schedule" icon="schedule" preview>
      <AutomationPreviewTable rows={rows} />
    </AutomationSectionCard>
  );
};
