import { FC, Fragment, useCallback, useState } from "react";
import {
  ListItemIcon,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from "@mui/material";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { useFlag } from "@unleash/proxy-client-react";
import { useToggle } from "rooks";
import { DashboardV2ItemRenameDialog } from "./dialogs/DashboardV2ItemRenameDialog";
import { DashboardV2ItemDeleteDialog } from "./dialogs/DashboardV2ItemDeleteDialog";
import { DashboardV2ItemMoveAction } from "./actions/move-action/DashboardV2ItemMoveAction";
import { DashboardV2AddWidgetAction } from "./actions/DashboardV2AddWidgetAction";
import { DashboardV2ItemDuplicateDialog } from "./dialogs/DashboardV2ItemDuplicateDialog";
import { CopyURLAction } from "../../../../../common/copy-url/CopyURLAction";
import { navigationHierarchyItemNoDndKey } from "../../../sortable-tree/utils/constants";
import { NavigationItemsVisibilityType } from "../../../../../../store/navigation/utils/types";
import { SendReportActionItem } from "../../../../../pages/common/send-report/SendReportActionItem";
import { ReportsBySourceActionItem } from "../../../../../pages/common/reports-by-source/ReportsBySourceActionItem";
import { ReportsBySourceDrawer } from "../../../../../pages/common/reports-by-source/ReportsBySourceDrawer";
import {
  EmbeddedUser,
  ProtectionDetails,
} from "../../../../../../services/cloudchipr.api";
import { ProtectAction } from "../../../common/protect-action/ProtectAction";

interface DashboardV2ItemListItemActionMenuProps {
  id: string;
  name: string;
  open: boolean;
  closeMenu(): void;
  anchor: HTMLElement | null;
  folderId?: string;
  visibility: NavigationItemsVisibilityType;
  renderReportsBySourceActionItem?: boolean;
  protectedBy?: EmbeddedUser;
  protectionDetails?: ProtectionDetails;
}

export const DashboardV2ItemListItemActionMenu: FC<
  DashboardV2ItemListItemActionMenuProps
> = ({
  name,
  id,
  open,
  anchor,
  closeMenu,
  folderId,
  visibility,
  renderReportsBySourceActionItem,
  protectedBy,
  protectionDetails,
}) => {
  const [selectedActionType, setSelectedActionType] = useState<
    "delete" | "rename" | "duplicate" | null
  >();
  const [reportsDrawerOpen, toggleReportsDrawerOpen] = useToggle(false);
  const enableSubscriptions = useFlag("EnableSubscriptionsPage");

  const enableResourceExplorerDuplicate = useFlag(
    "EnableResourceExplorerDuplicate",
  );

  const deleteClickHandler = useCallback(() => {
    setSelectedActionType("delete");
  }, []);

  const renameClickHandler = useCallback(() => {
    setSelectedActionType("rename");
  }, []);

  const duplicateClickHandler = useCallback(() => {
    setSelectedActionType("duplicate");
  }, []);

  const closeDialog = useCallback(() => {
    closeMenu();
    setSelectedActionType(null);
  }, [closeMenu]);

  const reportsDrawerOpenHandler = useCallback(() => {
    toggleReportsDrawerOpen();
    closeMenu();
  }, [closeMenu, toggleReportsDrawerOpen]);

  return (
    <Fragment>
      <Menu
        open={open}
        onClose={closeMenu}
        anchorEl={anchor}
        {...navigationHierarchyItemNoDndKey}
      >
        <Tooltip
          title={
            protectionDetails?.is_protected
              ? "Protected dashboards cannot be modified."
              : ""
          }
          arrow
          placement="right"
        >
          <span>
            <MenuItem
              onClick={renameClickHandler}
              sx={menuItemSx}
              disabled={!!protectionDetails?.is_protected}
            >
              <ListItemIcon>
                <EditOutlinedIcon fontSize="small" sx={iconSx} />
              </ListItemIcon>
              <Typography variant="body2">Rename</Typography>
            </MenuItem>
          </span>
        </Tooltip>

        {enableSubscriptions && (
          <SendReportActionItem
            sourceType="dashboard"
            sourceId={id}
            menuItemSx={menuItemSx}
            iconSx={iconSx}
            onClose={closeMenu}
          />
        )}

        <CopyURLAction
          iconSx={iconSx}
          link={`${window.location.host}/dashboards/${id}`}
          onClose={closeMenu}
          menuItemSx={menuItemSx}
        />

        {enableSubscriptions && !renderReportsBySourceActionItem && (
          <ReportsBySourceActionItem
            sourceId={id}
            sourceType="dashboard"
            onClick={reportsDrawerOpenHandler}
            menuItem
            menuItemSx={menuItemSx}
            iconSx={iconSx}
          />
        )}

        <DashboardV2AddWidgetAction
          iconSx={iconSx}
          dashboardId={id}
          onClose={closeMenu}
          menuItemSx={menuItemSx}
          isProtected={!!protectionDetails?.is_protected}
        />

        {visibility === "visible_to_everyone" && (
          <ProtectAction
            id={id}
            iconSx={iconSx}
            source="dashboard"
            onClose={closeMenu}
            menuItemSx={menuItemSx}
            protectedBy={protectedBy}
            protectionDetails={protectionDetails}
          />
        )}

        <DashboardV2ItemMoveAction
          id={id}
          name={name}
          folderId={folderId}
          onClose={closeMenu}
          visibility={visibility}
          isProtected={!!protectionDetails?.is_protected}
        />

        {enableResourceExplorerDuplicate && (
          <MenuItem onClick={duplicateClickHandler} sx={menuItemSx}>
            <ListItemIcon>
              <ContentCopyIcon fontSize="small" sx={iconSx} />
            </ListItemIcon>
            <Typography variant="body2">Duplicate</Typography>
          </MenuItem>
        )}

        <Tooltip
          title={
            protectionDetails?.is_protected
              ? "Protected dashboards cannot be modified."
              : ""
          }
          arrow
          placement="right"
        >
          <span>
            <MenuItem
              onClick={deleteClickHandler}
              sx={menuItemSx}
              disabled={!!protectionDetails?.is_protected}
            >
              <ListItemIcon>
                <DeleteOutlineIcon fontSize="small" sx={iconSx} />
              </ListItemIcon>
              <Typography variant="body2">Delete</Typography>
            </MenuItem>
          </span>
        </Tooltip>
      </Menu>

      {selectedActionType === "delete" && (
        <DashboardV2ItemDeleteDialog
          open
          id={id}
          name={name}
          closeMenu={closeDialog}
          visibility={visibility}
        />
      )}

      {selectedActionType === "rename" && (
        <DashboardV2ItemRenameDialog
          open
          id={id}
          name={name}
          closeMenu={closeDialog}
        />
      )}

      {selectedActionType === "duplicate" && (
        <DashboardV2ItemDuplicateDialog
          open
          id={id}
          folderId={folderId}
          name={name}
          visibility={visibility}
          closeMenu={closeDialog}
        />
      )}

      <ReportsBySourceDrawer
        sourceId={id}
        open={reportsDrawerOpen}
        sourceType="view"
        toggleOpen={toggleReportsDrawerOpen}
      />
    </Fragment>
  );
};

const menuItemSx = {
  pl: 1,
  pr: 1.5,
};

const iconSx = {
  minWidth: 28,
};
