import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  setResourceExplorerData,
  setResourceExplorerFilterTree,
  setResourceExplorerId,
} from "../../resourceExplorerSlice";
import { getResourceExplorerViewByIdThunk } from "../../../common/thunks/resource-explorer/getResourceExplorerViewByIdThunk";
import { addIdsToFilterTreeItems } from "../../../../components/pages/common/filters-tree/utils/helpers/addIdsToFilterTreeItems";
import { resourceExplorerDefaultData } from "../../../../components/pages/resource-explorer/components/resource-explorer-card/utils/constants/common";
import { enqueueSnackbarErrorAlert } from "../../../../components/pages/common/snackbar-alert/EnqueueSnackbarErrorAlert";

export const getResourceExplorerViewByIdAndSetDataThunk = createAsyncThunk(
  "resourceExplorer/getResourceExplorerViewByIdAndSetData",
  async (viewId: string, { dispatch }) => {
    try {
      const response = await dispatch(
        getResourceExplorerViewByIdThunk({ viewId, forceRefetch: true }),
      ).unwrap();

      if (!response) {
        return;
      }

      const filterTree = response?.filter_tree;

      dispatch(
        setResourceExplorerFilterTree(
          filterTree ? addIdsToFilterTreeItems(filterTree) : undefined,
        ),
      );

      const data =
        response.is_default && !response.data
          ? resourceExplorerDefaultData
          : { ...response.data, grouping: response.data.grouping ?? "service" };

      dispatch(setResourceExplorerData(data));
      dispatch(setResourceExplorerId(viewId));
    } catch (e) {
      // @ts-expect-error todo: fix api spec;
      enqueueSnackbarErrorAlert(e?.data?.message);
    }
  },
);
