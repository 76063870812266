import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useCallback } from "react";
import {
  accountIdsSearchParam,
  resourceTypeSearchParam,
} from "../../../../utils/constants/searchParams";
import { ResourceType } from "../../../../../../../services/cloudchipr.api";
import { allResourcesResourceType } from "../../../../utils/constants/common";

export type LiveUsageNavigationResourceType =
  | ResourceType
  | typeof allResourcesResourceType;

export const useLiveUsageMgmtPageNavigate = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const pathname = location.pathname;
  const [searchParams] = useSearchParams();
  const accountIds = searchParams.get(accountIdsSearchParam);

  return useCallback(
    (resourceType: LiveUsageNavigationResourceType) => {
      navigate({
        pathname,
        search: `?${accountIdsSearchParam}=${accountIds}&${resourceTypeSearchParam}=${resourceType}`,
      });
    },
    [navigate, pathname, accountIds],
  );
};
