import moment from "moment";
import {
  DateDataPoint,
  DateLabel,
  Dates,
  DateUnit,
} from "../../../../services/cloudchipr.api";

export const getGranularityDataPointByDates = (dates: Dates): DateDataPoint => {
  if (dates.label) {
    return getDataPointByDateLabel(dates.label);
  }

  if (dates.unit && dates.value) {
    return getDataPointByDateUnit(dates.unit, dates.value);
  }

  if (dates.from && dates.to) {
    return getDataPointByDateRange(dates.from, dates.to);
  }

  return "daily";
};

const getDataPointByDateLabel = (label: DateLabel) => {
  const monthlyLabels = new Set([
    "last_three_months",
    "past_ninety_days",
    "past_one_hundred_eighty_days",
    "year_to_date",
    "last_six_months",
    "last_twelve_months",
    "quarter_to_date",
    "last_quarter",
  ]);

  return monthlyLabels.has(label) ? "monthly" : "daily";
};

const getDataPointByDateUnit = (dateUnit: DateUnit | "year", value: number) => {
  if (dateUnit === "week" && value > 1) {
    return "weekly";
  }

  if (dateUnit === "month" && value > 1) {
    return "monthly";
  }

  if (dateUnit === "year") {
    return value === 1 ? "monthly" : "yearly";
  }

  if (dateUnit === "day" && value > 30) {
    return "monthly";
  }

  return "daily";
};

const getDataPointByDateRange = (from: string, to: string) => {
  const fromDate = moment(from, "YYYY-MM-DD");
  const toDate = moment(to, "YYYY-MM-DD");

  const daysRange = Math.abs(fromDate.diff(toDate, "days"));

  return daysRange > 30 ? "monthly" : "daily";
};
