import { createAsyncThunk } from "@reduxjs/toolkit";
import { checkForOrganizationExistenceThunk } from "./checkForOrganizationExistenceThunk";
import { RootState } from "../../../store";
import { cloudChiprApi } from "../../../../services/cloudchipr.api";
import { getCommitmentsRecommendationsPostFixedCacheKey } from "../../utils/helpers/fixedCacheKeys";
import { commitmentsCurrentOrgIdSelector } from "../../selectors/common/commitmentsCurrentOrgIdSelector";
import { enqueueSnackbarErrorAlert } from "../../../../components/pages/common/snackbar-alert/EnqueueSnackbarErrorAlert";

export const getCommitmentsRecommendationsThunk = createAsyncThunk(
  "commitments/getCommitmentsRecommendations",
  async (_: void, { dispatch, getState }) => {
    dispatch(checkForOrganizationExistenceThunk());

    const state = getState() as RootState;

    const {
      postUsersMeOrganisationsCurrentSavingsPlansRecommendationsSummary,
    } = cloudChiprApi.endpoints;

    const orgId = commitmentsCurrentOrgIdSelector(state);

    try {
      await dispatch(
        postUsersMeOrganisationsCurrentSavingsPlansRecommendationsSummary.initiate(
          { body: { provider_organisation_id: orgId } },
          {
            fixedCacheKey:
              getCommitmentsRecommendationsPostFixedCacheKey(orgId),
          },
        ),
      ).unwrap();
    } catch (e) {
      // @ts-expect-error todo: fix api spec;
      enqueueSnackbarErrorAlert(e?.data?.message);
      return null;
    }
  },
);
