import { createAsyncThunk } from "@reduxjs/toolkit";
import { getUtilizationAlertsThunk } from "./getUtilizationAlertsThunk";
import { RootState } from "../../../store";
import { cloudChiprApi } from "../../../../services/cloudchipr.api";
import { alertsDataSelector } from "../../selectors/common/data/alertsDataSelector";
import {
  createUtilizationAlertFixedCacheKey,
  editUtilizationAlertFixedCacheKey,
} from "../../utils/constants/fixedCacheKeys";
import { resetAlertsData, toggleAlertsOpenDrawer } from "../../alertsSlice";
import { utilizationAlertPayloadDataSelector } from "../../selectors/utilization-alerts/utilizationAlertPayloadDataSelector";
import { enqueueSnackbarErrorAlert } from "../../../../components/pages/common/snackbar-alert/EnqueueSnackbarErrorAlert";

export const saveUtilizationAlertThunk = createAsyncThunk(
  "alerts/saveUtilizationAlert",
  async (_, { dispatch, getState }) => {
    const state = getState() as RootState;

    const {
      patchUsersMeOrganisationsCurrentAlertsUtilizationAndCoverageByAlertId,
      postUsersMeOrganisationsCurrentAlertsUtilizationAndCoverage,
    } = cloudChiprApi.endpoints;

    const data = alertsDataSelector(state);
    const body = utilizationAlertPayloadDataSelector(state);

    try {
      let response;

      if (data.id) {
        response = await dispatch(
          patchUsersMeOrganisationsCurrentAlertsUtilizationAndCoverageByAlertId.initiate(
            {
              alertId: data.id,
              body,
            },
            { fixedCacheKey: editUtilizationAlertFixedCacheKey },
          ),
        );
      } else {
        response = await dispatch(
          postUsersMeOrganisationsCurrentAlertsUtilizationAndCoverage.initiate(
            {
              body,
            },
            { fixedCacheKey: createUtilizationAlertFixedCacheKey },
          ),
        ).unwrap();
      }

      dispatch(toggleAlertsOpenDrawer());
      dispatch(resetAlertsData());

      await dispatch(getUtilizationAlertsThunk()).unwrap();

      return response;
    } catch (e) {
      // @ts-expect-error todo: fix api spec;
      enqueueSnackbarErrorAlert(e?.data?.message);
    }
  },
);
