import { GetQuickFilterTextParams } from "ag-grid-community";
import { Schedule } from "../../../../../../../services/cloudchipr.api";

export const quickFilterNameText = (
  params: GetQuickFilterTextParams<Schedule>,
) => {
  const schedule = params.data;

  return `${schedule.name} ${schedule.description}`;
};
