import { FC, useRef } from "react";
import {
  Box,
  Chip,
  IconButton,
  Link,
  Stack,
  Tooltip,
  TypographyProps,
} from "@mui/material";
import {
  CloudOutlined,
  PlayCircleOutlined,
  OpenInNew,
} from "@mui/icons-material";
import { AccountStatusChip } from "./chips/account-status-chip/AccountStatusChip";
import { ProviderIcon } from "./ProviderIcon";
import { TypographyWithTooltip } from "./TypographyWithTooltip";
import { AccountAccessTypeChip } from "./chips/account-access-type-chip/AccountAccessTypeChip";
import { CopyIconButton } from "./CopyIconButton";
import {
  AccountStatus,
  ProviderAccessType,
  ProviderType,
} from "../../services/cloudchipr.api";
import { useHover } from "../../utils/hooks/useHover.hook";
import { getProviderName } from "../../utils/helpers/providers/getProviderName";

interface AccountInfoDataProps extends TypographyProps {
  provider?: ProviderType;
  accountStatus?: AccountStatus;
  accessType?: ProviderAccessType;
  accountName?: string | null;
  accountId?: string;
  chipView?: boolean;
  link?: string;
  providerLink?: string;
  linkTooltipTitle?: string;
  liveUsageIcon?: boolean;
  nameIdCopiable?: boolean;
  nameTypographyProps?: TypographyProps;
  idTypographyProps?: TypographyProps;
}

export const AccountInfoData: FC<AccountInfoDataProps> = ({
  accountName,
  accountStatus,
  accountId,
  provider,
  maxWidth,
  accessType,
  link,
  chipView,
  providerLink,
  liveUsageIcon,
  linkTooltipTitle,
  nameIdCopiable,
  nameTypographyProps,
  idTypographyProps,
}) => {
  const statusChipRef = useRef<null | HTMLDivElement>(null);
  const { ref, hovered } = useHover();

  if (chipView) {
    return (
      <Chip
        variant="outlined"
        avatar={provider ? <ProviderIcon provider={provider} /> : undefined}
        sx={{ pl: 1 }}
        size="small"
        label={
          <TypographyWithTooltip
            title={accountName || accountId || ""}
            fontSize="inherit"
            maxWidth={maxWidth}
          />
        }
      />
    );
  }

  const asName = accountName || accountId;

  return (
    <Stack
      direction="row"
      spacing={1}
      alignItems="center"
      ref={link || nameIdCopiable ? ref : undefined}
      overflow="hidden"
    >
      {provider && <ProviderIcon provider={provider} />}

      <Stack
        direction="row"
        spacing={0.5}
        alignItems="center"
        overflow="hidden"
      >
        <Stack overflow="hidden">
          {asName && (
            <TypographyWithTooltip
              title={asName}
              maxWidth={maxWidth}
              {...nameTypographyProps}
            />
          )}

          {accountId && accountId !== asName && (
            <TypographyWithTooltip
              title={accountId}
              color="text.disabled"
              variant="body2"
              maxWidth={maxWidth}
              {...idTypographyProps}
            />
          )}
        </Stack>

        {nameIdCopiable && (
          <CopyIconButton
            data={`${accountName} \n ${accountId}`}
            visible={hovered}
          />
        )}
      </Stack>

      <Stack direction="row" spacing={0.5} alignItems="center">
        {providerLink && provider && (
          <Tooltip
            title={`View in ${getProviderName(provider, {
              title: true,
            })}.`}
            arrow
            placement="top"
          >
            <IconButton
              size="small"
              component={Link}
              href={providerLink}
              sx={{
                display: hovered ? "flex" : "none",
                visibility: hovered ? "visible" : "hidden",
              }}
              target="_blank"
            >
              <CloudOutlined fontSize="small" color="action" />
            </IconButton>
          </Tooltip>
        )}

        {link && (
          <Tooltip title={linkTooltipTitle ?? ""} arrow placement="top">
            <IconButton
              size="small"
              component={Link}
              href={link}
              target="_blank"
              sx={{
                display: hovered ? "flex" : "none",
                visibility: hovered ? "visible" : "hidden",
              }}
            >
              {liveUsageIcon ? (
                <PlayCircleOutlined fontSize="small" color="action" />
              ) : (
                <OpenInNew fontSize="small" color="action" />
              )}
            </IconButton>
          </Tooltip>
        )}

        {accountStatus && (
          <Box flexGrow="1" textAlign="right" ref={statusChipRef}>
            <AccountStatusChip status={accountStatus} />
          </Box>
        )}

        {accessType && <AccountAccessTypeChip accessType={accessType} />}
      </Stack>
    </Stack>
  );
};
