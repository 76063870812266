import { createAsyncThunk } from "@reduxjs/toolkit";
import { taskManagementGetTasksThunk } from "./taskManagementGetTasksThunk";
import { cloudChiprApi } from "../../../../services/cloudchipr.api";
import { taskManagementTaskSavingPayloadSelector } from "../../selectors/form/payload/taskManagementTaskSavingPayloadSelector";
import { RootState } from "../../../store";
import {
  setTaskManagementDrawerOpen,
  setTaskManagementTask,
  setTaskManagementTaskTarget,
} from "../../taskManagementSlice";
import { taskCreationFixedCacheKey } from "../../utils/constants/fixedCacheKeys";
import { enqueueSnackbarErrorAlert } from "../../../../components/pages/common/snackbar-alert/EnqueueSnackbarErrorAlert";

export const taskManagementCreateTaskThunk = createAsyncThunk(
  "taskManagement/taskManagementSaveTask",
  async (_, { dispatch, getState }) => {
    const state = getState() as RootState;
    const { postUsersMeOrganisationsCurrentTasks } = cloudChiprApi.endpoints;

    const payload = taskManagementTaskSavingPayloadSelector(state);

    if (!payload) {
      return;
    }

    try {
      const response = await dispatch(
        postUsersMeOrganisationsCurrentTasks.initiate(
          { body: payload },
          { fixedCacheKey: taskCreationFixedCacheKey },
        ),
      ).unwrap();

      dispatch(taskManagementGetTasksThunk());
      dispatch(setTaskManagementTask(null));
      dispatch(setTaskManagementTaskTarget(null));
      dispatch(setTaskManagementDrawerOpen(false));

      return response;
    } catch (e) {
      // @ts-expect-error todo: fix api spec;
      enqueueSnackbarErrorAlert(e?.data?.message);
    }
  },
);
