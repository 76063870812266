import { createAsyncThunk } from "@reduxjs/toolkit";
import { cloudChiprApi } from "../../../../services/cloudchipr.api";
import { setTaskManagementProperties } from "../../taskManagementSlice";
import { enqueueSnackbarErrorAlert } from "../../../../components/pages/common/snackbar-alert/EnqueueSnackbarErrorAlert";

export const getTaskAvailablePropertiesThunk = createAsyncThunk(
  "taskManagement/getTaskAvailableProperties",
  async (forceRefetch: boolean | void, { dispatch }) => {
    const { getUsersMeOrganisationsCurrentTasksProperties } =
      cloudChiprApi.endpoints;

    try {
      const response = await dispatch(
        getUsersMeOrganisationsCurrentTasksProperties.initiate(undefined, {
          forceRefetch: forceRefetch ?? false,
        }),
      ).unwrap();

      if (response) {
        dispatch(setTaskManagementProperties(response));
      }
    } catch (e) {
      // @ts-expect-error todo: fix api spec;
      enqueueSnackbarErrorAlert(e?.data?.message);
    }
  },
);
