import { GetQuickFilterTextParams } from "ag-grid-community";
import { ReportsTableData } from "../../../../../../../../store/reports/utils/types/types";
import { titleBySourceType } from "../../constants/constants";

export const reportsSourceQuickFilter = (
  params: GetQuickFilterTextParams<ReportsTableData>,
) => {
  const report = params.data;

  return `${report.sourceName} ${titleBySourceType.get(report.source_type)}`.toLowerCase();
};
