import { enqueueSnackbar, closeSnackbar, CustomContentProps } from "notistack";
import { EngineeringOutlined } from "@mui/icons-material";
import { Alert, Stack, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

export const enqueueSnackbarErrorAlert = (
  message?: string,
  alertProps?: Partial<CustomContentProps>,
) => {
  const key = enqueueSnackbar(message ?? defaultErrorMessage, {
    variant: "snackbarAlert",
    ...(alertProps ?? {}),
    AlertSnackBarProps: {
      fullComponent: (
        <Alert variant="snackbar" severity="info" icon={false}>
          <Stack spacing={1} direction="row" alignItems="flex-start">
            <EngineeringOutlined fontSize="small" color="info" />

            <Stack>
              <Typography
                variant="body2"
                fontWeight="bold"
                color="text.primary"
              >
                {message ?? defaultErrorMessage}
              </Typography>
              <Typography variant="caption" color="text.primary">
                We’re already working to fix it—thanks for your patience!
              </Typography>
            </Stack>

            <CloseIcon
              onClick={() => closeSnackbar(key)}
              sx={{ fontSize: 20, ml: 1 }}
              color="action"
            />
          </Stack>
        </Alert>
      ),
    },
  });
};

const defaultErrorMessage = "Uh-oh! Something didn’t go as planned.";
