import { createAsyncThunk } from "@reduxjs/toolkit";
import { closeSnackbar, enqueueSnackbar, SnackbarKey } from "notistack";
import { cloudChiprApi } from "../../../../services/cloudchipr.api";
import { getResourceExplorerVisibilityHierarchyThunk } from "../resource-explorer-hierarchy-visibility/getResourceExplorerVisibilityHierarchyThunk";
import { enqueueSnackbarErrorAlert } from "../../../../components/pages/common/snackbar-alert/EnqueueSnackbarErrorAlert";

export const deleteResourceExplorerFolderThunkFixedCacheKey =
  "deleteResourceExplorerFolder-fixedCacheKey";

export const deleteResourceExplorerFolderThunk = createAsyncThunk(
  "resourceExplorer/deleteResourceExplorerFolder",
  async (id: string, { dispatch }) => {
    const {
      deleteV2UsersMeOrganisationsCurrentResourceExplorerFoldersByResourceExplorerFolderId,
    } = cloudChiprApi.endpoints;

    try {
      await dispatch(
        deleteV2UsersMeOrganisationsCurrentResourceExplorerFoldersByResourceExplorerFolderId.initiate(
          {
            resourceExplorerFolderId: id,
          },
          {
            fixedCacheKey: deleteResourceExplorerFolderThunkFixedCacheKey,
          },
        ),
      ).unwrap();
      dispatch(getResourceExplorerVisibilityHierarchyThunk());

      const snackbarId: SnackbarKey = enqueueSnackbar(
        "Folder successfully deleted.",
        {
          variant: "snackbarAlert",
          AlertSnackBarProps: {
            severity: "success",
            onClose: () => closeSnackbar(snackbarId),
          },
        },
      );
    } catch (e) {
      // @ts-expect-error todo: fix api spec;
      enqueueSnackbarErrorAlert(e?.data?.message);
    }
  },
);
