import { FC } from "react";
import {
  TextField,
  Button,
  Typography,
  Grid,
  Alert,
  Stack,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  useGetUsersMeOrganisationsCurrentApiKeysQuery,
  usePostUsersMeOrganisationsCurrentApiKeysMutation,
} from "../../../../../../services/cloudchipr.api";
import { enqueueSnackbarErrorAlert } from "../../../../common/snackbar-alert/EnqueueSnackbarErrorAlert";

const initialValues = {
  name: "",
  expires_in_days: 1,
};

const validationSchema = Yup.object({
  name: Yup.string().required("This field is required"),
  expires_in_days: Yup.number().required().min(1).max(365),
});

interface ApiKeysFormProps {
  hideForm(): void;
}

export const ApiKeysForm: FC<ApiKeysFormProps> = ({ hideForm }) => {
  const [createApiKeys, { isLoading }] =
    usePostUsersMeOrganisationsCurrentApiKeysMutation();
  const { refetch } = useGetUsersMeOrganisationsCurrentApiKeysQuery();

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      try {
        await createApiKeys({
          body: {
            name: values.name,
            expires_in_days: values.expires_in_days,
          },
        }).unwrap();
        await refetch();

        hideForm();
      } catch (e) {
        // @ts-expect-error todo: fix api spec;
        enqueueSnackbarErrorAlert(e?.data?.message);
      }
    },
  });

  return (
    <Grid container justifyContent="center">
      <Grid item pb={5}>
        <Typography variant="h6">Create Api Key</Typography>
        <Alert variant="outlined" severity="info" sx={{ my: 3 }}>
          The API keys have read-only access to Cloudchipr API and your cloud
          resources.
        </Alert>
        <form onSubmit={formik.handleSubmit}>
          <TextField
            fullWidth
            value={formik.values.name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={!!(formik.errors.name && formik.touched.name)}
            type="text"
            label="Key Name*"
            name="name"
            size="small"
            margin="normal"
          />
          <TextField
            fullWidth
            type="number"
            label="Expiration (d)*"
            InputProps={{ inputProps: { min: 1, max: 365 } }}
            value={formik.values.expires_in_days}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            size="small"
            error={
              !!(
                formik.errors.expires_in_days && formik.touched.expires_in_days
              )
            }
            name="expires_in_days"
            margin="normal"
            helperText="* minimum 1 day and maximum 1 year (365 d)."
          />
          <Stack direction="row" justifyContent="end" mt={2} spacing={3}>
            <Button onClick={hideForm} color="tertiary">
              Cancel
            </Button>
            <LoadingButton
              type="submit"
              color="primary"
              variant="contained"
              loading={isLoading}
            >
              Create
            </LoadingButton>
          </Stack>
        </form>
      </Grid>
    </Grid>
  );
};
