import { FC, useCallback } from "react";
import { Button, Dialog, DialogActions } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useSnackbar } from "notistack";
import { AzureSsoFormDialogBody } from "./AzureSsoFormDialogBody";
import {
  useGetUsersMeOrganisationsCurrentSsoOptionsCurrentQuery,
  usePostUsersMeOrganisationsCurrentSsoMutation,
} from "../../../../../../../../../services/cloudchipr.api";
import { currentOrganizationSelector } from "../../../../../../../../../store/organizations/organizationsSelectors";
import { useAppSelector } from "../../../../../../../../../store/hooks";
import { DialogTitleClosable } from "../../../../../../../../common/dialog-utils/DialogTitleClosable";
import { enqueueSnackbarErrorAlert } from "../../../../../../../common/snackbar-alert/EnqueueSnackbarErrorAlert";

interface AzureSsoFormDialogProps {
  open: boolean;
  onClose(): void;
  onSubmit(): void;
}

export const AzureSsoFormDialog: FC<AzureSsoFormDialogProps> = ({
  open,
  onClose,
  onSubmit,
}) => {
  const { data } = useGetUsersMeOrganisationsCurrentSsoOptionsCurrentQuery();

  const organization = useAppSelector(currentOrganizationSelector);

  const [postMetadata, mutationResult] =
    usePostUsersMeOrganisationsCurrentSsoMutation();
  const { enqueueSnackbar } = useSnackbar();

  const handleSSOMetadataSubmit = useCallback(
    (metadata: string) => {
      postMetadata({ body: { metadata, type: "azure_ad" } })
        .unwrap()
        .then(() => {
          onSubmit();
          enqueueSnackbar("You've successfully configured Microsoft SSO!", {
            variant: "snackbarAlert",
            AlertSnackBarProps: {
              severity: "success",
            },
            autoHideDuration: 2000,
          });
        })
        .catch((error) => {
          if (error && "status" in error && error.status === 400) {
            enqueueSnackbarErrorAlert(error?.data?.message, {
              autoHideDuration: 2000,
            });
          }
        });
    },
    [postMetadata, enqueueSnackbar, onSubmit],
  );

  const { dirty, isValid, handleSubmit, handleChange } = useFormik({
    initialValues: {
      metadata: "",
    },
    validationSchema: Yup.object({
      metadata: Yup.string().required("Metadata can't be empty"),
    }),
    onSubmit: (values) => {
      handleSSOMetadataSubmit(values.metadata);
    },
  });

  if (!data?.sp_entity_id || !data?.sso_url || !organization) {
    return null;
  }

  return (
    <Dialog open={open} fullWidth maxWidth="md">
      <DialogTitleClosable title="Enable Microsoft SSO" onClose={onClose} />

      <form onSubmit={handleSubmit}>
        <AzureSsoFormDialogBody
          entityId={data.sp_entity_id}
          replyUrl={data.sso_url}
          onChange={handleChange}
        />

        <DialogActions sx={{ py: 2, px: 3 }}>
          <Button onClick={onClose} color="tertiary">
            Cancel
          </Button>
          <LoadingButton
            variant="contained"
            type="submit"
            loading={mutationResult.isLoading}
            disabled={!dirty || !isValid}
          >
            Enable
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};
