import { themeQuartz } from "ag-grid-community";
import { grey, indigo } from "@mui/material/colors";

export const agGridThemeCustomization = themeQuartz.withParams({
  rowHoverColor: grey[100],
  // checkbox
  checkboxCheckedBackgroundColor: indigo["500"],
  checkboxIndeterminateBackgroundColor: indigo["500"],
  checkboxCheckedBorderColor: indigo["500"],
  checkboxIndeterminateBorderColor: indigo["500"],
});
