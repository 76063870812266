import { FC } from "react";
import { TypographyWithTooltip } from "../../../../common/TypographyWithTooltip";
import type { CustomHeaderProps } from "ag-grid-react";

export const ColumnsInnerHeaderComponent: FC<CustomHeaderProps> = ({
  displayName,
}) => {
  return (
    <TypographyWithTooltip title={displayName} variant="body2" width="100%" />
  );
};
