import { FC, useCallback, useMemo } from "react";
import { Box, Stack, Theme } from "@mui/material";
import { grey } from "@mui/material/colors";
import { CellContext } from "@tanstack/react-table";
import { useFlag } from "@unleash/proxy-client-react";
import { PreviewDataGridToolbar } from "./PreviewDataGridToolbar";
import { PreviewDataGridActionCell } from "./columns/PreviewDataGridActionsCell";
import { getPreviewDataGridActionDateColumn } from "./columns/getPreviewDataGridActionDateColumn";
import {
  ResourceFilters,
  ResourceType,
} from "../../../../../../../../services/cloudchipr.api";
import { CleanActionTypes } from "../../../../../../../../utils/clean-options";
import { ResourceDataType } from "../../../../../../../../store/live-usage-mgmt/utils/types/types";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../store/hooks";
import { useColumnsVisibility } from "../../../../../../live-usage-mgmt/utils/hooks/useColumnsVisibility.hook";
import { previewSelectedResourceIdsByResourceTypeSelector } from "../../../../../../../../store/automations/selectros/workflow/resources-preview/selected-resources/previewSelectedResourceIdsByResourceTypeSelector";
import { setPreviewSelectedResources } from "../../../../../../../../store/schedules/schedulesSlice";
import { getFirstParentInHierarchyResourceType } from "../../../../../../../../utils/helpers/resources/getFirstParentInHierarchyResourceType";
import { getResourceTypeColumns } from "../../../../../../live-usage-mgmt/components/resource-card/resource-card-content/resource-card-data/resource-card-grid/resouce-card-data-grid/utils/data-grid/columns-setup/getResourceTypeColumns";
import { newMetricColumnsByFT } from "../../../../../../live-usage-mgmt/components/resource-card/resource-card-content/resource-card-data/resource-card-grid/resouce-card-data-grid/cells/metrics-cells/utils/helpers/newMetricColumnsEnablenes";
import { expandableResourceTypes } from "../../../../../../live-usage-mgmt/components/resource-card/resource-card-content/resource-card-data/resource-card-grid/resouce-card-data-grid/utils/data-grid/constants";
import { generateColumns } from "../../../../../../live-usage-mgmt/components/resource-card/resource-card-content/resource-card-data/resource-card-grid/resouce-card-data-grid/utils/data-grid/columns-setup/resourcesColumnsGenerator";
import { ColumnSetupType } from "../../../../../../../../storybook/data-grid/utils/types/types";
import { nfVisibilitiesConverter } from "../../../../../../live-usage-mgmt/components/resource-card/resource-card-content/resource-card-data/resource-card-grid/resouce-card-data-grid/utils/nf-tmp-helpers";
import { DataGridProvider } from "../../../../../../../../storybook/data-grid/DataGridProvider";
import { DataGrid } from "../../../../../../../../storybook/data-grid/DataGrid";
import { resourcesDataGridSubRow } from "../../../../../../live-usage-mgmt/components/resource-card/resource-card-content/resource-card-data/resource-card-grid/resouce-card-data-grid/subrow/ResourcesSubRowNF";
import { getResourceTypeName } from "../../../../../../../../utils/helpers/resources/getResourceTypeName";

export interface PreviewDataGridContentProps {
  accountIds: string[];
  regions?: string[];
  scheduleId: string;
  batchId?: string;
  resourceFilter?: ResourceFilters;
  resourceType: ResourceType;
  action: CleanActionTypes;
  scheduleAction: string | null;
  singleGrid: boolean;
  loading: boolean;
  totalMonthlySpending?: number | null;
  data: ResourceDataType[] | null;
  gracePeriodEnabled: boolean;
}

export const PreviewDataGridContent: FC<PreviewDataGridContentProps> = ({
  accountIds,
  resourceFilter,
  regions,
  action,
  scheduleAction,
  singleGrid,
  scheduleId,
  resourceType,
  data,
  loading,
  batchId,
  totalMonthlySpending,
  gracePeriodEnabled,
}) => {
  const dispatch = useAppDispatch();
  const { visibility, setVisibility } = useColumnsVisibility(resourceType);
  const enableMetricsCharts = useFlag("EnableResourceSmallCharts");

  const selectedResources = useAppSelector((state) =>
    previewSelectedResourceIdsByResourceTypeSelector(state, resourceType),
  );

  const onRowSelectionChange = useCallback(
    (resources: Record<string, boolean>): void => {
      dispatch(
        setPreviewSelectedResources({
          resourceType,
          resources,
        }),
      );
    },
    [dispatch, resourceType],
  );

  const parentResourceType =
    getFirstParentInHierarchyResourceType(resourceType) ?? resourceType;

  const columns = useMemo(() => {
    const defaultColumns = getResourceTypeColumns(
      parentResourceType,
      newMetricColumnsByFT(enableMetricsCharts),
    );

    return defaultColumns
      ?.map((column, index) => {
        if (index === defaultColumns?.length - 1 && gracePeriodEnabled) {
          return [column, getPreviewDataGridActionDateColumn()];
        }

        return column;
      })
      .flat();
  }, [gracePeriodEnabled, parentResourceType, enableMetricsCharts]);

  const getActionColumn = useCallback(
    ({ row }: CellContext<ResourceDataType, any>) => {
      const resource = row?.original?.resource;

      if (!resource) {
        return null;
      }

      return (
        <PreviewDataGridActionCell
          scheduleId={scheduleId}
          batchId={batchId}
          accountIds={accountIds}
          resourceFilter={resourceFilter}
          regions={regions}
          resource={resource}
          action={action}
        />
      );
    },
    [action, accountIds, regions, scheduleId, batchId, resourceFilter],
  );

  const columnsSetup = useMemo(() => {
    const config = {
      includeExpander: expandableResourceTypes.has(parentResourceType),
      includeCheckbox: true,
      includeActions: true,
    };

    let generatedColumns = generateColumns(
      columns as ColumnSetupType<ResourceDataType>[],
      config,
    );

    generatedColumns = generatedColumns.map((column, index) => {
      if (index !== generatedColumns.length - 1) {
        return column;
      }

      return { ...column, cell: getActionColumn };
    });

    return generatedColumns;
  }, [columns, parentResourceType, getActionColumn]);

  const memoizedVisibility = useMemo(
    () => nfVisibilitiesConverter(visibility),
    [visibility],
  );

  if (!loading && (!data || !columnsSetup)) {
    return null;
  }

  return (
    <Box mb={singleGrid ? 0 : 3}>
      <DataGridProvider>
        <DataGrid
          data={data ?? []}
          columns={columnsSetup}
          columnResizeMode="onEnd"
          enableRowsVirtualization
          enableStickyColumns={!!data?.length}
          columnSorting={sortingConfig}
          rowExpanding={{
            renderExpandedRowSubRow: resourcesDataGridSubRow,
            parentRowStyles: { bgcolor: grey[100], "& > td": { pl: 4 } },
          }}
          rowSelection={{
            rowSelectionChange: onRowSelectionChange,
            initialSelectedItems: selectedResources,
          }}
          columnVisibility={{
            initialVisibility: memoizedVisibility,
            onColumnsVisibilityChange: setVisibility,
          }}
          toolbar={{
            renderToolbar: (props) => {
              return (
                <PreviewDataGridToolbar
                  {...props}
                  scheduleAction={scheduleAction}
                  loading={loading}
                  action={action}
                  count={data?.length ?? 0}
                  resourceType={resourceType}
                  totalMonthlySpending={totalMonthlySpending}
                />
              );
            },
          }}
          styles={{
            tableHeaderRow: {
              top: 0,
              position: "sticky",
              bgcolor: "grey.200",
              zIndex: (theme: Theme) => theme.zIndex.speedDial - 1,
            },
            tableRow: {
              background: "white",
            },
            tableToolbar: { px: 2 },
            tableContainer: {
              border: `1px solid ${grey[200]}`,
              maxHeight: singleGrid ? "80dvh" : 250,
              "& td": {
                borderRight: `1px solid ${grey[200]}`,
              },
            },
          }}
        />
      </DataGridProvider>

      {!data?.length && !loading && (
        <Stack
          p={1}
          borderTop={0}
          alignItems="center"
          justifyContent="center"
          border={`1px solid ${grey[300]}`}
        >
          No{" "}
          {getResourceTypeName(parentResourceType, {
            type: "long",
          })}{" "}
          Found
        </Stack>
      )}
    </Box>
  );
};

const sortingConfig = {
  initialSort: [{ id: "resource.price_per_month", desc: true }],
};
