import {
  createContext,
  Dispatch,
  FC,
  ReactNode,
  SetStateAction,
  useContext,
  useMemo,
  useState,
} from "react";
import type { GridApi } from "ag-grid-community";

type TaskManagementContextType = {
  gridApi: GridApi | null;
  setGridApi: Dispatch<SetStateAction<GridApi | null>>;
};

const TaskManagementContext = createContext({} as TaskManagementContextType);

export const TaskManagementProvider: FC<{
  children?: ReactNode;
}> = ({ children }) => {
  const [gridApi, setGridApi] = useState<GridApi | null>(null);

  const value = useMemo(() => {
    return { gridApi, setGridApi };
  }, [gridApi, setGridApi]);

  return (
    <TaskManagementContext.Provider value={value}>
      {children}
    </TaskManagementContext.Provider>
  );
};

export const useTaskManagementContext = () => {
  return useContext(TaskManagementContext);
};
