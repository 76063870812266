import { FC } from "react";
import { Divider, Stack, Typography } from "@mui/material";
import { useAppSelector } from "../../../../../../../../../../store/hooks";
import { largestCostChangesSetupPropertyByKeySelector } from "../../../../../../../../../../store/dashboards/selectors/setups/larges-cost-changes/largestCostChangesSetupPropertyByKeySelector";
import { largestCostChangesSetupWidgetTitleSelector } from "../../../../../../../../../../store/dashboards/selectors/setups/larges-cost-changes/largestCostChangesSetupWidgetTitleSelector";
import { largestCostChangesSetupPrevPeriodCostDateDetailsSelector } from "../../../../../../../../../../store/dashboards/selectors/setups/larges-cost-changes/data/largestCostChangesSetupPrevPeriodCostDateDetailsSelector";
import { WidgetSourceInfo } from "../../../../../../widgets/common/widget-header/source-and-name/WidgetSourceInfo";
import { LargestCostChangesWidgetCardHeaderDate } from "../../../../../../widgets/largest-cost-change/components/header/LargestCostChangesWidgetCardHeaderDate";

export const LargestCostChangesWidgetSetupHeader: FC = () => {
  const name = useAppSelector(
    largestCostChangesSetupPropertyByKeySelector("name"),
  );

  const prevDateDetails = useAppSelector(
    largestCostChangesSetupPrevPeriodCostDateDetailsSelector,
  );

  const widgetTitleProps = useAppSelector(
    largestCostChangesSetupWidgetTitleSelector,
  );

  return (
    <Stack direction="row">
      <Stack px={2} py={1}>
        <Typography variant="body1" fontWeight="bold" minHeight={24}>
          {name}
        </Typography>

        <Stack direction="row" alignItems="center" spacing={0.5}>
          <WidgetSourceInfo {...widgetTitleProps} hideDates>
            <LargestCostChangesWidgetCardHeaderDate
              prevDate={prevDateDetails}
              date={widgetTitleProps.dates}
            />
          </WidgetSourceInfo>
        </Stack>
      </Stack>
      <Divider />
    </Stack>
  );
};
