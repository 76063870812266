import moment from "moment";

export const isDateExpired = (expiresAt?: string): boolean => {
  if (!expiresAt) {
    return false;
  }
  const expirationDate = moment(expiresAt);
  const currentDate = moment();

  return expirationDate.isBefore(currentDate);
};
