import { SvgIcon, SvgIconProps, useTheme } from "@mui/material";

const EipSvgIcon = ({ primary }: SvgIconProps & { primary?: boolean }) => {
  const { palette } = useTheme();

  const color = primary ? palette.primary.main : palette.action.active;

  return (
    <SvgIcon>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.2109 9.87516V9.79137L16.2496 9.83327L16.2109 9.87516ZM5.83327 12.0317C4.62073 12.0317 3.63483 11.0458 3.63483 9.83327C3.63483 8.62105 4.62073 7.63483 5.83327 7.63483C7.04548 7.63483 8.03138 8.62105 8.03138 9.83327C8.03138 11.0458 7.04548 12.0317 5.83327 12.0317ZM16.9153 9.61742L14.8708 7.41582L14.4058 7.84782L15.9547 9.51585H8.64749C8.48909 8.10271 7.28831 7 5.83327 7C4.27094 7 3 8.27094 3 9.83327C3 11.3956 4.27094 12.6665 5.83327 12.6665C7.28831 12.6665 8.48909 11.5638 8.64749 10.1507H15.9547L14.4058 11.8187L14.8708 12.2507L16.9153 10.0494C17.0283 9.92754 17.0283 9.73899 16.9153 9.61742Z"
          fill={color}
          fillOpacity="0.87"
        />
      </svg>
    </SvgIcon>
  );
};

EipSvgIcon.muiName = "EipSvgIcon";

export default EipSvgIcon;
