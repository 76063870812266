import { GetQuickFilterTextParams } from "ag-grid-community";
import { ReportsTableData } from "../../../../../../../../store/reports/utils/types/types";

export const reportsNotificationsQuickFilter = (
  params: GetQuickFilterTextParams<ReportsTableData>,
) => {
  const report = params.data;
  const integrations = report.integrations.map(({ name }) => name).join(" ");
  const emails = report.emails?.to.join(" ");

  return `${integrations} ${emails ?? ""}`.toLowerCase();
};
