import React, { useEffect } from "react";
import { createRoot } from "react-dom/client";
import { posthog } from "posthog-js";
import * as Sentry from "@sentry/react";
import { inject } from "@vercel/analytics";
import { Userpilot } from "userpilot";
import {
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
} from "react-router-dom";
import { CssBaseline } from "@mui/material";
import { ModuleRegistry, AllCommunityModule } from "ag-grid-community";
import { AllEnterpriseModule, LicenseManager } from "ag-grid-enterprise";
import reportWebVitals from "./reportWebVitals";
import { Providers } from "./components/Providers";
import {
  POSTHOG_CONFIG,
  SENTRY_CONFIG,
} from "./utils/env-var/env-var-constants";

import { App } from "./App";

ModuleRegistry.registerModules([AllCommunityModule, AllEnterpriseModule]);

LicenseManager.setLicenseKey(
  "Using_this_{AG_Grid}_Enterprise_key_{AG-073124}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Cloudchipr}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{Cloudchipr}_only_for_{2}_Front-End_JavaScript_developers___All_Front-End_JavaScript_developers_working_on_{Cloudchipr}_need_to_be_licensed___{Cloudchipr}_has_been_granted_a_Deployment_License_Add-on_for_{1}_Production_Environment___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{6_December_2025}____[v3]_[01]_MTc2NDk3OTIwMDAwMA==62e0a1111c65b7fcee5c3b20cc8d7036",
);

if (POSTHOG_CONFIG.enabled === "true" && POSTHOG_CONFIG.token) {
  posthog.init(POSTHOG_CONFIG.token, {
    api_host: POSTHOG_CONFIG.api_host,
  });
}

inject();

Sentry.init({
  dsn: SENTRY_CONFIG.dsn,
  enabled: !!process.env.REACT_APP_VERCEL_ENV,
  environment: process.env.REACT_APP_VERCEL_ENV,
  integrations: [
    new Sentry.BrowserTracing({
      // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: SENTRY_CONFIG.tracePropagationTargets,
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      ),
    }),
    new Sentry.Replay(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

Userpilot.initialize(process.env.REACT_APP_USERPILOT_APP_TOKEN!);

const container = document.getElementById("root");
const root = createRoot(container!);

root.render(
  <React.StrictMode>
    <Providers>
      <CssBaseline />
      <App />
    </Providers>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
