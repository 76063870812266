import { FC, useEffect } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useEffectOnceWhen, useIntervalWhen } from "rooks";
import { Divider, LinearProgress, Stack } from "@mui/material";
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";
import { LiveUsageHeader } from "./components/header/LiveUsageHeader";
import { LiveUsageResourcesArea } from "./components/resources/LiveUsageResourcesArea";
import { accountIdsSearchParam } from "./utils/constants/searchParams";
import { generateAccountIdsQueryParams } from "./utils/helpers/generateAccountIdsQueryParams";
import { LiveUsageSidebarPanel } from "./components/LiveUsageSidebarPanel";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { useAppAbility } from "../../../services/permissions";
import { initiateLiveUsageMgmtThunk } from "../../../store/live-usage-mgmt/thunks/initiateLiveUsageMgmtThunk";
import { liveUsagePageStateSelector } from "../../../store/live-usage-mgmt/selectors/store-selectors/liveUsagePageStateSelector";
import { ProviderType } from "../../../services/cloudchipr.api";
import { CreateWorkflowDrawer } from "../automations/workflows/drawer/CreateWorkflowDrawer";
import { CleanV2Dialog } from "../common/clean-v2/clean-dialog/CleanV2Dialog";
import { refreshLiveUsageMgmtPageThunk } from "../../../store/live-usage-mgmt/thunks/refreshLiveUsageMgmtPageThunk";
import { liveUsageMgmtIsPageLoadingSelector } from "../../../store/live-usage-mgmt/selectors/store-selectors/liveUsageMgmtIsPageLoadingSelector";
import { liveUsageMgmtAccountsListSelector } from "../../../store/live-usage-mgmt/selectors/accounts-data/liveUsageMgmtAccountsListSelector";
import { pageHeaderHeight } from "../common/PageHeader";
import { accountOrganisationIdSelector } from "../../../store/accounts/selectors/slice-data/accountOrganisationIdSelector";
import { noOrgAccountOrgId } from "../accounts-group/utils/helpers/noOrganizationConstants";
import { setAccountsProviderOrganisationId } from "../../../store/accounts/accountsSlice";

export const LiveUsageMgmt: FC = () => {
  const dispatch = useAppDispatch();
  const { can } = useAppAbility();
  const canViewAccount = can("view", "account");

  const { provider } = useParams<{
    provider: ProviderType;
  }>();
  const [searchParams] = useSearchParams();
  const accountIds = searchParams.get(accountIdsSearchParam);
  const navigate = useNavigate();

  const orgId = useAppSelector(accountOrganisationIdSelector);
  const accounts = useAppSelector(liveUsageMgmtAccountsListSelector);
  const pageState = useAppSelector(liveUsagePageStateSelector);
  const isPageLoading = useAppSelector(liveUsageMgmtIsPageLoadingSelector);

  useEffect(() => {
    if (!accountIds && accounts?.[0]?.id) {
      navigate(
        `/${provider}/live-usage${generateAccountIdsQueryParams([accounts?.[0]?.id])}`,
      );
    }
  }, [provider, accounts, accountIds, navigate]);

  useEffect(() => {
    if (provider) {
      dispatch(
        initiateLiveUsageMgmtThunk({
          accountIds,
          provider,
        }),
      );
    }
  }, [provider, accountIds, dispatch]);

  useIntervalWhen(
    () => {
      if (accountIds) {
        dispatch(refreshLiveUsageMgmtPageThunk());
      }
    },
    180_000,
    !!(accountIds && canViewAccount),
    false,
  );

  useEffectOnceWhen(
    () => {
      dispatch(
        setAccountsProviderOrganisationId(
          accounts?.at(0)?.group_id || noOrgAccountOrgId,
        ),
      );
    },
    !!accounts?.at(0) && !orgId,
  );

  if (pageState !== "fulfilled") {
    return <LinearProgress />;
  }

  if (!canViewAccount) {
    return null;
  }

  return (
    <Stack height={`calc(100% - ${pageHeaderHeight}px)`}>
      {isPageLoading && <LinearProgress />}

      <LiveUsageHeader />

      <PanelGroup direction="horizontal" autoSaveId="account-page-navigation">
        <LiveUsageSidebarPanel />

        <PanelResizeHandle>
          <Divider orientation="vertical" />
        </PanelResizeHandle>

        <Panel style={{ overflowY: "auto" }}>
          <LiveUsageResourcesArea />
        </Panel>
      </PanelGroup>

      <CleanV2Dialog />

      <CreateWorkflowDrawer />
    </Stack>
  );
};
