import { FC } from "react";
import { CustomCellRendererProps } from "ag-grid-react";
import Stack from "@mui/material/Stack";
import { SavingsOpportunity } from "../../../../../services/cloudchipr.api";
import { TypographyWithTooltip } from "../../../../common/TypographyWithTooltip";
import { CopyIconButton } from "../../../../common/CopyIconButton";
import { useHover } from "../../../../../utils/hooks/useHover.hook";

export const SavingsOpportunityActionTypeCellRenderer: FC<
  CustomCellRendererProps<SavingsOpportunity>
> = (cell) => {
  const { ref, hovered } = useHover();

  const data = cell.data;

  if (!data) {
    return null;
  }

  return (
    <Stack direction="row" spacing={1} alignItems="center" ref={ref}>
      <TypographyWithTooltip title={data.action_type} variant="body2" />

      <CopyIconButton data={data.action_type} visible={hovered} />
    </Stack>
  );
};
