import { Dispatch, FC, SetStateAction, useMemo } from "react";
import { ColDef, GridApi } from "ag-grid-community";
import { savingsOpportunitiesTableColumns } from "./savingsOpportunitiesTableColumns";
import { AutoGroupColumnDefInnerRenderer } from "./cell-renderers/AutoGroupColumnDefInnerRenderer";
import { SavingsOpportunity } from "../../../../services/cloudchipr.api";
import { AgDataGrid } from "../../common/ag-grid/AgDataGrid";

interface SavingsOpportunitiesDataGridProps {
  data?: SavingsOpportunity[];
  setGridApi: Dispatch<SetStateAction<GridApi | null>>;
}

export const SavingsOpportunitiesDataGrid: FC<
  SavingsOpportunitiesDataGridProps
> = ({ data, setGridApi }) => {
  const autoGroupColumnDef = useMemo<ColDef>(
    () => ({
      minWidth: 250,
      pinned: "left",
      sortable: false,
      suppressMovable: true,
      headerName: "Grouped by",
      cellRendererParams: { innerRenderer: AutoGroupColumnDefInnerRenderer },
    }),
    [],
  );

  return (
    <AgDataGrid
      rowHeight={55}
      rowData={data}
      defaultColDef={defaultColDef}
      rowGroupPanelShow="always"
      groupDefaultExpanded={1}
      gridApiSetter={setGridApi}
      wrapperStyles={wrapperStyles}
      autoGroupColumnDef={autoGroupColumnDef}
      columnDefs={savingsOpportunitiesTableColumns}
      configToRememberStateInStorage={configToRememberStateInStorage}
    />
  );
};

const configToRememberStateInStorage = {
  uniqueNameAsKey: "savingsOpportunitiesDataGrid",
};

const wrapperStyles = {
  ".ag-cell-wrapper": { width: "100%", height: "100%", alignItems: "center" },
};

const defaultColDef: ColDef = {
  minWidth: 150,
  unSortIcon: true,
  enableRowGroup: true,
  filter: "agMultiColumnFilter",
};
