import { ResourceType } from "../../../../../services/cloudchipr.api";

export const isResourceTypeHiddenByFeatureToggleStorageKey = ` c8r:isResourceTypeHiddenByFeatureToggle`;

export const getIsResourceTypeHiddenByFeatureToggle = (
  resourceType?: ResourceType,
) => {
  const data = sessionStorage.getItem(
    isResourceTypeHiddenByFeatureToggleStorageKey,
  );
  const hiddenResourceTypes = data ? JSON.parse(data) : {};

  if (!resourceType) {
    return true;
  }

  return hiddenResourceTypes[resourceType];
};
