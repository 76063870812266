import { RootState } from "../../../store";
import { ResourceType } from "../../../../services/cloudchipr.api";
import { getProviderName } from "../../../../utils/helpers/providers/getProviderName";
import { money } from "../../../../utils/numeral/money";
import { liveUsageMgmtProviderSelector } from "../../../live-usage-mgmt/selectors/store-selectors/liveUsageMgmtProviderSelector";
import { liveUsageMgmtSelectedResourcesAccountsMetaDataSelector } from "../../../live-usage-mgmt/selectors/resource-type-data/liveUsageMgmtSelectedResourcesAccountsMetaDataSelector";
import { liveUsageMgmtSelectedResourcesByResourceTypeSelector } from "../../../live-usage-mgmt/selectors/resource-type-data/liveUsageMgmtSelectedResourcesByResourceTypeSelector";
import { generateAccountIdsQueryParams } from "../../../../components/pages/live-usage-mgmt/utils/helpers/generateAccountIdsQueryParams";
import { currentUserEmailSelector } from "../../../profile/currentUserEmailSelector";

export const emailMessageSelectorV2 = (
  state: RootState,
  resourceType: ResourceType,
) => {
  const userEmail = currentUserEmailSelector(state);
  const accountType = liveUsageMgmtProviderSelector(state);
  const accountData = liveUsageMgmtSelectedResourcesAccountsMetaDataSelector(
    state,
    resourceType,
  );

  const selectedResources =
    liveUsageMgmtSelectedResourcesByResourceTypeSelector(state, resourceType);

  if (!selectedResources || !accountType) {
    return "";
  }

  const account = getProviderName(accountType, {
    nameAndTitle: true,
    capitalize: true,
    plural: true,
  });

  const price = selectedResources.reduce(
    (acc, resource) => resource.resource.price_per_month + acc,
    0,
  );

  const accountUrls = accountData?.reduce((result, item) => {
    const url = `${window.location.origin}/${item.provider}/live-usage/${generateAccountIdsQueryParams([item.id])}`;
    const comma = result.length ? "," : "";
    result =
      result + ` <a href="${url}" target="_blank">${comma}${item.name}</a>`;
    return result;
  }, "");
  const monthlyPrice = `Monthly Price - <b>${money(price)}</b>/mo`;
  const user = userEmail
    ? `This email is sent from Cloudchipr by user <a href="mailto:${userEmail}" target="_blank">${userEmail}</a>`
    : "";

  return `Hi, 
            <br/>  
            <br/> 
            Resources in the attached file may need your attention. Please review and take the necessary actions.
            <br/> 
            <br/> 
            ${account} - ${accountUrls} 
            <br/> 
            ${monthlyPrice}
            <br/> 
            ${user} `;
};
