import { createDraftSafeSelector } from "@reduxjs/toolkit";
import { allAccountsGroupedByProviderAndOrgSelector } from "./allAccountsGroupedByProviderAndOrgSelector";
import { generateAccountIdsQueryParams } from "../../../../components/pages/live-usage-mgmt/utils/helpers/generateAccountIdsQueryParams";

export const liveUsageFirstOrgAllAccountsPathSelector = createDraftSafeSelector(
  [allAccountsGroupedByProviderAndOrgSelector],
  (accounts) => {
    let path = "";

    Object.entries(accounts ?? {}).forEach(([provider, orgs]) => {
      if (path) return;

      Object.values(orgs).forEach((accounts) => {
        if (path) return;
        const connectedAccounts = accounts.filter(
          (account) => account.status === "connected",
        );

        if (connectedAccounts.length) {
          const ids = connectedAccounts.map((account) => account.id);

          path = `/${provider}/live-usage${generateAccountIdsQueryParams(ids)}`;
        }
      });
    });

    return path;
  },
);
