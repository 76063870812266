import { resourceExplorerGroupingTagKeysSelector } from "./resourceExplorerGroupingTagKeysSelector";
import { RootState } from "../../../store";

import { connectedProvidersSelector } from "../connectedProvidersSelector";
import { ResourceExplorerFilterProvider } from "../../../../services/cloudchipr.api";

export const resourceExplorerGroupingTagKeysLoadingSelector = (
  state: RootState,
) => {
  const providers = connectedProvidersSelector(state);

  const cloudProviders: ResourceExplorerFilterProvider[] = [
    ...providers,
    "mongo",
  ];

  return cloudProviders.some((provider) => {
    return resourceExplorerGroupingTagKeysSelector(state, provider)?.isLoading;
  });
};
