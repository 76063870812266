import { createAsyncThunk } from "@reduxjs/toolkit";

import { getResourceExplorerVisibilityHierarchyThunk } from "./getResourceExplorerVisibilityHierarchyThunk";
import { NavigationItemsVisibilityType } from "../../../navigation/utils/types";
import { cloudChiprApi } from "../../../../services/cloudchipr.api";
import { updateResourceExplorerVisibilityHierarchyFixedCacheKey } from "../../utils/constants/fixedCacheKeys";
import { getAllBudgetsThunk } from "../../../budgets/thunks/getAllBudgetsThunk";
import { enqueueSnackbarErrorAlert } from "../../../../components/pages/common/snackbar-alert/EnqueueSnackbarErrorAlert";

interface UpdateResourceExplorerVisibilityHierarchyThunkArg {
  id: string;
  folderId?: string;
  index?: number;
  visibility: NavigationItemsVisibilityType;
  type: "folder" | "view";
}

export const updateResourceExplorerVisibilityHierarchyThunk = createAsyncThunk(
  "resourceExplorer/updateResourceExplorerVisibilityHierarchy",
  async (
    {
      id,
      folderId,
      index,
      visibility,
      type,
    }: UpdateResourceExplorerVisibilityHierarchyThunkArg,
    { dispatch },
  ) => {
    const { patchV2UsersMeOrganisationsCurrentResourceExplorerHierarchy } =
      cloudChiprApi.endpoints;

    try {
      const res = await dispatch(
        patchV2UsersMeOrganisationsCurrentResourceExplorerHierarchy.initiate(
          {
            body: {
              id,
              parent_id: folderId,
              index,
              visibility,
              type,
            },
          },
          {
            fixedCacheKey:
              updateResourceExplorerVisibilityHierarchyFixedCacheKey,
          },
        ),
      ).unwrap();
      dispatch(getResourceExplorerVisibilityHierarchyThunk());

      if (type === "view" && visibility === "visible_only_to_me") {
        dispatch(getAllBudgetsThunk());
      }

      return res.id;
    } catch (e) {
      // @ts-expect-error todo: fix api spec;
      enqueueSnackbarErrorAlert(e?.data?.message);
    }
  },
);
