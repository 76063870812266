import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../../../../../store";
import { currentDashboardIdSelector } from "../../../../selectors/dashboard/currentDashboardIdSelector";
import { commitmentUtilizationWidgetSetupSelector } from "../../../../selectors/setups/commitment-utilization/commitmentUtilizationWidgetSetupSelector";
import { DashboardWidgetCommitmentsUtilizationRequest } from "../../../../../../services/cloudchipr.api";
import { buildWidgetThunk } from "../../common/buildWidgetThunk";
import { getCommitmentUtilizationWidgetDataByWidgetIdThunk } from "../getCommitmentUtilizationWidgetDataByWidgetIdThunk";
import { enqueueSnackbarErrorAlert } from "../../../../../../components/pages/common/snackbar-alert/EnqueueSnackbarErrorAlert";

export const buildCommitmentUtilizationWidgetThunk = createAsyncThunk(
  "dashboards/buildCommitmentUtilizationWidget",
  async (_, { dispatch, getState }) => {
    const state = getState() as RootState;

    const dashboardId = currentDashboardIdSelector(state);
    const setup = commitmentUtilizationWidgetSetupSelector(state);

    if (!dashboardId || !setup?.providerOrganisationId) {
      return;
    }

    const widget: DashboardWidgetCommitmentsUtilizationRequest = {
      name: setup.name,
      provider_organisation_id: setup.providerOrganisationId,
      grouping: setup.grouping,
      type: "commitments_utilization",
      commitment_type: setup.commitmentType,
      dates: setup.dates,
      visualization: setup.visualization,
      aggregation: setup.aggregation,
      trend_type: setup.trendType,
      layout: null,
    };

    try {
      const response = await dispatch(
        buildWidgetThunk({
          widgetId: setup?.id,
          widget,
        }),
      ).unwrap();

      if (response && setup?.id) {
        await dispatch(
          getCommitmentUtilizationWidgetDataByWidgetIdThunk(response.id),
        );
      }
      return response?.id;
    } catch (e) {
      // @ts-expect-error todo: fix api spec;
      enqueueSnackbarErrorAlert(e?.data?.message);

      return null;
    }
  },
);
