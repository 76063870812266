import { FC } from "react";
import { LabelProps } from "recharts";
import { YAxisFormatter } from "./CustomizedYAxisTick";

interface BarsStackTotalProps extends LabelProps {
  opacity: number | string;
  yAxisFormatter?: YAxisFormatter;
  stackTotalDataByKey?: number[];
}

export const BarsStackTotal: FC<BarsStackTotalProps> = ({
  x,
  y,
  width,
  index,
  value,
  opacity,
  yAxisFormatter,
  stackTotalDataByKey,
}) => {
  if (typeof index !== "number") {
    return null;
  }

  const total = stackTotalDataByKey?.[index] ?? value;
  if (total == null) {
    return null;
  }

  const barWidth = +(width ?? 0);
  const labelText = yAxisFormatter ? yAxisFormatter(total) : total;
  const approxTextWidth = 4 * 6;

  if (Math.abs(barWidth) < approxTextWidth) {
    return null;
  }

  const labelX = +(x ?? 0) + barWidth / 2;
  const labelY = +(y ?? 0) - 5;

  return (
    <text
      x={labelX}
      y={labelY}
      textAnchor="middle"
      opacity={opacity}
      style={{ fontSize: 10, fontWeight: "bold" }}
    >
      {labelText}
    </text>
  );
};
