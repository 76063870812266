import { FC, Fragment, useCallback, useState } from "react";
import {
  Card,
  Container,
  Link,
  Stack,
  Typography,
  CardContent,
  LinearProgress,
} from "@mui/material";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { ApiKeysListGrid } from "./components/grid/components/ApiKeysListGrid";
import { ApiKeysEmptyState } from "./components/ApiKeysEmptyState";
import { ApiKeyCreateValidation } from "./components/ApiKeyCreateValidation";
import { ApiKeysForm } from "./components/ApiKeysForm";
import { useGetUsersMeOrganisationsCurrentApiKeysQuery } from "../../../../../services/cloudchipr.api";
import { PageHeader } from "../../../common/PageHeader";

export const apiKeysMaxCount = 2;

export const ApiKeysList: FC = () => {
  const { data, isLoading: loading } =
    useGetUsersMeOrganisationsCurrentApiKeysQuery();
  const [showCreateApiKeyForm, setShowCreateApiKeyForm] = useState(false);
  const isMaxCount = data?.length === apiKeysMaxCount;

  const showForm = useCallback(() => {
    setShowCreateApiKeyForm(true);
  }, []);

  const hideForm = useCallback(() => {
    setShowCreateApiKeyForm(false);
  }, []);

  return (
    <Fragment>
      <PageHeader
        title={
          <Stack>
            <Typography variant="h6">API Keys </Typography>
            <Typography variant="subtitle1" color="text.secondary">
              Number of API Keys{" "}
              <Typography component="span" fontWeight="bold">
                {data?.length}/{apiKeysMaxCount}
              </Typography>
            </Typography>
          </Stack>
        }
        actions={
          data?.length ? (
            <ApiKeyCreateValidation
              isMaxCount={isMaxCount}
              showForm={showForm}
            />
          ) : null
        }
      />

      {loading && <LinearProgress />}

      {!showCreateApiKeyForm && (
        <Container sx={{ p: 2 }} maxWidth={false} disableGutters>
          {!loading &&
            (data?.length ? (
              <Fragment>
                <Card variant="outlined">
                  <ApiKeysListGrid showForm={showForm} />
                </Card>

                <Link
                  href="https://cloudchipr.github.io/api-service/"
                  target="_blank"
                  underline="hover"
                  sx={{ display: "flex", alignItems: "center", mt: 1 }}
                >
                  Cloudchipr Enterprise API
                  <OpenInNewIcon
                    fontSize="small"
                    color="inherit"
                    sx={{ ml: 1 }}
                  />
                </Link>
              </Fragment>
            ) : (
              <ApiKeysEmptyState showForm={showForm} />
            ))}
        </Container>
      )}
      {showCreateApiKeyForm && (
        <Container sx={{ p: 2 }} disableGutters>
          <Card variant="outlined">
            <CardContent>
              <ApiKeysForm hideForm={hideForm} />
            </CardContent>
          </Card>
        </Container>
      )}
    </Fragment>
  );
};
