import { FC, useMemo } from "react";
import { red } from "@mui/material/colors";
import { RowClassParams } from "ag-grid-community";
import { ApiKeysListGridNoRowsComponent } from "./ApiKeysListGridNoRowsComponent";
import { getApiKeysListGridColumns } from "./apiKeysListGridColumns";
import { AgDataGrid } from "../../../../../../common/ag-grid/AgDataGrid";
import { useGetUsersMeOrganisationsCurrentApiKeysQuery } from "../../../../../../../../services/cloudchipr.api";
import { isDateExpired } from "../utils/helpers/isDateExpired";

interface ApiKeysListGridProps {
  showForm(): void;
}

const rowHeight = 64;

export const ApiKeysListGrid: FC<ApiKeysListGridProps> = ({ showForm }) => {
  const { data: apiKeys } = useGetUsersMeOrganisationsCurrentApiKeysQuery();
  const columns = useMemo(() => {
    return getApiKeysListGridColumns(showForm);
  }, [showForm]);

  return (
    <AgDataGrid
      columnDefs={columns}
      defaultColDef={defaultColDef}
      rowData={apiKeys}
      rowHeight={rowHeight}
      domLayout="autoHeight"
      getRowStyle={getRowStyle}
      noRowsOverlayComponent={ApiKeysListGridNoRowsComponent}
      suppressMovableColumns
    />
  );
};

const getRowStyle = (params: RowClassParams<any, any>) => {
  if (isDateExpired(params.data.expires_at)) {
    return { background: red[50] };
  }
};

const defaultColDef = {
  sortable: false,
  resizable: false,
  suppressHeaderMenuButton: true,
};
