import { FC, useMemo } from "react";
import { Box } from "@mui/material";
import { getCommitmentUtilizationTableViewColumnDefs } from "../../../adding-widget/widget-create/widget-setups/commitment-utilization /utils/helpers/getCommitmentUtilizationTableViewColumnDefs";
import {
  GetUsersMeOrganisationsCurrentWidgetsCommitmentsUtilizationByWidgetIdApiResponse,
  WidgetCommitmentsUtilizationVisualization,
} from "../../../../../../../services/cloudchipr.api";
import { AgDataGrid } from "../../../../../common/ag-grid/AgDataGrid";

interface CommitmentUtilizationWidgetTableViewProps {
  data?: GetUsersMeOrganisationsCurrentWidgetsCommitmentsUtilizationByWidgetIdApiResponse;
  visualization?: WidgetCommitmentsUtilizationVisualization;
}

export const CommitmentUtilizationWidgetTableView: FC<
  CommitmentUtilizationWidgetTableViewProps
> = ({ data, visualization }) => {
  const { groups } = data ?? {};

  const columnDefs = useMemo(
    () => getCommitmentUtilizationTableViewColumnDefs(visualization),
    [visualization],
  );

  return (
    <Box height="100%" p={2}>
      <AgDataGrid
        rowHeight={40}
        headerHeight={44}
        rowData={groups ?? []}
        columnDefs={columnDefs}
      />
    </Box>
  );
};
