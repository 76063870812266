import { createAsyncThunk } from "@reduxjs/toolkit";
import { cloudChiprApi } from "../../../../../services/cloudchipr.api";
import { widgetDataNotFoundSelector } from "../../../selectors/widgets/common/widgetDataNotFoundSelector";
import { RootState } from "../../../../store";
import { enqueueSnackbarErrorAlert } from "../../../../../components/pages/common/snackbar-alert/EnqueueSnackbarErrorAlert";

export const getCommitmentUtilizationWidgetDataByWidgetIdThunk =
  createAsyncThunk(
    "dashboards/getCommitmentUtilizationWidgetDataByWidgetId",
    async (widgetId: string, { dispatch, getState }) => {
      const state = getState() as RootState;

      const {
        getUsersMeOrganisationsCurrentWidgetsCommitmentsUtilizationByWidgetId,
      } = cloudChiprApi.endpoints;

      const widgetNotFound = widgetDataNotFoundSelector(
        state,
        widgetId ?? "",
        "commitments_utilization",
      );

      try {
        await dispatch(
          getUsersMeOrganisationsCurrentWidgetsCommitmentsUtilizationByWidgetId.initiate(
            {
              widgetId,
            },
            {
              forceRefetch: true,
            },
          ),
        );
      } catch (e) {
        if (!widgetNotFound) {
          // @ts-expect-error todo: fix api spec;
          enqueueSnackbarErrorAlert(e?.data?.message);
        }
      }
    },
  );
