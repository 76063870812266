import { FC } from "react";
import { CustomCellRendererProps } from "ag-grid-react";
import {
  EmbeddedAccount,
  SavingsOpportunity,
} from "../../../../../services/cloudchipr.api";
import { AccountInfoData } from "../../../../common/AccountInfoData";

export const SavingsOpportunityAccountCellRenderer: FC<
  CustomCellRendererProps<SavingsOpportunity>
> = (cell) => {
  const account = cell.data?.account as EmbeddedAccount;

  if (!account) {
    return null;
  }

  return (
    <AccountInfoData
      provider={account.provider}
      accountId={account.provider_account_id}
      accountName={account.provider_account_name}
    />
  );
};
