import { createAsyncThunk } from "@reduxjs/toolkit";
import { openDrawerForTaskByIdThunk } from "./openDrawerForTaskByIdThunk";
import { RootState } from "../../store";
import { taskManagementTaskIdSelector } from "../selectors/form/properties/taskManagementTaskIdSelector";

export const reInitiateEditingTaskThunk = createAsyncThunk(
  "taskManagement/reInitiateEditingTask",
  async (_: void, { dispatch, getState }) => {
    const state = getState() as RootState;

    const editingTaskId = taskManagementTaskIdSelector(state);

    if (!editingTaskId) {
      return;
    }

    dispatch(openDrawerForTaskByIdThunk(editingTaskId));
  },
);
