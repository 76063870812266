import { createAsyncThunk } from "@reduxjs/toolkit";
import { closeSnackbar, enqueueSnackbar, SnackbarKey } from "notistack";
import { updateResourceExplorerById } from "./updateResourceExplorerById";
import { enqueueSnackbarErrorAlert } from "../../../../components/pages/common/snackbar-alert/EnqueueSnackbarErrorAlert";

interface UpdateResourceExplorerViewNameThunkArg {
  id: string;
  name: string;
}
export const updateResourceExplorerViewNameThunk = createAsyncThunk(
  "resourceExplorer/updateResourceExplorerViewName",
  async (
    { name, id }: UpdateResourceExplorerViewNameThunkArg,
    { dispatch },
  ) => {
    try {
      await dispatch(
        updateResourceExplorerById({
          body: { name },
          viewId: id,
        }),
      ).unwrap();

      const snackbarId: SnackbarKey = enqueueSnackbar(
        "Report name successfully updated.",
        {
          variant: "snackbarAlert",
          AlertSnackBarProps: {
            severity: "success",
            onClose: () => closeSnackbar(snackbarId),
          },
        },
      );
    } catch (e) {
      // @ts-expect-error todo: fix api spec;
      enqueueSnackbarErrorAlert(e?.data?.message);
    }
  },
);
