import { createAsyncThunk } from "@reduxjs/toolkit";
import { enqueueSnackbar } from "notistack";

import { updateDashboardByIdThunk } from "./updateDashboardByIdThunk";
import { getDashboardsVisibilityHierarchyThunk } from "../dashboard-hierarchy-visibility/getDashboardsVisibilityHierarchyThunk";
import { getDashboardByIdThunk } from "../dashboard/getDashboardByIdThunk";
import { PatchUsersMeOrganisationsCurrentDashboardsByDashboardIdApiArg } from "../../../../services/cloudchipr.api";
import { enqueueSnackbarErrorAlert } from "../../../../components/pages/common/snackbar-alert/EnqueueSnackbarErrorAlert";

type UpdateDashboardHierarchyItemNameThunkArgs = {
  body: Partial<
    PatchUsersMeOrganisationsCurrentDashboardsByDashboardIdApiArg["body"]
  >;
  dashboardId: string;
  activeDashboardId?: string;
};

export const updateDashboardHierarchyItemThunk = createAsyncThunk(
  "dashboards/updateDashboardHierarchyItemName",
  async (
    {
      body,
      dashboardId,
      activeDashboardId,
    }: UpdateDashboardHierarchyItemNameThunkArgs,
    { dispatch },
  ) => {
    try {
      await dispatch(
        updateDashboardByIdThunk({
          dashboardId,
          body,
        }),
      ).unwrap();
      dispatch(getDashboardsVisibilityHierarchyThunk());

      if (dashboardId === activeDashboardId) {
        dispatch(getDashboardByIdThunk(dashboardId));
      }

      enqueueSnackbar("Dashboard successfully updated.", {
        variant: "snackbarAlert",
        AlertSnackBarProps: {
          severity: "success",
        },
      });
    } catch (e) {
      // @ts-expect-error todo: fix api spec;
      enqueueSnackbarErrorAlert(e?.data?.message);
    }
  },
);
