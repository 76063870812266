import Stack from "@mui/material/Stack";
import { Button } from "@mui/material";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { Link } from "react-router-dom";
import { FC } from "react";
import { ManageAllReportsButton } from "../../reports/components/table/components/toolbar/ManageAllReportsButton";
import { pathReportsKey } from "../../reports/utils/constants/constants";

interface CreateReportSnackbarFooterProps {
  subscriptionId: string;
}

export const CreateReportSnackbarFooter: FC<
  CreateReportSnackbarFooterProps
> = ({ subscriptionId }) => {
  return (
    <Stack direction="row" justifyContent="flex-end" flex={1} spacing={1}>
      <ManageAllReportsButton size="small" />
      <Button
        variant="contained"
        size="small"
        sx={{ textTransform: "none" }}
        endIcon={<OpenInNewIcon />}
        component={Link}
        target="_blank"
        to={`/subscriptions?${pathReportsKey}=${subscriptionId}`}
      >
        Open Subscription
      </Button>
    </Stack>
  );
};
