import { createAsyncThunk } from "@reduxjs/toolkit";
import { closeSnackbar, enqueueSnackbar, SnackbarKey } from "notistack";
import { getResourceExplorerVisibilityHierarchyThunk } from "./getResourceExplorerVisibilityHierarchyThunk";
import {
  cloudChiprApi,
  PostV2UsersMeOrganisationsCurrentDashboardsApiArg,
} from "../../../../services/cloudchipr.api";
import { enqueueSnackbarErrorAlert } from "../../../../components/pages/common/snackbar-alert/EnqueueSnackbarErrorAlert";

export const createResourceExplorerFolderThunkFixedCacheKey =
  "create-resource-explorer-folder";

interface CreateResourceExplorerVisibilityHierarchyFolderThunkArgs {
  name: string;
  visibility: PostV2UsersMeOrganisationsCurrentDashboardsApiArg["body"]["visibility"];
}
export const createResourceExplorerVisibilityHierarchyFolderThunk =
  createAsyncThunk(
    "resourceExplorer/createResourceExplorerVisibilityHierarchyFolder",
    async (
      {
        name,
        visibility,
      }: CreateResourceExplorerVisibilityHierarchyFolderThunkArgs,
      { dispatch },
    ) => {
      const { postV3UsersMeOrganisationsCurrentResourceExplorerFolders } =
        cloudChiprApi.endpoints;

      try {
        const response = await dispatch(
          postV3UsersMeOrganisationsCurrentResourceExplorerFolders.initiate(
            {
              body: {
                name: name,
                visibility,
              },
            },
            {
              fixedCacheKey: createResourceExplorerFolderThunkFixedCacheKey,
            },
          ),
        ).unwrap();

        if (response) {
          dispatch(getResourceExplorerVisibilityHierarchyThunk());
          const snackbarId: SnackbarKey = enqueueSnackbar(
            "Folder successfully created.",
            {
              variant: "snackbarAlert",
              AlertSnackBarProps: {
                severity: "success",
                onClose: () => closeSnackbar(snackbarId),
              },
            },
          );
        }
      } catch (e) {
        // @ts-expect-error todo: fix api spec;
        enqueueSnackbarErrorAlert(e?.data?.message);
      }
    },
  );
