import { FC, Fragment } from "react";
import { IconButton } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";

import { DashboardV2ItemListItemActionMenu } from "./DashboardV2ItemListItemActionMenu";
import { useMenuHook } from "../../../../../../utils/hooks/useMenu.hook";
import { useAppAbility } from "../../../../../../services/permissions";
import { NavigationItemsVisibilityType } from "../../../../../../store/navigation/utils/types";
import {
  EmbeddedUser,
  ProtectionDetails,
} from "../../../../../../services/cloudchipr.api";

interface DashboardV2ItemActionButtonProps {
  id: string;
  name: string;
  visibility: NavigationItemsVisibilityType;
  renderReportsBySourceActionItem?: boolean;
  folderId?: string;
  size?: "small" | "medium" | "large";
  protectedBy?: EmbeddedUser;
  protectionDetails?: ProtectionDetails;
}

export const DashboardV2ItemActionButton: FC<
  DashboardV2ItemActionButtonProps
> = ({
  id,
  name,
  folderId,
  visibility,
  size,
  renderReportsBySourceActionItem,
  protectedBy,
  protectionDetails,
}) => {
  const { cannot } = useAppAbility();
  const cannotDeleteDashboard = cannot("delete", "dashboard");
  const { anchor, open, openMenu, closeMenu } = useMenuHook();

  if (cannotDeleteDashboard) {
    return null;
  }

  return (
    <Fragment>
      <IconButton
        size="small"
        onClick={openMenu}
        sx={open ? openedStyles : undefined}
      >
        <MoreVertIcon fontSize={size ?? "inherit"} />
      </IconButton>
      <DashboardV2ItemListItemActionMenu
        id={id}
        name={name}
        open={open}
        anchor={anchor}
        closeMenu={closeMenu}
        folderId={folderId}
        visibility={visibility}
        renderReportsBySourceActionItem={renderReportsBySourceActionItem}
        protectedBy={protectedBy}
        protectionDetails={protectionDetails}
      />
    </Fragment>
  );
};

//TODO: add to all NavItemExpanded secondary actions
const openedStyles = {
  visibility: "visible !important",
  bgcolor: "grey.200",
};
