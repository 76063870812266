import { FC } from "react";
import { Stack } from "@mui/material";
import { useHover } from "../../../../../../../../utils/hooks/useHover.hook";
import { CopyIconButton } from "../../../../../../../common/CopyIconButton";
import { TypographyWithTooltip } from "../../../../../../../common/TypographyWithTooltip";

interface ApiKeyCellProps {
  data: string;
}

export const ApiKeyCell: FC<ApiKeyCellProps> = ({ data }) => {
  const { ref, hovered } = useHover();

  return (
    <Stack direction="row" ref={ref} alignItems="center" overflow="hidden">
      <TypographyWithTooltip title={data} variant="body2" />
      {data && <CopyIconButton data={data} visible={hovered} />}
    </Stack>
  );
};
