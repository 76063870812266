import { FC, memo, useCallback } from "react";
import { TabList } from "@mui/lab";
import Tab from "@mui/material/Tab";
import { Link } from "react-router-dom";
import { Typography } from "@mui/material";
import { ViewAgendaOutlined } from "@mui/icons-material";
import { ProviderType } from "../../../../../../services/cloudchipr.api";
import { getProviderName } from "../../../../../../utils/helpers/providers/getProviderName";
import { noOrgAccountOrgId } from "../../../utils/helpers/noOrganizationConstants";
import GaugeSvgIcon from "../../../../../../assets/images/icons/GaugeSvgIcon";
import { AccountTabsWithResources } from "../../../utils/types/types";
import { useAccountStorageMemoizedTabHook } from "../../../utils/hooks/useAccountStorageMemoizedTabHook";
import { useAppSelector } from "../../../../../../store/hooks";
import { activeAccountsIdsQueryParamSelector } from "../../../../../../store/accounts/selectors/activeAccountsIdsQueryParamSelector";
import { pageHeaderHeight } from "../../../../common/PageHeader";
import { accountsPageTabSearchParam } from "../../../../live-usage-mgmt/utils/constants/searchParams";

interface AccountsTabsProps {
  provider: ProviderType;
  activeCount: number;
  inactiveCount: number;
  orgId?: string;
  activeTab: AccountTabsWithResources;
}

export const AccountsTabs: FC<AccountsTabsProps> = memo(
  ({ provider, activeCount, inactiveCount, orgId, activeTab }) => {
    const activeAccountsIdsQueryParam = useAppSelector((state) =>
      activeAccountsIdsQueryParamSelector(state, provider, orgId),
    );
    const organizationId = orgId === noOrgAccountOrgId ? undefined : orgId;
    const { setAccountTab } = useAccountStorageMemoizedTabHook();

    const getTabClickHandler = useCallback(
      (tabValue: AccountTabsWithResources) => () => setAccountTab(tabValue),
      [setAccountTab],
    );

    return (
      <TabList sx={{ background: "white" }}>
        <Tab
          component={Link}
          to={`/${provider}/${orgId}/accounts?${accountsPageTabSearchParam}=active`}
          icon={
            <GaugeSvgIcon
              color={activeTab === "active" ? "primary" : undefined}
            />
          }
          iconPosition="start"
          label={
            <Typography
              ml={1}
              variant="inherit"
              textTransform="none"
              width="max-content"
            >
              {organizationId ?? getProviderName(provider, { title: true })}{" "}
              {getProviderName(provider, { plural: true })} ({activeCount ?? 0})
            </Typography>
          }
          onClick={getTabClickHandler("active")}
          value="active"
          sx={tabStyles}
        />

        <Tab
          component={Link}
          to={`/${provider}/live-usage/${activeAccountsIdsQueryParam}`}
          icon={<ViewAgendaOutlined fontSize="small" />}
          iconPosition="start"
          disabled={!activeCount}
          label={
            <Typography
              variant="inherit"
              textTransform="none"
              width="max-content"
            >
              Live Resources
            </Typography>
          }
          onClick={getTabClickHandler("live-resources")}
          value="live-resources"
          sx={{ ...tabStyles, ...borderStyles }}
        />

        <Tab
          component={Link}
          to={`/${provider}/${orgId}/accounts?${accountsPageTabSearchParam}=inactive`}
          label={
            <Typography
              variant="inherit"
              textTransform="none"
              width="max-content"
            >
              Inactive {getProviderName(provider, { plural: true })} (
              {inactiveCount})
            </Typography>
          }
          value="inactive"
          disabled={!inactiveCount}
          sx={tabStyles}
        />
      </TabList>
    );
  },
);

const paddingHeight = 2;
const tabStyles = {
  minHeight: "inherit",
  height: pageHeaderHeight - paddingHeight,
};

const borderStyles = {
  borderRight: 1,
  borderColor: "divider",
};
