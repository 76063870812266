export const protectTitlesByProtection = new Map<
  boolean,
  {
    title: string;
    contentTitle: string;
  }
>([
  [
    true,
    {
      title: "Unprotect",
      contentTitle: "Please confirm if you want to unprotect this report.",
    },
  ],
  [
    false,
    {
      title: "Protect",
      contentTitle:
        "Anyone with Edit permission can unprotect. Add an optional message for users unprotecting this report.",
    },
  ],
]);
