import moment from "moment";

export const getTaskDueDateLabel = (date?: string | null) => {
  const isToday = moment(date).isSame(moment(), "day");
  if (isToday) {
    return "Today";
  }

  const isYesterday = moment(date).isSame(moment().subtract(1, "day"), "day");
  if (isYesterday) {
    return "Yesterday";
  }

  const isTomorrow = moment(date).isSame(moment().add(1, "day"), "day");
  if (isTomorrow) {
    return "Tomorrow";
  }

  return null;
};
