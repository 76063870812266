import { createDraftSafeSelector } from "@reduxjs/toolkit";
import { accountsDividedToStatusesByOrgIdSelector } from "./accountsDividedToStatusesByOrgIdSelector";
import { generateAccountIdsQueryParams } from "../../../components/pages/live-usage-mgmt/utils/helpers/generateAccountIdsQueryParams";

export const activeAccountsIdsQueryParamSelector = createDraftSafeSelector(
  [accountsDividedToStatusesByOrgIdSelector],
  (accounts) => {
    const activeAccountIds = accounts?.active
      ?.filter(({ status }) => status === "connected")
      ?.map((account) => account.id);

    if (!activeAccountIds) {
      return;
    }

    return generateAccountIdsQueryParams(activeAccountIds);
  },
);
