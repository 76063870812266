import { FC } from "react";
import {
  IconButton,
  Link,
  Stack,
  Tooltip,
  TypographyProps,
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import CloudOutlinedIcon from "@mui/icons-material/CloudOutlined";
import { PlayCircleOutlined } from "@mui/icons-material";
import { ResourceTarget } from "../../../../../../../../../../services/cloudchipr.api";
import { getProviderName } from "../../../../../../../../../../utils/helpers/providers/getProviderName";
import { TypographyWithTooltip } from "../../../../../../../../../common/TypographyWithTooltip";
import { useHover } from "../../../../../../../../../../utils/hooks/useHover.hook";
import { CopyIconButton } from "../../../../../../../../../common/CopyIconButton";
import {
  resourceTypeSearchParam,
  resourceIdSearchParam,
} from "../../../../../../../../live-usage-mgmt/utils/constants/searchParams";

interface TargetResourceTableIdentifierRowProps {
  resource: ResourceTarget;
  typographyProps?: TypographyProps;
  cloudLink?: boolean | "hover";
  copyButton?: boolean | "hover";
  liveUsageLink?: boolean | "hover";
}

export const TargetResourceTableIdentifierRow: FC<
  TargetResourceTableIdentifierRowProps
> = ({ resource, cloudLink, liveUsageLink, typographyProps, copyButton }) => {
  const { ref, hovered } = useHover();
  const hoverNeeded =
    cloudLink === "hover" ||
    liveUsageLink === "hover" ||
    copyButton === "hover";

  const showCloudLink = cloudLink === "hover" ? hovered : cloudLink;
  const showLiveUsageLink = liveUsageLink === "hover" ? hovered : liveUsageLink;
  const showCopyButton = copyButton === "hover" ? hovered : copyButton;

  return (
    <Stack
      direction="row"
      spacing={1}
      alignItems="center"
      ref={hoverNeeded ? ref : undefined}
    >
      <TypographyWithTooltip
        title={resource.provider_identifier}
        variant="body2"
        fontWeight="medium"
        {...typographyProps}
      />

      {showCloudLink && resource.link && resource.state === "live" && (
        <Tooltip
          arrow
          placement="top"
          title={`View in ${getProviderName(resource.account.provider, { title: true })}`}
        >
          <IconButton
            size="small"
            target="_blank"
            to={resource.link}
            component={RouterLink}
          >
            <CloudOutlinedIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      )}

      {showLiveUsageLink && resource.state === "live" && (
        <Tooltip title="View in Live Usage & Mgmt." arrow placement="top">
          <IconButton
            size="small"
            component={Link}
            href={`/${resource.account.provider}/live-usage/?accountIds=${resource.account.id}&${resourceTypeSearchParam}=${resource.resource_type}&${resourceIdSearchParam}=${resource.provider_identifier}`}
            target="_blank"
          >
            <PlayCircleOutlined fontSize="small" color="action" />
          </IconButton>
        </Tooltip>
      )}

      <CopyIconButton
        data={resource.provider_identifier}
        visible={showCopyButton}
      />
    </Stack>
  );
};
