import { Dispatch, FC, SetStateAction } from "react";
import { GridApi } from "ag-grid-community";
import { DomLayoutType } from "ag-grid-community/dist/types/src/entities/gridOptions";
import { reportsTableColumns } from "./reportsTableColumns";
import { ReportsTableNoRowsComponent } from "./ReportsTableNoRowsComponent";
import { ReportsTableData } from "../../../../../../store/reports/utils/types/types";
import { AgDataGrid } from "../../../../common/ag-grid/AgDataGrid";

interface ReportsTableComponentProps {
  setGridApi: Dispatch<SetStateAction<GridApi | undefined>>;
  reports?: ReportsTableData[];
  domLayout?: DomLayoutType;
}

export const ReportsTableComponent: FC<ReportsTableComponentProps> = ({
  setGridApi,
  reports,
  domLayout,
}) => {
  return (
    <AgDataGrid
      rowHeight={56}
      rowData={reports}
      headerHeight={56}
      domLayout={domLayout}
      columnDefs={reportsTableColumns}
      gridApiSetter={setGridApi}
      noRowsOverlayComponent={ReportsTableNoRowsComponent}
      configToRememberStateInStorage={configToRememberStateInStorage}
    />
  );
};

const configToRememberStateInStorage = {
  uniqueNameAsKey: "reportsDataGrid",
};
