import { costBreakdownWidgetByIdSelector } from "./costBreakdownWidgetByIdSelector";
import { costBreakdownWidgetNotFoundSelector } from "./costBreakdownWidgetNotFoundSelector";
import { RootState } from "../../../../../store";
import { resourceExplorerViewNameByViewIdSelector } from "../../../../../resource-explorer/selectors/all-resource-explorers/resourceExplorerViewNameByViewIdSelector";
import { objectToUrl } from "../../../../../../components/utils/helpers/dataToUrlToDataConvertors";
import { WidgetTitleProps } from "../../../../../../components/pages/dashboard/utils/types/types";

export const costBreakdownWidgetTitlePropsSelector = (
  state: RootState,
  widgetId: string,
): WidgetTitleProps | null => {
  const widget = costBreakdownWidgetByIdSelector(state, widgetId);

  if (!widget) {
    return null;
  }

  const viewName = resourceExplorerViewNameByViewIdSelector(
    state,
    widget.view_id ?? undefined,
  );

  const viewNotFound = costBreakdownWidgetNotFoundSelector(state, widgetId);
  const path = objectToUrl([
    {
      groupedBy: widget.grouping,
      groupValues: widget.group_values,
      forecastOption: widget.forecast_option,
      dates: widget.dates,
      dateGranularity: widget.date_granularity,
      chartType: widget.chart_type,
      filterTree: undefined,
    },
  ]);

  return {
    groupBy: widget.grouping,
    groupValues: widget.group_values,
    widgetName: widget.name,
    dates: widget.dates ?? {},
    sourceData: {
      title: viewNotFound || !viewName ? "" : viewName,
      url: `/resource-explorer/${widget.view_id}?path=${path}`,
    },
  };
};
