import { FC } from "react";
import { Stack, Typography } from "@mui/material";
import { LiveUsageFiltersContentCard } from "./LiveUsageFiltersContentCard";
import { useAppSelector } from "../../../../../../../../store/hooks";
import { liveUsageMgmtAppliedFiltersCountSelector } from "../../../../../../../../store/live-usage-mgmt/selectors/store-selectors/liveUsageMgmtAppliedFiltersCountSelector";
import { liveUsageMgmtAppliedFiltersSelector } from "../../../../../../../../store/live-usage-mgmt/selectors/store-selectors/liveUsageMgmtAppliedFiltersSelector";
import { allResourcesByProvider } from "../../../../../../../../utils/constants/resources/resourceGroupsByProvider";
import { SetRefsFromKey } from "../../../../../../../../utils/hooks/useRefs";
import { ProviderType } from "../../../../../../../../services/cloudchipr.api";
import { getIsResourceTypeHiddenByFeatureToggle } from "../../../../../utils/helpers/getIsResourceTypeHiddenByFeatureToggle";

interface LiveUsageFiltersContentProps {
  setRefFromKey: SetRefsFromKey;
  provider: ProviderType;
  onClose(): void;
}

export const LiveUsageFiltersContent: FC<LiveUsageFiltersContentProps> = ({
  setRefFromKey,
  provider,
  onClose,
}) => {
  const filtersCount = useAppSelector(liveUsageMgmtAppliedFiltersCountSelector);
  const filters = useAppSelector(liveUsageMgmtAppliedFiltersSelector);
  const resources = allResourcesByProvider.get(provider);

  if (!provider) {
    return null;
  }

  return (
    <Stack>
      <Typography variant="subtitle1" fontWeight="medium" p={1} pb={2} pl={2}>
        Filters({filtersCount})
      </Typography>

      <Stack overflow="auto" px={1}>
        <Stack spacing={2}>
          {resources?.map((resourceType) => {
            const filter = filters[resourceType];
            if (
              getIsResourceTypeHiddenByFeatureToggle(resourceType) ||
              !filter
            ) {
              return null;
            }

            return (
              <LiveUsageFiltersContentCard
                filter={filter}
                onClose={onClose}
                key={resourceType}
                setRefFromKey={setRefFromKey}
              />
            );
          })}
        </Stack>
      </Stack>
    </Stack>
  );
};
