import { FC } from "react";
import { CustomCellRendererProps } from "ag-grid-react";
import Stack from "@mui/material/Stack";
import { Box, Tooltip } from "@mui/material";
import { SavingsOpportunity } from "../../../../../services/cloudchipr.api";
import {
  possibleEfforts,
  possibleEffortsLabels,
} from "../../utils/constants/common";

export const SavingsOpportunityImplementationEffortCellRenderer: FC<
  CustomCellRendererProps<SavingsOpportunity>
> = (cell) => {
  const data = cell.data as SavingsOpportunity;

  if (!data) {
    return null;
  }

  const currentEffortIndex = possibleEfforts.findIndex(
    (effort) => effort === data.implementation_effort,
  );

  return (
    <Tooltip
      title={possibleEffortsLabels[data.implementation_effort]}
      arrow
      placement="top"
    >
      <Stack direction="row" spacing={0.5} width="fit-content">
        <Box width={10} height={6} borderRadius={2} bgcolor="primary.main" />

        <Box
          width={10}
          height={6}
          borderRadius={2}
          bgcolor={currentEffortIndex > 1 ? "primary.main" : "grey.300"}
        />
        <Box
          width={10}
          height={6}
          borderRadius={2}
          bgcolor={currentEffortIndex > 3 ? "primary.main" : "grey.300"}
        />
      </Stack>
    </Tooltip>
  );
};
