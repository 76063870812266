export const agGridOverwrittenIcons = {
  sortAscending: `<svg xmlns="http://www.w3.org/2000/svg" data-type="ag-overwritten-icon" viewBox="0 -960 960 960" width="16" height="16"  fill="currentColor"><path d="M444-192v-438L243-429l-51-51 288-288 288 288-51 51-201-201v438h-72Z"/></svg>`,
  sortDescending: `<svg xmlns="http://www.w3.org/2000/svg" data-type="ag-overwritten-icon"  height="18" viewBox="0 -960 960 960" width="18" fill="currentColor"><path d="M444-768v438L243-531l-51 51 288 288 288-288-51-51-201 201v-438h-72Z"/></svg>`,
  sortUnSort: `<svg xmlns="http://www.w3.org/2000/svg" data-type="ag-overwritten-icon"  height="16" viewBox="0 -960 960 960" width="16" fill="currentColor"><path d="M480-144 318-306l51-51 111 111 111-111 51 51-162 162ZM369-603l-51-51 162-162 162 162-51 51-111-111-111 111Z"/></svg>`,

  rowDrag: `<svg xmlns="http://www.w3.org/2000/svg" data-type="ag-overwritten-icon"  height="16" width="16" viewBox="0 -960 960 960" fill="currentColor"><path d="M360-160q-33 0-56.5-23.5T280-240q0-33 23.5-56.5T360-320q33 0 56.5 23.5T440-240q0 33-23.5 56.5T360-160Zm240 0q-33 0-56.5-23.5T520-240q0-33 23.5-56.5T600-320q33 0 56.5 23.5T680-240q0 33-23.5 56.5T600-160ZM360-400q-33 0-56.5-23.5T280-480q0-33 23.5-56.5T360-560q33 0 56.5 23.5T440-480q0 33-23.5 56.5T360-400Zm240 0q-33 0-56.5-23.5T520-480q0-33 23.5-56.5T600-560q33 0 56.5 23.5T680-480q0 33-23.5 56.5T600-400ZM360-640q-33 0-56.5-23.5T280-720q0-33 23.5-56.5T360-800q33 0 56.5 23.5T440-720q0 33-23.5 56.5T360-640Zm240 0q-33 0-56.5-23.5T520-720q0-33 23.5-56.5T600-800q33 0 56.5 23.5T680-720q0 33-23.5 56.5T600-640Z"/></svg>`,
  columnDrag: `<svg xmlns="http://www.w3.org/2000/svg" data-type="ag-overwritten-icon"  height="16" width="16" viewBox="0 -960 960 960" fill="currentColor"><path d="M360-160q-33 0-56.5-23.5T280-240q0-33 23.5-56.5T360-320q33 0 56.5 23.5T440-240q0 33-23.5 56.5T360-160Zm240 0q-33 0-56.5-23.5T520-240q0-33 23.5-56.5T600-320q33 0 56.5 23.5T680-240q0 33-23.5 56.5T600-160ZM360-400q-33 0-56.5-23.5T280-480q0-33 23.5-56.5T360-560q33 0 56.5 23.5T440-480q0 33-23.5 56.5T360-400Zm240 0q-33 0-56.5-23.5T520-480q0-33 23.5-56.5T600-560q33 0 56.5 23.5T680-480q0 33-23.5 56.5T600-400ZM360-640q-33 0-56.5-23.5T280-720q0-33 23.5-56.5T360-800q33 0 56.5 23.5T440-720q0 33-23.5 56.5T360-640Zm240 0q-33 0-56.5-23.5T520-720q0-33 23.5-56.5T600-800q33 0 56.5 23.5T680-720q0 33-23.5 56.5T600-640Z"/></svg>`,

  dropNotAllowed: `<svg xmlns="http://www.w3.org/2000/svg" data-type="ag-overwritten-icon"  width="16" height="16" fill="none"><path fill="#000" d="m3.928 2.867.53.53 8.05 8.05.53.531-1.06 1.06-.53-.53-8.05-8.05-.53-.53 1.06-1.06Z" /><path fill="#000" d="M8 2.5a5.5 5.5 0 1 0 0 11 5.5 5.5 0 0 0 0-11ZM1 8a7 7 0 1 1 14 0A7 7 0 0 1 1 8Z"/></svg>`,
  smallRight: `<svg xmlns="http://www.w3.org/2000/svg" data-type="ag-overwritten-icon"  height="18" viewBox="0 0 24 24" width="18" fill="currentColor"><path d="M0 0h24v24H0z" fill="none"/><path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"/></svg>`,
  menuAlt: `<svg xmlns="http://www.w3.org/2000/svg" data-type="ag-overwritten-icon"  height="18" viewBox="0 0 24 24" width="18"  fill="currentColor"><path d="M0 0h24v24H0z" fill="none"/><path d="M12 8c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z"/></svg>`,
  menuPin: `<svg xmlns="http://www.w3.org/2000/svg" data-type="ag-overwritten-icon"  height="18" viewBox="0 0 24 24" width="18" fill="transparent" stroke="black" stroke-width="1"><g><path d="M16,9V4l1,0c0.55,0,1-0.45,1-1v0c0-0.55-0.45-1-1-1H7C6.45,2,6,2.45,6,3v0 c0,0.55,0.45,1,1,1l1,0v5c0,1.66-1.34,3-3,3h0v2h5.97v7l1,1l1-1v-7H19v-2h0C17.34,12,16,10.66,16,9z" /></g></svg>`,
  cancel: `<svg xmlns="http://www.w3.org/2000/svg" data-type="ag-overwritten-icon"  height="18" viewBox="0 0 24 24" width="18" fill="currentColor"><path d="M0 0h24v24H0z" fill="none"/><path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"/></svg>`,
  close: `<svg xmlns="http://www.w3.org/2000/svg" data-type="ag-overwritten-icon"  height="18" viewBox="0 0 24 24" width="18" fill="currentColor"><path d="M0 0h24v24H0z" fill="none"/><path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"/></svg>`,
  check: `<svg xmlns="http://www.w3.org/2000/svg" data-type="ag-overwritten-icon"  height="18" viewBox="0 0 24 24" width="18" fill="currentColor"><path d="M0 0h24v24H0z" fill="none"/><path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z"/></svg>`,

  groupContracted: `<svg xmlns="http://www.w3.org/2000/svg" data-type="ag-overwritten-icon" height="18" viewBox="0 0 24 24" width="18" fill="currentColor"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M8.59 7.41L13.17 12l-4.58 4.59L10 18l6-6-6-6-1.41 1.41z"/></svg>`,
  groupExpanded: `<svg xmlns="http://www.w3.org/2000/svg" data-type="ag-overwritten-icon"  height="18" viewBox="0 0 24 24" width="18" fill="currentColor"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z"/></svg>`,
  rowGroupPanel: `<svg xmlns="http://www.w3.org/2000/svg" data-type="ag-overwritten-icon"  height="18" viewBox="0 0 24 24" width="18" fill="currentColor"><g><rect fill="none" height="24" width="24"/></g><g><g><rect height="2" width="11" x="3" y="10"/><rect height="2" width="11" x="3" y="6"/><rect height="2" width="7" x="3" y="14"/><polygon points="20.59,11.93 16.34,16.17 14.22,14.05 12.81,15.46 16.34,19 22,13.34"/></g></g></svg>`,
  menuRemoveRowGroup: `<svg xmlns="http://www.w3.org/2000/svg" data-type="ag-overwritten-icon"  height="18" viewBox="0 0 24 24" width="18" fill="currentColor"><g><rect fill="none" height="24" width="24"/></g><g><g><rect height="2" width="11" x="3" y="10"/><rect height="2" width="11" x="3" y="6"/><rect height="2" width="7" x="3" y="14"/><polygon points="20.59,11.93 16.34,16.17 14.22,14.05 12.81,15.46 16.34,19 22,13.34"/></g></g></svg>`,
  menuAddRowGroup: `<svg xmlns="http://www.w3.org/2000/svg" data-type="ag-overwritten-icon"  height="18" viewBox="0 0 24 24" width="18" fill="currentColor"><g><rect fill="none" height="24" width="24"/></g><g><g><rect height="2" width="11" x="3" y="10"/><rect height="2" width="11" x="3" y="6"/><rect height="2" width="7" x="3" y="14"/><polygon points="20.59,11.93 16.34,16.17 14.22,14.05 12.81,15.46 16.34,19 22,13.34"/></g></g></svg>`,

  columnMoveGroup: `<svg xmlns="http://www.w3.org/2000/svg" data-type="ag-overwritten-icon"  height="18" viewBox="0 0 24 24" width="18" fill="currentColor"><g><rect fill="none" height="24" width="24"/></g><g><g><rect height="2" width="11" x="3" y="10"/><rect height="2" width="11" x="3" y="6"/><rect height="2" width="7" x="3" y="14"/><polygon points="20.59,11.93 16.34,16.17 14.22,14.05 12.81,15.46 16.34,19 22,13.34"/></g></g></svg>`,
  columnMoveMove: `<svg xmlns="http://www.w3.org/2000/svg" data-type="ag-overwritten-icon"  height="16" viewBox="0 -960 960 960" width="16" fill="currentColor"><path d="M480-96 312-264l51-51 81 81v-174h72v174l81-81 51 51L480-96ZM264-312 96-480l168-168 51 51-81 81h174v72H234l81 81-51 51Zm432 0-51-51 81-81H552v-72h174l-81-81 51-51 168 168-168 168ZM444-552v-174l-81 81-51-51 168-168 168 168-51 51-81-81v174h-72Z"/></svg>`,
  columnMoveHide: `<svg height="18" viewBox="0 0 24 24"  data-type="ag-overwritten-icon"   width="18" fill="currentColor"><path d="M12 6c3.79 0 7.17 2.13 8.82 5.5-.59 1.22-1.42 2.27-2.41 3.12l1.41 1.41c1.39-1.23 2.49-2.77 3.18-4.53C21.27 7.11 17 4 12 4c-1.27 0-2.49.2-3.64.57l1.65 1.65C10.66 6.09 11.32 6 12 6m-1.07 1.14L13 9.21c.57.25 1.03.71 1.28 1.28l2.07 2.07c.08-.34.14-.7.14-1.07C16.5 9.01 14.48 7 12 7c-.37 0-.72.05-1.07.14M2.01 3.87l2.68 2.68C3.06 7.83 1.77 9.53 1 11.5 2.73 15.89 7 19 12 19c1.52 0 2.98-.29 4.32-.82l3.42 3.42 1.41-1.41L3.42 2.45zm7.5 7.5 2.61 2.61c-.04.01-.08.02-.12.02-1.38 0-2.5-1.12-2.5-2.5 0-.05.01-.08.01-.13m-3.4-3.4 1.75 1.75c-.23.55-.36 1.15-.36 1.78 0 2.48 2.02 4.5 4.5 4.5.63 0 1.23-.13 1.77-.36l.98.98c-.88.24-1.8.38-2.75.38-3.79 0-7.17-2.13-8.82-5.5.7-1.43 1.72-2.61 2.93-3.53"></path></svg>`,
  columnMoveLeft: `<svg xmlns="http://www.w3.org/2000/svg" data-type="ag-overwritten-icon"  height="18" viewBox="0 0 24 24" width="18" fill="currentColor"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M15.41 16.59L10.83 12l4.58-4.59L14 6l-6 6 6 6 1.41-1.41z"/></svg>`,
  columnMoveRight: `<svg xmlns="http://www.w3.org/2000/svg" data-type="ag-overwritten-icon"  height="18" viewBox="0 0 24 24" width="18" fill="currentColor"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M8.59 16.59L13.17 12 8.59 7.41 10 6l6 6-6 6-1.41-1.41z"/></svg>`,

  advancedFilterBuilder: `<svg xmlns="http://www.w3.org/2000/svg" data-type="ag-overwritten-icon"   height="18" viewBox="0 0 24 24" width="18" fill='none' stroke="currentColor"><g><path d="M0,0h24 M24,24H0" fill="red" stroke="none" /><path d="M4.25,5.61C6.27,8.2,10,13,10,13v6c0,0.55,0.45,1,1,1h2c0.55,0,1-0.45,1-1v-6c0,0,3.72-4.8,5.74-7.39 C20.25,4.95,19.78,4,18.95,4H5.04C4.21,4,3.74,4.95,4.25,5.61z"/><path d="M0,0h24v24H0V0z" fill="none" stroke="none"/></g></svg>`,
  advancedFilterBuilderDrag: `<svg xmlns="http://www.w3.org/2000/svg" data-type="ag-overwritten-icon"  height="16" width="16" viewBox="0 -960 960 960" fill="currentColor"><path d="M360-160q-33 0-56.5-23.5T280-240q0-33 23.5-56.5T360-320q33 0 56.5 23.5T440-240q0 33-23.5 56.5T360-160Zm240 0q-33 0-56.5-23.5T520-240q0-33 23.5-56.5T600-320q33 0 56.5 23.5T680-240q0 33-23.5 56.5T600-160ZM360-400q-33 0-56.5-23.5T280-480q0-33 23.5-56.5T360-560q33 0 56.5 23.5T440-480q0 33-23.5 56.5T360-400Zm240 0q-33 0-56.5-23.5T520-480q0-33 23.5-56.5T600-560q33 0 56.5 23.5T680-480q0 33-23.5 56.5T600-400ZM360-640q-33 0-56.5-23.5T280-720q0-33 23.5-56.5T360-800q33 0 56.5 23.5T440-720q0 33-23.5 56.5T360-640Zm240 0q-33 0-56.5-23.5T520-720q0-33 23.5-56.5T600-800q33 0 56.5 23.5T680-720q0 33-23.5 56.5T600-640Z"/></svg>`,
  advancedFilterBuilderAdd: `<svg xmlns="http://www.w3.org/2000/svg" height="18" viewBox="0 0 24 24" data-type="ag-overwritten-icon"  fill="currentColor" width="18"><path d="M0 0h24v24H0z" fill="none"/><path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z"/></svg>`,
  advancedFilterBuilderInvalid: `<svg xmlns="http://www.w3.org/2000/svg" height="18" viewBox="0 0 24 24" width="18" data-type="ag-overwritten-icon"  fill="currentColor"><path d="M0 0h24v24H0z" fill="none"/><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.42 0-8-3.58-8-8 0-1.85.63-3.55 1.69-4.9L16.9 18.31C15.55 19.37 13.85 20 12 20zm6.31-3.1L7.1 5.69C8.45 4.63 10.15 4 12 4c4.42 0 8 3.58 8 8 0 1.85-.63 3.55-1.69 4.9z"/></svg>`,
  advancedFilterBuilderRemove: `<svg xmlns="http://www.w3.org/2000/svg" data-type="ag-overwritten-icon"  height="18" viewBox="0 0 24 24" width="18" fill="currentColor"><path d="M0 0h24v24H0z" fill="none"/><path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"/></svg>`,
  advancedFilterBuilderMoveUp: `<svg xmlns="http://www.w3.org/2000/svg" data-type="ag-overwritten-icon" viewBox="0 -960 960 960" width="16" height="16"  fill="currentColor"><path d="M444-192v-438L243-429l-51-51 288-288 288 288-51 51-201-201v438h-72Z"/></svg>`,
  advancedFilterBuilderMoveDown: `<svg xmlns="http://www.w3.org/2000/svg" data-type="ag-overwritten-icon"  height="18" viewBox="0 -960 960 960" width="18" fill="currentColor"><path d="M444-768v438L243-531l-51 51 288 288 288-288-51-51-201 201v-438h-72Z"/></svg>`,

  // columnMoveAdd: '<i class="fa fa-university"/>',
  // columnMovePivot: '<i class="fa fa-university"/>',
  // columnMovePin: '<i class="fa fa-university"/>',
  // menu: '<i class="fa fa-university"/>',
  // filter:: '<i class="fa fa-university"/>',
  // columns: '<i class="fa fa-university"/>',
  // pivotPanel: '<i class="fa fa-magic"/>',
  // valuePanel: '<i class="fa fa-magnet"/>',
  // menuValue: "V",
  // clipboardCopy: ">>",
  // clipboardCut: "<<",
  // clipboardPaste: ">>",
  // columnGroupOpened: "expanded",
  // columnGroupClosed: "contracted",
  // columnSelectClosed: "tree-closed",
  // columnSelectOpen: "tree-open",
  // columnSelectIndeterminate: "tree-indeterminate",
  // setFilterGroupClosed: "tree-closed",
  // setFilterGroupOpen: "tree-open",
  // setFilterGroupIndeterminate: "tree-indeterminate",
  // chart: "chart",
  // check: "tick",
  // first: "first",
  // previous: "previous",
  // next: "next",
  // last: "last",
  // linked: "linked",
  // unlinked: "unlinked",
  // colorPicker: "color-picker",
  // groupLoading: "loading",
  // maximize: "maximize",
  // minimize: "minimize",
  // save: "save",
  // csvExport: "csv",
  // excelExport: "excel",
  // smallDown: "small-down",
  // smallLeft: "small-left",
  // smallUp: "small-up",
  // chartsMenuEdit: "chart",
  // chartsMenuAdvancedSettings: "settings",
  // chartsMenuAdd: "plus",
  // checkboxChecked: "checkbox-checked",
  // checkboxIndeterminate: "checkbox-indeterminate",
  // checkboxUnchecked: "checkbox-unchecked",
  // radioButtonOn: "radio-button-on",
  // radioButtonOff: "radio-button-off",
};
