import { FC } from "react";
import { Stack } from "@mui/material";
import { CommitmentUtilizationWidgetHeaderInfo } from "./CommitmentUtilizationWidgetHeaderInfo";
import { WidgetCardHeaderActionRow } from "../../common/widget-header/WidgetCardHeaderActionRow";
import { useAppSelector } from "../../../../../../../store/hooks";
import { commitmentUtilizationWidgetByIdSelector } from "../../../../../../../store/dashboards/selectors/widgets/commitment-utilization/widget-view/commitmentUtilizationWidgetByIdSelector";
import { WidgetTitleProps } from "../../../../utils/types/types";

interface CommitmentUtilizationWidgetCardHeaderProps {
  widgetId: string;
  hovered: boolean;
}

export const CommitmentUtilizationWidgetCardHeader: FC<
  CommitmentUtilizationWidgetCardHeaderProps
> = ({ widgetId, hovered }) => {
  const widget = useAppSelector((state) =>
    commitmentUtilizationWidgetByIdSelector(state, widgetId),
  );

  const widgetTitleProps: WidgetTitleProps = {
    widgetName: widget?.name ?? "",
    dates: {},
  };

  return (
    <Stack
      pl={2}
      pr={0}
      py={1}
      borderBottom={1}
      position="sticky"
      top={0}
      bgcolor="white"
      zIndex={2}
      borderColor="grey.300"
    >
      <WidgetCardHeaderActionRow
        widgetId={widgetId}
        hovered={hovered}
        widgetType="commitments_utilization"
        widgetTitleProps={widgetTitleProps}
      />

      <CommitmentUtilizationWidgetHeaderInfo
        providerOrganisationId={widget?.provider_organisation_id}
        commitmentType={widget?.commitment_type}
        grouping={widget?.grouping}
        dates={widget?.dates}
      />
    </Stack>
  );
};
