import { useRef } from "react";

export type RefsCollection = Record<string, any>;

export type SetRefsFromKey = (key: string) => (element: any) => void;

export const useRefs = (): {
  refs: RefsCollection;
  setRefFromKey: SetRefsFromKey;
} => {
  const refs = useRef<RefsCollection>({});

  const setRefFromKey: SetRefsFromKey = (key) => (element) => {
    refs.current[key] = element;
  };

  return { refs: refs.current, setRefFromKey };
};
