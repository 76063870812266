import { ColumnSetupType } from "../../../../../../../../../../../../../../storybook/data-grid/utils/types/types";
import { RdsSnapshot } from "../../../../../../../../../../../../../../services/cloudchipr.api";

export const rdsSnapshotColumns: ColumnSetupType<RdsSnapshot>[] = [
  {
    accessorKey: "name",
    header: "Snapshot name",
    type: "identifier",
    meta: { sticky: "left", hideFromSettings: true },
  },
  {
    accessorKey: "price_per_month",
    header: "Monthly Price",
    type: "money",
  },
  {
    accessorKey: "all_time_spent",
    header: "Total Spend",
    type: "money",
  },
  {
    accessorKey: "creation_data",
    header: "Created By",
    headerTooltip:
      "The Created By value is only available for resources created within the last 90 days.",
    type: "createdBy",
  },
  {
    accessorKey: "created_at",
    header: "Creation Time",
    type: "date",
  },
  {
    accessorKey: "storage_type",
    header: "Storage Type",
  },
  {
    accessorKey: "storage",
    header: "DB Storage Size",
    type: "byteToGib",
    minSize: 170,
  },
  {
    accessorKey: "is_public",
    header: "Is Public",
    type: "hasField",
  },
  {
    accessorKey: "type",
    header: "Backup Type",
  },
  {
    accessorKey: "engine",
    header: "DB Engine",
  },
  {
    accessorKey: "db_type",
    header: "DB Type",
  },
  {
    accessorKey: "is_encrypted",
    header: "Encrypted",
    cell: (cell) => String(!!cell.getValue()),
  },
  {
    accessorKey: "billable",
    header: "Billable",
    type: "billable",
  },
  { accessorKey: "zone", header: "Region", type: "withCopy" },
  { accessorKey: "tags", header: "Tags", type: "tag", enableSorting: false },
  {
    accessorKey: "tags",
    header: "Smart Tags",
    id: "smart_tag",
    type: "smartTag",
    enableSorting: false,
  },

  {
    accessorKey: "task",
    header: "Task Status",
    type: "taskStatus",
    enableSorting: false,
  },
  {
    accessorKey: "issues",
    header: "JIRA Status",
    type: "jiraTicketStatus",
    enableSorting: false,
  },
];
