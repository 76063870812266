import { FC, Fragment, MouseEvent, ReactNode } from "react";
import {
  Button,
  Checkbox,
  ListItemButton,
  ListItemText,
  Stack,
  Theme,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import { DropdownSelectOption } from "../utils/types/types";

interface FilterListItemButtonProps {
  wrappedWithButton: boolean;
  option: DropdownSelectOption;
  selected?: boolean;
  singleSelect: boolean;
  showOnly: boolean;
  isIndeterminate?: boolean;
  onOnlyClick(e: MouseEvent): void;
  renderOption?(option: DropdownSelectOption): ReactNode;
}

export const FilterListItemButton: FC<FilterListItemButtonProps> = ({
  wrappedWithButton,
  option,
  selected,
  singleSelect,
  isIndeterminate,
  onOnlyClick,
  renderOption,
  showOnly,
}) => {
  const { label, disabled, hideCheckbox } = option;

  const withoutCheckboxColor = selected
    ? (theme: Theme) => theme.palette.primary.light
    : "unset";

  const buttonContent = (
    <Fragment>
      {!singleSelect && !hideCheckbox && (
        <Checkbox
          disableRipple
          size="small"
          tabIndex={-1}
          disabled={!!disabled}
          checked={selected}
          indeterminate={!disabled && !selected && isIndeterminate}
        />
      )}

      <ListItemText>{renderOption ? renderOption(option) : label}</ListItemText>

      {showOnly && (
        <Stack
          pl={10}
          right={0}
          height="100%"
          justifyContent="center"
          position="absolute"
          sx={{ background: onlyButtonBackground }}
        >
          <Button
            size="small"
            onClick={onOnlyClick}
            sx={{
              background: "transparent",
              "&:hover": { background: onlyButtonBackground },
              "& .MuiTouchRipple-root span": { background: "transparent" },
            }}
          >
            Only
          </Button>
        </Stack>
      )}
    </Fragment>
  );

  if (wrappedWithButton) {
    return (
      <ListItemButton
        dense
        disabled={!!disabled}
        sx={{
          px: singleSelect ? 2 : 1,
          bgcolor: hideCheckbox ? withoutCheckboxColor : undefined,
        }}
      >
        {buttonContent}
      </ListItemButton>
    );
  }

  return (
    <Stack
      direction="row"
      flex={1}
      px={1}
      py={0.5}
      sx={{ ".MuiListItemText-primary": { fontSize: 14 } }}
    >
      {buttonContent}
    </Stack>
  );
};

const onlyButtonBackground = `linear-gradient(to right, transparent, ${grey[100]}, ${grey[100]})`;
