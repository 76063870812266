import { createAsyncThunk } from "@reduxjs/toolkit";
import { addNewWidgetToLayoutThunk } from "./addNewWidgetToLayoutThunk";
import { cloudChiprApi } from "../../../../../services/cloudchipr.api";
import { currentDashboardIdSelector } from "../../../selectors/dashboard/currentDashboardIdSelector";
import { RootState } from "../../../../store";
import { generateUpdateDashboardWidgetFixedCacheKey } from "../../../../../components/pages/dashboard/utils/helpers/dashbaordFixedCacheKeyGenerator";
import { setWidgetSetup } from "../../../dashboardsSlice";
import { UpdateDashboardWidgetRequestType } from "../../../utils/types/types";
import { enqueueSnackbarErrorAlert } from "../../../../../components/pages/common/snackbar-alert/EnqueueSnackbarErrorAlert";

interface UpdateWidgetThunkArgs {
  widgetId: string;
  widget: UpdateDashboardWidgetRequestType;
}

export const updateWidgetThunk = createAsyncThunk(
  "dashboards/updateWidget",
  async (
    { widgetId, widget }: UpdateWidgetThunkArgs,
    { dispatch, getState },
  ) => {
    const state = getState() as RootState;
    const {
      putUsersMeOrganisationsCurrentDashboardsByDashboardIdWidgetsAndWidgetId,
    } = cloudChiprApi.endpoints;

    const dashboardId = currentDashboardIdSelector(state);

    if (!dashboardId) {
      return;
    }

    try {
      const response = await dispatch(
        putUsersMeOrganisationsCurrentDashboardsByDashboardIdWidgetsAndWidgetId.initiate(
          {
            dashboardId,
            widgetId,
            body: { widget },
          },
          {
            fixedCacheKey: generateUpdateDashboardWidgetFixedCacheKey(widgetId),
          },
        ),
      ).unwrap();

      dispatch(setWidgetSetup(null));
      await dispatch(addNewWidgetToLayoutThunk(response.id));

      return response;
    } catch (e) {
      // @ts-expect-error todo: fix api spec;
      enqueueSnackbarErrorAlert(e?.data?.message);

      return null;
    }
  },
);
