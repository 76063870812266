import {
  ChangeEvent,
  FC,
  Fragment,
  useCallback,
  useEffect,
  useState,
  Dispatch,
  SetStateAction,
} from "react";
import { CircularProgress, IconButton, TextField } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import { taskManagementUpdateTaskThunk } from "../../../../../../../store/task-management/thunks/actions/taskManagementUpdateTaskThunk";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../store/hooks";
import { tasksListGetLoadingSelector } from "../../../../../../../store/task-management/selectors/loading/tasksListGetLoadingSelector";
import { taskUpdateLoadingSelector } from "../../../../../../../store/task-management/selectors/loading/taskUpdateLoadingSelector";

interface NameCellRendererEditModeProps {
  name?: string;
  id?: string;
  setNameEditMode: Dispatch<SetStateAction<boolean>>;
}

export const NameCellRendererEditMode: FC<NameCellRendererEditModeProps> = ({
  name,
  id,
  setNameEditMode,
}) => {
  const dispatch = useAppDispatch();
  const [editName, setEditName] = useState(name ?? "");

  const getLoading = useAppSelector(tasksListGetLoadingSelector);
  const editLoading = useAppSelector(taskUpdateLoadingSelector);

  const nameChangeHandler = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setEditName(event.target.value);
    },
    [],
  );

  const editedNameSaveHandler = useCallback(async () => {
    if (editName === name) {
      setNameEditMode(false);
      return;
    }

    if (!id) {
      return;
    }

    await dispatch(
      taskManagementUpdateTaskThunk({
        name: editName,
        task_ids: [id],
      }),
    );
    setNameEditMode(false);
  }, [dispatch, editName, name, id, setNameEditMode]);

  useEffect(() => {
    setEditName(name ?? "");
  }, [name]);

  return (
    <Fragment>
      <TextField
        autoFocus
        fullWidth
        type="text"
        size="xsmall"
        value={editName}
        placeholder="Name"
        onChange={nameChangeHandler}
      />

      {getLoading || editLoading ? (
        <CircularProgress size={16} sx={{ ml: 1 }} />
      ) : (
        <IconButton size="small" onClick={editedNameSaveHandler}>
          <CheckIcon fontSize="inherit" />
        </IconButton>
      )}
    </Fragment>
  );
};
