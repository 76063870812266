import { SvgIcon, SvgIconProps } from "@mui/material";

function MongoSvgIcon({ color, ...props }: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9.48844 1.78331C8.76936 0.98658 8.15015 0.177397 8.02364 0.00933669C8.01033 -0.00311223 7.99035 -0.00311223 7.97703 0.00933669C7.85053 0.177397 7.23131 0.98658 6.51223 1.78331C0.340117 9.13439 7.48433 14.0953 7.48433 14.0953L7.54425 14.1327C7.59752 14.8983 7.73068 16 7.73068 16H7.99701H8.26333C8.26333 16 8.3965 14.9045 8.44977 14.1327L8.50968 14.0891C8.51635 14.0891 15.6605 9.13439 9.48844 1.78331ZM7.99701 13.9833C7.99701 13.9833 7.67742 13.7281 7.59086 13.5973V13.5849L7.97703 5.58024C7.97703 5.55534 8.01699 5.55534 8.01699 5.58024L8.40316 13.5849V13.5973C8.3166 13.7281 7.99701 13.9833 7.99701 13.9833Z"
          fill="#00684A"
        />
      </svg>
    </SvgIcon>
  );
}

MongoSvgIcon.muiName = "MongoSvgIcon";

export default MongoSvgIcon;
