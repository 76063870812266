import { FC, Fragment } from "react";
import { CustomCellRendererProps } from "ag-grid-react";
import { Button } from "@mui/material";
import { useToggle } from "rooks";
import { ApiKeysTest } from "../../ApiKeysTest";
import { ApiKey } from "../../../../../../../../services/cloudchipr.api";

export const ApiKeysListGridTestButtonCell: FC<
  CustomCellRendererProps<ApiKey>
> = ({ data: apiKey }) => {
  const [open, toggleOpen] = useToggle(false);

  if (!apiKey) {
    return;
  }

  return (
    <Fragment>
      <Button variant="outlined" onClick={toggleOpen} size="small">
        Test
      </Button>
      <ApiKeysTest
        open={open}
        handleClose={toggleOpen}
        apiKey={apiKey?.api_key}
      />
    </Fragment>
  );
};
