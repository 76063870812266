import { ColumnSetupType } from "../../../../../../../../../../../../../../storybook/data-grid/utils/types/types";
import { S3 } from "../../../../../../../../../../../../../../services/cloudchipr.api";
import { ResourceMetricsHeaderCell } from "../../../../../cells/metrics-cells/components/ResourceMetricsHeaderCell";

export const s3Columns: ColumnSetupType<S3>[] = [
  {
    accessorKey: "provider_unique_identifier",
    header: "Buckets",
    type: "identifier",
    size: 320,
    meta: { sticky: "left", hideFromSettings: true },
  },
  {
    accessorKey: "price_per_month",
    header: "Monthly Price",
    type: "money",
  },
  {
    accessorKey: "account",
    header: "Account",
    type: "account",
    size: 210,
  },
  {
    id: "all_request_sum",
    size: 220,
    minSize: 165,
    maxSize: 300,
    meta: { cellStyles: { p: 0 }, headerTitle: "All Requests" },
    accessorKey: "inline_metrics",
    type: "storageMetricsChart",
    header: ResourceMetricsHeaderCell,
  },
  {
    accessorKey: "created_at",
    header: "Creation Date",
    type: "date",
  },
  {
    accessorKey: "all_time_spent",
    header: "Total Spend",
    type: "money",
  },
  {
    accessorKey: "requests_value",
    header: "All Requests",
    headerTooltip: "Overall requests for the last 7 days.",
    type: "numeralWithNA",
  },
  {
    accessorKey: "size",
    header: "Size",
    type: "byteToGib",
  },
  {
    accessorKey: "public_access",
    header: "Public Access",
    headerTooltip:
      "Cloudchipr checks ACLs and bucket policies to detect if Public Read or Public Write permissions are enabled.",
    type: "publicAccess",
  },
  { accessorKey: "region", header: "Region", type: "withCopy" },
  { accessorKey: "tags", header: "Tags", type: "tag", enableSorting: false },
  {
    accessorKey: "tags",
    header: "Smart Tags",
    id: "smart_tag",
    type: "smartTag",
    enableSorting: false,
  },

  {
    accessorKey: "task",
    header: "Task Status",
    type: "taskStatus",
    enableSorting: false,
  },
  {
    accessorKey: "issues",
    header: "JIRA Status",
    type: "jiraTicketStatus",
    enableSorting: false,
  },
];
