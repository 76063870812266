import { FC } from "react";
import { Divider, Drawer, LinearProgress } from "@mui/material";
import { useWillUnmount } from "rooks";
import { ReportsBySourceDrawerHeader } from "./ReportsBySourceDrawerHeader";
import { ReportsBySourceDrawerContent } from "./ReportsBySourceDrawerContent";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { getAllReportsLoadingSelector } from "../../../../store/reports/selector/getAllReportsLoadingSelector";
import { ReportsDrawer } from "../../reports/components/drawer/ReportsDrawer";
import { Report } from "../../../../services/cloudchipr.api";
import { resetReportsData } from "../../../../store/reports/reportsSlice";

interface ReportsSourceDrawerProps {
  open: boolean;
  toggleOpen(): void;
  sourceType: Report["source_type"];
  sourceId: string;
}

export const ReportsBySourceDrawer: FC<ReportsSourceDrawerProps> = ({
  toggleOpen,
  open,
  sourceId,
  sourceType,
}) => {
  const dispatch = useAppDispatch();
  const loading = useAppSelector(getAllReportsLoadingSelector);

  useWillUnmount(() => {
    dispatch(resetReportsData());
  });

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={toggleOpen}
      PaperProps={paperProps}
      slotProps={slotProps}
    >
      <ReportsBySourceDrawerHeader onClose={toggleOpen} />
      <Divider />
      {loading && <LinearProgress />}

      <ReportsBySourceDrawerContent sourceId={sourceId} />
      <ReportsDrawer
        disableSourceSelect
        defaultSourceType={sourceType}
        onSave={toggleOpen}
      />
    </Drawer>
  );
};

const paperProps = {
  sx: {
    width: "50dvw",
  },
};

const slotProps = {
  backdrop: {
    invisible: true,
  },
};
