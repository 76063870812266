import { ColDef } from "ag-grid-community";

export const automationsNextRunSortingFn: ColDef["comparator"] = (
  valueA,
  valueB,
  nodeA,
  nodeB,
) => {
  const aNextRun = nodeA.data.metadata?.next_run_at;
  const bNextRun = nodeB.data.metadata?.next_run_at;
  const aStatus = nodeA.data.status;
  const bStatus = nodeB.data.status;

  const aIsDate = aNextRun !== null && !isNaN(Date.parse(aNextRun));
  const bIsDate = bNextRun !== null && !isNaN(Date.parse(bNextRun));

  if (aIsDate && bIsDate) {
    return new Date(aNextRun).getTime() - new Date(bNextRun).getTime();
  }
  if (aIsDate) return -1;
  if (bIsDate) return 1;

  if (aNextRun === null && bNextRun === null) {
    return aStatus === "inactive" ? 1 : -1;
  }
  if (aNextRun === null) return -1;
  if (bNextRun === null) return 1;

  if (aStatus === "inactive" && bStatus === "inactive") {
    return 0;
  }

  return aStatus === "inactive" ? 1 : -1;
};
