import { createDraftSafeSelector } from "@reduxjs/toolkit";
import { accountsFilterValueSelector } from "./slice-data/accountsFilterValueSelector";
import { accountsSortBySelector } from "./slice-data/accountsSortBySelector";
import { accountsDividedToStatusesByOrgIdSelector } from "./accountsDividedToStatusesByOrgIdSelector";
import { accountsSortingCombiner } from "../combiners/accountsSortingCombiner";
import { Account } from "../../../services/cloudchipr.api";
import { AccountsPageTabs } from "../../../components/pages/accounts-group/utils/types/types";

export const filteredAccountsConvertedForProviderAccountsPageByOrgIdSelector =
  createDraftSafeSelector(
    [
      accountsDividedToStatusesByOrgIdSelector,
      accountsFilterValueSelector,
      accountsSortBySelector,
    ],
    (
      accounts,
      filterValue,
      sortBy,
    ): Record<AccountsPageTabs, Account[]> | null => {
      if (!accounts) {
        return null;
      }

      const searchValue = filterValue.toLowerCase();

      const filteredActiveAccounts = accounts.active.filter(
        (account) =>
          account.provider_account_id?.toLowerCase()?.includes(searchValue) ||
          account.provider_account_name?.toLowerCase()?.includes(searchValue),
      );

      const filteredInactiveAccounts = accounts.inactive.filter(
        (account) =>
          account.provider_account_id?.toLowerCase()?.includes(searchValue) ||
          account.provider_account_name?.toLowerCase()?.includes(searchValue),
      );

      const sortedActiveAccounts =
        accountsSortingCombiner(filteredActiveAccounts, sortBy) ?? [];

      const sortedInactiveAccounts =
        accountsSortingCombiner(filteredInactiveAccounts, sortBy) ?? [];

      return {
        active: sortedActiveAccounts,
        inactive: sortedInactiveAccounts,
      };
    },
  );
