import { FC } from "react";
import { getTotalCostTooltipText } from "./utils/helpers/getTotalCostTooltipText";
import {
  CostType,
  DateLabelNullable,
  ResourceExplorerCostsDateDetails,
} from "../../../../../../../../services/cloudchipr.api";
import { money } from "../../../../../../../../utils/numeral/money";
import { TrendChip } from "../../../../../../../common/chips/TrendChip";
import { InfoCard } from "../../../../../../common/info-cards/InfoCard";
import { getTrendTooltipTitle } from "../../../../../../dashboard/utils/helpers/trend-tooltip-title/getTrendTooltipTitle";
import { useAppSelector } from "../../../../../../../../store/hooks";
import { connectedProvidersSelector } from "../../../../../../../../store/common/selectors/connectedProvidersSelector";

interface TotalCostProps {
  totalCost: number;
  totalCostTrend: number | null;
  totalCostDates?: ResourceExplorerCostsDateDetails;
  previousPeriodCostDates?: ResourceExplorerCostsDateDetails;
  dateLabel?: DateLabelNullable;
  costType?: CostType;
}

export const TotalCost: FC<TotalCostProps> = ({
  totalCost,
  totalCostTrend,
  totalCostDates,
  previousPeriodCostDates,
  dateLabel,
  costType,
}) => {
  const tooltipTitle = getTrendTooltipTitle(
    totalCostDates?.from_date,
    totalCostDates?.to_date,
    previousPeriodCostDates?.from_date,
    previousPeriodCostDates?.to_date,
    undefined,
    dateLabel,
  );
  const connectedProviders = useAppSelector(connectedProvidersSelector);

  return (
    <InfoCard
      title="Total Cost"
      value={money(totalCost)}
      tooltip={getTotalCostTooltipText(connectedProviders, costType)}
      extra={<TrendChip value={totalCostTrend} tooltipTitle={tooltipTitle} />}
    />
  );
};
