import { createDraftSafeSelector } from "@reduxjs/toolkit";
import { getUsersMeSelector } from "./getUsersMeSelector";
import { User } from "../../services/cloudchipr.api";

export const currentUserSelector = createDraftSafeSelector(
  [getUsersMeSelector],
  (getUsersMe): User | null => {
    const { data: user } = getUsersMe;

    return user ?? null;
  },
);
