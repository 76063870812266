import { forwardRef, useCallback, useMemo } from "react";
import { useLocation } from "react-router-dom";
import { SxProps } from "@mui/system";
import {
  UniqueIdentifier,
  DraggableAttributes,
  DraggableSyntheticListeners,
} from "@dnd-kit/core";
import FolderOpenIcon from "@mui/icons-material/FolderOpen";
import { Box, ListItemProps, Stack, Theme, Typography } from "@mui/material";

import {
  SortableTreeFolderActionArgs,
  SortableTreeItemActionArgs,
  TreeItems,
} from "./utils/types";
import { NavItem, NavItemProps } from "../nav-item/NavItem";
import { TypographyWithTooltip } from "../../../common/TypographyWithTooltip";
import {
  EmbeddedUser,
  ProtectionDetails,
} from "../../../../services/cloudchipr.api";

export interface TreeItemProps {
  collapsed?: boolean;
  depth: number;
  ghost?: boolean;
  handleProps?: DraggableAttributes | DraggableSyntheticListeners;
  indentationWidth: number;
  id: string;
  onCollapse?(id: UniqueIdentifier): void;
  wrapperRef?(node: HTMLLIElement): void;
  name: string;
  disabled?: boolean;
  type: "folder" | "item";
  renderFolderAction?: (
    args: SortableTreeFolderActionArgs,
  ) => ListItemProps["secondaryAction"];
  renderItemAction?: (
    args: SortableTreeItemActionArgs,
  ) => ListItemProps["secondaryAction"];
  parentId: UniqueIdentifier | null;
  sx?: SxProps<Theme>;
  items?: TreeItems | null;
  getNavigateTo(id: UniqueIdentifier): string;
  icon?: NavItemProps["icon"];
  rightIcon?: NavItemProps["icon"];
  emptyText?: string;
  protectedBy?: EmbeddedUser;
  protectionDetails?: ProtectionDetails;
}

export const TreeItem = forwardRef<HTMLDivElement | null, TreeItemProps>(
  (
    {
      depth,
      ghost,
      handleProps,
      indentationWidth,
      collapsed,
      onCollapse,
      sx,
      wrapperRef,
      name,
      type,
      disabled,
      renderFolderAction,
      renderItemAction,
      id,
      parentId,
      items,
      getNavigateTo,
      icon,
      rightIcon: RightIcon,
      emptyText,
      protectionDetails,
      protectedBy,
    },
    ref,
  ) => {
    const localItemAction = useMemo(() => {
      return renderItemAction?.({
        id,
        name,
        parentId,
        protectedBy,
        protectionDetails,
      });
    }, [renderItemAction, id, name, parentId, protectionDetails, protectedBy]);

    const containsProtectedItem = useMemo(() => {
      return items?.some((item) => item.protectionDetails?.is_protected);
    }, [items]);

    const localFolderAction = useMemo(() => {
      return renderFolderAction?.({ id, name, containsProtectedItem });
    }, [renderFolderAction, id, name, containsProtectedItem]);

    const localItemStyle = ghost ? itemIndicatorStyle : {};

    const collapseFolderClickHandler = useCallback(() => {
      onCollapse?.(id);
    }, [onCollapse, id]);

    const location = useLocation();

    const folderSelected = useMemo(() => {
      return items?.some((item) => {
        const to = getNavigateTo(item.id);
        return location.pathname.startsWith(to);
      });
    }, [items, location.pathname, getNavigateTo]);

    const navigateTo = useMemo(() => {
      return getNavigateTo(id);
    }, [id, getNavigateTo]);

    return (
      <Box
        ref={disabled ? undefined : wrapperRef}
        sx={{
          pl: `${indentationWidth * depth}px`,
          cursor: !disabled ? "grab" : "not-allowed",
          userSelect: !disabled ? "inherit" : "non",
        }}
      >
        {ghost && <Box sx={indicatorStyle} />}

        <Stack sx={{ ...localItemStyle, ...sx }} ref={ref}>
          <Stack
            {...handleProps}
            sx={ghost ? { opacity: 0 } : { opacity: 1 }}
            direction="row"
          >
            {type === "folder" ? (
              <Box width="100%">
                <NavItem
                  nested
                  primary={
                    <Stack direction="row" spacing={0.5} alignItems="center">
                      <TypographyWithTooltip title={name} variant="inherit" />
                      {RightIcon && (
                        <RightIcon
                          color="action"
                          sx={{
                            fontSize: 14,
                          }}
                        />
                      )}
                    </Stack>
                  }
                  secondaryAction={localFolderAction}
                  secondaryActionHover
                  expandable
                  icon={FolderOpenIcon}
                  selected={folderSelected}
                  onButtonClick={collapseFolderClickHandler}
                  expanded={collapsed}
                />
                {collapsed && !items?.length && (
                  <Typography
                    variant="caption"
                    color="text.secondary"
                    pl={6}
                    key="empty"
                  >
                    {emptyText}
                  </Typography>
                )}
              </Box>
            ) : (
              <NavItem
                nested
                primary={
                  <Stack direction="row" spacing={0.5} alignItems="center">
                    <TypographyWithTooltip title={name} variant="inherit" />
                    {RightIcon && (
                      <RightIcon
                        color="action"
                        sx={{
                          fontSize: 14,
                        }}
                      />
                    )}
                  </Stack>
                }
                to={navigateTo}
                icon={icon}
                isProtected={!!protectionDetails?.is_protected}
                secondaryAction={!disabled ? localItemAction : null}
                secondaryActionHover
              />
            )}
          </Stack>
        </Stack>
      </Box>
    );
  },
);

const itemIndicatorStyle = {
  position: "relative",
  height: "2px",
  bgcolor: "primary.main",
  mx: 4,
};

const indicatorStyle = {
  opacity: 1,
  position: "relative",
  zIndex: 1,
};
