import { FC, useCallback } from "react";
import { Button, Tooltip } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useAppAbility } from "../../../../../services/permissions";
import { useGetUsersMeOrganisationsCurrentDashboardsPossibleWidgetsQuery } from "../../../../../services/cloudchipr.api";
import { useAppDispatch, useAppSelector } from "../../../../../store/hooks";
import { setWidgetSelectorState } from "../../../../../store/dashboards/dashboardsSlice";
import { currentDashboardProtectedSelector } from "../../../../../store/dashboards/selectors/dashboard-hierarchy-visibility/currentDashboardProtectedSelector";

interface AddingWidgetProps {
  startIcon?: boolean;
  variant?: "text" | "contained";
  disabled?: boolean;
}

export const AddingWidget: FC<AddingWidgetProps> = ({
  startIcon,
  variant = "contained",
  disabled,
}) => {
  const dispatch = useAppDispatch();
  const { cannot } = useAppAbility();
  const canNotCreateWidget = cannot("create", "dashboard");
  const isProtected = useAppSelector(currentDashboardProtectedSelector);
  const { refetch } =
    useGetUsersMeOrganisationsCurrentDashboardsPossibleWidgetsQuery({});

  const openWidgetSelector = useCallback(() => {
    dispatch(setWidgetSelectorState(true));
    refetch();
  }, [dispatch, refetch]);

  return (
    <Tooltip
      arrow
      placement="top"
      title={isProtected ? "Protected dashboards cannot be modified." : ""}
    >
      <span>
        <Button
          startIcon={startIcon ? <AddIcon /> : undefined}
          disabled={canNotCreateWidget || disabled || isProtected}
          variant={variant}
          onClick={openWidgetSelector}
          sx={{ whiteSpace: "nowrap" }}
        >
          Add Widget
        </Button>
      </span>
    </Tooltip>
  );
};
