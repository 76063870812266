import { FC, Fragment } from "react";
import { Stack } from "@mui/material";
import { useFlag } from "@unleash/proxy-client-react";
import { useToggle } from "rooks";
import LinkIcon from "@mui/icons-material/Link";
import { ResourceExplorerBillingStatuses } from "./resource-explorer-billing-statuses/ResourceExplorerBillingStatuses";
import { ResourceExplorerResetButton } from "./ResourceExplorerResetButton";
import { ResourceExplorerAppBarSaveButton } from "./ResourceExplorerAppBarSaveButton";
import { ResourceExplorerActionType } from "../../../utils/types/types";
import { ResourceExplorerCsvDownloader } from "../../resource-explorer-card/components/resource-explorer-data-grid/components/csv/ResourceExplorerCSVDownloader";
import { SendReportActionItem } from "../../../../common/send-report/SendReportActionItem";
import { ReportsBySourceActionItem } from "../../../../common/reports-by-source/ReportsBySourceActionItem";
import { ReportsBySourceDrawer } from "../../../../common/reports-by-source/ReportsBySourceDrawer";
import { CopyIconButton } from "../../../../../common/CopyIconButton";

interface ResourceExplorerAppBarActionsProps {
  viewId: string;
  loading?: boolean;
  onSaveClick(type: ResourceExplorerActionType): void;
}

export const ResourceExplorerAppBarActions: FC<
  ResourceExplorerAppBarActionsProps
> = ({ onSaveClick, viewId, loading }) => {
  const enableSubscriptions = useFlag("EnableSubscriptionsPage");
  const [reportsDrawerOpen, toggleReportsDrawerOpen] = useToggle(false);

  return (
    <Stack direction="row" spacing={2} alignItems="center">
      <ResourceExplorerCsvDownloader trigger="button" color="primary" />
      <CopyIconButton
        data={window.location.href}
        TooltipProps={{ title: "Copy URL", placement: "top" }}
      >
        <LinkIcon fontSize="small" color="primary" />
      </CopyIconButton>
      {enableSubscriptions && (
        <Fragment>
          <SendReportActionItem
            iconButton
            disabled={loading}
            sourceType="view"
            sourceId={viewId}
          />

          <ReportsBySourceActionItem
            sourceId={viewId}
            sourceType="view"
            onClick={toggleReportsDrawerOpen}
          />
        </Fragment>
      )}

      <ResourceExplorerBillingStatuses />

      <ResourceExplorerResetButton />

      <ResourceExplorerAppBarSaveButton
        openDialog={onSaveClick}
        viewId={viewId}
      />

      <ReportsBySourceDrawer
        sourceId={viewId}
        open={reportsDrawerOpen}
        sourceType="view"
        toggleOpen={toggleReportsDrawerOpen}
      />
    </Stack>
  );
};
