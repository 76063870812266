import { FC, useState } from "react";
import { Stack } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { useEffectOnceWhen } from "rooks";
import { LiveUsageFiltersNavigationItemByResourceType } from "./LiveUsageFiltersNavigationItemByResourceType";
import { allResourcesByProvider } from "../../../../../../../../utils/constants/resources/resourceGroupsByProvider";
import { RefsCollection } from "../../../../../../../../utils/hooks/useRefs";
import { ProviderType } from "../../../../../../../../services/cloudchipr.api";
import { LiveUsageNavigationResourceType } from "../../../../navigation/utils/hooks/useLiveUsageMgmtPageNavigate.hook";
import { getIsResourceTypeHiddenByFeatureToggle } from "../../../../../utils/helpers/getIsResourceTypeHiddenByFeatureToggle";
import { allResourcesResourceType } from "../../../../../utils/constants/common";
import { resourceTypeSearchParam } from "../../../../../utils/constants/searchParams";

interface LiveUsageFiltersNavigationProps {
  provider: ProviderType;
  resourcesRefs: RefsCollection;
}

export const LiveUsageFiltersNavigation: FC<
  LiveUsageFiltersNavigationProps
> = ({ resourcesRefs, provider }) => {
  const [activeItem, setActiveItem] = useState<LiveUsageNavigationResourceType>(
    allResourcesResourceType,
  );

  const [searchParams] = useSearchParams();
  const searchParamsResourceType = searchParams.get(
    resourceTypeSearchParam,
  ) as LiveUsageNavigationResourceType;

  useEffectOnceWhen(() => {
    resourcesRefs[searchParamsResourceType]?.scrollIntoView({
      behavior: "smooth",
    });

    setActiveItem(searchParamsResourceType);
  }, searchParamsResourceType !== allResourcesResourceType);

  if (!activeItem) {
    return null;
  }

  const resources = allResourcesByProvider.get(provider);

  return (
    <Stack overflow="auto" pr={1}>
      {resources?.map((resourceType) => {
        if (getIsResourceTypeHiddenByFeatureToggle(resourceType)) {
          return null;
        }

        return (
          <LiveUsageFiltersNavigationItemByResourceType
            key={resourceType}
            resourceRef={resourcesRefs[resourceType] ?? null}
            active={activeItem === resourceType}
            setActiveItem={setActiveItem}
            resourceType={resourceType}
          />
        );
      })}
    </Stack>
  );
};
