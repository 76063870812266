import { FC, ReactNode } from "react";
import { IconButton, Tooltip, TooltipProps } from "@mui/material";
import { useToggle } from "rooks";

interface LiveUsageCollapsedNavItemProps {
  selected?: boolean;
  icon: ReactNode;
  onClick(): void;
  children: ReactNode;
}

export const LiveUsageCollapsedNavItem: FC<LiveUsageCollapsedNavItemProps> = ({
  selected,
  icon,
  onClick,
  children,
}) => {
  const [open, toggleOpen] = useToggle(false);

  return (
    <Tooltip
      open={open}
      onClose={toggleOpen}
      onOpen={toggleOpen}
      disableFocusListener
      title={children}
      placement="right-start"
      slotProps={slotProps}
    >
      <IconButton
        sx={{
          mb: 1,
          ml: 1,
          borderRadius: 2,
          bgcolor: selected ? "primary.light" : undefined,
        }}
        size="small"
        onClick={onClick}
      >
        {icon}
      </IconButton>
    </Tooltip>
  );
};

const slotProps: TooltipProps["slotProps"] = {
  tooltip: {
    sx: {
      px: 0,
      minWidth: 280,
      bgcolor: (theme) => theme.palette.common.white,
      color: (theme) => theme.palette.text.primary,
      boxShadow: (theme) => theme.shadows.at(1),
    },
  },
};
