import { FC, Fragment, useCallback } from "react";
import {
  Button,
  Chip,
  ListItemIcon,
  MenuItem,
  Typography,
} from "@mui/material";
import { ScheduleSendOutlined } from "@mui/icons-material";
import { SxProps, Theme } from "@mui/system";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { reportsTableFilteredDataBySourceLengthSelector } from "../../../../store/reports/selector/reportsTableFilteredDataBySourceLengthSelector";
import { Report } from "../../../../services/cloudchipr.api";
import { setReportsData } from "../../../../store/reports/reportsSlice";
import { getAllReportsLoadingSelector } from "../../../../store/reports/selector/getAllReportsLoadingSelector";

interface ReportsBySourceActionItemProps {
  sourceId: string;
  sourceType: Report["source_type"];
  onClick(): void;
  menuItem?: boolean;
  iconSx?: SxProps<Theme>;
  menuItemSx?: SxProps<Theme>;
}

export const ReportsBySourceActionItem: FC<ReportsBySourceActionItemProps> = ({
  sourceId,
  sourceType,
  onClick,
  menuItem,
  iconSx,
  menuItemSx,
}) => {
  const dispatch = useAppDispatch();
  const length = useAppSelector(reportsTableFilteredDataBySourceLengthSelector);
  const loading = useAppSelector(getAllReportsLoadingSelector);

  const handleClick = useCallback(() => {
    onClick();
    dispatch(
      setReportsData({
        source_type: sourceType,
        source_id: sourceId,
      }),
    );
  }, [onClick, dispatch, sourceType, sourceId]);

  return (
    <Fragment>
      {menuItem ? (
        <MenuItem onClick={handleClick} sx={menuItemSx}>
          <ListItemIcon>
            <ScheduleSendOutlined fontSize="small" sx={iconSx} />
          </ListItemIcon>
          <Typography variant="body2">Subscriptions</Typography>
        </MenuItem>
      ) : (
        <Button
          variant="outlined"
          size="small"
          onClick={handleClick}
          disabled={loading}
          startIcon={length ? <ScheduleSendOutlined /> : undefined}
        >
          {length ? (
            <Chip
              size="small"
              label={length}
              sx={{
                bgcolor: loading ? "grey.100" : "primary.light",
                color: loading ? "text.secondary" : "primary.main",
              }}
            />
          ) : (
            <ScheduleSendOutlined fontSize="small" />
          )}
        </Button>
      )}
    </Fragment>
  );
};
