import {
  Dispatch,
  FC,
  Fragment,
  SetStateAction,
  useCallback,
  useMemo,
} from "react";
import {
  CircularProgress,
  ListItemButton,
  Stack,
  Typography,
} from "@mui/material";
import { DropdownSelectOption } from "./utils/types/types";
import {
  FilterItemsList,
  FilterItemsListProps,
} from "./components/FilterItemsList";
import { FilterSelectAllItem } from "./components/FilterSelectAllItem";
import { filterOptionsByKeyword } from "./utils/helpers/filterOptionsByKeyword";

type FilterItemsListExtendedProps = Omit<
  FilterItemsListProps,
  "virtualized" | "filteredOptions"
>;
export interface DropdownListContentProps extends FilterItemsListExtendedProps {
  keyword: string;
  options: DropdownSelectOption[];
  creatable?: boolean;
  showSelectAll?: boolean;
  optionsLoading?: boolean;
  filterFn?(option: DropdownSelectOption, keyword: string): boolean;
  setExtraOptions?: Dispatch<SetStateAction<DropdownSelectOption[]>>;
}

export const DropdownListContent: FC<DropdownListContentProps> = ({
  options,
  submitHandlerOnClose,
  filterFn,
  singleSelect,
  listWidth,
  optionsLoading,
  closeHandler,
  keyword,
  selectedValues,
  setSelectedValues,
  creatable,
  showSelectAll = true,
  setExtraOptions,
  renderOption,
  noItemsLabel,
  listMaxHeight,
  lastSelectedOptionDisabled,
}) => {
  const filteredOptions = useMemo(() => {
    return filterOptionsByKeyword(options, keyword, filterFn);
  }, [keyword, options, filterFn]);

  const addNewSelectedValueHandler = useCallback(() => {
    closeHandler();

    if (!keyword || !setExtraOptions) {
      return;
    }
    setExtraOptions((options) => [
      ...options,
      { value: keyword, label: keyword },
    ]);
    if (singleSelect) {
      setSelectedValues([keyword]);
      submitHandlerOnClose?.([keyword]);
    } else {
      setSelectedValues((selectedValues) => [...selectedValues, keyword]);
      submitHandlerOnClose?.(selectedValues.concat([keyword]));
    }
  }, [
    submitHandlerOnClose,
    setExtraOptions,
    keyword,
    setSelectedValues,
    singleSelect,
    closeHandler,
    selectedValues,
  ]);

  const showAddButton = !optionsLoading && !!creatable && !!keyword.length;

  return (
    <Fragment>
      {showSelectAll && !singleSelect && !!filteredOptions.length && (
        <FilterSelectAllItem
          selectedValues={selectedValues}
          filteredOptions={filteredOptions}
          allOptions={options}
          setSelectedValues={setSelectedValues}
        />
      )}

      <FilterItemsList
        keyword={keyword}
        listWidth={listWidth}
        listMaxHeight={listMaxHeight}
        noItemsLabel={noItemsLabel}
        closeHandler={closeHandler}
        singleSelect={singleSelect}
        renderOption={renderOption}
        optionsLoading={optionsLoading}
        selectedValues={selectedValues}
        virtualized={options.length > 30}
        filteredOptions={filteredOptions}
        setSelectedValues={setSelectedValues}
        submitHandlerOnClose={submitHandlerOnClose}
        lastSelectedOptionDisabled={lastSelectedOptionDisabled}
      />

      {optionsLoading && LoadingState}

      {showAddButton && (
        <ListItemButton onClick={addNewSelectedValueHandler}>
          <Typography variant="body2">Add “{keyword}”</Typography>
        </ListItemButton>
      )}
    </Fragment>
  );
};

const LoadingState = (
  <Stack
    p={2}
    gap={2}
    direction="row"
    alignItems="center"
    justifyContent="center"
  >
    <Typography color="text.secondary" lineHeight={1}>
      <CircularProgress size={16} disableShrink color="inherit" />
    </Typography>
    <Typography color="text.secondary" variant="body2" lineHeight={1}>
      Loading
    </Typography>
  </Stack>
);
