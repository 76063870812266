import { ColDef } from "ag-grid-community";
import { utilizationSoringFn } from "./sortingFunctions/utilizationSoringFn";
import { commitmentsSoringFn } from "./sortingFunctions/commitmentsSoringFn";
import {
  CommitmentsGroupResponse,
  WidgetCommitmentsUtilizationVisualization,
} from "../../../../../../../../../../services/cloudchipr.api";
import { TypographyWithTooltip } from "../../../../../../../../../common/TypographyWithTooltip";
import { money } from "../../../../../../../../../../utils/numeral/money";
import { agGridEmptyColumnToFitEmptySpace } from "../../../../../../../../common/ag-grid/utils/constants/agGridEmptyColumnToFitEmptySpace";

export const getCommitmentUtilizationTableViewColumnDefs = (
  visualization?: WidgetCommitmentsUtilizationVisualization,
) => {
  const columns: ColDef[] = [
    {
      field: "name",
      minWidth: 100,
      flex: 3.5,
      unSortIcon: true,
      cellStyle: { paddingLeft: 16 },
      suppressColumnsToolPanel: true,
      cellRenderer: (cell: any) => {
        const data = cell.data as CommitmentsGroupResponse;

        return <TypographyWithTooltip title={data.name} variant="caption" />;
      },
    },
  ];

  if (visualization?.utilization) {
    columns.push({
      field: "utilization",
      minWidth: 100,
      flex: 1,
      unSortIcon: true,
      comparator: utilizationSoringFn,
      cellRenderer: (cell: any) => {
        const data = cell.data as CommitmentsGroupResponse;

        return (
          <TypographyWithTooltip
            title={`${money(data.utilization?.amount)} (${data.utilization?.percentage}%)`}
            variant="caption"
          />
        );
      },
    });
  }

  if (visualization?.commitment) {
    columns.push({
      field: "commitment",
      minWidth: 100,
      flex: 1,
      unSortIcon: true,
      comparator: commitmentsSoringFn,
      cellRenderer: (cell: any) => {
        const data = cell.data as CommitmentsGroupResponse;

        return (
          <TypographyWithTooltip
            title={money(
              +(data.utilization?.amount ?? 0) + +(data.waste?.amount ?? 0),
            )}
            variant="caption"
          />
        );
      },
    });
  }

  if (visualization?.net_savings) {
    columns.push({
      field: "net_savings",
      minWidth: 100,
      flex: 1,
      unSortIcon: true,
      headerName: "Net Savings",
      cellRenderer: (cell: any) => {
        const data = cell.data as CommitmentsGroupResponse;

        return (
          <TypographyWithTooltip
            title={money(data.net_savings)}
            variant="caption"
          />
        );
      },
    });
  }

  columns.push(agGridEmptyColumnToFitEmptySpace);

  return columns;
};
