import { TasksGroupingByProperty } from "../types/common";
import { GetUsersMeOrganisationsCurrentTasksPropertiesApiResponse } from "../../../../../services/cloudchipr.api";

export const taskPropertyNameByGrouping: Record<
  TasksGroupingByProperty,
  keyof GetUsersMeOrganisationsCurrentTasksPropertiesApiResponse
> = {
  status: "statuses",
  action: "actions",
  priority: "priorities",
  environment: "environments",
};

export const ungroupedLabelsByGrouping: Record<string, string> = {
  action: "Empty",
  priority: "No Priority",
  assignees: "Unassigned",
  environment: "Empty",
};
