import { createDraftSafeSelector } from "@reduxjs/toolkit";
import moment from "moment/moment";
import { resourceExplorerViewsDataSelector } from "../../../../resource-explorer/selectors/all-resource-explorers/resourceExplorerViewsDataSelector";
import { capitalizeString } from "../../../../../utils/helpers/capitalizeString";
import { formatDate } from "../../../../../utils/helpers/date-time/datetime-format";
import { money } from "../../../../../utils/numeral/money";
import { budgetQuarters } from "../../../../../components/pages/budgets/components/budget-create/steps/steps/utils/constants/date-selection";
import { step0FormDataSelector } from "../forms/step0FormDataSelector";

export const budgetPreviewSummaryDataSelector = createDraftSafeSelector(
  [step0FormDataSelector, resourceExplorerViewsDataSelector],
  (
    formData,
    resourceExplorerViews,
  ): { key: string; value: string }[] | null => {
    if (!formData) {
      return null;
    }

    const viewName =
      resourceExplorerViews.find(({ id }) => id === formData.viewId)?.name ??
      "";

    const data = [
      {
        key: "Budget Name",
        value: formData.name,
      },
      { key: "Report", value: viewName },
      { key: "Amount", value: money(formData.amount) },
      { key: "Period", value: capitalizeString(formData.period) },
    ];

    if (formData.period === "quarterly") {
      const month = moment(formData.startDate).get("month");

      data.push({
        key: "Start Quarter",
        value:
          budgetQuarters.find(
            ({ firstMonthIndex }) => firstMonthIndex === month,
          )?.quarterName ?? `${month}`,
      });
    } else {
      data.push({
        key: "Start Date",
        value: formatDate(formData.startDate, { type: "date" }),
      });
    }

    return data;
  },
);
