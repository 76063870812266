import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  cloudChiprApi,
  PatchUsersMeOrganisationsCurrentResourceExplorerViewsByResourceExplorerViewIdApiArg,
} from "../../../../services/cloudchipr.api";
import { getResourceExplorerVisibilityHierarchyThunk } from "../resource-explorer-hierarchy-visibility/getResourceExplorerVisibilityHierarchyThunk";
import { enqueueSnackbarErrorAlert } from "../../../../components/pages/common/snackbar-alert/EnqueueSnackbarErrorAlert";

interface UpdateResourceExplorerArguments {
  body: PatchUsersMeOrganisationsCurrentResourceExplorerViewsByResourceExplorerViewIdApiArg["body"];
  viewId: string;
}

export const updateResourceExplorerById = createAsyncThunk(
  "resourceExplorer/updateResourceExplorer",
  async ({ body, viewId }: UpdateResourceExplorerArguments, { dispatch }) => {
    const {
      patchUsersMeOrganisationsCurrentResourceExplorerViewsByResourceExplorerViewId,
    } = cloudChiprApi.endpoints;

    try {
      const response = await dispatch(
        patchUsersMeOrganisationsCurrentResourceExplorerViewsByResourceExplorerViewId.initiate(
          {
            resourceExplorerViewId: viewId,
            body: body,
          },
          { fixedCacheKey: viewId },
        ),
      ).unwrap();

      if (response) {
        dispatch(getResourceExplorerVisibilityHierarchyThunk());
      }

      return response;
    } catch (e) {
      // @ts-expect-error todo: fix api spec;
      enqueueSnackbarErrorAlert(e?.data?.message);
    }
  },
);
