import { createAsyncThunk } from "@reduxjs/toolkit";
import { createWidgetThunk } from "./createWidgetThunk";
import { updateWidgetThunk } from "./updateWidgetThunk";
import { currentDashboardIdSelector } from "../../../selectors/dashboard/currentDashboardIdSelector";
import { RootState } from "../../../../store";
import {
  CreateDashboardWidgetRequestType,
  UpdateDashboardWidgetRequestType,
} from "../../../utils/types/types";
import { openWidgetBuildingSnackbar } from "../utils/openWidgetBuildingSnackbar";
import { enqueueSnackbarErrorAlert } from "../../../../../components/pages/common/snackbar-alert/EnqueueSnackbarErrorAlert";

interface BuildWidgetThunkArgs {
  widgetId?: string;
  widget: CreateDashboardWidgetRequestType | UpdateDashboardWidgetRequestType;
}

export const buildWidgetThunk = createAsyncThunk(
  "dashboards/buildWidget",
  async (
    { widgetId, widget }: BuildWidgetThunkArgs,
    { dispatch, getState },
  ) => {
    const state = getState() as RootState;

    const dashboardId = currentDashboardIdSelector(state);

    if (!dashboardId) {
      return;
    }

    try {
      let response;

      if (widgetId) {
        response = await dispatch(
          updateWidgetThunk({
            widget: widget as UpdateDashboardWidgetRequestType,
            widgetId,
          }),
        ).unwrap();
        if (response) {
          openWidgetBuildingSnackbar(widget.type, "update");
        }
      } else {
        response = await dispatch(
          createWidgetThunk(widget as CreateDashboardWidgetRequestType),
        ).unwrap();
        if (response) {
          openWidgetBuildingSnackbar(widget.type, "create");
        }
      }

      return response;
    } catch (e) {
      // @ts-expect-error todo: fix api spec;
      enqueueSnackbarErrorAlert(e?.data?.message);

      return null;
    }
  },
);
