import { FC } from "react";
import { Typography } from "@mui/material";

interface EbsAttachmentsCellProps {
  hasAttachments?: boolean;
}

export const EbsAttachmentsCell: FC<EbsAttachmentsCellProps> = ({
  hasAttachments,
}) => {
  if (hasAttachments === undefined) {
    return <Typography variant="body2">N/A</Typography>;
  }

  return (
    <Typography variant="body2">
      {hasAttachments ? "Attached" : "Detached"}
    </Typography>
  );
};
