import { RootState } from "../../../store";
import {
  cloudChiprApi,
  ProviderType,
} from "../../../../services/cloudchipr.api";
import { AccountsPageTabs } from "../../../../components/pages/accounts-group/utils/types/types";
import { accountsSortBySelector } from "../slice-data/accountsSortBySelector";
import { getAccountsOrderingSettingsKey } from "../../../../components/pages/live-usage-mgmt/utils/hooks/useAccountsDnD";

const selector =
  cloudChiprApi.endpoints.getUsersMeCurrentPreferenceByKey.select;
export const accountsOrderingLoadingSelector = (
  state: RootState,
  provider: ProviderType,
  tabValue: AccountsPageTabs,
  orgId?: string,
) => {
  const sortBy = accountsSortBySelector(state);

  const key = getAccountsOrderingSettingsKey(provider, tabValue, orgId);

  const data = selector({ key })(state);

  return data?.error || sortBy ? false : data.isLoading;
};
