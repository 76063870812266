import { FC, useMemo } from "react";
import { Chip, IconButton, Stack, Tooltip } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { OpenInNew } from "@mui/icons-material";
import { EmbeddedTask } from "../../../../../../../../../../services/cloudchipr.api";
import { useAppSelector } from "../../../../../../../../../../store/hooks";
import { taskAvailableStatusPropertiesSelector } from "../../../../../../../../../../store/task-management/selectors/properties/taskAvailableStatusPropertiesSelector";

interface TaskStatusCellProps {
  task: EmbeddedTask | null;
}

export const TaskStatusCell: FC<TaskStatusCellProps> = ({ task }) => {
  const statuses = useAppSelector(taskAvailableStatusPropertiesSelector);

  const status = useMemo(() => {
    return statuses?.find((status) => status.id === task?.status_id);
  }, [statuses, task?.status_id]);

  if (!status) {
    return "-";
  }

  return (
    <Stack direction="row" spacing={0.5} alignItems="center">
      <Chip
        size="small"
        variant="filled"
        label={status.name}
        sx={{ bgcolor: status.color, color: "white" }}
      />

      <Tooltip title="View Task" placement="top" arrow>
        <IconButton
          size="small"
          component={RouterLink}
          target="_blank"
          to={`${window.location.origin}/task-mgmt?taskId=${task?.id}`}
        >
          <OpenInNew fontSize="small" />
        </IconButton>
      </Tooltip>
    </Stack>
  );
};
