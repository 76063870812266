import { createAsyncThunk } from "@reduxjs/toolkit";
import { cloudChiprApi } from "../../../../services/cloudchipr.api";
import { enqueueSnackbarErrorAlert } from "../../../../components/pages/common/snackbar-alert/EnqueueSnackbarErrorAlert";

interface GetResourceExplorerViewByIdThunkArgs {
  viewId: string;
  forceRefetch?: boolean;
}

export const getResourceExplorerViewByIdThunk = createAsyncThunk(
  "resourceExplorer/getResourceExplorerViewById",
  async (
    { viewId, forceRefetch }: GetResourceExplorerViewByIdThunkArgs,
    { dispatch },
  ) => {
    const {
      getUsersMeOrganisationsCurrentResourceExplorerViewsByResourceExplorerViewId,
    } = cloudChiprApi.endpoints;
    try {
      return await dispatch(
        getUsersMeOrganisationsCurrentResourceExplorerViewsByResourceExplorerViewId.initiate(
          {
            resourceExplorerViewId: viewId,
          },
          { forceRefetch },
        ),
      ).unwrap();
    } catch (e) {
      // @ts-expect-error todo: fix api spec;
      enqueueSnackbarErrorAlert(e?.data?.message);
    }
  },
);
