import React, { FC, Fragment, MouseEvent, useCallback } from "react";
import {
  IconButton,
  Popover,
  PopoverOrigin,
  Tooltip,
  Typography,
} from "@mui/material";
import { NotesRounded } from "@mui/icons-material";
import { UseMenuHookOpenMenu } from "../../../../../../../utils/hooks/useMenu.hook";

interface NameCellDescriptionProps {
  open: boolean;
  hovered: boolean;
  description: string;
  closeMenu: () => void;
  anchor: HTMLElement | null;
  openMenu: UseMenuHookOpenMenu;
}

export const NameCellDescription: FC<NameCellDescriptionProps> = ({
  open,
  anchor,
  hovered,
  openMenu,
  closeMenu,
  description,
}) => {
  const handleClick = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      openMenu(e);
      stopPropagation(e);
    },
    [openMenu],
  );

  const handleCLose = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      closeMenu();
      stopPropagation(e);
    },
    [closeMenu],
  );

  return (
    <Fragment>
      <Tooltip arrow placement="top" title="Description">
        <IconButton
          size="small"
          onClick={handleClick}
          sx={{
            visibility: hovered || open ? "visible" : "hidden",
          }}
        >
          <NotesRounded fontSize="small" />
        </IconButton>
      </Tooltip>

      <Popover
        open={open}
        anchorEl={anchor}
        onClose={handleCLose}
        onClick={stopPropagation}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
        slotProps={slotProps}
      >
        {
          <Typography
            variant="body2"
            p={1.5}
            sx={typographySx}
            whiteSpace="break-all"
            dangerouslySetInnerHTML={{ __html: description }}
          />
        }
      </Popover>
    </Fragment>
  );
};

const stopPropagation = (e: MouseEvent<HTMLButtonElement | HTMLDivElement>) =>
  e.stopPropagation();

const typographySx = {
  "& *": {
    wordBreak: "break-all",
    margin: 0,
  },
};

const anchorOrigin: PopoverOrigin = {
  vertical: "bottom",
  horizontal: "left",
};
const transformOrigin: PopoverOrigin = {
  vertical: "top",
  horizontal: "left",
};

const slotProps = {
  paper: {
    sx: { maxWidth: 500 },
  },
};
