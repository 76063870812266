import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../../../store";
import { cloudChiprApi } from "../../../../services/cloudchipr.api";
import { commitmentsGranularPayloadDataSelector } from "../../selectors/common/payload/commitmentsGranularPayloadDataSelector";
import { awsBillingStatusNotAvailableSelector } from "../../selectors/organisations/awsBillingStatusNotAvailableSelector";
import { enqueueSnackbarErrorAlert } from "../../../../components/pages/common/snackbar-alert/EnqueueSnackbarErrorAlert";

export const getSavingsPlansForGranularThunk = createAsyncThunk(
  "commitments/getSavingsPlansForGranular",
  async (_: void, { dispatch, getState }) => {
    const state = getState() as RootState;

    const { getUsersMeOrganisationsCurrentSavingsPlans } =
      cloudChiprApi.endpoints;

    const payload = commitmentsGranularPayloadDataSelector(state);
    const billingStatusNotAvailable =
      awsBillingStatusNotAvailableSelector(state);

    try {
      await dispatch(
        getUsersMeOrganisationsCurrentSavingsPlans.initiate(payload),
      ).unwrap();
    } catch (e) {
      if (billingStatusNotAvailable) {
        return;
      }

      // @ts-expect-error todo: fix api spec;
      enqueueSnackbarErrorAlert(e?.data?.message);

      return null;
    }
  },
);
