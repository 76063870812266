import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  cloudChiprApi,
  PostUsersMeSchedulesApiArg,
} from "../../../services/cloudchipr.api";
import { RootState } from "../../store";
import { workflowsDataSelector } from "../../automations/selectros/workflow/list-data/workflowsDataSelector";
import { enqueueSnackbarErrorAlert } from "../../../components/pages/common/snackbar-alert/EnqueueSnackbarErrorAlert";

interface DuplicateOffHourThunkArgs {
  id: string;
  name: string;
}
export const duplicateWorkflowThunk = createAsyncThunk(
  "schedules/duplicateWorkflow",
  async ({ id, name }: DuplicateOffHourThunkArgs, { dispatch, getState }) => {
    const { postUsersMeSchedules } = cloudChiprApi.endpoints;
    const state = getState() as RootState;

    const data = workflowsDataSelector(state);

    const workflow = data?.find((item) => item.id === id);

    if (!workflow) {
      return;
    }

    const {
      start_date_time,
      end_date_time,
      cron,
      regions,
      action,
      time_zone,
      min_threshold,
      emails,
      accounts,
      notifications,
      is_snoozed,
      grace_period,
      weekly_schedule_interval,
      daily_schedule_interval,
      description,
    } = workflow;
    const accountIds = accounts.map((item) => item.id);

    const body: PostUsersMeSchedulesApiArg = {
      body: {
        name,
        start_date_time,
        end_date_time,
        cron,
        regions,
        action,
        notifications,
        time_zone,
        min_threshold,
        is_snoozed,
        grace_period,
        emails,
        weekly_schedule_interval,
        daily_schedule_interval,
        account_ids: accountIds ?? [],
        filter: workflow?.filter ?? [],
        status: "inactive",
        description,
      },
    };
    try {
      return await dispatch(
        postUsersMeSchedules.initiate(body, {
          fixedCacheKey: id,
        }),
      ).unwrap();
    } catch (e) {
      // @ts-expect-error todo: fix api spec;
      enqueueSnackbarErrorAlert(e?.data?.message);
    }
  },
);
