import { ColDef } from "ag-grid-community";
import { TaskPriority } from "../../../../../../services/cloudchipr.api";

export const priorityComparatorFn =
  (priorities?: TaskPriority[]): ColDef["comparator"] =>
  (_, __, nodeA, nodeB) => {
    const current = nodeA?.data?.priority?.id ?? "";
    const next = nodeB?.data?.priority?.id ?? "";

    const currentIndex = priorities?.findIndex((pr) => pr.id === current) ?? 0;
    const nextIndex = priorities?.findIndex((pr) => pr.id === next) ?? 0;

    return currentIndex - nextIndex;
  };
