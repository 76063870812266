import { FC, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Stack } from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { SvgIconComponent, VerifiedUserOutlined } from "@mui/icons-material";
import { VisibilityType } from "../../utils/types/types";
import { TypographyWithTooltip } from "../../../../../common/TypographyWithTooltip";

interface ResourceExplorerViewNameCellProps {
  name: string;
  visibility: VisibilityType;
  icon: SvgIconComponent;
  link: string;
  isProtected?: boolean;
}

export const HierarchyAllItemsItemNameCell: FC<
  ResourceExplorerViewNameCellProps
> = ({ name, visibility, link, icon: Icon, isProtected }) => {
  const navigate = useNavigate();

  const handleCLick = useCallback(() => {
    navigate(link);
  }, [navigate, link]);

  return (
    <Stack
      direction="row"
      pl={1}
      py={1}
      spacing={1}
      alignItems="center"
      onClick={handleCLick}
      sx={{ cursor: "pointer" }}
    >
      <Icon fontSize="small" />
      {visibility === "visible_only_to_me" && (
        <LockOutlinedIcon color="action" sx={{ fontSize: 16 }} />
      )}
      {isProtected && (
        <VerifiedUserOutlined color="action" sx={{ fontSize: 16 }} />
      )}
      <TypographyWithTooltip variant="inherit" title={name} />
    </Stack>
  );
};
