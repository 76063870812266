import { Bar, LabelList } from "recharts";
import { ChartColorsType } from "../../../utils/types/types";
import { ApproxBarLabel } from "../../common/ApproxBarLabel";
import { YAxisFormatter } from "../../common/CustomizedYAxisTick";
import { BarsStackTotal } from "../../common/BarsStackTotal";

export const barChartBars = ({
  keys,
  stack,
  colors,
  highlighted,
  onMouseEnter,
  onMouseLeave,
  yAxisFormatter,
  stackTotalDataByKey,
}: {
  keys: string[];
  highlighted: string;
  onMouseEnter: (payload: any) => void;
  onMouseLeave(): void;
  colors: ChartColorsType;
  stack?: boolean;
  yAxisFormatter?: YAxisFormatter;
  stackTotalDataByKey?: number[];
}) => {
  const specificProps = stack
    ? { stackId: "stacked_chart_bar_id" }
    : { minPointSize: 3 };

  return keys.map((key, idx) => {
    const filter = highlighted === key ? "none" : "grayscale(1)";
    const opacity = highlighted === key ? 1 : 0.2;
    const isTopBar = idx === keys.length - 1;

    return (
      <Bar
        key={key}
        unit={key}
        dataKey={key}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        filter={highlighted ? filter : "none"}
        opacity={highlighted ? opacity : "none"}
        fill={colors[key]}
        {...specificProps}
      >
        {stack ? (
          <LabelList
            dataKey={key}
            position="top"
            content={
              <ApproxBarLabel
                color={colors[key]}
                opacity={highlighted ? opacity : "none"}
                yAxisFormatter={yAxisFormatter}
              />
            }
          />
        ) : (
          <LabelList
            dataKey={key}
            position="top"
            content={
              <BarsStackTotal
                stackTotalDataByKey={stackTotalDataByKey}
                yAxisFormatter={yAxisFormatter}
                opacity={highlighted ? opacity : "none"}
              />
            }
          />
        )}
        {isTopBar && stackTotalDataByKey && (
          <LabelList
            dataKey={key}
            position="top"
            content={
              <BarsStackTotal
                stackTotalDataByKey={stackTotalDataByKey}
                yAxisFormatter={yAxisFormatter}
                opacity={highlighted ? opacity : "none"}
              />
            }
          />
        )}
      </Bar>
    );
  });
};
