import { FC } from "react";
import { GridApi, RowNode } from "ag-grid-community";
import { Box, Chip } from "@mui/material";
import { ungroupedLabelsByGrouping } from "../../../utils/constants/grouping";

interface AutoGroupColumnDefInnerRendererProps {
  api: GridApi;
  node: RowNode;
}

export const AutoGroupColumnDefInnerRenderer: FC<
  AutoGroupColumnDefInnerRendererProps
> = (props) => {
  const groupingColumns = props.api.getRowGroupColumns();
  const colId = props.node?.field ?? props.node?.rowGroupColumn?.getColId?.();

  if (!colId) {
    return null;
  }

  const currentCol = groupingColumns.find((col) => col?.getColId() === colId);

  if (!currentCol) {
    return null;
  }

  const data = props.node?.allLeafChildren?.at(0)?.data;

  const noLabel = ungroupedLabelsByGrouping[colId] && !data?.[colId];

  if (noLabel) {
    return (
      <Box pr={1} pl={2} minWidth={150}>
        <Chip
          clickable={false}
          size="small"
          variant="filled"
          label={ungroupedLabelsByGrouping[colId]}
        />
      </Box>
    );
  }

  return (
    <Box pr={1} minWidth={150}>
      {currentCol?.getColDef()?.cellRenderer?.({ ...props, data })}
    </Box>
  );
};
