import { createAsyncThunk } from "@reduxjs/toolkit";
import { closeSnackbar, enqueueSnackbar, SnackbarKey } from "notistack";
import { getResourceExplorerViewByIdAndSetDataThunk } from "./getResourceExplorerViewByIdAndSetDataThunk";
import { PatchUsersMeOrganisationsCurrentResourceExplorerViewsByResourceExplorerViewIdApiArg } from "../../../../services/cloudchipr.api";
import { RootState } from "../../../store";
import { resourceExplorerFilterTreeSelector } from "../../selectors/filter-tree/resourceExplorerFilterTreeSelector";
import { resourceExplorerDataSelector } from "../../selectors/current-resource-explorer/data/resourceExplorerDataSelector";
import { updateResourceExplorerById } from "../common/updateResourceExplorerById";
import { enqueueSnackbarErrorAlert } from "../../../../components/pages/common/snackbar-alert/EnqueueSnackbarErrorAlert";

interface UpdateResourceExplorerThunkArgs {
  viewId: string;
  name: string;
}

export const updateCurrentResourceExplorerThunk = createAsyncThunk(
  "resourceExplorer/updateResourceExplorer",
  async (
    { viewId, name }: UpdateResourceExplorerThunkArgs,
    { dispatch, getState },
  ) => {
    const state = getState() as RootState;
    const data = resourceExplorerDataSelector(state);

    const filterTree = resourceExplorerFilterTreeSelector(state);

    let response;
    try {
      const payload: PatchUsersMeOrganisationsCurrentResourceExplorerViewsByResourceExplorerViewIdApiArg["body"] =
        { data, name, filter_tree: filterTree };

      response = await dispatch(
        updateResourceExplorerById({
          body: payload,
          viewId,
        }),
      ).unwrap();

      if (response) {
        dispatch(getResourceExplorerViewByIdAndSetDataThunk(viewId));
        const snackbarId: SnackbarKey = enqueueSnackbar(
          "Report successfully updated.",
          {
            variant: "snackbarAlert",
            AlertSnackBarProps: {
              severity: "success",
              onClose: () => closeSnackbar(snackbarId),
            },
          },
        );
      }
    } catch (e) {
      // @ts-expect-error todo: fix api spec;
      enqueueSnackbarErrorAlert(e?.data?.message);
    }
    return response;
  },
);
