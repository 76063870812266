import { FC, useCallback } from "react";
import { Chip } from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CloseIcon from "@mui/icons-material/Close";
import { useAppDispatch, useAppSelector } from "../../../../../../store/hooks";
import { setTaskManagementShowCompletedTasks } from "../../../../../../store/task-management/taskManagementSlice";
import { taskManagementShowCompletedTasksSelector } from "../../../../../../store/task-management/selectors/list/taskManagementShowCompletedTasksSelector";
import { tasksListIsEmptySelector } from "../../../../../../store/task-management/selectors/data/tasksListIsEmptySelector";

export const TaskManagementShowCompleted: FC = () => {
  const dispatch = useAppDispatch();
  const show = useAppSelector(taskManagementShowCompletedTasksSelector);
  const empty = useAppSelector(tasksListIsEmptySelector);

  const showCompletedHandler = useCallback(() => {
    dispatch(setTaskManagementShowCompletedTasks(true));
  }, [dispatch]);

  const hideCompletedHandler = useCallback(() => {
    dispatch(setTaskManagementShowCompletedTasks(false));
  }, [dispatch]);

  return (
    <Chip
      color={show ? "primary" : undefined}
      label="Show Completed"
      disabled={empty}
      variant="outlined"
      onClick={showCompletedHandler}
      icon={<CheckCircleOutlineIcon fontSize="small" />}
      deleteIcon={<CloseIcon fontSize="small" />}
      onDelete={show ? hideCompletedHandler : undefined}
    />
  );
};
