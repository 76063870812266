import { createAsyncThunk } from "@reduxjs/toolkit";
import { cloudChiprApi } from "../../../../services/cloudchipr.api";
import { setBillingStatusProcess } from "../../commonSlice";
import { enqueueSnackbarErrorAlert } from "../../../../components/pages/common/snackbar-alert/EnqueueSnackbarErrorAlert";

export const goAwsConsoleThunk = createAsyncThunk(
  "resourceExplorer/getAllProvidersBillingStatus",
  async (accountId: string, { dispatch }) => {
    const { postUsersMeProvidersAwsAccountsByAccountIdCfnChangeSets } =
      cloudChiprApi.endpoints;

    try {
      const response = await dispatch(
        postUsersMeProvidersAwsAccountsByAccountIdCfnChangeSets.initiate(
          {
            accountId,
          },
          {
            fixedCacheKey: "cfn-chang-sets",
          },
        ),
      ).unwrap();
      window.open(response.link, "_blank");
      dispatch(setBillingStatusProcess("aws"));
    } catch (e) {
      // @ts-expect-error todo: fix api spec;
      enqueueSnackbarErrorAlert(e?.data?.message);
    }
  },
);
