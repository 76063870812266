import { FC, useCallback } from "react";
import {
  ListItemIcon,
  ListItemText,
  MenuItem,
  Tooltip,
  Typography,
} from "@mui/material";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../store/hooks";
import { useAppAbility } from "../../../../../../../../services/permissions";
import { WidgetType } from "../../../../../../../../services/cloudchipr.api";
import { editWidgetThunk } from "../../../../../../../../store/dashboards/thunks/widgets/common/editWidgetThunk";
import { currentDashboardProtectedSelector } from "../../../../../../../../store/dashboards/selectors/dashboard-hierarchy-visibility/currentDashboardProtectedSelector";

interface WidgetEditActionMenuItemProps {
  widgetId: string;
  widgetType: WidgetType;
  onClose(): void;
}

export const WidgetEditActionMenuItem: FC<WidgetEditActionMenuItemProps> = ({
  widgetType,
  widgetId,
  onClose,
}) => {
  const dispatch = useAppDispatch();
  const { cannot } = useAppAbility();
  const canNotEditWidget = cannot("edit", "dashboard");
  const isProtected = useAppSelector(currentDashboardProtectedSelector);

  const editWidgetHandler = useCallback(() => {
    dispatch(
      editWidgetThunk({
        widgetId,
        widgetType,
      }),
    );

    onClose();
  }, [dispatch, onClose, widgetId, widgetType]);

  return (
    <Tooltip
      title={isProtected ? "Protected dashboards cannot be modified." : ""}
      arrow
      placement="right"
    >
      <span>
        <MenuItem
          onClick={editWidgetHandler}
          disabled={canNotEditWidget || isProtected}
        >
          <ListItemIcon>
            <EditOutlinedIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>
            <Typography variant="body2">Edit</Typography>
          </ListItemText>
        </MenuItem>
      </span>
    </Tooltip>
  );
};
