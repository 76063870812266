import { FC, useCallback } from "react";
import { Outlet, useOutletContext, useParams } from "react-router-dom";
import TabContext from "@mui/lab/TabContext";
import { AccountsHeader } from "./header/AccountsHeader";
import { OutletContextType } from "../../../layout/MainLayout";
import { useAppSelector } from "../../../../store/hooks";
import { accountOrganisationIdSelector } from "../../../../store/accounts/selectors/slice-data/accountOrganisationIdSelector";
import { useAccountTabInitializerHook } from "../utils/hooks/useAccountTabInitializerHook";
import { RootState } from "../../../../store/store";
import { accountsDividedToStatusesByOrgIdSelector } from "../../../../store/accounts/selectors/accountsDividedToStatusesByOrgIdSelector";
import { ProviderType } from "../../../../services/cloudchipr.api";
import { AccountSetupDialog } from "../../common/connectors/AccountSetupDialog";

export const AccountHeaderWrapper: FC = () => {
  const outletContext = useOutletContext<OutletContextType>();
  const { provider } = useParams<{ provider: ProviderType }>();

  const orgId = useAppSelector(accountOrganisationIdSelector);

  const accountsSelector = useCallback(
    (state: RootState) =>
      accountsDividedToStatusesByOrgIdSelector(state, provider, orgId),
    [provider, orgId],
  );
  const accounts = useAppSelector(accountsSelector);
  const activeAccountsLength = accounts?.active?.length ?? 0;
  const inactiveAccountsLength = accounts?.inactive?.length ?? 0;

  const { activeTab, renderOutlet } = useAccountTabInitializerHook(
    activeAccountsLength,
    inactiveAccountsLength,
  );

  const render = activeTab === "live-resources" ? true : renderOutlet;

  if (!provider) {
    return null;
  }

  return (
    <TabContext value={activeTab}>
      <AccountsHeader
        orgId={orgId}
        provider={provider}
        activeTab={activeTab}
        activeCount={activeAccountsLength}
        inactiveCount={inactiveAccountsLength}
      />

      {render && <Outlet context={outletContext} />}
      <AccountSetupDialog />
    </TabContext>
  );
};
