import { FC, useCallback } from "react";
import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
} from "@mui/material";
import WidgetsOutlinedIcon from "@mui/icons-material/WidgetsOutlined";
import { LiveUsageMgmtNavItemStats } from "./components/LiveUsageMgmtNavItemStats";
import { LiveUsageCollapsedNavItem } from "./components/LiveUsageCollapsedNavItem";
import { useAppSelector } from "../../../../../../../store/hooks";
import { TypographyWithTooltip } from "../../../../../../common/TypographyWithTooltip";
import { liveUsageMgmtResourcesEmsSelector } from "../../../../../../../store/live-usage-mgmt/selectors/accounts-data/liveUsageMgmtResourcesEmsSelector";
import { liveUsageMgmtResourcesSpendingSelector } from "../../../../../../../store/live-usage-mgmt/selectors/accounts-data/liveUsageMgmtResourcesSpendingSelector";
import { useLiveUsageMgmtPageNavigate } from "../../utils/hooks/useLiveUsageMgmtPageNavigate.hook";
import { allResourcesResourceType } from "../../../../utils/constants/common";

interface LiveUsageAllResourcesNavbarItemProps {
  collapsed: boolean;
  selected: boolean;
}

export const LiveUsageAllResourcesNavbarItem: FC<
  LiveUsageAllResourcesNavbarItemProps
> = ({ collapsed, selected }) => {
  const ems = useAppSelector(liveUsageMgmtResourcesEmsSelector);
  const spending = useAppSelector(liveUsageMgmtResourcesSpendingSelector);

  const handleNavigationClick = useLiveUsageMgmtPageNavigate();

  const navigateHandler = useCallback(() => {
    handleNavigationClick(allResourcesResourceType);
  }, [handleNavigationClick]);

  const navItem = (
    <ListItem sx={listItemSx}>
      <ListItemButton
        sx={listItemButtonSx}
        selected={selected}
        onClick={navigateHandler}
      >
        <ListItemIcon sx={listItemIconSx}>
          <WidgetsOutlinedIcon fontSize="small" />
        </ListItemIcon>

        <ListItemText
          primary={
            <Stack direction="row" justifyContent="space-between" spacing={1}>
              <TypographyWithTooltip
                variant="subtitle2"
                title="All Resources"
              />
              <LiveUsageMgmtNavItemStats ems={ems ?? 0} total={spending ?? 0} />
            </Stack>
          }
        />
      </ListItemButton>
    </ListItem>
  );

  if (collapsed) {
    return (
      <LiveUsageCollapsedNavItem
        onClick={navigateHandler}
        icon={<WidgetsOutlinedIcon fontSize="small" />}
        selected={selected}
      >
        {navItem}
      </LiveUsageCollapsedNavItem>
    );
  }

  return navItem;
};

const listItemSx = { px: 1, py: 0.25 };
const listItemButtonSx = { borderRadius: 2, pl: 1, bgcolor: "grey.50" };
const listItemIconSx = { minWidth: 32 };
