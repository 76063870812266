import { Task } from "../../../../../../services/cloudchipr.api";
import { formatDate } from "../../../../../../utils/helpers/date-time/datetime-format";

export const taskCreationFilterText = ({ data }: { data?: Task }) => {
  const createdBy = data?.created_by?.name ?? data?.created_by?.email;
  const date = data?.created_at ?? "";
  const target = `${createdBy} ${formatDate(date, { type: "dateTime" })}`;

  return target.toLowerCase();
};
