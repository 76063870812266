import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  cloudChiprApi,
  PatchUsersMeOrganisationsCurrentDashboardsByDashboardIdApiArg,
} from "../../../../services/cloudchipr.api";
import { updateDashboardFixedCacheKey } from "../../../../components/pages/dashboard/utils/constants/fixedCacheKeys";
import { enqueueSnackbarErrorAlert } from "../../../../components/pages/common/snackbar-alert/EnqueueSnackbarErrorAlert";

type UpdateDashboardByIdThunkArguments = {
  dashboardId: string;
  body: Partial<
    PatchUsersMeOrganisationsCurrentDashboardsByDashboardIdApiArg["body"]
  >;
};

export const updateDashboardByIdThunk = createAsyncThunk(
  "dashboards/updateDashboardById",
  async (
    { dashboardId, body }: UpdateDashboardByIdThunkArguments,
    { dispatch },
  ) => {
    const { patchUsersMeOrganisationsCurrentDashboardsByDashboardId } =
      cloudChiprApi.endpoints;

    try {
      return await dispatch(
        patchUsersMeOrganisationsCurrentDashboardsByDashboardId.initiate(
          {
            dashboardId,
            body: {
              ...body,
              name: body.name ?? null,
            },
          },
          { fixedCacheKey: updateDashboardFixedCacheKey },
        ),
      ).unwrap();
    } catch (e) {
      // @ts-expect-error todo: fix api spec;
      enqueueSnackbarErrorAlert(e?.data?.message);
    }
  },
);
