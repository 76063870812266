import { FC } from "react";
import { Stack, Typography } from "@mui/material";
import { useAppSelector } from "../../../../../../../store/hooks";
import { liveUsageMgmtResourcesEmsSelector } from "../../../../../../../store/live-usage-mgmt/selectors/accounts-data/liveUsageMgmtResourcesEmsSelector";
import { liveUsageMgmtResourcesSpendingSelector } from "../../../../../../../store/live-usage-mgmt/selectors/accounts-data/liveUsageMgmtResourcesSpendingSelector";
import { liveUsageMgmtFilterTemplatesEnabledSelector } from "../../../../../../../store/live-usage-mgmt/selectors/store-selectors/liveUsageMgmtFilterTemplatesEnabledSelector";
import { TypographyWithTooltip } from "../../../../../../common/TypographyWithTooltip";
import { money } from "../../../../../../../utils/numeral/money";

export const LiveUsageMgmtServicesCosts: FC = () => {
  const ems = useAppSelector(liveUsageMgmtResourcesEmsSelector);
  const spending = useAppSelector(liveUsageMgmtResourcesSpendingSelector);
  const filterTemplatesEnabled = useAppSelector(
    liveUsageMgmtFilterTemplatesEnabledSelector,
  );

  return (
    <Stack direction="row" justifyContent="space-between" pl={2} pr={3} mb={1}>
      <TypographyWithTooltip variant="subtitle2" title="Services" />
      <Typography variant="subtitle2" noWrap>
        {filterTemplatesEnabled && (
          <Typography color="error" variant="inherit" component="span">
            {money(ems, 1000)}
          </Typography>
        )}
        <Typography color="text.secondary" variant="inherit" component="span">
          {filterTemplatesEnabled && " / "} {money(spending, 1000)}
        </Typography>
      </Typography>
    </Stack>
  );
};
