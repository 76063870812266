import { FC } from "react";
import { Button } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { PlayCircleOutlined } from "@mui/icons-material";
import { taskManagementTargetResourcesSelector } from "../../../../../../../../../../store/task-management/selectors/form/target/resources/taskManagementTargetResourcesSelector";
import { useAppSelector } from "../../../../../../../../../../store/hooks";
import { isTaskManagementDrawerEditModeSelector } from "../../../../../../../../../../store/task-management/selectors/form/options/isTaskManagementDrawerEditModeSelector";
import {
  resourceTypeSearchParam,
  resourceIdSearchParam,
  accountIdsSearchParam,
} from "../../../../../../../../live-usage-mgmt/utils/constants/searchParams";

export const ViewTargetInLiveUsageButton: FC = () => {
  const resources = useAppSelector(taskManagementTargetResourcesSelector);
  const editMode = useAppSelector(isTaskManagementDrawerEditModeSelector);

  const singleResource = resources?.at(0);

  if (
    !editMode ||
    !singleResource ||
    (resources?.length ?? 0) > 1 ||
    singleResource.state !== "live"
  ) {
    return null;
  }

  return (
    <Button
      size="small"
      target="_blank"
      variant="outlined"
      component={RouterLink}
      startIcon={<PlayCircleOutlined fontSize="small" color="primary" />}
      to={`/${singleResource.account.provider}/live-usage?${accountIdsSearchParam}=${singleResource.account.id}&${resourceTypeSearchParam}=${singleResource.resource_type}&${resourceIdSearchParam}=${singleResource.provider_identifier}`}
    >
      View in Live Usage & Mgmt
    </Button>
  );
};
