import { ColDef, ICellRendererParams } from "ag-grid-community";
import { ApiKeysListGridTestButtonCell } from "./ApiKeysListGridTestButtonCell";
import { ApiKeysListGridActionCell } from "./ApiKeysListGridActionCell";
import { LocalDateCell } from "./LocalDateCell";
import { ApiKeyCell } from "./ApiKeyCell";
import { ApiKey } from "../../../../../../../../services/cloudchipr.api";
import { isDateExpired } from "../utils/helpers/isDateExpired";

const apiKeysListGridColumns: ColDef[] = [
  {
    field: "name",
    headerName: "Name",
    flex: 1,
    minWidth: 150,
    suppressColumnsToolPanel: true,
  },
  {
    field: "api_key",
    headerName: "Key",
    flex: 2,
    minWidth: 200,
    cellRenderer: ({ data }: ICellRendererParams<ApiKey>) => {
      const apiKey = data?.api_key;
      if (!apiKey) {
        return;
      }
      return <ApiKeyCell data={apiKey} />;
    },
  },
  {
    field: "last_used_at",
    headerName: "Last Used",
    width: 140,
    cellRenderer: ({ data }: ICellRendererParams<ApiKey>) => (
      <LocalDateCell date={data?.last_used_at} />
    ),
  },
  {
    field: "created_at",
    headerName: "Created",
    width: 140,
    cellRenderer: ({ data }: ICellRendererParams<ApiKey>) => (
      <LocalDateCell date={data?.created_at} />
    ),
  },
  {
    field: "expires_at",
    headerName: "Expires",
    width: 180,
    cellRenderer: ({ data }: ICellRendererParams<ApiKey>) => (
      <LocalDateCell
        date={data?.expires_at}
        isDateExpired={isDateExpired(data?.expires_at)}
      />
    ),
  },
  {
    width: 140,
    cellRenderer: ApiKeysListGridTestButtonCell,
  },
];

export const getApiKeysListGridColumns = (showForm: () => void): ColDef[] => {
  return [
    ...apiKeysListGridColumns,
    {
      width: 70,
      cellRenderer: ({ data }: ICellRendererParams<ApiKey>) => {
        if (!data) {
          return;
        }

        return (
          <ApiKeysListGridActionCell
            keyId={data?.id}
            keyName={data?.name}
            showForm={showForm}
          />
        );
      },
    },
  ];
};
