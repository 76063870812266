import { createAsyncThunk } from "@reduxjs/toolkit";
import { enqueueSnackbar } from "notistack";
import { generateSelectedRolePermissionNamesByGroupedPermissions } from "./utils/generateSelectedRolePermissionNamesByGroupedPermissions";
import { postCustomUserRoleThunk } from "./postCustomUserRoleThunk";
import { userRoleDataSelector } from "../../selectors/roles/userRoleDataSelector";
import { RootState } from "../../../store";
import { enqueueSnackbarErrorAlert } from "../../../../components/pages/common/snackbar-alert/EnqueueSnackbarErrorAlert";

export const userRoleCreationFixedCacheKey =
  "postUsersMeOrganisationsCurrentRoles_create_user_role";

export const createCustomUserRoleThunk = createAsyncThunk(
  "roles/createCustomUserRole",
  async (_, { dispatch, getState }) => {
    const state = getState() as RootState;
    const creationData = userRoleDataSelector(state);
    const { name, description } = creationData;

    if (!creationData.permissions) {
      return;
    }
    try {
      const response = await dispatch(
        postCustomUserRoleThunk({
          name,
          description: description,
          permissions: generateSelectedRolePermissionNamesByGroupedPermissions(
            creationData.permissions,
          ),
        }),
      ).unwrap();

      if (response) {
        enqueueSnackbar("Role created!", {
          variant: "snackbarAlert",
          AlertSnackBarProps: { severity: "success" },
        });
      }

      return response;
    } catch (e) {
      // @ts-expect-error todo: fix api spec;
      enqueueSnackbarErrorAlert(e?.data?.message);
    }
  },
);
