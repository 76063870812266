import { GetQuickFilterTextParams } from "ag-grid-community";
import { Schedule } from "../../../../../../../services/cloudchipr.api";

export const quickFilterAccountsText = (
  params: GetQuickFilterTextParams<Schedule>,
) => {
  const accounts = params.data.accounts;
  const aimAccounts = accounts
    .filter(({ status }) => status === "connected" || status === undefined)
    .map(
      (account) =>
        `${account.provider_account_id} ${account.provider_account_name} ${account.provider}`,
    );

  return aimAccounts.join(" ").toLowerCase();
};
