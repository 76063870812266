import { useCallback, useMemo } from "react";
import { useCounter } from "rooks";
import { useAccountsSortBy } from "./useAccountsSortBy.hook";
import { setAccountSortBy } from "../../../../../store/accounts/accountsSlice";
import {
  Account,
  ProviderType,
  useGetUsersMeCurrentPreferenceByKeyQuery,
  usePatchUsersMeCurrentPreferenceByKeyMutation,
  usePostUsersMeCurrentPreferenceMutation,
  UserPreference,
} from "../../../../../services/cloudchipr.api";
import { useAppDispatch, useAppSelector } from "../../../../../store/hooks";
import { accountsSortBySelector } from "../../../../../store/accounts/selectors/slice-data/accountsSortBySelector";
import { AccountsPageTabs } from "../../../accounts-group/utils/types/types";

export const getAccountsOrderingSettingsKey = (
  provider: ProviderType,
  type: AccountsPageTabs,
  id?: string,
) => {
  const identifier = id ? `${provider}-${id}` : provider;
  return `c8r:${identifier}:${type}AccountsOrdering`;
};

const sortDataByIds = (
  accountsSorting: UserPreference,
  accounts: Account[],
) => {
  const accountIds = accountsSorting?.value?.split(",") ?? [];

  accounts?.forEach((account) => {
    if (!accountIds.includes(account.id)) {
      accountIds.push(account.id);
    }
  });

  return accountIds.reduce((acc, id) => {
    const account = accounts?.find((account) => account.id === id);

    if (account) {
      acc.push(account);
    }

    return acc;
  }, [] as Account[]);
};

export function useAccountsDnD(
  provider: ProviderType,
  data: Account[],
  type: AccountsPageTabs,
  orgId: string,
) {
  const sortBy = useAppSelector(accountsSortBySelector);
  const dispatch = useAppDispatch();

  const key = getAccountsOrderingSettingsKey(provider, type, orgId);

  const { saveAccountSortBy } = useAccountsSortBy(provider, type, orgId);

  const [createOrdering] = usePostUsersMeCurrentPreferenceMutation();
  const [updateOrdering] = usePatchUsersMeCurrentPreferenceByKeyMutation();
  const { data: accountsSorting, refetch } =
    useGetUsersMeCurrentPreferenceByKeyQuery({
      key,
    });
  const { value: forceRerenderKey, increment: rerender } = useCounter(1);

  const sortingChangeHandler = useCallback(
    async (data: any, dropped?: boolean) => {
      if (!dropped) {
        rerender();
        return;
      }

      const accountIdsArr = data.map((account: Account) => account.id);

      const body = {
        key,
        body: { value: accountIdsArr.join(",") },
      };

      if (accountsSorting) {
        await updateOrdering(body);
      } else {
        await createOrdering({ body });
      }

      await refetch();
      await saveAccountSortBy("custom");
      dispatch(setAccountSortBy("custom"));
    },
    [
      key,
      rerender,
      refetch,
      dispatch,
      createOrdering,
      updateOrdering,
      accountsSorting,
      saveAccountSortBy,
    ],
  );

  return useMemo(() => {
    const sortedData =
      sortBy === "custom" && accountsSorting && forceRerenderKey
        ? sortDataByIds(accountsSorting, data)
        : data;

    return {
      sortedData,
      onSortingChange: sortingChangeHandler,
    };
  }, [sortBy, sortingChangeHandler, forceRerenderKey, accountsSorting, data]);
}
