import { createAsyncThunk } from "@reduxjs/toolkit";
import { enqueueSnackbar } from "notistack";
import { Layouts } from "react-grid-layout";
import { cloudChiprApi } from "../../../../services/cloudchipr.api";
import { currentDashboardIdSelector } from "../../selectors/dashboard/currentDashboardIdSelector";
import { RootState } from "../../../store";
import { generateRemoveDashboardWidgetFixedCacheKey } from "../../../../components/pages/dashboard/utils/helpers/dashbaordFixedCacheKeyGenerator";
import { currentDashboardLayoutSelector } from "../../selectors/dashboard/currentDashboardLayoutSelector";
import { updateDashboardByIdLayoutThunk } from "../dashboard/updateDashboardByIdLayoutThunk";
import { enqueueSnackbarErrorAlert } from "../../../../components/pages/common/snackbar-alert/EnqueueSnackbarErrorAlert";

export const removeDashboardWidgetByIdThunk = createAsyncThunk(
  "dashboards/removeDashboardWidgetById",
  async (id: string, { dispatch, getState }) => {
    const state = getState() as RootState;
    const {
      deleteUsersMeOrganisationsCurrentDashboardsByDashboardIdWidgetsAndWidgetId,
    } = cloudChiprApi.endpoints;

    const dashboardId = currentDashboardIdSelector(state);
    const layout = currentDashboardLayoutSelector(state);

    const newLayout = Object.entries(layout).reduce((acc, [key, layouts]) => {
      acc[key] = layouts.filter((layout) => layout.i !== id);

      return acc;
    }, {} as Layouts);

    if (!dashboardId) {
      return;
    }

    try {
      await dispatch(
        deleteUsersMeOrganisationsCurrentDashboardsByDashboardIdWidgetsAndWidgetId.initiate(
          {
            dashboardId,
            widgetId: id,
          },
          { fixedCacheKey: generateRemoveDashboardWidgetFixedCacheKey(id) },
        ),
      ).unwrap();

      dispatch(updateDashboardByIdLayoutThunk({ dashboardId, newLayout }));

      enqueueSnackbar("Widget successfully deleted.", {
        variant: "snackbarAlert",
        AlertSnackBarProps: { severity: "success" },
      });
    } catch (e) {
      // @ts-expect-error todo: fix api spec;
      enqueueSnackbarErrorAlert(e?.data?.message);
    }
  },
);
