import { FC, Fragment, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Stack, Typography } from "@mui/material";
import InsertChartOutlinedOutlinedIcon from "@mui/icons-material/InsertChartOutlinedOutlined";
import BarChartOutlinedIcon from "@mui/icons-material/BarChartOutlined";
import { useFlag } from "@unleash/proxy-client-react";
import { BreadcrumbNavigationTrigger } from "./BreadcrumbNavigationTrigger";
import { useAppSelector } from "../../../../../../../store/hooks";
import { resourceExplorerWidgetDataLoadingSelector } from "../../../../../../../store/resource-explorer/selectors/loading/resourceExplorerWidgetDataLoadingSelector";
import { resourceExplorerViewByIdLoadingSelector } from "../../../../../../../store/resource-explorer/selectors/resource-explorer-by-id/resourceExplorerViewByIdLoadingSelector";
import { currentResourceExplorerFolderSelector } from "../../../../../../../store/resource-explorer/selectors/current-resource-explorer/folder/currentResourceExplorerFolderSelector";
import { resourceExplorerNameSelector } from "../../../../../../../store/resource-explorer/selectors/current-resource-explorer/resourceExplorerNameSelector";
import { HierarchicalBreadcrumbs } from "../../../../../common/breadcrumbs/hierarchical-breadcrumbs/HierarchicalBreadcrumbs";
import { currentResourceExplorerHierarchyVisibilitySelector } from "../../../../../../../store/resource-explorer/selectors/views-hierarchy-visibility/currentResourceExplorerHierarchyVisibilitySelector";
import { HierarchyViewSelectionWrapper } from "../../../../../common/hierarchy-view-selection/HierarchyViewSelectionWrapper";
import { FilterTriggerComponentProps } from "../../../../../../common/select/dropdown-select/utils/types/filterTriggerComponentProps";
import { Breadcrumbs } from "../../../../../common/breadcrumbs/Breadcrumbs";
import { CurrentResourceExplorerActionButton } from "../../../../../../navigation/components/resource-explorer-v2/components/view-action-menu/for-current-view/CurrentResourceExplorerActionButton";
import { resourceExplorerProtectedBySelector } from "../../../../../../../store/resource-explorer/selectors/current-resource-explorer/resourceExplorerProtectedBySelector";
import { resourceExplorerProtectionDetailsSelector } from "../../../../../../../store/resource-explorer/selectors/current-resource-explorer/resourceExplorerProtectionDetailsSelector";

interface ResourceExplorerBreadcrumbsProps {
  viewId: string;
}

export const ResourceExplorerAppBarNavigation: FC<
  ResourceExplorerBreadcrumbsProps
> = ({ viewId }) => {
  const navigate = useNavigate();
  const enableHierarchySeparatePages = useFlag("EnableHierarchySeparatePages");

  const widgetsGettingLoading = useAppSelector(
    resourceExplorerWidgetDataLoadingSelector,
  );
  const visibility = useAppSelector(
    currentResourceExplorerHierarchyVisibilitySelector,
  );

  const viewGettingLoading = useAppSelector((state) =>
    resourceExplorerViewByIdLoadingSelector(state, viewId),
  );
  const currentFolder = useAppSelector(currentResourceExplorerFolderSelector);
  const name = useAppSelector(resourceExplorerNameSelector);
  const protectedBy = useAppSelector(resourceExplorerProtectedBySelector);
  const protectionDetails = useAppSelector(
    resourceExplorerProtectionDetailsSelector,
  );

  const changeNavigationHandler = useCallback(
    (id: string) => {
      navigate(`/resource-explorer/${id}`);
    },
    [navigate],
  );

  const loading = widgetsGettingLoading || viewGettingLoading;

  return (
    <Stack direction="row" spacing={1} alignItems="center">
      {enableHierarchySeparatePages && (
        <Fragment>
          <Breadcrumbs breadcrumbs={allViewsBreadcrumbs} />
          <Typography variant="body1" color="text.secondary">
            /
          </Typography>
        </Fragment>
      )}

      <HierarchicalBreadcrumbs
        folderName={currentFolder?.name}
        loading={!name && loading}
      >
        <HierarchyViewSelectionWrapper
          selectedItemId={viewId}
          onChange={changeNavigationHandler}
          CustomTriggerComponent={ResourceExplorerBreadcrumbNavigationTrigger}
        />
      </HierarchicalBreadcrumbs>

      <CurrentResourceExplorerActionButton
        id={viewId}
        name={name ?? ""}
        protectedBy={protectedBy}
        folderId={currentFolder?.id}
        protectionDetails={protectionDetails}
        visibility={visibility ?? "visible_to_everyone"}
      />
    </Stack>
  );
};

const ResourceExplorerBreadcrumbNavigationTrigger = (
  props: FilterTriggerComponentProps,
) => {
  return (
    <BreadcrumbNavigationTrigger
      {...props}
      icon={InsertChartOutlinedOutlinedIcon}
    />
  );
};

const allViewsBreadcrumbs = [
  {
    title: "Billing Explorer",
    icon: <BarChartOutlinedIcon fontSize="small" />,
    to: "/resource-explorer",
  },
];
