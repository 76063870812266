import { createAsyncThunk } from "@reduxjs/toolkit";
import { getLiveUsageMgmtResourcesWithFiltersByProviderThunk } from "./getLiveUsageMgmtResourcesWithFiltersByProviderThunk";
import { resourceTypesByProvider } from "../../../../utils/helpers/providers/resourceTypesByProvider";
import { RootState } from "../../../store";
import { liveUsageMgmtProviderSelector } from "../../selectors/store-selectors/liveUsageMgmtProviderSelector";
import { getIsResourceTypeHiddenByFeatureToggle } from "../../../../components/pages/live-usage-mgmt/utils/helpers/getIsResourceTypeHiddenByFeatureToggle";

export const getLiveUsageMgmtAllResourceTypesDataThunk = createAsyncThunk(
  "liveUsageMgmt/getLiveUsageMgmtAllResourceTypesDataThunk",
  async (_: void, { dispatch, getState }) => {
    const state = getState() as RootState;
    const provider = liveUsageMgmtProviderSelector(state);

    if (!provider) {
      return;
    }

    const resourceTypes = resourceTypesByProvider(provider);
    resourceTypes?.forEach((resourceType) => {
      if (getIsResourceTypeHiddenByFeatureToggle(resourceType)) {
        return;
      }

      dispatch(
        getLiveUsageMgmtResourcesWithFiltersByProviderThunk(resourceType),
      );
    });
  },
);
