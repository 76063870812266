import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../../../../../store";
import { cloudChiprApi } from "../../../../../../services/cloudchipr.api";
import { largesCostChangesSetupWidgetPayloadDataSelector } from "../../../../selectors/setups/larges-cost-changes/largesCostChangesSetupWidgetPayloadDataSelector";
import { viewNotFoundSelector } from "../../../../../resource-explorer/selectors/all-resource-explorers/viewNotFoundSelector";
import { enqueueSnackbarErrorAlert } from "../../../../../../components/pages/common/snackbar-alert/EnqueueSnackbarErrorAlert";

export const getLargestCostChangesWidgetDataBySetupThunk = createAsyncThunk(
  "dashboards/getLargestCostChangesWidgetDataBySetup",
  async (_, { dispatch, getState }) => {
    const state = getState() as RootState;

    const { getUsersMeOrganisationsCurrentWidgetsLargestCostChanges } =
      cloudChiprApi.endpoints;

    const payload = largesCostChangesSetupWidgetPayloadDataSelector(state);

    if (!payload?.viewId) {
      return;
    }

    const viewNotFound = viewNotFoundSelector(state, payload.viewId);

    if (viewNotFound) {
      return;
    }

    try {
      return await dispatch(
        getUsersMeOrganisationsCurrentWidgetsLargestCostChanges.initiate(
          payload,
        ),
      ).unwrap();
    } catch (e) {
      // @ts-expect-error todo: fix api spec;
      enqueueSnackbarErrorAlert(e?.data?.message);
    }
  },
);
