import { createAsyncThunk } from "@reduxjs/toolkit";
import { cloudChiprApi } from "../../../../services/cloudchipr.api";
import { RootState } from "../../../store";
import { taskManagementEditingPrioritiesSelector } from "../../selectors/properties/edit/taskManagementEditingPrioritiesSelector";
import { updateTaskPriorityPropertiesFixedCacheKey } from "../../utils/constants/fixedCacheKeys";
import { taskAvailablePropertiesSelector } from "../../selectors/properties/taskAvailablePropertiesSelector";
import { isDeepEqual } from "../../../../utils/is-deep-equal";
import { removeIdFromNewCreatedProperties } from "../../utils/helpers/removeIdFromNewCreatedProperties";
import { enqueueSnackbarErrorAlert } from "../../../../components/pages/common/snackbar-alert/EnqueueSnackbarErrorAlert";

export const updateTaskPriorityPropertiesThunk = createAsyncThunk(
  "taskManagement/updateTaskPriorityProperties",
  async (_: void, { dispatch, getState }) => {
    const state = getState() as RootState;

    const { putUsersMeOrganisationsCurrentTasksPropertiesPriorities } =
      cloudChiprApi.endpoints;

    const properties = taskManagementEditingPrioritiesSelector(state);
    const initialProperties = taskAvailablePropertiesSelector(state);

    if (isDeepEqual(properties, initialProperties?.priorities ?? [])) {
      return;
    }

    try {
      return await dispatch(
        putUsersMeOrganisationsCurrentTasksPropertiesPriorities.initiate(
          {
            body: { properties: removeIdFromNewCreatedProperties(properties) },
          },
          { fixedCacheKey: updateTaskPriorityPropertiesFixedCacheKey },
        ),
      ).unwrap();
    } catch (e) {
      // @ts-expect-error todo: fix api spec;
      enqueueSnackbarErrorAlert(e?.data?.message);
    }
  },
);
