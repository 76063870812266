import { FC, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { ListItemIcon, MenuItem, Tooltip, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { SxProps, Theme } from "@mui/system";
import { useAppDispatch } from "../../../../../../../store/hooks";
import { useGetUsersMeOrganisationsCurrentDashboardsPossibleWidgetsQuery } from "../../../../../../../services/cloudchipr.api";
import { setWidgetSelectorState } from "../../../../../../../store/dashboards/dashboardsSlice";

interface DashboardV2ActionProps {
  onClose(): void;
  dashboardId: string;
  iconSx: SxProps<Theme>;
  menuItemSx: SxProps<Theme>;
  isProtected: boolean;
}

export const DashboardV2AddWidgetAction: FC<DashboardV2ActionProps> = ({
  dashboardId,
  onClose,
  menuItemSx,
  iconSx,
  isProtected,
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { refetch } =
    useGetUsersMeOrganisationsCurrentDashboardsPossibleWidgetsQuery({});

  const openWidgetSelector = useCallback(() => {
    navigate(`/dashboards/${dashboardId}`);
    dispatch(setWidgetSelectorState(true));
    refetch();
    onClose();
  }, [dispatch, refetch, dashboardId, navigate, onClose]);

  return (
    <Tooltip
      title={isProtected ? "Protected dashboards cannot be modified." : ""}
      arrow
      placement="right"
    >
      <span>
        <MenuItem
          sx={menuItemSx}
          onClick={openWidgetSelector}
          disabled={isProtected}
        >
          <ListItemIcon>
            <AddIcon fontSize="small" sx={iconSx} />
          </ListItemIcon>
          <Typography variant="body2">Add Widget</Typography>
        </MenuItem>
      </span>
    </Tooltip>
  );
};
