import { FC, Fragment, useCallback, useMemo } from "react";
import { ListItemIcon, ListItemText, MenuItem } from "@mui/material";
import { GppBadOutlined, VerifiedUserOutlined } from "@mui/icons-material";
import { useAppSelector } from "../../../../../../../../../../../store/hooks";
import { ResourceType } from "../../../../../../../../../../../services/cloudchipr.api";
import { useAppAbility } from "../../../../../../../../../../../services/permissions";
import { liveUsageMgmtSelectedResourcesByResourceTypeSelector } from "../../../../../../../../../../../store/live-usage-mgmt/selectors/resource-type-data/liveUsageMgmtSelectedResourcesByResourceTypeSelector";
import { liveUsageMgmtResourcesProtectDisabledSelector } from "../../../../../../../../../../../store/live-usage-mgmt/selectors/resource-type-data/liveUsageMgmtResourcesProtectDisabledSelector";

interface ProtectionMenuItemsProps {
  resourceType: ResourceType;
  onClose(): void;
  openProtectDialog(): void;
  setProtectAction(type: "protect" | "unprotect"): void;
}

export const LiveUsageMgmtProtectionMenuItems: FC<ProtectionMenuItemsProps> = ({
  openProtectDialog,
  resourceType,
  onClose,
  setProtectAction,
}) => {
  const { cannot } = useAppAbility();
  const cleanDisabled = useAppSelector((state) =>
    liveUsageMgmtResourcesProtectDisabledSelector(state, resourceType),
  );
  const canNotCleanResource = cannot("protect", "resource");
  const selectedResources = useAppSelector((state) =>
    liveUsageMgmtSelectedResourcesByResourceTypeSelector(state, resourceType),
  );

  const { hideProtect, hideUnProtect } = useMemo(() => {
    return {
      hideProtect: selectedResources?.every(
        ({ resource }) => resource.is_protected,
      ),
      hideUnProtect: selectedResources?.every(
        ({ resource }) => !resource.is_protected,
      ),
    };
  }, [selectedResources]);

  const onProtectItemClick = useCallback(() => {
    setProtectAction("protect");
    onClose();
    openProtectDialog();
  }, [onClose, setProtectAction, openProtectDialog]);

  const onUnprotectItemClick = useCallback(() => {
    setProtectAction("unprotect");
    onClose();
    openProtectDialog();
  }, [onClose, setProtectAction, openProtectDialog]);

  return (
    <Fragment>
      {!hideProtect && (
        <MenuItem
          dense
          onClick={onProtectItemClick}
          disabled={cleanDisabled || canNotCleanResource}
        >
          <ListItemIcon>
            <VerifiedUserOutlined fontSize="small" />
          </ListItemIcon>
          <ListItemText>Protect</ListItemText>
        </MenuItem>
      )}
      {!hideUnProtect && (
        <MenuItem
          dense
          onClick={onUnprotectItemClick}
          disabled={cleanDisabled || canNotCleanResource}
        >
          <ListItemIcon>
            <GppBadOutlined fontSize="small" />
          </ListItemIcon>
          <ListItemText>Unprotect</ListItemText>
        </MenuItem>
      )}
    </Fragment>
  );
};
