import { FC } from "react";
import { Stack } from "@mui/material";
import { OffHoursSchedulerFieldLabel } from "../OffHoursSchedulerFieldLabel";
import { OffHoursStopStartTime } from "../OffHoursStopStartTime";
import { useAppSelector } from "../../../../../../../../store/hooks";
import { offHoursSchedulerSelector } from "../../../../../../../../store/automations/selectros/off-hours/offHoursSchedulerSelector";
import { automationFrequencySelector } from "../../../../../../../../store/automations/selectros/common/automationFrequencySelector";
import { StopDateInfoTooltip } from "../StopDateInfoTooltip";
import { AutomationTimeZone } from "../../../../../common/components/form/schedule-content/AutomationTimeZone";

export const OffHoursOnceScheduler: FC = () => {
  const scheduler = useAppSelector(offHoursSchedulerSelector);
  const frequency = useAppSelector(automationFrequencySelector);

  if (frequency !== "once") {
    return null;
  }

  return (
    <Stack direction="row" spacing={2} alignItems="flex-end" flex={1}>
      <Stack spacing={1}>
        <OffHoursSchedulerFieldLabel type="stop" title="Stop resources at" />
        <OffHoursStopStartTime
          value={scheduler?.stopTime}
          type="stop"
          dateTimePicker
        />
      </Stack>

      <Stack spacing={1}>
        <OffHoursSchedulerFieldLabel type="start" title="Start resources at" />
        <Stack direction="row" spacing={1} alignItems="center">
          <OffHoursStopStartTime
            value={scheduler?.startTime}
            type="start"
            dateTimePicker
          />
          <StopDateInfoTooltip />
        </Stack>
      </Stack>

      <AutomationTimeZone />
    </Stack>
  );
};
