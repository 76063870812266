import { useCallback } from "react";
import { useAllResourcesVisibility } from "./useAllResourcesVisibility.hook";
import {
  ProviderType,
  ResourceType,
} from "../../../../../../services/cloudchipr.api";
import { resourcesNotIncludedInScheduleSelect } from "../constants/resourcesNotIncludedInScheduleSelect";
import { offHoursAvailableResourceTypes } from "../constants/common";
import { AutomationType } from "../../../../../../store/automations/utils/types/common";
import { getIsResourceTypeHiddenByFeatureToggle } from "../../../../live-usage-mgmt/utils/helpers/getIsResourceTypeHiddenByFeatureToggle";

export function useResourceVisibilityHook({
  accountIds,
  provider,
  visibleFor,
}: {
  accountIds?: string | null;
  provider?: ProviderType | null;
  visibleFor: AutomationType | null;
}) {
  const resourcesVisibility = useAllResourcesVisibility({
    accountIds,
    provider,
  });

  return useCallback(
    (resourceType: ResourceType) => {
      const isVisible =
        Object.values(resourcesVisibility).every((visibility) => {
          return visibility?.[resourceType]?.show;
        }) &&
        !getIsResourceTypeHiddenByFeatureToggle(resourceType) &&
        !resourcesNotIncludedInScheduleSelect.has(resourceType);

      return visibleFor === "offHours"
        ? isVisible && offHoursAvailableResourceTypes.includes(resourceType)
        : isVisible;
    },
    [visibleFor, resourcesVisibility],
  );
}
