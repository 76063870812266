import { CustomFilterProps, useGridFilter } from "ag-grid-react";
import { FC, useCallback, MouseEvent } from "react";
import { IDoesFilterPassParams } from "ag-grid-community";
import { Checkbox, FormControlLabel, Stack } from "@mui/material";
import { useAppSelector } from "../../../../../../store/hooks";
import { tasksAllAssigneesSelector } from "../../../../../../store/task-management/selectors/data/tasksAllAssigneesSelector";
import { propertySelectionStyles } from "../../../../common/task-management/components/common/properties/assignee/AssigneeSelectionTrigger";
import { ChartsColors } from "../../../../../../storybook/charts/multi-type-chart/utils/constants/colors";
import { TypographyWithTooltip } from "../../../../../common/TypographyWithTooltip";

export const AssigneeFilter: FC<CustomFilterProps> = ({
  model,
  onModelChange,
  getValue,
}) => {
  const assignees = useAppSelector(tasksAllAssigneesSelector);

  const doesFilterPass = useCallback(
    (params: IDoesFilterPassParams) => {
      const { node } = params;
      const filterTexts: string[] = model ?? [];
      const value: string = getValue(node).toString().toLowerCase();

      return filterTexts.some((text) =>
        value.toLowerCase().includes(text.toLowerCase()),
      );
    },
    [getValue, model],
  );

  const changeHandler = useCallback(
    (e: MouseEvent<HTMLLabelElement>) => {
      if (!("value" in e.target)) {
        return;
      }

      const selected = e.target.value;

      if (!model) {
        onModelChange(
          assignees
            .map((user) => user.name || user.email)
            .filter((value) => value !== selected),
        );

        return;
      }

      let values = [...(model ?? [])];

      if (values.includes(selected)) {
        values = values.filter((value) => value !== selected);
      } else {
        values.push(selected);
      }

      onModelChange(
        values?.length && values?.length !== assignees.length ? values : null,
      );
    },
    [assignees, model, onModelChange],
  );

  useGridFilter({
    doesFilterPass,
  });

  return (
    <Stack p={1} pl={0} spacing={1}>
      {assignees.map((user, index) => {
        const identifier = user.name || user.email || "";

        return (
          <FormControlLabel
            onClick={changeHandler}
            key={identifier}
            sx={{ px: 1, mr: 0, width: "fit-content" }}
            control={
              <Checkbox
                value={identifier}
                size="small"
                checked={(!model || model?.includes(identifier)) ?? false}
              />
            }
            label={
              <Stack
                direction="row"
                spacing={1}
                alignItems="center"
                key={identifier}
              >
                <Stack
                  {...propertySelectionStyles}
                  bgcolor={ChartsColors[index]}
                >
                  {identifier.substring(0, 1)}
                </Stack>

                <TypographyWithTooltip variant="body2" title={identifier} />
              </Stack>
            }
          />
        );
      })}
    </Stack>
  );
};
