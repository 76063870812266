import { RootState } from "../../../store";
import { allAccountsGroupedByProviderSelector } from "../../../accounts/selectors/all-providers/allAccountsGroupedByProviderSelector";
import { liveUsageMgmtProviderSelector } from "../store-selectors/liveUsageMgmtProviderSelector";
import { Account } from "../../../../services/cloudchipr.api";
import { AccountOptionType } from "../../../../components/common/account-select/utils/types/types";
import { sortByAlphanumericForAccountOptionType } from "../../../../components/common/account-select/utils/helpers/sorting/sortByAlphanumericForAccountOptionType";
import { sortAccountOptionTypeByOrgIdCompareFn } from "../utils/helpers/sortingFn";

export const liveUsageMgmtAccountsSelectOptionsSelector = (
  state: RootState,
): AccountOptionType[] | undefined => {
  const accountsGroupedByProvider = allAccountsGroupedByProviderSelector(state);
  const provider = liveUsageMgmtProviderSelector(state);

  if (!provider || !accountsGroupedByProvider?.[provider]) {
    return;
  }

  return generateAccountSelectionOptions(
    accountsGroupedByProvider?.[provider] ?? [],
  );
};

const generateAccountSelectionOptions = (
  accounts: Account[] | null,
): AccountOptionType[] => {
  if (!accounts) {
    return [];
  }

  const options = accounts.reduce((result, account) => {
    if (account.status === "deactivated" || account.status !== "connected") {
      return result;
    }
    result.push({
      value: account.id,
      label:
        account.provider_account_name ?? `ID: ${account.provider_account_id}`,
      orgId: account.group_id ?? undefined,
      providerAccountId: account.provider_account_id ?? "",
      accountType: account.type,
      accessType: account.access_type,
      status: account.status,
      warning: !account.has_satisfied_permissions,
    });

    return result;
  }, [] as AccountOptionType[]);

  const accountSelectionOptions =
    sortByAlphanumericForAccountOptionType(options);

  return accountSelectionOptions
    .flat()
    .toSorted(sortAccountOptionTypeByOrgIdCompareFn);
};
