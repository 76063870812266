import { reportsDataPropertyByKeySelector } from "./reportsDataPropertyByKeySelector";
import { RootState } from "../../store";
import { resourceExplorerViewNameByViewIdSelector } from "../../resource-explorer/selectors/all-resource-explorers/resourceExplorerViewNameByViewIdSelector";
import { dashboardByIdDataSelector } from "../../dashboards/selectors/dashboard/dashboardByIdDataSelector";

export const reportCurrentSourceNameBySourceSelector = (state: RootState) => {
  const sourceType = reportsDataPropertyByKeySelector("source_type")(state);
  const sourceId = reportsDataPropertyByKeySelector("source_id")(state);

  if (!sourceId) {
    return;
  }

  if (sourceType === "dashboard") {
    return dashboardByIdDataSelector(state, sourceId)?.name;
  }

  return resourceExplorerViewNameByViewIdSelector(state, sourceId);
};
