import {
  Dispatch,
  FC,
  Fragment,
  SetStateAction,
  useCallback,
  useMemo,
} from "react";
import { Divider, Typography } from "@mui/material";
import { FilterListItem } from "./FilterListItem";
import { DropdownSelectOption } from "../utils/types/types";
import { spreadAllOptions } from "../utils/helpers/spreadAllOptions";

interface FilterSelectAllItemProps {
  selectedValues: string[];
  filteredOptions: DropdownSelectOption[];
  allOptions: DropdownSelectOption[];
  setSelectedValues: Dispatch<SetStateAction<string[]>>;
}

export const FilterSelectAllItem: FC<FilterSelectAllItemProps> = ({
  setSelectedValues,
  selectedValues,
  filteredOptions,
  allOptions,
}) => {
  const filteredOptionsWithNested = useMemo(() => {
    return spreadAllOptions(filteredOptions);
  }, [filteredOptions]);

  const allOptionsWithNested = useMemo(() => {
    return spreadAllOptions(allOptions);
  }, [allOptions]);

  const allOptionsLength = allOptionsWithNested.length;
  const filtered = filteredOptionsWithNested.length !== allOptionsLength;
  const options = filtered ? filteredOptionsWithNested : allOptionsWithNested;

  const selectAllChecked = useMemo(() => {
    const checkObj = reduceArrToObj(selectedValues);

    return options
      .filter((option) => !option.disabled)
      .every((v) => checkObj[v.value]);
  }, [options, selectedValues]);

  const indeterminate = useMemo(() => {
    const checkObj = reduceArrToObj(selectedValues);

    return !!selectedValues.length && options.some((v) => checkObj[v.value]);
  }, [options, selectedValues]);

  const handleSelectAllToggle = useCallback(() => {
    const currentFilteredValues = options
      .filter((option) => !option.disabled)
      .map((v) => v.value);

    if (!selectAllChecked) {
      const checkObj = reduceArrToObj(selectedValues);
      const newSelectedValues = currentFilteredValues.filter(
        (v) => !checkObj[v],
      );

      setSelectedValues([...newSelectedValues, ...selectedValues]);
    } else {
      const checkObj = reduceArrToObj(currentFilteredValues);
      const newSelectedValues = selectedValues.filter((v) => !checkObj[v]);

      setSelectedValues(newSelectedValues);
    }
  }, [options, selectedValues, setSelectedValues, selectAllChecked]);

  if (!options?.length) {
    return null;
  }

  const untouchedState =
    allOptionsLength === selectedValues.length || !selectedValues.length;

  return (
    <Fragment>
      <FilterListItem
        singleSelect={false}
        lastSelectedOptionDisabled={false}
        selectedValues={selectedValues}
        isSelected={selectAllChecked}
        isIndeterminate={indeterminate}
        onClick={handleSelectAllToggle}
        option={{
          value: "",
          label:
            filtered || untouchedState ? (
              getSelectAllText(filteredOptionsWithNested.length, filtered)
            ) : (
              <Typography variant="body2">
                {selectedValues.length} of {allOptionsLength} selected
              </Typography>
            ),
        }}
      />
      <Divider />
    </Fragment>
  );
};

const reduceArrToObj = (arr: string[]) => {
  // for optimization to not check for a string that exists in array using the ".includes()" method
  return arr.reduce(
    (acc, value) => {
      acc[value] = true;
      return acc;
    },
    {} as Record<string, boolean>,
  );
};

const getSelectAllText = (count: number, filtered: boolean) => {
  return (
    <Typography variant="body2">
      Select All {filtered ? `Matching` : " "}
      <Typography component="span" color="text.disabled" variant="inherit">
        ({count})
      </Typography>
    </Typography>
  );
};
