import { SvgIcon, SvgIconProps, useTheme } from "@mui/material";

const AsgSvgIcon = ({ primary }: SvgIconProps & { primary?: boolean }) => {
  const { palette } = useTheme();

  const color = primary ? palette.primary.main : palette.action.active;

  return (
    <SvgIcon>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_18827_36518)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12.333 9.31399V8.55182C12.333 8.41158 12.2205 8.29776 12.083 8.29776H8.58305C8.44555 8.29776 8.33305 8.41158 8.33305 8.55182V9.31399V9.8221V10.8383V11.3464V12.1086C8.33305 12.2488 8.44555 12.3627 8.58305 12.3627H9.33305H9.83305H10.833H11.333H12.083C12.2205 12.3627 12.333 12.2488 12.333 12.1086V11.3464V10.8383V9.8221V9.31399ZM12.833 7.78965H13.333V8.29776H12.787C12.815 8.37754 12.833 8.46239 12.833 8.55182V9.31399H13.333V9.8221H12.833V10.8383H13.333V11.3464H12.833V12.1086C12.833 12.198 12.815 12.2829 12.787 12.3627H12.833H13.333V12.8708H12.833V13.3789H12.333V12.8708V12.824C12.2545 12.8525 12.171 12.8708 12.083 12.8708H11.333V13.3789H10.833V12.8708H9.83305V13.3789H9.33305V12.8708H8.58305C8.49505 12.8708 8.41155 12.8525 8.33305 12.824V12.8708V13.3789H7.83305V12.8708H7.33305V12.3627H7.83305H7.87905C7.85105 12.2829 7.83305 12.198 7.83305 12.1086V11.3464H7.33305V10.8383H7.83305V9.8221H7.33305V9.31399H7.83305V8.55182C7.83305 8.46239 7.85105 8.37754 7.87905 8.29776H7.33305V7.78965H7.83305V7.28154H8.33305V7.78965V7.8364C8.41155 7.80794 8.49505 7.78965 8.58305 7.78965H9.33305V7.28154H9.83305V7.78965H10.833V7.28154H11.333V7.78965H12.083C12.171 7.78965 12.2545 7.80794 12.333 7.8364V7.78965V7.28154H12.833V7.78965ZM10.5592 15.9179V13.5536H10.0592V15.9179L8.99971 14.6532L8.61871 14.983L10.3092 16.9997L11.9997 14.983L11.6187 14.6532L10.5592 15.9179ZM5.02355 8.939L6.26805 10.0157H3.43555V10.5238H6.26805L5.02355 11.6005L5.34755 11.9877L7.33305 10.2697L5.34755 8.55182L5.02355 8.939ZM17.2305 10.0157H14.398L15.6425 8.939L15.3185 8.55182L13.333 10.2697L15.3185 11.9877L15.6425 11.6005L14.398 10.5238H17.2305V10.0157ZM8.99971 6.0657L8.61871 5.73594L10.3092 3.71874L11.9997 5.73594L11.6187 6.0657L10.5592 4.80101V7.15916H10.0592V4.80101L8.99971 6.0657Z"
            fill={color}
            fillOpacity="0.87"
          />
        </g>
        <defs>
          <clipPath id="clip0_18827_36518">
            <rect width="20" height="20" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  );
};

AsgSvgIcon.muiName = "AsgSvgIcon";

export default AsgSvgIcon;
