import { createAsyncThunk } from "@reduxjs/toolkit";
import { createResourceExplorerVisibilityHierarchyItemThunk } from "./createResourceExplorerVisibilityHierarchyItemThunk";
import { updateResourceExplorerVisibilityHierarchyThunk } from "./updateResourceExplorerVisibilityHierarchyThunk";
import { PostV2UsersMeOrganisationsCurrentDashboardsApiArg } from "../../../../services/cloudchipr.api";
import { enqueueSnackbarErrorAlert } from "../../../../components/pages/common/snackbar-alert/EnqueueSnackbarErrorAlert";

interface CreateResourceExplorerVisibilityHierarchyItemByFolderIdThunkArg {
  name: string;
  folderId: string;
  visibility: PostV2UsersMeOrganisationsCurrentDashboardsApiArg["body"]["visibility"];
}

export const createResourceExplorerVisibilityHierarchyItemByFolderIdThunk =
  createAsyncThunk(
    "resourceExplorer/createResourceExplorerVisibilityHierarchyItemByFolderId",
    async (
      {
        name,
        folderId,
        visibility,
      }: CreateResourceExplorerVisibilityHierarchyItemByFolderIdThunkArg,
      { dispatch },
    ) => {
      try {
        const response = await dispatch(
          createResourceExplorerVisibilityHierarchyItemThunk({
            name,
            visibility,
          }),
        ).unwrap();

        if (!response?.id) {
          return;
        }

        await dispatch(
          updateResourceExplorerVisibilityHierarchyThunk({
            folderId,
            id: response.id,
            visibility,
            type: "view",
          }),
        );

        return response;
      } catch (e) {
        // @ts-expect-error todo: fix api spec;
        enqueueSnackbarErrorAlert(e?.data?.message);
      }
    },
  );
