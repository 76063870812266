import {
  ResourceExplorerFilterProvider,
  ResourceExplorerGrouping,
} from "../../../../../../services/cloudchipr.api";
import { getProviderName } from "../../../../../../utils/helpers/providers/getProviderName";

export const getCloudProvidersGroupTooltipTitle = (
  grouping: ResourceExplorerGrouping,
  providers?: ResourceExplorerFilterProvider[],
) => {
  const providerTitles = providers?.map((provider) =>
    getProviderName(provider, { title: true }),
  );

  const last = providerTitles?.at(-1);
  const startPart = providerTitles?.slice(0, -1).join(", ");

  if (providers?.length === 1) {
    return getTitlesText(providerTitles?.at(0), grouping);
  }

  return getTitlesText(`${startPart} and ${last}`, grouping);
};

const modifyGrouping = (grouping?: ResourceExplorerGrouping) => {
  return grouping?.replace("cost_allocation_tag", "tag").replace("_", "-");
};

const getTitlesText = (
  providers?: string,
  grouping?: ResourceExplorerGrouping,
) =>
  `This grouping works only with ${providers}. Resources from other sources will be listed under the “no-${modifyGrouping(grouping)}” item.`;
