import { FC } from "react";
import {
  IconButton,
  ListItemIcon,
  MenuItem,
  Tooltip,
  Typography,
} from "@mui/material";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import { SvgIconOwnProps } from "@mui/material/SvgIcon/SvgIcon";

interface ResourceExplorerCsvDownloaderTriggerProps {
  onClick?(): void;
  disabled?: boolean;
  trigger: "button" | "menuItem";
  color?: SvgIconOwnProps["color"];
}

export const ResourceExplorerCsvDownloaderTrigger: FC<
  ResourceExplorerCsvDownloaderTriggerProps
> = ({ trigger, disabled, onClick, color }) => {
  if (trigger === "button") {
    return (
      <Tooltip arrow placement="top" title="Download CSV">
        <span>
          <IconButton size="small" disabled={disabled} onClick={onClick}>
            <DownloadOutlinedIcon color={color ?? "action"} fontSize="small" />
          </IconButton>
        </span>
      </Tooltip>
    );
  }

  return (
    <MenuItem disabled={disabled} onClick={onClick}>
      <ListItemIcon>
        <DownloadOutlinedIcon fontSize="small" color={color} />
      </ListItemIcon>

      <Typography variant="body2" color="text.primary">
        Export CSV
      </Typography>
    </MenuItem>
  );
};
