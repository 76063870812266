import { ColDef } from "ag-grid-community";

export const utilizationSoringFn: ColDef["comparator"] = (
  valueA,
  valueB,
  nodeA,
  nodeB,
) => {
  return Number(nodeA.data?.utilization?.amount) >
    Number(nodeB.data?.utilization?.amount)
    ? -1
    : 1;
};
