import { createAsyncThunk } from "@reduxjs/toolkit";
import { closeSnackbar, enqueueSnackbar, SnackbarKey } from "notistack";
import {
  cloudChiprApi,
  PostV2UsersMeOrganisationsCurrentDashboardsApiArg,
} from "../../../../services/cloudchipr.api";
import { createDashboardFixedCacheKey } from "../../../../components/pages/dashboard/utils/constants/fixedCacheKeys";
import { enqueueSnackbarErrorAlert } from "../../../../components/pages/common/snackbar-alert/EnqueueSnackbarErrorAlert";

export const createDashboardVisibilityHierarchyItemThunk = createAsyncThunk(
  "dashboard/createDashboardVisibilityHierarchyItem",
  async (
    {
      name,
      visibility,
      widgets,
    }: PostV2UsersMeOrganisationsCurrentDashboardsApiArg["body"],
    { dispatch },
  ) => {
    const { postV2UsersMeOrganisationsCurrentDashboards } =
      cloudChiprApi.endpoints;
    let response;

    try {
      response = await dispatch(
        postV2UsersMeOrganisationsCurrentDashboards.initiate(
          { body: { name, visibility, widgets } },
          { fixedCacheKey: createDashboardFixedCacheKey },
        ),
      ).unwrap();

      if (response) {
        const snackbarId: SnackbarKey = enqueueSnackbar(
          "Dashboard successfully created.",
          {
            variant: "snackbarAlert",
            AlertSnackBarProps: {
              severity: "success",
              onClose: () => closeSnackbar(snackbarId),
            },
          },
        );
      }
      return response;
    } catch (e) {
      // @ts-expect-error todo: fix api spec;
      enqueueSnackbarErrorAlert(e?.data?.message);
    }
  },
);
