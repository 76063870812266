import { FC, useCallback } from "react";
import { Box, Button, Popover, PopoverOrigin, Stack } from "@mui/material";
import DragHandleIcon from "@mui/icons-material/DragHandle";
import { LiveUsageFiltersNavigation } from "./navigation/LiveUsageFiltersNavigation";
import { LiveUsageFiltersContent } from "./content/LiveUsageFiltersContent";
import { useRefs } from "../../../../../../../utils/hooks/useRefs";
import { useLiveUsageFiltersPopoverHeight } from "../utils/hooks/useLiveUsageFiltersPopoverHeight.hook";
import { useAppSelector } from "../../../../../../../store/hooks";
import { liveUsageMgmtProviderSelector } from "../../../../../../../store/live-usage-mgmt/selectors/store-selectors/liveUsageMgmtProviderSelector";
import { resourceTypeFilterSubmitHandlerKeyGenerator } from "../utils/helpers/resourceTypeFilterSubmitHandlerKeyGenerator";

interface LiveUsageAccountFiltersPopoverProps {
  open: boolean;
  onClose(): void;
  anchor: HTMLElement | null;
}

export const LiveUsageFiltersPopover: FC<
  LiveUsageAccountFiltersPopoverProps
> = ({ open, anchor, onClose }) => {
  const { handleMouseDown, height } = useLiveUsageFiltersPopoverHeight();
  const provider = useAppSelector(liveUsageMgmtProviderSelector);

  const { setRefFromKey, refs } = useRefs();

  const applyHandler = useCallback(() => {
    if (!refs) {
      return;
    }

    Object.entries(refs).forEach(([key, value]) => {
      const foundCallback = key?.endsWith(
        resourceTypeFilterSubmitHandlerKeyGenerator(),
      );

      if (foundCallback && typeof value === "function") {
        value();
      }
    });
  }, [refs]);

  return (
    <Popover
      open={open}
      anchorEl={anchor}
      onClose={onClose}
      anchorOrigin={defaultAnchorOrigin}
      transformOrigin={defaultTransformOrigin}
    >
      <Stack minWidth={400} maxHeight="90dvh" height={height} overflow="hidden">
        {provider && (
          <Stack flex={1} direction="row" p={2} overflow="auto">
            <LiveUsageFiltersNavigation
              resourcesRefs={refs}
              provider={provider}
            />

            <LiveUsageFiltersContent
              onClose={onClose}
              provider={provider}
              setRefFromKey={setRefFromKey}
            />
          </Stack>
        )}

        <Stack direction="row" spacing={1} justifyContent="end" pr={2}>
          <Button onClick={onClose} color="tertiary">
            Cancel
          </Button>

          <Button type="submit" variant="contained" onClick={applyHandler}>
            Apply
          </Button>
        </Stack>

        <Box sx={dragHandleStyles} onMouseDown={handleMouseDown}>
          <DragHandleIcon color="action" />
        </Box>
      </Stack>
    </Popover>
  );
};

const defaultAnchorOrigin: PopoverOrigin = {
  vertical: "bottom",
  horizontal: "left",
};

const defaultTransformOrigin: PopoverOrigin = {
  vertical: "top",
  horizontal: "left",
};

const dragHandleStyles = {
  lineHeight: 1,
  width: "100%",
  bgcolor: "white",
  textAlign: "center",
  cursor: "ns-resize",
};
