import { FC, Fragment, useState } from "react";
import { TextField } from "@mui/material";
import { useFormik } from "formik";
import { JiraTicketForm, JiraTicketFormProps } from "./JiraTicketForm";
import { JiraConnectAction } from "../JiraConnectAction";
import { jiraConnectionValidationSchema } from "../../../../../utils/validation";
import { usePostUsersMeJiraIntegrationsProjectsMutation } from "../../../../../../../../services/cloudchipr.api";
import { jiraProjectsOnCreateFixedCacheKey } from "../../../../../utils/constants/fixedCacheKeys";
import { enqueueSnackbarErrorAlert } from "../../../../../../common/snackbar-alert/EnqueueSnackbarErrorAlert";

export const JiraForm: FC<JiraTicketFormProps> = ({
  url,
  integrationId,
  api_token,
  jira_email,
  setFieldValue,
  ...ticketFormProps
}) => {
  const [fetchProjects, { isLoading }] =
    usePostUsersMeJiraIntegrationsProjectsMutation({
      fixedCacheKey: jiraProjectsOnCreateFixedCacheKey,
    });

  const [connected, setConnected] = useState(!!integrationId);

  const formik = useFormik({
    initialValues: {
      type: "jira",
      jira_email,
      api_token,
      url,
    },
    validationSchema: jiraConnectionValidationSchema,
    onSubmit: async ({ url, jira_email, api_token }) => {
      if (!api_token || !url || !jira_email) {
        return;
      }

      const body = { api_token, url, email: jira_email };

      try {
        await fetchProjects({ body }).unwrap();

        setConnected(true);

        setFieldValue("api_token", api_token);
        setFieldValue("jira_email", jira_email);
        setFieldValue("url", url);
      } catch (e) {
        // @ts-expect-error todo: fix api spec;
        enqueueSnackbarErrorAlert(e?.data?.message);
      }
    },
  });
  const { dirty, isValid, values, errors, handleChange, touched, submitForm } =
    formik;

  return (
    <Fragment>
      <TextField
        fullWidth
        disabled={connected}
        value={values.jira_email || ""}
        type="email"
        size="small"
        label="Email Address*"
        name="jira_email"
        variant="outlined"
        error={!!(touched.jira_email && errors.jira_email)}
        helperText={touched.jira_email && errors.jira_email}
        onChange={handleChange}
      />
      <TextField
        fullWidth
        disabled={connected}
        value={values.api_token || ""}
        size="small"
        variant="outlined"
        label="API Token*"
        name="api_token"
        error={!!(touched.api_token && errors.api_token)}
        helperText={touched.api_token && errors.api_token}
        onChange={handleChange}
      />
      <TextField
        fullWidth
        disabled={connected}
        value={values.url || ""}
        size="small"
        label="URL *"
        name="url"
        variant="outlined"
        error={!!(touched.url && errors.url)}
        helperText={
          (touched.url && errors.url) ||
          'Please make sure the URL starts with "https"'
        }
        onChange={handleChange}
      />

      {!integrationId && (
        <JiraConnectAction
          connected={connected}
          disabled={!dirty || !isValid}
          isLoading={isLoading}
          onConnect={submitForm}
        />
      )}

      {connected && (
        <JiraTicketForm
          url={values.url}
          integrationId={integrationId}
          api_token={values.api_token}
          jira_email={values.jira_email}
          setFieldValue={setFieldValue}
          {...ticketFormProps}
        />
      )}
    </Fragment>
  );
};
