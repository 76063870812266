import { Dispatch, FC, SetStateAction, useCallback, useMemo } from "react";
import {
  DragStoppedEvent,
  FilterChangedEvent,
  GridApi,
  SortChangedEvent,
} from "ag-grid-community";
import { workflowsTableColumns } from "./grid-options/workflowsTableColumns";
import { WorkflowDataGridNoRowsComponent } from "./grid-options/WorkflowDataGridNoRowsComponent";
import { Schedule } from "../../../../../../services/cloudchipr.api";
import {
  SettingsType,
  useAutomationsRowsOrder,
} from "../utils/hooks/useAutomationsRowsOrder.hook";
import { useAppSelector } from "../../../../../../store/hooks";
import { originalDataCountSelector } from "../../../../../../store/automations/selectros/workflow/list-data/originalDataCountSelector";
import { AgDataGrid } from "../../../../common/ag-grid/AgDataGrid";

interface WorkflowsDataGridComponentProps {
  data?: Schedule[];
  setGridApi: Dispatch<SetStateAction<GridApi | null>>;
  onFilterChanged(event: FilterChangedEvent): void;
}

export const WorkflowsDataGridComponent: FC<
  WorkflowsDataGridComponentProps
> = ({ setGridApi, onFilterChanged, data }) => {
  const { sorting, onColumnsSortingChange, sortedData, onRowsOrderChange } =
    useAutomationsRowsOrder<Schedule>("workflow", data ?? []);
  const originalDataCount = useAppSelector(originalDataCountSelector);
  const filterApplied = originalDataCount === data?.length;

  const columns = useMemo(() => {
    return workflowsTableColumns.map((column) => {
      const rowDrag = column.rowDrag ? filterApplied : undefined;

      return { ...column, rowDrag };
    });
  }, [filterApplied]);

  const configToRememberStateInStorage = useMemo(() => {
    if (!sorting) {
      return null;
    }

    return {
      stateByDefault: { sort: { sortModel: sorting } },
      uniqueNameAsKey: "workflowsDataGrid",
    };
  }, [sorting]);

  const sortChangeHandler = useCallback(
    (event: SortChangedEvent) => {
      const columnState = event.api.getColumnState();
      const sortState = columnState
        .filter(({ sort }) => sort !== null)
        .map((s) => {
          return {
            colId: s.colId,
            sort: s.sort,
            sortIndex: s.sortIndex,
          } as SettingsType;
        });

      onColumnsSortingChange(sortState);
    },
    [onColumnsSortingChange],
  );

  const rowDragStopHandler = useCallback(
    (event: DragStoppedEvent<Schedule>) => {
      const rows: Schedule[] = [];
      event.api.forEachNode((node) => node.data && rows.push(node.data));

      onRowsOrderChange(rows);
    },
    [onRowsOrderChange],
  );

  if (!configToRememberStateInStorage) {
    return null;
  }

  return (
    <AgDataGrid
      rowDragManaged
      gridApiSetter={setGridApi}
      rowHeight={64}
      headerHeight={56}
      rowData={sortedData}
      columnDefs={columns}
      onSortChanged={sortChangeHandler}
      onDragStopped={rowDragStopHandler}
      onFilterChanged={onFilterChanged}
      noRowsOverlayComponent={WorkflowDataGridNoRowsComponent}
      configToRememberStateInStorage={configToRememberStateInStorage}
    />
  );
};
