import { SvgIcon } from "@mui/material";

const MigSvgIcon = () => {
  //TODO: add colors

  return (
    <SvgIcon>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_18775_86949)">
          <rect width="20" height="20" fill="#EEEEEE" />
          <g clipPath="url(#clip1_18775_86949)">
            <path
              d="M6.94074 12.8867H4.89062V14.9368H6.94074V12.8867Z"
              fill="#BDBDBD"
            />
            <path
              d="M6.25954 10.4922H5.57617V11.8589H6.25954V10.4922Z"
              fill="#9E9E9E"
            />
            <path
              d="M4.89236 10.4922H4.20898V11.8589H4.89236V10.4922Z"
              fill="#9E9E9E"
            />
            <path
              d="M7.62283 10.4922H6.93945V11.8589H7.62283V10.4922Z"
              fill="#9E9E9E"
            />
            <path
              d="M6.25954 15.9609H5.57617V17.3277H6.25954V15.9609Z"
              fill="#757575"
            />
            <path
              d="M4.89236 15.9609H4.20898V17.3277H4.89236V15.9609Z"
              fill="#757575"
            />
            <path
              d="M7.62283 15.9609H6.93945V17.3277H7.62283V15.9609Z"
              fill="#757575"
            />
            <path
              d="M7.9668 13.5666V14.25H9.33354V13.5666H7.9668Z"
              fill="#757575"
            />
            <path
              d="M7.9668 14.9299V15.6133H9.33354V14.9299H7.9668Z"
              fill="#757575"
            />
            <path
              d="M7.9668 12.2033V12.8867H9.33354V12.2033H7.9668Z"
              fill="#757575"
            />
            <path d="M2.5 13.5666V14.25H3.86674V13.5666H2.5Z" fill="#9E9E9E" />
            <path
              d="M2.5 14.9299V15.6133H3.86674V14.9299H2.5Z"
              fill="#9E9E9E"
            />
            <path
              d="M2.5 12.2033V12.8867H3.86674V12.2033H2.5Z"
              fill="#9E9E9E"
            />
            <path
              d="M3.52539 11.5156V16.2992H8.30899V11.5156H3.52539ZM7.62562 15.6159H4.20876V12.199H7.62562V15.6159Z"
              fill="#BDBDBD"
            />
            <path
              d="M4.89062 14.9352H6.94074L5.91568 13.9102L4.89062 14.9352Z"
              fill="#9E9E9E"
            />
            <path
              d="M5.91602 13.9118L6.94107 14.9368V12.8867L5.91602 13.9118Z"
              fill="#757575"
            />
            <path
              d="M15.1068 12.8867H13.0566V14.9368H15.1068V12.8867Z"
              fill="#BDBDBD"
            />
            <path
              d="M14.4256 10.4922H13.7422V11.8589H14.4256V10.4922Z"
              fill="#9E9E9E"
            />
            <path
              d="M13.0584 10.4922H12.375V11.8589H13.0584V10.4922Z"
              fill="#9E9E9E"
            />
            <path
              d="M15.7888 10.4922H15.1055V11.8589H15.7888V10.4922Z"
              fill="#9E9E9E"
            />
            <path
              d="M14.4256 15.9609H13.7422V17.3277H14.4256V15.9609Z"
              fill="#757575"
            />
            <path
              d="M13.0584 15.9609H12.375V17.3277H13.0584V15.9609Z"
              fill="#757575"
            />
            <path
              d="M15.7888 15.9609H15.1055V17.3277H15.7888V15.9609Z"
              fill="#757575"
            />
            <path
              d="M16.1328 13.5666V14.25H17.4996V13.5666H16.1328Z"
              fill="#757575"
            />
            <path
              d="M16.1328 14.9299V15.6133H17.4996V14.9299H16.1328Z"
              fill="#757575"
            />
            <path
              d="M16.1328 12.2033V12.8867H17.4996V12.2033H16.1328Z"
              fill="#757575"
            />
            <path
              d="M10.666 13.5666V14.25H12.0328V13.5666H10.666Z"
              fill="#9E9E9E"
            />
            <path
              d="M10.666 14.9299V15.6133H12.0328V14.9299H10.666Z"
              fill="#9E9E9E"
            />
            <path
              d="M10.666 12.2033V12.8867H12.0328V12.2033H10.666Z"
              fill="#9E9E9E"
            />
            <path
              d="M11.6914 11.5156V16.2992H16.475V11.5156H11.6914ZM15.7916 15.6159H12.3748V12.199H15.7916V15.6159Z"
              fill="#BDBDBD"
            />
            <path
              d="M13.0566 14.9352H15.1068L14.0817 13.9102L13.0566 14.9352Z"
              fill="#9E9E9E"
            />
            <path
              d="M14.082 13.9118L15.1071 14.9368V12.8867L14.082 13.9118Z"
              fill="#757575"
            />
            <path
              d="M10.9368 4.89453H8.88672V6.94465H10.9368V4.89453Z"
              fill="#BDBDBD"
            />
            <path
              d="M10.2556 2.5H9.57227V3.86674H10.2556V2.5Z"
              fill="#9E9E9E"
            />
            <path
              d="M8.88845 2.5H8.20508V3.86674H8.88845V2.5Z"
              fill="#9E9E9E"
            />
            <path
              d="M11.6189 2.5H10.9355V3.86674H11.6189V2.5Z"
              fill="#9E9E9E"
            />
            <path
              d="M10.2556 7.96875H9.57227V9.33549H10.2556V7.96875Z"
              fill="#757575"
            />
            <path
              d="M8.88845 7.96875H8.20508V9.33549H8.88845V7.96875Z"
              fill="#757575"
            />
            <path
              d="M11.6189 7.96875H10.9355V9.33549H11.6189V7.96875Z"
              fill="#757575"
            />
            <path
              d="M11.9629 5.57444V6.25781H13.3296V5.57444H11.9629Z"
              fill="#757575"
            />
            <path
              d="M11.9629 6.93772V7.62109H13.3296V6.93772H11.9629Z"
              fill="#757575"
            />
            <path
              d="M11.9629 4.21116V4.89453H13.3296V4.21116H11.9629Z"
              fill="#757575"
            />
            <path
              d="M6.49609 5.57444V6.25781H7.86284V5.57444H6.49609Z"
              fill="#9E9E9E"
            />
            <path
              d="M6.49609 6.93772V7.62109H7.86284V6.93772H6.49609Z"
              fill="#9E9E9E"
            />
            <path
              d="M6.49609 4.21116V4.89453H7.86284V4.21116H6.49609Z"
              fill="#9E9E9E"
            />
            <path
              d="M7.52148 3.52344V8.30704H12.3051V3.52344H7.52148ZM11.6217 7.62367H8.20486V4.20681H11.6217V7.62367Z"
              fill="#BDBDBD"
            />
            <path
              d="M8.88672 6.94303H10.9368L9.91178 5.91797L8.88672 6.94303Z"
              fill="#9E9E9E"
            />
            <path
              d="M9.91211 5.91959L10.9372 6.94465V4.89453L9.91211 5.91959Z"
              fill="#757575"
            />
          </g>
        </g>
        <defs>
          <clipPath id="clip0_18775_86949">
            <rect width="20" height="20" fill="white" />
          </clipPath>
          <clipPath id="clip1_18775_86949">
            <rect width="20" height="20" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  );
};

MigSvgIcon.muiName = "MigSvgIcon";

export default MigSvgIcon;
