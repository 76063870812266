import { createAsyncThunk } from "@reduxjs/toolkit";
import { cloudChiprApi } from "../../../../../services/cloudchipr.api";
import { RootState } from "../../../../store";
import { recommendationRestoreByResourceFixedCacheKey } from "../../../utils/constants/fixedCacheKeys";
import { refetchDataAfterActionThunk } from "../../refetchDataAfterActionThunk";
import { rightsizingRecommendationsDismissedResourcesAccountIdsByResourceIdSelector } from "../../../selectors/rightsizing/dismissed-resources/rightsizingRecommendationsDismissedResourcesAccountIdsByResourceIdSelector";
import { enqueueSnackbarErrorAlert } from "../../../../../components/pages/common/snackbar-alert/EnqueueSnackbarErrorAlert";

export const restoreRightsizingRecommendationResourceThunk = createAsyncThunk(
  "recommendations/restoreRightsizingRecommendationResourceThunk",
  async (_: void, { dispatch, getState }) => {
    const state = getState() as RootState;

    const {
      patchUsersMeOrganisationsCurrentRecommendationsRightSizingByResource,
    } = cloudChiprApi.endpoints;

    const selectedIds =
      rightsizingRecommendationsDismissedResourcesAccountIdsByResourceIdSelector(
        state,
      );

    if (!selectedIds) {
      return;
    }

    try {
      await dispatch(
        patchUsersMeOrganisationsCurrentRecommendationsRightSizingByResource.initiate(
          { restoreRecommendationByResourceRequest: selectedIds },
          { fixedCacheKey: recommendationRestoreByResourceFixedCacheKey },
        ),
      ).unwrap();

      await dispatch(
        refetchDataAfterActionThunk({
          rightsizing: true,
          rightsizingDismissedResources: true,
        }),
      );
    } catch (e: any) {
      enqueueSnackbarErrorAlert(e?.data?.message);
    }
  },
);
