import { createAsyncThunk } from "@reduxjs/toolkit";
import { setResourceExplorerPathThunk } from "./setResourceExplorerPathThunk";
import { getResourceExplorerDataThunk } from "../getResourceExplorerDataThunk";
import { setResourceExplorerGrouping } from "../../../resourceExplorerSlice";
import { ResourceExplorerPath } from "../../../utils/types/resourceExplorer";
import { possibleGroupingsByCurrentGrouping } from "../../../../../components/pages/resource-explorer/components/resource-explorer-card/utils/constants/groupings";
import { RootState } from "../../../../store";
import {
  FilterTreeGenerationForPathArgs,
  generateFilterTreeForPath,
} from "../../../utils/helpers/generateFilterTreeForPath";
import { resourceExplorerGroupValuesSelector } from "../../../selectors/current-resource-explorer/data/resourceExplorerGroupValuesSelector";
import { getNextGroupingByCurrentGroupingAndProvider } from "../../../../../components/pages/resource-explorer/components/resource-explorer-card/utils/helpers/getNextGroupingByCurrentGroupingAndProvider";

export const changePathOnRowSelectThunk = createAsyncThunk(
  "resourceExplorer/changePathOnRowSelectThunk",
  (args: FilterTreeGenerationForPathArgs, { dispatch, getState }) => {
    const {
      pathName,
      currentPath,
      currentGroupBy,
      provider,
      nextGroupingDependingOnRowValue,
    } = args;

    const state = getState() as RootState;

    if (!currentGroupBy) {
      return currentPath;
    }

    const groupValues = resourceExplorerGroupValuesSelector(state);
    const dimensionId = groupValues?.at(0);

    const filterTree = generateFilterTreeForPath(args, dimensionId);

    const nextGrouping =
      nextGroupingDependingOnRowValue ??
      getNextGroupingByCurrentGroupingAndProvider(provider, currentGroupBy);

    if (!nextGrouping) {
      return currentPath;
    }

    const pathItem: ResourceExplorerPath = {
      filterTree,
      name: pathName,
      groupedBy: nextGrouping,
      groupingOptions: possibleGroupingsByCurrentGrouping[nextGrouping],
    };

    dispatch(setResourceExplorerPathThunk([...currentPath, pathItem]));
    dispatch(setResourceExplorerGrouping({ grouping: nextGrouping }));
    dispatch(getResourceExplorerDataThunk(filterTree));
  },
);
