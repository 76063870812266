import { createAsyncThunk } from "@reduxjs/toolkit";

import { getDashboardsVisibilityHierarchyThunk } from "./getDashboardsVisibilityHierarchyThunk";
import { NavigationItemsVisibilityType } from "../../../navigation/utils/types";
import { cloudChiprApi } from "../../../../services/cloudchipr.api";
import { updateDashboardVisibilityHierarchyFixedCacheKey } from "../../utils/constants/fixedCacheKeys";
import { enqueueSnackbarErrorAlert } from "../../../../components/pages/common/snackbar-alert/EnqueueSnackbarErrorAlert";

interface UpdateDashboardVisibilityHierarchyThunkArg {
  id: string;
  folderId?: string;
  index?: number;
  visibility: NavigationItemsVisibilityType;
  type: "dashboard" | "folder";
}

export const updateDashboardVisibilityHierarchyThunk = createAsyncThunk(
  "dashboard/updateDashboardVisibilityHierarchy",
  async (
    {
      id,
      folderId,
      index,
      visibility,
      type,
    }: UpdateDashboardVisibilityHierarchyThunkArg,
    { dispatch },
  ) => {
    const { patchV2UsersMeOrganisationsCurrentDashboardHierarchy } =
      cloudChiprApi.endpoints;

    try {
      await dispatch(
        patchV2UsersMeOrganisationsCurrentDashboardHierarchy.initiate(
          {
            body: {
              id,
              parent_id: folderId,
              index,
              visibility,
              type,
            },
          },
          {
            fixedCacheKey: updateDashboardVisibilityHierarchyFixedCacheKey,
          },
        ),
      ).unwrap();
      dispatch(getDashboardsVisibilityHierarchyThunk());
    } catch (e) {
      // @ts-expect-error todo: fix api spec;
      enqueueSnackbarErrorAlert(e?.data?.message);
    }
  },
);
