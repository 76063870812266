import { FC, Fragment, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { enqueueSnackbar } from "notistack";
import { useFlag } from "@unleash/proxy-client-react";
import { OffHoursSchedule } from "../../../../../../services/cloudchipr.api";
import { ScheduleListGridActionCellFunctionality } from "../../../../automations/workflows/data-grid/components/columns/actions/ScheduleListGridActionCellFunctionality";
import { WithoutAccountsOffHoursDialog } from "../WithoutAccountsOffHoursDialog";
import { useDialog } from "../../../../../../utils/hooks/useDialog.hook";
import { toggleOffHoursStatusThunk } from "../../../../../../store/schedules/thunks/toggleOffHoursStatusThunk";
import { useAppDispatch, useAppSelector } from "../../../../../../store/hooks";
import { offHoursUpdateLoadingSelector } from "../../../../../../store/schedules/selectors/off-hours/offHoursUpdateLoadingSelector";
import { enqueueSnackbarErrorAlert } from "../../../../common/snackbar-alert/EnqueueSnackbarErrorAlert";

type OffHoursListGridActionCellProps = {
  schedule: OffHoursSchedule;
};

export const OffHoursDataGridActionCell: FC<
  OffHoursListGridActionCellProps
> = ({ schedule }) => {
  const { open, openDialog, closeDialog } = useDialog();
  const enableNewAutomations = useFlag("EnableWorkflowAutomationSinglePage");

  const dispatch = useAppDispatch();
  const loading = useAppSelector(offHoursUpdateLoadingSelector);

  const navigate = useNavigate();

  const editScheduleHandler = useCallback(() => {
    const withoutAccounts = schedule.accounts.every(
      (account) => account.status !== "connected",
    );

    if (withoutAccounts) {
      openDialog();
    } else {
      navigate(
        enableNewAutomations
          ? `/automations/off-hours/edit/${schedule.id}`
          : `/schedule/off-hours/edit/${schedule.id}`,
      );
    }
  }, [schedule, navigate, openDialog, enableNewAutomations]);

  const deleteScheduleHandler = useCallback(async () => {
    try {
      await dispatch(
        toggleOffHoursStatusThunk({
          schedule: schedule as OffHoursSchedule,
          status: "deleted",
        }),
      ).unwrap();

      enqueueSnackbar("Off Hours successfully deleted.", {
        variant: "snackbarAlert",
        AlertSnackBarProps: { severity: "success" },
      });
    } catch (e) {
      // @ts-expect-error todo: fix api spec;
      enqueueSnackbarErrorAlert(e?.data?.message);
    }
  }, [dispatch, schedule]);

  return (
    <Fragment>
      <ScheduleListGridActionCellFunctionality
        name={schedule.name}
        id={schedule.id}
        isLoading={loading}
        onEdit={editScheduleHandler}
        onDelete={deleteScheduleHandler}
        type="offHours"
      />

      {open && (
        <WithoutAccountsOffHoursDialog
          onClose={closeDialog}
          offHours={schedule}
        />
      )}
    </Fragment>
  );
};
