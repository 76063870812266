import { largestCostChangesWidgetByIdSelector } from "./largestCostChangesWidgetByIdSelector";
import { largestCostChangesWidgetNotFoundSelector } from "./largestCostChangesWidgetNotFoundSelector";
import { RootState } from "../../../../store";
import { resourceExplorerViewNameByViewIdSelector } from "../../../../resource-explorer/selectors/all-resource-explorers/resourceExplorerViewNameByViewIdSelector";
import { objectToUrl } from "../../../../../components/utils/helpers/dataToUrlToDataConvertors";
import { WidgetTitleProps } from "../../../../../components/pages/dashboard/utils/types/types";

export const largestCostChangesWidgetTitlePropsSelector = (
  state: RootState,
  widgetId: string,
): WidgetTitleProps | null => {
  const widget = largestCostChangesWidgetByIdSelector(state, widgetId);

  if (!widget) {
    return null;
  }

  const viewName = resourceExplorerViewNameByViewIdSelector(
    state,
    widget.view_id,
  );

  const viewNotFound = largestCostChangesWidgetNotFoundSelector(
    state,
    widgetId,
  );

  const path = objectToUrl([
    {
      groupedBy: widget.grouping,
      groupValues: widget.group_values,
      dates: widget.dates,
      filterTree: undefined,
    },
  ]);

  return {
    groupBy: widget.grouping,
    groupValues: widget.group_values,
    widgetName: widget.name,
    dates: widget.dates,
    sourceData: {
      title: viewNotFound || !viewName ? "" : viewName,
      url: `/resource-explorer/${widget.view_id}?path=${path}`,
    },
  };
};
