import { createAsyncThunk } from "@reduxjs/toolkit";
import { Layouts } from "react-grid-layout";
import { getDashboardByIdThunk } from "./getDashboardByIdThunk";
import { currentDashboardLayoutSelector } from "../../selectors/dashboard/currentDashboardLayoutSelector";
import { RootState } from "../../../store";
import { isLayoutsEqual } from "../../utils/helpers/isLayoutsEqual";
import { updateDashboardByIdThunk } from "../common/updateDashboardByIdThunk";

type UpdateDashboardLayoutByIdThunkArgs = {
  dashboardId: string;
  newLayout: Layouts;
};

export const updateDashboardByIdLayoutThunk = createAsyncThunk(
  "dashboards/updateDashboardByIdLayout",
  async (
    { newLayout, dashboardId }: UpdateDashboardLayoutByIdThunkArgs,
    { dispatch, getState },
  ) => {
    const state = getState() as RootState;

    const currentLayouts = currentDashboardLayoutSelector(state);

    const equal = isLayoutsEqual(currentLayouts, newLayout);

    if (equal) {
      return;
    }

    await dispatch(
      updateDashboardByIdThunk({
        dashboardId,
        body: {
          name: null,
          layout: newLayout,
        },
      }),
    ).unwrap();

    await dispatch(getDashboardByIdThunk(dashboardId));
  },
);
