import {
  ProviderType,
  ResourceType,
} from "../../../../services/cloudchipr.api";
import { RootState } from "../../../store";
import { resourceGroupsByProvider } from "../../../../utils/constants/resources/resourceGroupsByProvider";
import { liveUsageMgmtResourceTypeDataSpendingSelector } from "../resource-type-data/liveUsageMgmtResourceTypeDataSpendingSelector";
import { getIsResourceTypeHiddenByFeatureToggle } from "../../../../components/pages/live-usage-mgmt/utils/helpers/getIsResourceTypeHiddenByFeatureToggle";

export const liveUsageMgmtResourceGroupSpendingSelector = (
  state: RootState,
  provider: ProviderType,
  resourceGroupKey: ResourceType,
) => {
  const resourceGroupTypes = resourceGroupsByProvider
    .get(provider)
    ?.find((resourceGroup) => resourceGroup.value === resourceGroupKey)
    ?.resourceTypes.filter(
      (resourceType) => !getIsResourceTypeHiddenByFeatureToggle(resourceType),
    );

  return (
    resourceGroupTypes
      ?.map((resourceType) =>
        liveUsageMgmtResourceTypeDataSpendingSelector(state, resourceType),
      )
      ?.reduce((acc, currentValue) => acc + currentValue, 0) ?? 0
  );
};
