import { FC, memo } from "react";
import { Stack } from "@mui/material";
import { AccountsViewToggle } from "./AccountsViewToggle";
import { AccountSortBy } from "./AccountSortBy";
import { AccountsSearch } from "./AccountsSearch";
import { AccountsFilterTemplateDropdown } from "./AccountsFilterTemplateDropdown";
import { AccountsPageTabs } from "../../../utils/types/types";
import { ProviderType } from "../../../../../../services/cloudchipr.api";

interface AccountsToolbarProps {
  provider: ProviderType;
  tabValue: AccountsPageTabs;
}

export const AccountsToolbar: FC<AccountsToolbarProps> = memo(
  ({ provider, tabValue }) => {
    return (
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Stack direction="row" spacing={1}>
          <AccountsSearch />
        </Stack>

        <Stack direction="row" spacing={1}>
          <AccountsFilterTemplateDropdown provider={provider} />
          <AccountSortBy tabValue={tabValue} provider={provider} />
          <AccountsViewToggle tabValue={tabValue} provider={provider} />
        </Stack>
      </Stack>
    );
  },
);
