import { createDraftSafeSelector } from "@reduxjs/toolkit";
import { dimensionCategoriesEntriesSelector } from "./dimensionCategoriesEntriesSelector";
import {
  FilterItemNodeWithId,
  FilterTreeNodeWithId,
} from "../../../../components/pages/common/filters-tree/utils/types/common";
import { isTreeNodeFilterGroup } from "../../../../components/pages/common/filters-tree/utils/helpers/isTreeNodeFilterGroup";

type AccountIds = Record<string, string[]>;

export const dimensionUsedAccountsSelector = createDraftSafeSelector(
  [dimensionCategoriesEntriesSelector],
  (categoriesEntries) => {
    const categories = categoriesEntries.map(([_, category]) => category);
    const accountsIds: AccountIds = {};

    categories.forEach((category) => {
      getAccountRecursion(
        category.filters.filter_tree,
        category.name,
        accountsIds,
      );
    });

    return accountsIds;
  },
);

const getAccountRecursion = (
  filterTree: FilterTreeNodeWithId,
  categoryName: string,
  accountsIds: AccountIds,
) => {
  if (!isTreeNodeFilterGroup(filterTree)) {
    addAccountToSet(filterTree, categoryName, accountsIds);
  } else {
    filterTree.items.forEach((item) => {
      getAccountRecursion(item, categoryName, accountsIds);
    });
  }
};

const addAccountToSet = (
  filterTree: FilterItemNodeWithId,
  categoryName: string,
  accountsIds: AccountIds,
) => {
  if (
    filterTree.type !== "account" ||
    filterTree.operator !== "in" ||
    !isStringArray(filterTree.value)
  ) {
    return;
  }

  filterTree.value.forEach((account) => {
    const currentArray = accountsIds[account] ?? [];

    if (!currentArray.includes(categoryName)) {
      accountsIds[account] = [...currentArray, categoryName];
    }
  });
};

const isStringArray = (values: any): values is Array<string> => {
  return (
    Array.isArray(values) &&
    values.every((value) => typeof value?.at(0) === "string")
  );
};
