import { createAsyncThunk } from "@reduxjs/toolkit";
import { closeSnackbar, enqueueSnackbar, SnackbarKey } from "notistack";

import { getDashboardsVisibilityHierarchyThunk } from "./getDashboardsVisibilityHierarchyThunk";
import {
  cloudChiprApi,
  PostV2UsersMeOrganisationsCurrentDashboardsApiArg,
} from "../../../../services/cloudchipr.api";
import { enqueueSnackbarErrorAlert } from "../../../../components/pages/common/snackbar-alert/EnqueueSnackbarErrorAlert";

export const createDashboardVisibilityHierarchyFolderThunkFixedCacheKey =
  "createDashboardVisibilityHierarchyFolderThunk-fixedCacheKey";

interface CreateDashboardHierarchyFolderThunkArgs {
  name: string;
  visibility: PostV2UsersMeOrganisationsCurrentDashboardsApiArg["body"]["visibility"];
}
export const createDashboardVisibilityHierarchyFolderThunk = createAsyncThunk(
  "dashboard/createDashboardVisibilityHierarchyFolder",
  async (
    { name, visibility }: CreateDashboardHierarchyFolderThunkArgs,
    { dispatch },
  ) => {
    const { postV2UsersMeOrganisationsCurrentDashboardFolders } =
      cloudChiprApi.endpoints;

    try {
      const response = await dispatch(
        postV2UsersMeOrganisationsCurrentDashboardFolders.initiate(
          {
            body: {
              name: name,
              visibility,
            },
          },
          {
            fixedCacheKey:
              createDashboardVisibilityHierarchyFolderThunkFixedCacheKey,
          },
        ),
      ).unwrap();

      if (response) {
        dispatch(getDashboardsVisibilityHierarchyThunk());
        const snackbarId: SnackbarKey = enqueueSnackbar(
          "Folder successfully created.",
          {
            variant: "snackbarAlert",
            AlertSnackBarProps: {
              severity: "success",
              onClose: () => closeSnackbar(snackbarId),
            },
          },
        );
      }
    } catch (e) {
      // @ts-expect-error todo: fix api spec;
      enqueueSnackbarErrorAlert(e?.data?.message);
    }
  },
);
