import { ProviderType, ResourceType } from "../../../services/cloudchipr.api";

export type ResourceGroupType = {
  value: ResourceType;
  resourceTypes: ResourceType[];
  actionableResourceTypes: ResourceType[];
  expandable?: boolean;
  hierarchy?: Partial<Record<ResourceType, ResourceType[]>>;
};

const awsResourceGroups: ResourceGroupType[] = [
  {
    value: "ec2",
    resourceTypes: ["ec2", "asg", "ebs", "ebs_snapshot", "eip", "elb"],
    actionableResourceTypes: [
      "ec2",
      "asg",
      "ebs",
      "ebs_snapshot",
      "eip",
      "elb",
    ],
    expandable: true,
  },
  {
    value: "s3",
    resourceTypes: ["s3"],
    actionableResourceTypes: ["s3"],
  },
  {
    value: "rds",
    resourceTypes: ["rds", "rds_snapshot_source"],
    actionableResourceTypes: ["rds", "rds_snapshot_source"],
    expandable: true,
  },
  {
    value: "eks",
    resourceTypes: ["eks"],
    actionableResourceTypes: ["eks"],
  },
  {
    value: "elc",
    resourceTypes: ["elc"],
    actionableResourceTypes: ["elc"],
  },
  {
    value: "dynamo_db_table",
    resourceTypes: ["dynamo_db_table", "dax_cluster", "dynamo_db_backup"],
    actionableResourceTypes: [
      "dynamo_db_table",
      "dax_cluster",
      "dynamo_db_backup",
    ],
    expandable: true,
  },
  {
    value: "nat_gateway",
    resourceTypes: ["nat_gateway"],
    actionableResourceTypes: ["nat_gateway"],
    expandable: true,
  },

  {
    value: "ecs",
    resourceTypes: ["ecs"],
    actionableResourceTypes: ["ecs", "ecs_workload"],
    hierarchy: {
      ecs: ["ecs", "ecs_workload"],
    },
  },
];

const gcpResourceGroups: ResourceGroupType[] = [
  {
    value: "vm",
    resourceTypes: ["vm", "mig", "disk"],
    actionableResourceTypes: ["vm", "mig", "disk"],
    expandable: true,
  },
  {
    value: "ip",
    resourceTypes: ["ip", "lb"],
    actionableResourceTypes: ["ip", "lb"],
    expandable: true,
  },
  {
    value: "sql",
    actionableResourceTypes: ["sql"],
    resourceTypes: ["sql"],
  },
  {
    value: "gke",
    resourceTypes: ["gke"],
    actionableResourceTypes: ["gke"],
  },
];

const azureResourceGroups: ResourceGroupType[] = [
  {
    value: "az_vm",
    resourceTypes: ["az_vm"],
    actionableResourceTypes: ["az_vm"],
  },
  {
    value: "az_disk",
    resourceTypes: ["az_disk"],
    actionableResourceTypes: ["az_disk"],
  },
  {
    value: "az_ip",
    resourceTypes: ["az_ip"],
    actionableResourceTypes: ["az_ip"],
  },
  {
    value: "az_lb",
    resourceTypes: ["az_lb"],
    actionableResourceTypes: ["az_lb"],
  },
  {
    value: "az_sql",
    resourceTypes: ["az_sql"],
    actionableResourceTypes: ["az_sql"],
  },
  {
    value: "az_vmss",
    resourceTypes: ["az_vmss"],
    actionableResourceTypes: ["az_vmss"],
  },
  {
    value: "az_aks",
    resourceTypes: ["az_aks"],
    actionableResourceTypes: ["az_aks"],
  },
];

export const resourceGroupsByProvider: Map<ProviderType, ResourceGroupType[]> =
  new Map([
    ["aws", awsResourceGroups],
    ["gcp", gcpResourceGroups],
    ["azure", azureResourceGroups],
  ]);

export const allResourcesByProvider: Map<ProviderType, ResourceType[]> =
  new Map([
    [
      "aws",
      awsResourceGroups
        .map((resourceGroup) => resourceGroup.resourceTypes)
        .flat(),
    ],
    [
      "gcp",
      gcpResourceGroups
        .map((resourceGroup) => resourceGroup.resourceTypes)
        .flat(),
    ],
    [
      "azure",
      azureResourceGroups
        .map((resourceGroup) => resourceGroup.resourceTypes)
        .flat(),
    ],
  ]);
