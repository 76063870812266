import { FC } from "react";
import { Typography } from "@mui/material";
import { useAppSelector } from "../../../../../../../../store/hooks";
import { workflowsDataLoadingSelector } from "../../../../../../../../store/automations/selectros/workflow/list-data/workflowsDataLoadingSelector";

export const ApiKeysListGridNoRowsComponent: FC = () => {
  const loading = useAppSelector(workflowsDataLoadingSelector);

  if (loading) {
    return null;
  }

  return <Typography variant="body1">No matching results found.</Typography>;
};
