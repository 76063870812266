import { createAsyncThunk } from "@reduxjs/toolkit";
import { taskManagementGetTasksThunk } from "./taskManagementGetTasksThunk";
import {
  cloudChiprApi,
  PatchUsersMeOrganisationsCurrentTasksApiArg,
} from "../../../../services/cloudchipr.api";
import { taskUpdateFixedCacheKey } from "../../utils/constants/fixedCacheKeys";
import { enqueueSnackbarErrorAlert } from "../../../../components/pages/common/snackbar-alert/EnqueueSnackbarErrorAlert";

export const taskManagementUpdateTaskThunk = createAsyncThunk(
  "taskManagement/taskManagementUpdateTask",
  async (
    payload: PatchUsersMeOrganisationsCurrentTasksApiArg["body"],
    { dispatch },
  ) => {
    const { patchUsersMeOrganisationsCurrentTasks } = cloudChiprApi.endpoints;

    try {
      await dispatch(
        patchUsersMeOrganisationsCurrentTasks.initiate(
          { body: payload },
          { fixedCacheKey: taskUpdateFixedCacheKey },
        ),
      ).unwrap();

      await dispatch(taskManagementGetTasksThunk()).unwrap();
    } catch (e) {
      // @ts-expect-error todo: fix api spec;
      enqueueSnackbarErrorAlert(e?.data?.message);
    }
  },
);
