import { FC } from "react";
import { Stack, Typography } from "@mui/material";
import { TaskPropertiesSettingsDrawerActions } from "./TaskPropertiesSettingsDrawerActions";

interface TaskPropertiesSettingsDrawerHeaderProps {
  onClose(): void;
}

export const TaskPropertiesSettingsDrawerHeader: FC<
  TaskPropertiesSettingsDrawerHeaderProps
> = ({ onClose }) => {
  return (
    <Stack
      p={1.5}
      top={0}
      bgcolor="white"
      zIndex="drawer"
      direction="row"
      position="sticky"
      borderBottom={1}
      alignItems="center"
      borderColor="grey.300"
      justifyContent="space-between"
    >
      <Typography fontWeight="medium">Edit Properties</Typography>

      <Stack direction="row" spacing={1} alignItems="center">
        <TaskPropertiesSettingsDrawerActions
          closeIconButton
          onClose={onClose}
        />
      </Stack>
    </Stack>
  );
};
