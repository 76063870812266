import { SvgIcon } from "@mui/material";

const LbSvgIcon = () => {
  return (
    <SvgIcon>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M16.6667 10H15V13.3333H16.6667V10Z" fill="#9E9E9E" />
        <path d="M10.8327 10H9.16602V13.3333H10.8327V10Z" fill="#9E9E9E" />
        <path d="M4.9987 10H3.33203V13.3333H4.9987V10Z" fill="#9E9E9E" />
        <path
          d="M10.8327 9.16927H9.16602V5.83594H10.8327V9.16927Z"
          fill="#757575"
        />
        <path
          d="M16.6654 9.16797H3.33203V10.8346H16.6654V9.16797Z"
          fill="#9E9E9E"
        />
        <path d="M15 1.66797H5V5.83464H15V1.66797Z" fill="#BDBDBD" />
        <path d="M15 1.66797H10V5.83464H15V1.66797Z" fill="#9E9E9E" />
        <path
          d="M18.332 13.3359H13.332V18.3359H18.332V13.3359Z"
          fill="#BDBDBD"
        />
        <path
          d="M6.66602 13.3359H1.66602V18.3359H6.66602V13.3359Z"
          fill="#BDBDBD"
        />
        <path
          d="M6.66602 13.3359H4.16602V18.3359H6.66602V13.3359Z"
          fill="#9E9E9E"
        />
        <path d="M12.5 13.3359H7.5V18.3359H12.5V13.3359Z" fill="#BDBDBD" />
        <path d="M12.5 13.3359H10V18.3359H12.5V13.3359Z" fill="#9E9E9E" />
        <path
          d="M18.332 13.3359H15.832V18.3359H18.332V13.3359Z"
          fill="#9E9E9E"
        />
      </svg>
    </SvgIcon>
  );
};

LbSvgIcon.muiName = "LbSvgIcon";

export default LbSvgIcon;
