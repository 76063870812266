import { FC } from "react";
import { IconButton, Stack } from "@mui/material";
import { Tune } from "@mui/icons-material";
import { RenderToolbarProps } from "../../../../../../../../../../storybook/data-grid/utils/types/prop-types";
import { ColumnsSwitcher } from "../../../../../../../../../../storybook/data-grid/toolbar/ColumnsSwitcher";
import { useMenuHook } from "../../../../../../../../../../utils/hooks/useMenu.hook";
import { CsvDataDownloader } from "../toolbar/CsvDataDownloader";
import { ResourceType } from "../../../../../../../../../../services/cloudchipr.api";
import { useAppSelector } from "../../../../../../../../../../store/hooks";
import { getFirstParentInHierarchyResourceType } from "../../../../../../../../../../utils/helpers/resources/getFirstParentInHierarchyResourceType";
import { LiveUsageMgmtResourcesCount } from "../toolbar/LiveUsageMgmtResourcesCount";
import { ResourceFiltersTextView } from "../../../../resource-filters/ResourceFiltersTextView";
import { liveUsageMgmtApplyingFilterTemplateFiltersByResourceTypeSelector } from "../../../../../../../../../../store/live-usage-mgmt/selectors/liveUsageMgmtApplyingFilterTemplateFiltersByResourceTypeSelector";
import { liveUsageMgmtAppliedFiltersByResourceTypeSelector } from "../../../../../../../../../../store/live-usage-mgmt/selectors/liveUsageMgmtAppliedFiltersByResourceTypeSelector";
import { isLiveUsageMgmtFilterAppliedToResourceSelector } from "../../../../../../../../../../store/live-usage-mgmt/selectors/isLiveUsageMgmtFilterAppliedToResourceSelector";
import { liveUsageMgmtResourceTypeDataLoadingSelector } from "../../../../../../../../../../store/live-usage-mgmt/selectors/resource-type-data/liveUsageMgmtResourceTypeDataLoadingSelector";

interface ResourcesDataGridSubRowToolbarProps extends RenderToolbarProps {
  totalCount: number;
  resourceType: ResourceType;
  count: number;

  csvData?: Record<string, string | number>[] | null;
}

export const ResourcesDataGridSubRowToolbar: FC<
  ResourcesDataGridSubRowToolbarProps
> = ({ table, totalCount, count, resourceType, csvData }) => {
  const { anchor, openMenu, closeMenu, open } = useMenuHook();

  const localResourceType =
    getFirstParentInHierarchyResourceType(resourceType) ?? resourceType;
  const accountApplyingFilters = useAppSelector((state) =>
    liveUsageMgmtApplyingFilterTemplateFiltersByResourceTypeSelector(
      state,
      localResourceType,
    ),
  );
  const appliedFilters = useAppSelector((state) =>
    liveUsageMgmtAppliedFiltersByResourceTypeSelector(state, localResourceType),
  );
  const isLoading = useAppSelector((state) =>
    liveUsageMgmtResourceTypeDataLoadingSelector(state, resourceType),
  );
  const filtersApplied = useAppSelector((state) =>
    isLiveUsageMgmtFilterAppliedToResourceSelector(state, localResourceType),
  );

  if (!accountApplyingFilters || !appliedFilters) {
    return null;
  }

  return (
    <Stack direction="row" justifyContent="space-between" py={0.5}>
      <Stack direction="row" spacing={0.5}>
        <ResourceFiltersTextView resourceType={localResourceType} />
        <LiveUsageMgmtResourcesCount
          resourceType={resourceType}
          dataCount={count}
          isLoading={isLoading}
          totalDataCount={totalCount}
          filtersApplied={filtersApplied}
        />
      </Stack>

      <Stack justifyContent="flex-end" direction="row" spacing={1}>
        <CsvDataDownloader resourceType={resourceType} data={csvData} />

        <IconButton onClick={openMenu} size="small">
          <Tune fontSize="small" />
        </IconButton>

        <ColumnsSwitcher
          open={open}
          onClose={closeMenu}
          anchor={anchor}
          table={table}
        />
      </Stack>
    </Stack>
  );
};
