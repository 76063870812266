import { FC, Fragment } from "react";
import moment from "moment";
import { Stack, Typography } from "@mui/material";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { formatDate } from "../../../../../../../../utils/helpers/date-time/datetime-format";

interface LocalDateCellProps {
  date?: string | null;
  isDateExpired?: boolean;
}

export const LocalDateCell: FC<LocalDateCellProps> = ({
  date,
  isDateExpired,
}) => {
  if (!date) {
    return "-";
  }
  const localDate = moment.utc(date).local();

  return (
    <Stack
      justifyContent="center"
      alignItems="start"
      height="100%"
      overflow="hidden"
      spacing={0.5}
    >
      {isDateExpired ? (
        <Fragment>
          <Stack direction="row" spacing={1} alignItems="center">
            <WarningAmberIcon color="error" fontSize="small" />
            <Typography variant="body2" color="error.main">
              Expired
            </Typography>
          </Stack>
          <Typography variant="body2" color="text.secondary">
            {formatDate(localDate, { type: "date" })}{" "}
            {formatDate(localDate, { type: "time" })}
          </Typography>
        </Fragment>
      ) : (
        <Fragment>
          <Typography variant="body2">
            {formatDate(localDate, { type: "date" })}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {formatDate(localDate, { type: "time" })}
          </Typography>
        </Fragment>
      )}
    </Stack>
  );
};
