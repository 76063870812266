import { FC, Fragment, useCallback, useMemo, useRef, useState } from "react";
import {
  Grow,
  Paper,
  Popper,
  Button,
  MenuItem,
  ClickAwayListener,
  Tooltip,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import MenuList from "@mui/material/MenuList";
import ButtonGroup from "@mui/material/ButtonGroup";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import { ResourceExplorerActionType } from "../../../utils/types/types";
import { useAppSelector } from "../../../../../../store/hooks";
import { resourceExplorerProtectionDetailsSelector } from "../../../../../../store/resource-explorer/selectors/current-resource-explorer/resourceExplorerProtectionDetailsSelector";

interface ResourceExplorerAppBarSplitButtonProps {
  onViewAction: (type: ResourceExplorerActionType) => void;
  loading: boolean;
  disabled: Record<ResourceExplorerActionType, boolean>;
}
export const ResourceExplorerAppBarSplitButton: FC<
  ResourceExplorerAppBarSplitButtonProps
> = ({ onViewAction, loading, disabled }) => {
  const anchorRef = useRef<HTMLDivElement>(null);

  const [popperOpen, setPopperOpen] = useState(false);
  const protectionDetails = useAppSelector(
    resourceExplorerProtectionDetailsSelector,
  );

  const tooltipTitle = useMemo(() => {
    if (disabled.update) {
      return "";
    }

    if (protectionDetails?.is_protected) {
      return "Save as New";
    }

    return "Save";
  }, [disabled.update, protectionDetails?.is_protected]);

  const dropdownButtonClickHandler = useCallback(() => {
    setPopperOpen(true);
  }, [setPopperOpen]);

  const handleClose = useCallback(() => {
    setPopperOpen(false);
  }, []);

  const saveButtonClickHandler = useCallback(() => {
    onViewAction("update");
    setPopperOpen(false);
  }, [onViewAction]);

  const saveAsNewButtonClickHandler = useCallback(() => {
    onViewAction("create");
    setPopperOpen(false);
  }, [onViewAction]);

  return (
    <Fragment>
      <ButtonGroup
        variant="contained"
        ref={anchorRef}
        aria-label="split button"
      >
        <Tooltip title={tooltipTitle}>
          <LoadingButton
            variant="contained"
            onClick={
              protectionDetails?.is_protected
                ? saveAsNewButtonClickHandler
                : saveButtonClickHandler
            }
            loading={loading}
            disabled={disabled.update}
          >
            <SaveOutlinedIcon />
          </LoadingButton>
        </Tooltip>
        {!protectionDetails?.is_protected && (
          <Button
            disabled={loading || (disabled.update && disabled.create)}
            size="small"
            aria-haspopup="menu"
            onClick={dropdownButtonClickHandler}
          >
            <ArrowDropDownIcon />
          </Button>
        )}
      </ButtonGroup>
      <Popper
        sx={{
          zIndex: "appBar",
        }}
        open={popperOpen}
        anchorEl={anchorRef.current}
        transition
        disablePortal
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" autoFocusItem>
                  <MenuItem
                    onClick={saveButtonClickHandler}
                    disabled={disabled.update}
                  >
                    Save
                  </MenuItem>
                  <MenuItem
                    onClick={saveAsNewButtonClickHandler}
                    disabled={disabled.create}
                  >
                    Save as new
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Fragment>
  );
};
