import { FC, useCallback } from "react";
import Dialog from "@mui/material/Dialog";
import {
  Box,
  Chip,
  DialogContent,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import { useSnackbar } from "notistack";
import * as yup from "yup";
import { useEffectOnceWhen } from "rooks";
import AttachmentIcon from "@mui/icons-material/Attachment";
import { CSVLink } from "react-csv";
import { CreateEmailMessageEditor } from "./components/CreateEmailMessageEditor";
import { EmailsSelection } from "./components/email-selection/EmailsSelection";
import { getEmailsValidationSchema } from "./utils/validation";
import { convertCsvDataForBackend } from "./utils/convertCsvDataForBackend";
import { CreateEmailResourcesTableV2 } from "./components/resources-table/CreateEmailResourcesTableV2";
import { CreateIntegrationMessageFormActions } from "../common/CreateIntegrationMessageFormActions";
import {
  Emails,
  PostV2UsersMeEmailsMessageApiArg,
  ResourceType,
  usePostV2UsersMeEmailsMessageMutation,
} from "../../../../../services/cloudchipr.api";
import { DialogTitleClosable } from "../../../dialog-utils/DialogTitleClosable";
import { useAppSelector } from "../../../../../store/hooks";
import { RootState } from "../../../../../store/store";
import { liveUsageMgmtSelectedResourcesByResourceTypeCsvDataSelector } from "../../../../../store/live-usage-mgmt/selectors/resource-type-data/liveUsageMgmtSelectedResourcesByResourceTypeCsvDataSelector";
import { emailMessageSelectorV2 } from "../../../../../store/integrations/selectors/email/emailMessageSelectorV2";
import { enqueueSnackbarErrorAlert } from "../../../../pages/common/snackbar-alert/EnqueueSnackbarErrorAlert";

interface CreateEmailMessageDialogProps {
  resourceType: ResourceType;
  onClose(): void;
}

const validationSchema = yup.object({
  body: yup.object({
    emails: getEmailsValidationSchema(),

    message: yup.object({
      subject: yup.string().required("This field is required"),
    }),
  }),
});

const initialValues: PostV2UsersMeEmailsMessageApiArg = {
  body: {
    message: null,
    emails: { to: [] },
    resources: [],
  },
};

export const CreateEmailMessageDialogV2: FC<CreateEmailMessageDialogProps> = ({
  resourceType,
  onClose,
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const message = useAppSelector((state) => {
    return emailMessageSelectorV2(state, resourceType);
  });

  const csvSelector = useCallback(
    (state: RootState) =>
      liveUsageMgmtSelectedResourcesByResourceTypeCsvDataSelector(
        state,
        resourceType,
      ),
    [resourceType],
  );

  const resourcesCsv = useAppSelector(csvSelector);

  const [createMessage, { isLoading }] =
    usePostV2UsersMeEmailsMessageMutation();

  const formik = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      try {
        await createMessage(values).unwrap();

        enqueueSnackbar("Email message successfully sent.", {
          variant: "snackbarAlert",
          AlertSnackBarProps: { severity: "success" },
        });

        onClose();
      } catch (e) {
        // @ts-expect-error todo: fix api spec;
        enqueueSnackbarErrorAlert(e?.data?.message);
      }
    },
  });

  const {
    isValid,
    values,
    submitForm,
    setFieldValue,
    setTouched,
    handleChange,
    errors,
    touched,
  } = formik;

  const messageChangeHandler = useCallback(
    (value: string) => setFieldValue("body.message.body", value),
    [setFieldValue],
  );

  const resourcesChangeHandler = useCallback(
    (csv: Record<string, string | number>[]) => {
      const converted = convertCsvDataForBackend(resourcesCsv, csv);

      setFieldValue("body.resources", converted);
    },

    [setFieldValue, resourcesCsv],
  );

  const emailsChangeHandler = useCallback(
    (emails: Emails) => {
      setTouched({
        body: {
          emails: {
            to: !!emails.to?.length,
            cc: !!emails.cc?.length,
            bcc: !!emails.bcc?.length,
          },
        },
      });
      setFieldValue("body.emails", emails);
    },
    [setFieldValue, setTouched],
  );

  useEffectOnceWhen(
    () => {
      setFieldValue("body.message", {
        body: message,
        subject: "Please Review the Attached Resources",
      });

      setFieldValue(
        "body.resources",
        convertCsvDataForBackend(resourcesCsv, resourcesCsv),
      );
    },
    !!message && !!resourcesCsv?.length && !!resourcesCsv,
  );

  return (
    <Dialog open maxWidth="md" fullWidth>
      <DialogTitleClosable title="Send Email" onClose={onClose} />

      <DialogContent dividers>
        <Stack spacing={3}>
          <EmailsSelection
            toEmails={values.body.emails.to}
            ccEmails={values.body.emails.cc}
            bccEmails={values.body.emails.bcc}
            onEmailsChange={emailsChangeHandler}
            errors={touched.body?.emails && errors.body?.emails}
          />

          <TextField
            fullWidth
            size="small"
            sx={{ mt: 2 }}
            label="Subject"
            onChange={handleChange}
            name="body.message.subject"
            value={values.body.message?.subject}
            error={!!errors?.body?.message && !!touched?.body?.message}
          />
        </Stack>

        <Stack p={2} my={3} bgcolor="grey.50" borderRadius={1}>
          <CreateEmailMessageEditor
            onChange={messageChangeHandler}
            value={values.body?.message?.body}
          />

          <CreateEmailResourcesTableV2
            resourceType={resourceType}
            onResourcesChange={resourcesChangeHandler}
          />

          <Box width="fit-content">
            <CSVLink
              filename="Resources.csv"
              data={resourcesCsv ?? []}
              enclosingCharacter=""
              separator=","
            >
              <Chip
                sx={{ mt: 2, cursor: "pointer" }}
                icon={<AttachmentIcon color="action" />}
                label={<Typography color="primary">Resources.csv</Typography>}
              />
            </CSVLink>
          </Box>
        </Stack>
      </DialogContent>

      <CreateIntegrationMessageFormActions
        disabled={!isValid}
        onCancel={onClose}
        onSubmit={submitForm}
        isLoading={isLoading}
      />
    </Dialog>
  );
};
