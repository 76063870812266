import { createAsyncThunk } from "@reduxjs/toolkit";
import { applyLiveUsageMgmtFilterTemplateThunk } from "./applyLiveUsageMgmtFilterTemplateThunk";
import { RootState } from "../../../store";
import { AccountId } from "../../../../services/cloudchipr.api";
import { liveUsageMgmtProviderSelector } from "../../selectors/store-selectors/liveUsageMgmtProviderSelector";
import { findAndApplyFilterTemplatesThunk } from "../filters/findAndApplyLiveUsageMgmtFiltersThunk";
import { getAccountsThunk } from "../../../accounts/thunks/getAccountsThunk";
import { enqueueSnackbarErrorAlert } from "../../../../components/pages/common/snackbar-alert/EnqueueSnackbarErrorAlert";

interface PatchLiveUsageMgmtFilterTemplateByAccountIdsThunkArgs {
  accountIds: AccountId[];
  filterTemplateId: string;
  name: string;
}
export const patchLiveUsageMgmtFilterTemplateByAccountIdsThunk =
  createAsyncThunk(
    "liveUsageMgmt/patchLiveUsageMgmtFilterTemplateByAccountIds",
    async (
      {
        accountIds,
        filterTemplateId,
        name,
      }: PatchLiveUsageMgmtFilterTemplateByAccountIdsThunkArgs,
      { dispatch, getState },
    ) => {
      const state = getState() as RootState;

      const provider = liveUsageMgmtProviderSelector(state);

      if (!accountIds || !provider) {
        return;
      }

      try {
        const response = await dispatch(
          applyLiveUsageMgmtFilterTemplateThunk({
            filterTemplateId,
            accountIds,
            name,
          }),
        );

        await dispatch(getAccountsThunk());

        dispatch(findAndApplyFilterTemplatesThunk());

        return response;
      } catch (e) {
        // @ts-expect-error todo: fix api spec;
        enqueueSnackbarErrorAlert(e?.data?.message);
      }
    },
  );
