import { ColDef } from "ag-grid-community";

export const agGridEmptyColumnToFitEmptySpace: ColDef = {
  flex: 1,
  colId: "toFitEmptySpace",
  headerName: "",
  sortable: false,
  enableRowGroup: false,
  cellDataType: false,
  resizable: false,
  unSortIcon: false,
  filter: false,

  suppressPaste: true,
  suppressMovable: true,
  suppressAutoSize: true,
  suppressSizeToFit: true,
  suppressNavigable: true,
  suppressFillHandle: true,
  suppressColumnsToolPanel: true,
  suppressSpanHeaderHeight: true,
  suppressFiltersToolPanel: true,
  suppressHeaderMenuButton: true,
  suppressHeaderContextMenu: true,
  suppressHeaderFilterButton: true,
  suppressFloatingFilterButton: true,
  suppressKeyboardEvent: () => true,
  suppressHeaderKeyboardEvent: () => true,
};
