import { allAccountsGroupedByProviderAndOrgSelector } from "./all-providers/allAccountsGroupedByProviderAndOrgSelector";
import { RootState } from "../../store";
import { getProviderName } from "../../../utils/helpers/providers/getProviderName";
import { ProviderType } from "../../../services/cloudchipr.api";
import {
  noOrgAccountOrgId,
  noOrgAccountOrgName,
} from "../../../components/pages/accounts-group/utils/helpers/noOrganizationConstants";
import { generateAccountIdsQueryParams } from "../../../components/pages/live-usage-mgmt/utils/helpers/generateAccountIdsQueryParams";

export const liveUsageNavigationItemsSelector = (state: RootState) => {
  const allAccountsGroupedByProviderAndOrg =
    allAccountsGroupedByProviderAndOrgSelector(state);

  const items: {
    provider: ProviderType;
    org: string;
    orgId: string;
    accountIdsQueryParam: string;
  }[] = [];

  if (allAccountsGroupedByProviderAndOrg) {
    Object.entries(allAccountsGroupedByProviderAndOrg).forEach(
      ([key, accountsGroupedByOrg]) => {
        const provider = key as ProviderType;
        const organizations = Object.keys(accountsGroupedByOrg);

        if (organizations.length > 1) {
          items.push(
            ...organizations.map((org) => {
              const orgName =
                org === noOrgAccountOrgId
                  ? noOrgAccountOrgName.get(provider)!
                  : org;
              const activeAccountIds: string[] | undefined =
                accountsGroupedByOrg[org]
                  ?.filter((account) => account.status === "connected")
                  ?.map((account) => account.id);

              return {
                provider,
                org: orgName,
                orgId: org,
                accountIdsQueryParam:
                  generateAccountIdsQueryParams(activeAccountIds),
              };
            }),
          );
        } else {
          const singleOrgId =
            Object.keys(accountsGroupedByOrg).at(0) ?? noOrgAccountOrgId;
          const activeAccountIds: string[] | undefined = accountsGroupedByOrg[
            organizations?.at(0) ?? ""
          ]
            ?.filter((account) => account.status === "connected")
            ?.map((account) => account.id);

          items.push({
            provider,
            org: getProviderName(provider, {
              nameAndTitle: true,
              capitalize: true,
              plural: true,
            }),
            orgId: singleOrgId,
            accountIdsQueryParam:
              generateAccountIdsQueryParams(activeAccountIds),
          });
        }
      },
    );
  }

  return items;
};
