import { FC, useCallback, useEffect } from "react";
import { MenuItem, Select } from "@mui/material";
import { SelectChangeEvent } from "@mui/material/Select";
import { useParams } from "react-router-dom";
import { useWillUnmount } from "rooks";
import { ArrowUpward } from "@mui/icons-material";
import { AccountsPageTabs } from "../../../utils/types/types";
import {
  ProviderType,
  useGetUsersMeCurrentPreferenceByKeyQuery,
} from "../../../../../../services/cloudchipr.api";
import { useAppDispatch, useAppSelector } from "../../../../../../store/hooks";
import { accountsSortBySelector } from "../../../../../../store/accounts/selectors/slice-data/accountsSortBySelector";
import { connectedAccountsSelector } from "../../../../../../store/accounts/selectors/connected-accounts/connectedAccountsSelector";
import {
  accountsDefaultSort,
  AccountSortByFields,
  setAccountSortBy,
} from "../../../../../../store/accounts/accountsSlice";
import { accountsSortByLoadingSelector } from "../../../../../../store/accounts/selectors/loadings/accountsSortByLoadingSelector";
import { getAccountsOrderingSettingsKey } from "../../../../live-usage-mgmt/utils/hooks/useAccountsDnD";
import { useAccountsSortBy } from "../../../../live-usage-mgmt/utils/hooks/useAccountsSortBy.hook";

interface AccountSortByProps {
  provider: ProviderType;
  tabValue: AccountsPageTabs;
}

export const AccountSortBy: FC<AccountSortByProps> = ({
  provider,
  tabValue,
}) => {
  const { orgId } = useParams();
  const dispatch = useAppDispatch();

  const key = getAccountsOrderingSettingsKey(provider, tabValue, orgId);
  const { data: accountsOrdering, isLoading: orderingLoading } =
    useGetUsersMeCurrentPreferenceByKeyQuery({
      key,
    });

  const sortBy = useAppSelector(accountsSortBySelector);
  const sortByLoading = useAppSelector((state) =>
    accountsSortByLoadingSelector(state, provider, tabValue, orgId),
  );

  const accounts = useAppSelector(connectedAccountsSelector);
  const { accountSortBy, saveAccountSortBy } = useAccountsSortBy(
    provider,
    tabValue,
    orgId,
  );

  const accountsSortChangeHandler = useCallback(
    (event: SelectChangeEvent<AccountSortByFields>) => {
      const sort = event.target.value as AccountSortByFields;

      dispatch(setAccountSortBy(sort));
      saveAccountSortBy(sort);
    },
    [dispatch, saveAccountSortBy],
  );

  useEffect(() => {
    if (sortBy || sortByLoading || orderingLoading) {
      return;
    }

    if (!!accountsOrdering && !accountSortBy) {
      dispatch(setAccountSortBy("custom"));
      return;
    }

    if (accountSortBy) {
      dispatch(setAccountSortBy(accountSortBy));
      return;
    }

    dispatch(setAccountSortBy(accountsDefaultSort));
  }, [
    dispatch,
    sortBy,
    sortByLoading,
    orderingLoading,
    accountsOrdering,
    accountSortBy,
  ]);

  useWillUnmount(() => {
    dispatch(setAccountSortBy(null));
  });

  if (!accounts || !sortBy || accounts?.length <= 1) {
    return null;
  }

  return (
    <Select
      size="small"
      startAdornment={
        <ArrowUpward fontSize="small" color="action" sx={{ mr: 1 }} />
      }
      sx={{ minWidth: 210, bgcolor: "white" }}
      value={sortBy}
      onChange={accountsSortChangeHandler}
    >
      {!!accountsOrdering && <MenuItem value="custom">User Defined</MenuItem>}

      <MenuItem value="ems">Filtered resources by $</MenuItem>
      <MenuItem value="total_costs">Tracked total by $</MenuItem>
      <MenuItem value="provider_account_name">Account Name</MenuItem>
      <MenuItem value="provider_account_id">Account ID</MenuItem>
    </Select>
  );
};
