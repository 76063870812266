import { FC, useMemo } from "react";
import {
  Cell,
  Legend,
  Pie,
  PieChart as RechartsPieChart,
  PieLabelRenderProps,
  Tooltip,
  TooltipProps,
} from "recharts";
import { Box, Stack } from "@mui/material";
import { PieTooltipContent } from "./PieTooltipContent";
import { PieLegendContent, PieLegendContentProps } from "./PieLegentContent";
import { PieChartLoadingOrEmptyState } from "./PieChartLoadingOrEmptyState";
import { useMultiTypeChartContext } from "../../../MultiTypeChartProvider";
import { ChartsTooltipProps } from "../../common/ChartsTooltipContent";
import { chartDataPointKey } from "../../../utils/constants/constants";

interface PieChartProps {
  highlight: boolean;
  selectable: boolean;
  loading: boolean;
  tooltipProps?: ChartsTooltipProps;
  TooltipContent?: FC<TooltipProps<any, any>>;
  LegendContent?: FC<PieLegendContentProps>;
  emptyText?: string;
}

export const PieChart: FC<PieChartProps> = ({
  highlight,
  selectable,
  tooltipProps,
  loading,
  TooltipContent,
  LegendContent,
  emptyText,
}) => {
  const { data, keys, colors } = useMultiTypeChartContext();

  const normalizedData = useMemo(() => {
    const totals = data.reduce(
      (acc, item) => {
        Object.entries(item).forEach(([key, value]) => {
          if (key !== chartDataPointKey) {
            acc[key] = (acc[key] ?? 0) + +(value ?? 0);
          }
        });

        return acc;
      },
      {} as Record<string, number>,
    );

    return Object.keys(totals).map((key) => {
      return { name: key, value: totals[key] };
    });
  }, [data]);

  const chartVisibleData = useMemo(() => {
    const formattedData = normalizedData.reduce(
      (result, item) => {
        if (keys.visibleKeys.includes(item.name)) {
          result.push({
            ...item,
            label: item.value,
            value: Math.abs(item.value),
          });
        }
        return result;
      },
      [] as { name: string; value: number; label: number }[],
    );

    return formattedData.sort((a, b) => b.value - a.value);
  }, [normalizedData, keys.visibleKeys]);

  const total = useMemo(() => {
    return normalizedData.reduce(
      (sum: number, { value }) => sum + Math.abs(value),
      0,
    );
  }, [normalizedData]);

  const isEmpty = total === 0;

  if (isEmpty) {
    return (
      <PieChartLoadingOrEmptyState loading={loading} emptyText={emptyText} />
    );
  }

  const legendContentProps = {
    colors,
    selectable,
    highlightable: highlight,
    data: normalizedData,
    keys: keys.allKeys,
    visibleKeys: keys.visibleKeys,
    setVisibleKeys: keys.setVisibleKeys,
  };

  return (
    <Stack direction="row" alignItems="center" justifyContent="space-evenly">
      <Box>
        <RechartsPieChart width={300} height={300}>
          <Pie
            cx="50%"
            cy="50%"
            labelLine={false}
            data={chartVisibleData}
            label={renderCustomizedLabel}
            innerRadius={70}
            outerRadius={130}
            dataKey="value"
            isAnimationActive={false}
          >
            {chartVisibleData.map((entry, index) => {
              const color = keys.visibleKeys.includes(entry.name)
                ? colors[entry.name]
                : "transparent";

              const positive = entry.value > 0;

              return (
                <Cell
                  key={index}
                  stroke={color}
                  strokeWidth={2}
                  strokeLinecap="round"
                  strokeDasharray={positive ? undefined : "8 7"}
                  fill={positive ? color : "transparent"}
                />
              );
            })}
          </Pie>

          <Tooltip
            content={
              TooltipContent ? (
                <TooltipContent />
              ) : (
                <PieTooltipContent total={total} {...tooltipProps} />
              )
            }
          />
        </RechartsPieChart>
      </Box>
      {keys && (
        <Box overflow="hidden">
          <Legend
            wrapperStyle={{
              width: "100%",
              position: "initial",
              alignSelf: "center",
            }}
            content={
              LegendContent ? (
                <LegendContent {...legendContentProps} />
              ) : (
                <PieLegendContent {...legendContentProps} />
              )
            }
          />
        </Box>
      )}
    </Stack>
  );
};

const RADIAN = Math.PI / 180;
export const renderCustomizedLabel = ({
  cx = 0,
  cy = 0,
  midAngle = 0,
  innerRadius = 0,
  outerRadius = 0,
  percent = 0,
}: PieLabelRenderProps) => {
  const label = `${(percent * 100).toFixed(0)}%`;
  const labelWidth = label.length * 6;
  const arcLength = 2 * Math.PI * +outerRadius * percent;

  if (arcLength < labelWidth) {
    return null;
  }

  const radius = +innerRadius + +(+outerRadius - +innerRadius) * 0.5;
  const x = +cx + radius * Math.cos(-midAngle * RADIAN);
  const y = +cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      textAnchor={x > +cx ? "start" : "end"}
      dominantBaseline="central"
      fill="#fff"
      style={{ fontSize: 12 }}
    >
      {label}
    </text>
  );
};
