import { ColumnSetupType } from "../../../../../../../../../../../../../../storybook/data-grid/utils/types/types";
import { Eks } from "../../../../../../../../../../../../../../services/cloudchipr.api";
import { CapitalizedTextCell } from "../../../../helpers/CapitalizedTextCell";

export const eksColumns: ColumnSetupType<Eks>[] = [
  {
    accessorKey: "name",
    header: "Name",
    size: 280,
    type: "identifierWithRecommendationLink",
    meta: { sticky: "left", hideFromSettings: true },
  },
  {
    accessorKey: "price_per_month",
    header: "Monthly Price",
    type: "money",
    headerTooltip:
      "Monthly prices are calculated based on the on-demand list price of each resource.",
  },
  {
    accessorKey: "support_type",
    header: "Support Type",
    type: "supportTypeWithDate",
    headerTooltip: "Extended support period has additional hourly cost.",
  },
  {
    accessorKey: "upgrade_policy",
    header: "Upgrade Policy",
    cell: CapitalizedTextCell,
  },
  {
    accessorKey: "version",
    header: "Version",
    cell: CapitalizedTextCell,
  },
  {
    accessorKey: "account",
    header: "Account",
    type: "account",
    size: 210,
  },
  {
    accessorKey: "all_time_spent",
    header: "Total Spend",
    type: "money",
  },
  {
    accessorKey: "instances_count",
    header: "Node Groups/Pools Count",
    size: 240,
  },
  {
    accessorKey: "node_count",
    header: "Node Count",
  },
  {
    accessorKey: "created_at",
    header: "Launch time",
    type: "date",
  },
  { accessorKey: "region", header: "Region", type: "withCopy" },
  { accessorKey: "tags", header: "Tags", type: "tag", enableSorting: false },
  {
    accessorKey: "tags",
    header: "Smart Labels",
    id: "smart_tag",
    type: "smartTag",
    enableSorting: false,
  },

  {
    accessorKey: "task",
    header: "Task Status",
    type: "taskStatus",
    enableSorting: false,
  },
  {
    accessorKey: "issues",
    header: "JIRA Status",
    type: "jiraTicketStatus",
    enableSorting: false,
  },
];
