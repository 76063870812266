import { createAsyncThunk } from "@reduxjs/toolkit";
import { getCostAnomalyAlertsThunk } from "./getCostAnomalyAlertsThunk";
import { cloudChiprApi } from "../../../../services/cloudchipr.api";
import { deleteCostAnomalyAlertFixedCacheKey } from "../../utils/constants/fixedCacheKeys";
import { enqueueSnackbarErrorAlert } from "../../../../components/pages/common/snackbar-alert/EnqueueSnackbarErrorAlert";

export const deleteCostAnomalyAlertByIdThunk = createAsyncThunk(
  "alerts/deleteAlertByIdAndType",
  async (id: string, { dispatch }) => {
    const { deleteUsersMeOrganisationsCurrentAlertsCostAnomalyByAlertId } =
      cloudChiprApi.endpoints;

    try {
      await dispatch(
        deleteUsersMeOrganisationsCurrentAlertsCostAnomalyByAlertId.initiate(
          { alertId: id },
          { fixedCacheKey: deleteCostAnomalyAlertFixedCacheKey },
        ),
      ).unwrap();

      await dispatch(getCostAnomalyAlertsThunk()).unwrap();
    } catch (e) {
      // @ts-expect-error todo: fix api spec;
      enqueueSnackbarErrorAlert(e?.data?.message);
    }
  },
);
