import { createAsyncThunk } from "@reduxjs/toolkit";
import { coverageRecommendationsPayloadSelector } from "../../selectors/coverage-and-utilization/details-drawer/recommendations/coverageRecommendationsPayloadSelector";
import { cloudChiprApi } from "../../../../services/cloudchipr.api";
import { RootState } from "../../../store";
import { enqueueSnackbarErrorAlert } from "../../../../components/pages/common/snackbar-alert/EnqueueSnackbarErrorAlert";

export const getSavingPlansCoverageRecommendationsThunk = createAsyncThunk(
  "commitments/getSavingPlansCoverageRecommendations",
  async (_: void, { dispatch, getState }) => {
    const state = getState() as RootState;

    const { getUsersMeOrganisationsCurrentSavingsPlansRecommendations } =
      cloudChiprApi.endpoints;

    const payload = coverageRecommendationsPayloadSelector(state);
    try {
      await dispatch(
        getUsersMeOrganisationsCurrentSavingsPlansRecommendations.initiate({
          ...payload,
        }),
      ).unwrap();
    } catch (e) {
      // @ts-expect-error todo: fix api spec;
      enqueueSnackbarErrorAlert(e?.data?.message);

      return null;
    }
  },
);
