import { useParams } from "react-router-dom";
import { useCallback } from "react";
import {
  ProviderType,
  useGetUsersMeCurrentPreferenceByKeyQuery,
  usePatchUsersMeCurrentPreferenceByKeyMutation,
  usePostUsersMeCurrentPreferenceMutation,
} from "../../../../../services/cloudchipr.api";
import { AccountsPageTabs } from "../../../accounts-group/utils/types/types";
import { AccountsViewType } from "../../../../../store/accounts/accountsSlice";

export function useAccountsView(
  provider: ProviderType,
  type: AccountsPageTabs,
) {
  const { orgId } = useParams();
  const sortByKey = getAccountsViewSettingsKey(provider, type, orgId);

  const [createViewSetting] = usePostUsersMeCurrentPreferenceMutation();
  const [updateViewSetting] = usePatchUsersMeCurrentPreferenceByKeyMutation();
  const {
    data: accountView,
    refetch,
    isLoading,
  } = useGetUsersMeCurrentPreferenceByKeyQuery({
    key: sortByKey,
  });

  const saveAccountsView = useCallback(
    async (sortBy: AccountsViewType) => {
      const body = {
        key: sortByKey,
        body: { value: sortBy },
      };

      if (accountView) {
        await updateViewSetting(body);
      } else {
        await createViewSetting({ body });
      }

      refetch();
    },
    [accountView, createViewSetting, updateViewSetting, sortByKey, refetch],
  );

  return {
    isLoading,
    accountsView: accountView?.value as AccountsViewType,
    saveAccountsView,
  };
}

export const getAccountsViewSettingsKey = (
  provider: ProviderType,
  type: AccountsPageTabs,
  id?: string,
) => {
  const identifier = id ? `${provider}-${id}` : provider;
  return `c8r:${identifier}:${type}AccountsView`;
};
