import { FC, useCallback } from "react";
import { Drawer, Stack } from "@mui/material";
import { ReportsDrawerHeader } from "./components/ReportsDrawerHeader";
import { ReportsDrawerNameField } from "./components/ReportsDrawerNameField";
import { ReportsDrawerSourceSection } from "./components/source-section/ReportsDrawerSourceSection";
import { ReportsDrawerScheduleSection } from "./components/schedule-section/ReportsDrawerScheduleSection";
import { ReportsDrawerAttachments } from "./components/attachments-section/ReportsDrawerAttachments";
import { ReportsDrawerNotifications } from "./components/notifications-section/ReportsDrawerNotifications";
import { ReportsDrawerActions } from "./components/ReportsDrawerActions";
import { useAppDispatch, useAppSelector } from "../../../../../store/hooks";
import { reportsOpenDrawerSelector } from "../../../../../store/reports/selector/reportsOpenDrawerSelector";
import {
  resetReportsData,
  toggleReportsOpenDrawer,
} from "../../../../../store/reports/reportsSlice";
import { Report } from "../../../../../services/cloudchipr.api";

interface ReportsDrawerProps {
  disableSourceSelect?: boolean;
  defaultSourceType?: Report["source_type"];
  onSave?(): void;
}

export const ReportsDrawer: FC<ReportsDrawerProps> = ({
  disableSourceSelect,
  defaultSourceType,
  onSave,
}) => {
  const dispatch = useAppDispatch();
  const openDrawer = useAppSelector(reportsOpenDrawerSelector);

  const handleClose = useCallback(() => {
    dispatch(toggleReportsOpenDrawer());
    dispatch(resetReportsData(defaultSourceType));
  }, [dispatch, defaultSourceType]);

  return (
    <Drawer
      anchor="right"
      open={openDrawer}
      onClose={handleClose}
      PaperProps={paperProps}
      slotProps={slotProps}
    >
      <ReportsDrawerHeader onClose={handleClose} />

      <Stack spacing={3} p={2}>
        <ReportsDrawerNameField />
        <ReportsDrawerSourceSection disableSourceSelect={disableSourceSelect} />
        <ReportsDrawerScheduleSection />
        <ReportsDrawerAttachments />
        <ReportsDrawerNotifications />
        <ReportsDrawerActions
          onClose={handleClose}
          onSave={onSave}
          snackbarWithLinks={disableSourceSelect}
        />
      </Stack>
    </Drawer>
  );
};

const paperProps = {
  sx: {
    width: "50dvw",
    minWidth: 600,
  },
};

const slotProps = {
  backdrop: {
    invisible: true,
  },
};
