import { RootState } from "../../../store";
import { navigationDashboardPrivateDataSelector } from "../../../navigation/selectors/dashboard/navigationDashboardPrivateDataSelector";
import { navigationDashboardPublicDataSelector } from "../../../navigation/selectors/dashboard/navigationDashboardPublicDataSelector";

export const dashboardsItemsCountSelector = (
  state: RootState,
): number | undefined => {
  const privateDataCount =
    navigationDashboardPrivateDataSelector(state)?.length;
  const publicDataCount = navigationDashboardPublicDataSelector(state)?.length;

  if (privateDataCount === undefined && publicDataCount === undefined) {
    return undefined;
  }

  return (publicDataCount ?? 0) + (privateDataCount ?? 0);
};
