import { FC, useCallback, useMemo, useState } from "react";
import { TextFieldProps } from "@mui/material/TextField/TextField";
import { ListItemText, Stack } from "@mui/material";
import { useDidMount } from "rooks";
import { GroupingByTagValueOption } from "./components/GroupingByTagValueOption";
import { GroupBySelectorTrigger } from "./components/triggers/GroupBySelectorTrigger";
import { GroupingOptionIcon } from "./components/GroupingOptionIcon";
import { getDimensionGroupingOptions } from "./components/specific-options/getDimensionGroupingOptions";
import { eksGroupingOptions } from "./components/specific-options/eksGroupingOptions";
import { GroupingAvailableProviders } from "./components/GroupingAvailableProviders";
import { mongoGroupingOptions } from "./components/specific-options/mongoGroupingOptions";
import {
  ResourceExplorerGrouping,
  useGetUsersMeOrganisationsCurrentResourceExplorerFiltersByFilterProviderAndFilterTypeValuesQuery as useValuesQuery,
} from "../../../../services/cloudchipr.api";
import { DropdownSelect } from "../../../common/select/dropdown-select/DropdownSelect";
import { FilterTriggerComponentProps } from "../../../common/select/dropdown-select/utils/types/filterTriggerComponentProps";
import { getResourcesExplorerGroupingLabel } from "../../resource-explorer/components/resource-explorer-card/utils/helpers/getResourcesExplorerGroupingLabel";
import { DropdownSelectOption } from "../../../common/select/dropdown-select/utils/types/types";
import {
  resourceExplorerAllGroupings,
  resourceExplorerGroupings,
} from "../../resource-explorer/components/resource-explorer-card/utils/constants/groupings";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { connectedProvidersSelector } from "../../../../store/common/selectors/connectedProvidersSelector";
import { getResourceExplorerGroupingTagKeysThunk } from "../../../../store/common/thunks/resource-explorer/getResourceExplorerGroupingTagKeysThunk";

interface GroupingSelectorProps {
  disabled?: boolean;
  dropDownMaxHeight?: number;
  groupValues?: string[];
  value: ResourceExplorerGrouping;
  triggerSize?: TextFieldProps["size"];
  hiddenGroupings?: ResourceExplorerGrouping[];
  onChange(grouping: ResourceExplorerGrouping, groupValue?: string[]): void;
}

export const GroupingSelector: FC<GroupingSelectorProps> = ({
  value,
  groupValues,
  disabled,
  onChange,
  hiddenGroupings,
  triggerSize,
  dropDownMaxHeight,
}) => {
  const dispatch = useAppDispatch();
  const [forceToClose, setForceToClose] = useState(false);
  const providers = useAppSelector(connectedProvidersSelector);
  const { data: dimensionsOptions, isLoading } = useValuesQuery({
    filterProvider: "dimensions",
    filterType: "dimension_id",
  });

  const closeHandler = useCallback(() => {
    setForceToClose(true);

    setTimeout(() => {
      setForceToClose(false);
    }, 300);
  }, []);

  const renderOptions = useMemo(() => {
    const options = resourceExplorerGroupings
      .filter((grouping) => !hiddenGroupings?.includes(grouping))
      .map((grouping) => {
        const label = getResourcesExplorerGroupingLabel(
          grouping,
          true,
          providers,
        );

        if (grouping === "cost_allocation_tag") {
          return {
            value: grouping,
            disableSelection: true,
            rawValue: { search: `${grouping} ${label}` },
            label: (
              <GroupingByTagValueOption
                onChange={onChange}
                onClose={closeHandler}
                values={value === "cost_allocation_tag" ? groupValues : []}
                label={label}
              />
            ),
          } as DropdownSelectOption;
        }

        return {
          value: grouping,
          rawValue: { search: `${grouping} ${label}` },
          label: (
            <Stack direction="row" spacing={1} alignItems="center">
              <GroupingOptionIcon grouping={grouping} />
              <ListItemText
                primary={label}
                primaryTypographyProps={{
                  sx: { whiteSpace: "nowrap", width: "fit-content" },
                }}
                sx={{ flexGrow: 0 }}
              />

              <GroupingAvailableProviders grouping={grouping} />
            </Stack>
          ),
        } as DropdownSelectOption;
      });

    options.push(mongoGroupingOptions);
    options.push(eksGroupingOptions);

    const dimensionGroupingOptions =
      getDimensionGroupingOptions(dimensionsOptions);
    if (dimensionGroupingOptions) {
      options.push(dimensionGroupingOptions);
    }

    return options;
  }, [
    dimensionsOptions,
    closeHandler,
    hiddenGroupings,
    value,
    groupValues,
    onChange,
    providers,
  ]);

  const submitHandlerOnClose = useCallback(
    (values: string[]) => {
      const selected = values.at(0) as ResourceExplorerGrouping;

      if (!selected) {
        return;
      }

      if (resourceExplorerAllGroupings.includes(selected)) {
        onChange(selected);
      } else {
        onChange("category", [selected]);
      }
    },
    [onChange],
  );

  const TriggerComponent = useCallback(
    (props: FilterTriggerComponentProps) => {
      return (
        <GroupBySelectorTrigger
          {...props}
          groupValues={groupValues}
          size={triggerSize}
        />
      );
    },
    [groupValues, triggerSize],
  );

  useDidMount(() => {
    dispatch(getResourceExplorerGroupingTagKeysThunk());
  });

  if (!renderOptions) {
    return null;
  }

  const groupingAsValue = value ? [value] : [];

  return (
    <DropdownSelect
      singleSelect
      listWidth={320}
      label="Group by"
      disabled={disabled}
      filterFn={filterFn}
      showSelectAll={false}
      options={renderOptions}
      optionsLoading={isLoading}
      sortSelectedOptions={false}
      forceToClose={forceToClose}
      listMaxHeight={dropDownMaxHeight}
      TriggerComponent={TriggerComponent}
      submitHandlerOnClose={submitHandlerOnClose}
      values={value === "category" ? groupValues : groupingAsValue}
    />
  );
};

const filterFn = (option: DropdownSelectOption, keyword: string): boolean => {
  keyword = keyword.trim().toLowerCase();

  return (
    option.rawValue?.search?.toLowerCase()?.includes(keyword) ||
    !!option.nestedOptions?.some((option) => filterFn(option, keyword))
  );
};
