import { costAndUsageSumWidgetByIdSelector } from "./costAndUsageSumWidgetByIdSelector";
import { costAndUsageWidgetNotFoundSelector } from "./costAndUsageWidgetNotFoundSelector";
import { RootState } from "../../../../store";
import { resourceExplorerViewNameByViewIdSelector } from "../../../../resource-explorer/selectors/all-resource-explorers/resourceExplorerViewNameByViewIdSelector";
import { objectToUrl } from "../../../../../components/utils/helpers/dataToUrlToDataConvertors";
import { WidgetTitleProps } from "../../../../../components/pages/dashboard/utils/types/types";

export const costAndUsageSumWidgetTitlePropsSelector = (
  state: RootState,
  widgetId: string,
): WidgetTitleProps | null => {
  const widget = costAndUsageSumWidgetByIdSelector(state, widgetId);

  if (!widget) {
    return null;
  }

  const viewName = resourceExplorerViewNameByViewIdSelector(
    state,
    widget.view_id,
  );

  const viewNotFound = costAndUsageWidgetNotFoundSelector(state, widgetId);
  const path = objectToUrl([
    {
      dates: widget.dates,
    },
  ]);

  return {
    widgetName: widget.name,
    dates: widget.dates,
    sourceData: {
      title: viewNotFound || !viewName ? "" : viewName,
      url: `/resource-explorer/${widget.view_id}?path=${path}`,
    },
  };
};
