import { ColumnSetupType } from "../../../../../../../../../../../../../../storybook/data-grid/utils/types/types";
import { Ebs } from "../../../../../../../../../../../../../../services/cloudchipr.api";

export const ebsColumns: ColumnSetupType<Ebs>[] = [
  {
    accessorKey: "volume_id",
    header: "Volume-id",
    type: "identifier",
    size: 220,
    meta: { sticky: "left", hideFromSettings: true },
  },
  {
    accessorKey: "price_per_month",
    header: "Monthly Price",
    type: "money",
    headerTooltip:
      "Monthly prices are calculated based on the on-demand list price of each resource.",
  },
  {
    accessorKey: "account",
    header: "Account",
    type: "account",
    size: 210,
  },
  {
    accessorKey: "all_time_spent",
    header: "Total Spend",
    type: "money",
  },
  {
    accessorKey: "creation_data",
    header: "Created By",
    type: "createdBy",
    headerTooltip:
      "The Created By value is only available for resources created within the last 90 days.",
  },
  {
    accessorKey: "created_at",
    header: "Creation Time",
    type: "date",
  },
  {
    accessorKey: "size",
    header: "Size",
    type: "byteToGib",
  },
  {
    accessorKey: "has_attachments",
    header: "Attachments",
    type: "ebsAttachmentsCell",
    size: 240,
  },
  {
    accessorKey: "is_encrypted",
    header: "Encryption",
    cell: (cell) => {
      return cell.getValue() ? "Encrypted" : "Unencrypted";
    },
  },
  { accessorKey: "region", header: "Region", type: "withCopy" },
  { accessorKey: "tags", header: "Tags", type: "tag", enableSorting: false },
  {
    accessorKey: "tags",
    header: "Smart Tags",
    id: "smart_tag",
    type: "smartTag",
    enableSorting: false,
  },

  {
    accessorKey: "task",
    header: "Task Status",
    type: "taskStatus",
    enableSorting: false,
  },
  {
    accessorKey: "issues",
    header: "JIRA Status",
    type: "jiraTicketStatus",
    enableSorting: false,
  },
];
