import { resourceExplorerGroupingTagKeysSelector } from "./resourceExplorerGroupingTagKeysSelector";
import { RootState } from "../../../store";
import { ResourceExplorerFilterProvider } from "../../../../services/cloudchipr.api";
import { connectedProvidersSelector } from "../connectedProvidersSelector";
import { getAzureTagsFeatureToggleFromStorage } from "../../../../components/pages/resource-explorer/utils/helpers/azure-tags/getAzureTagsFeatureToggleFromStorage";

export const resourceExplorerGroupingTagKeysDataSelector = (
  state: RootState,
) => {
  const providers = connectedProvidersSelector(state);
  const enableAzureTags = getAzureTagsFeatureToggleFromStorage();

  const cloudProviders: ResourceExplorerFilterProvider[] = [
    ...providers,
    "mongo",
  ];

  return cloudProviders.reduce(
    (acc, provider) => {
      if (provider === "azure" && !enableAzureTags) {
        return acc;
      }

      const data = resourceExplorerGroupingTagKeysSelector(
        state,
        provider,
      )?.data;

      data?.forEach((item) => {
        acc.push({ value: item.value, provider });
      });

      return acc;
    },
    [] as { value: string; provider: ResourceExplorerFilterProvider }[],
  );
};
