import { FC, Fragment, useCallback, useMemo } from "react";
import { LoadingButton } from "@mui/lab";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../store/hooks";
import { taskManagementEditingPropertiesSelector } from "../../../../../../../../store/task-management/selectors/properties/edit/taskManagementEditingPropertiesSelector";
import { taskAvailablePropertiesSelector } from "../../../../../../../../store/task-management/selectors/properties/taskAvailablePropertiesSelector";
import { taskManagementPropertiesEditingLoadingSelector } from "../../../../../../../../store/task-management/selectors/properties/edit/loadings/taskManagementPropertiesEditingLoadingSelector";
import { setTaskManagementProperties } from "../../../../../../../../store/task-management/taskManagementSlice";
import { updateTaskPropertiesThunk } from "../../../../../../../../store/task-management/thunks/properties/updateTaskPropertiesThunk";
import { taskManagementGetTasksThunk } from "../../../../../../../../store/task-management/thunks/actions/taskManagementGetTasksThunk";
import { tasksListGetLoadingSelector } from "../../../../../../../../store/task-management/selectors/loading/tasksListGetLoadingSelector";
import { taskAvailablePropertiesLoadingSelector } from "../../../../../../../../store/task-management/selectors/properties/edit/loadings/taskAvailablePropertiesLoadingSelector";

interface TaskPropertiesSettingsDrawerActionsProps {
  closeIconButton?: boolean;
  onClose(): void;
}

export const TaskPropertiesSettingsDrawerActions: FC<
  TaskPropertiesSettingsDrawerActionsProps
> = ({ closeIconButton, onClose }) => {
  const dispatch = useAppDispatch();

  const editingProperties = useAppSelector(
    taskManagementEditingPropertiesSelector,
  );
  const availableProperties = useAppSelector(taskAvailablePropertiesSelector);
  const editLoading = useAppSelector(
    taskManagementPropertiesEditingLoadingSelector,
  );
  const tasksLoading = useAppSelector(tasksListGetLoadingSelector);
  const propertiesLoading = useAppSelector(
    taskAvailablePropertiesLoadingSelector,
  );
  const loading = editLoading || tasksLoading || propertiesLoading;

  const disabled = useMemo(() => {
    return Object.values(editingProperties)
      .flat()
      .some((property) => !property.name);
  }, [editingProperties]);

  const closeHandler = useCallback(() => {
    if (availableProperties) {
      dispatch(setTaskManagementProperties(availableProperties));
    }

    onClose();
  }, [dispatch, onClose, availableProperties]);

  const saveHandler = useCallback(async () => {
    await dispatch(updateTaskPropertiesThunk());
    await dispatch(taskManagementGetTasksThunk());
    onClose();
  }, [dispatch, onClose]);

  return (
    <Fragment>
      <LoadingButton
        size="small"
        variant="contained"
        onClick={saveHandler}
        disabled={disabled}
        loading={loading}
      >
        Save
      </LoadingButton>

      {closeIconButton && (
        <IconButton sx={{ p: 0.25 }} size="small" onClick={closeHandler}>
          <CloseIcon fontSize="small" />
        </IconButton>
      )}
    </Fragment>
  );
};
