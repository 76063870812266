import { RootState } from "../../../store";
import { resourceTypesByProvider } from "../../../../utils/helpers/providers/resourceTypesByProvider";
import { liveUsageMgmtResourceTypeDataEmsSelector } from "../resource-type-data/liveUsageMgmtResourceTypeDataEmsSelector";
import { liveUsageMgmtProviderSelector } from "../store-selectors/liveUsageMgmtProviderSelector";
import { getIsResourceTypeHiddenByFeatureToggle } from "../../../../components/pages/live-usage-mgmt/utils/helpers/getIsResourceTypeHiddenByFeatureToggle";

export const liveUsageMgmtResourcesEmsSelector = (state: RootState) => {
  const provider = liveUsageMgmtProviderSelector(state);
  if (!provider) {
    return;
  }

  const resourceTypes = resourceTypesByProvider(provider).filter(
    (resourceType) => !getIsResourceTypeHiddenByFeatureToggle(resourceType),
  );

  return (
    resourceTypes
      ?.map((resourceType) =>
        liveUsageMgmtResourceTypeDataEmsSelector(state, resourceType),
      )
      ?.reduce((acc, currentValue) => acc + currentValue, 0) || 0
  );
};
