import { FC, useCallback, useRef } from "react";
import { useEffectOnceWhen } from "rooks";
import { SnackbarKey, useSnackbar } from "notistack";
import { ImpersonationAlertContent } from "./ImpersonationAlertContent";
import { useAppSelector } from "../../../../store/hooks";
import { currentOrganizationAccessEnabledSelector } from "../../../../store/organizations/organizationsSelectors";
import { currentUserRoleSelector } from "../../../../store/profile/currentUserRoleSelector";

export const ImpersonationAlert: FC = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const snackBarKeyRef = useRef<SnackbarKey>("");

  const accessEnabled = useAppSelector(
    currentOrganizationAccessEnabledSelector,
  );
  const role = useAppSelector(currentUserRoleSelector);
  const closeSnackBar = useCallback(() => {
    closeSnackbar(snackBarKeyRef.current);
  }, [closeSnackbar]);

  const enqueueSnackbarHandler = useCallback(() => {
    snackBarKeyRef.current = enqueueSnackbar("", {
      variant: "snackbarAlert",
      persist: true,
      autoHideDuration: null,
      AlertSnackBarProps: {
        fullComponent: <ImpersonationAlertContent onClose={closeSnackBar} />,
      },
    });
  }, [closeSnackBar, enqueueSnackbar]);

  useEffectOnceWhen(
    () => {
      enqueueSnackbarHandler();
    },
    accessEnabled === false &&
      (role === "super_admin" || role === "owner" || role === "admin"),
  );

  return null;
};
