import { FC, useCallback, useState } from "react";
import { Stack, Typography, Chip } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { useEffectOnceWhen } from "rooks";
import { GridApi } from "ag-grid-community";
import { WorkflowsDataGridFilters } from "../filters/WorkflowsDataGridFilters";
import { useAppSelector } from "../../../../../../../store/hooks";
import { originalDataCountSelector } from "../../../../../../../store/automations/selectros/workflow/list-data/originalDataCountSelector";
import { GlobalFilter } from "../../../../../../../storybook/data-grid/toolbar/GlobalFilter";
import { AGGridColumnsToolPanel } from "../../../../../common/ag-grid/components/AGGridColumnsToolPanel";

interface WorkflowsListToolbarProps {
  dataCount?: number;
  gridApi: GridApi | null;
}

export const WorkflowsListToolbar: FC<WorkflowsListToolbarProps> = ({
  gridApi,
  dataCount = 0,
}) => {
  const [searchParams] = useSearchParams();
  const searchFromPath = searchParams.get("search") ?? null;
  const [searchValue, setSearchValue] = useState(searchFromPath ?? "");
  const originalDataCount = useAppSelector(originalDataCountSelector);

  const searchChangeHandler = useCallback(
    (keyword: string) => {
      gridApi?.setGridOption("quickFilterText", keyword);
      setSearchValue(keyword);
    },
    [gridApi],
  );

  useEffectOnceWhen(() => {
    searchChangeHandler(searchFromPath ?? "");
  }, !!searchFromPath && !!gridApi);

  return (
    <Stack
      p={2}
      top={0}
      spacing={2}
      bgcolor="white"
      zIndex="appBar"
      borderBottom={1}
      position="sticky"
      borderColor="grey.300"
    >
      {!!originalDataCount && (
        <Typography variant="subtitle1" color="text.secondary">
          {originalDataCount} scheduled Workflows
        </Typography>
      )}

      <Stack justifyContent="space-between" direction="row">
        <Stack direction="row" spacing={2} alignItems="center">
          <WorkflowsDataGridFilters />

          {!!dataCount && dataCount !== originalDataCount && (
            <Typography variant="body2" color="text.secondary" component="div">
              Filtered Result:{" "}
              <Chip
                size="small"
                variant="filled"
                sx={{
                  borderRadius: 1,
                  color: "text.secondary",
                  fontWeight: "bold",
                }}
                label={`${dataCount} ${dataCount === 1 ? "Workflow" : "Workflows"}`}
              />
            </Typography>
          )}
        </Stack>

        <Stack direction="row" spacing={2}>
          <GlobalFilter
            globalFilter={searchValue}
            setGlobalFilter={searchChangeHandler}
            size="xsmall"
          />

          <AGGridColumnsToolPanel gridApi={gridApi} />
        </Stack>
      </Stack>
    </Stack>
  );
};
