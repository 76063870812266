import { tasksListGridDataSelector } from "./tasksListGridDataSelector";
import { RootState } from "../../../store";
import { EmbeddedUser } from "../../../../services/cloudchipr.api";

export const tasksAllAssigneesSelector = (state: RootState): EmbeddedUser[] => {
  const tasks = tasksListGridDataSelector(state);

  const assignees: Record<string, EmbeddedUser> = {};

  tasks?.forEach((task) => {
    task.assignees?.forEach((user) => {
      assignees[user.id] = user;
    });
  });

  return Object.values(assignees);
};
