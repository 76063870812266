import { FC, Fragment, useCallback } from "react";
import { Collapse } from "@mui/material";
import { useToggle } from "rooks";
import { LiveUsageMgmtNestedNavItem } from "./LiveUsageMgmtNestedNavItem";
import { LiveUsageResourceNavItemCollapsed } from "./LiveUsageResourceNavItemCollapsed";
import { LiveUsageMgmtNavItemMain } from "./LiveUsageMgmtNavItemMain";
import { ResourceGroupType } from "../../../../../../../../utils/constants/resources/resourceGroupsByProvider";
import { ProviderType } from "../../../../../../../../services/cloudchipr.api";
import {
  LiveUsageNavigationResourceType,
  useLiveUsageMgmtPageNavigate,
} from "../../../utils/hooks/useLiveUsageMgmtPageNavigate.hook";
import { getIsResourceTypeHiddenByFeatureToggle } from "../../../../../utils/helpers/getIsResourceTypeHiddenByFeatureToggle";

interface LiveUsageResourceNavItemProps extends ResourceGroupType {
  provider: ProviderType;
  selectedResourceType?: LiveUsageNavigationResourceType;
  sidebarCollapsed?: boolean;
}

export const LiveUsageResourceNavItem: FC<LiveUsageResourceNavItemProps> = ({
  expandable,
  actionableResourceTypes,
  value,
  provider,
  selectedResourceType,
  sidebarCollapsed,
}) => {
  const [expanded, toggleExpanded] = useToggle();

  const handleNavigationClick = useLiveUsageMgmtPageNavigate();

  const nestedSelected = actionableResourceTypes.some(
    (resourceType) => resourceType === selectedResourceType,
  );

  const mainSelected =
    (!expandable || (expandable && !expanded)) && nestedSelected;

  const handleMainItemClick = useCallback(() => {
    toggleExpanded();

    if (!expanded) {
      handleNavigationClick(value);
    }
  }, [toggleExpanded, expanded, value, handleNavigationClick]);

  if (sidebarCollapsed) {
    return (
      <LiveUsageResourceNavItemCollapsed
        resourceType={value}
        selected={nestedSelected}
        provider={provider}
        expandable={expandable}
        actionableResourceTypes={actionableResourceTypes}
        selectedResourceType={selectedResourceType}
      />
    );
  }

  return (
    <Fragment>
      <LiveUsageMgmtNavItemMain
        resourceType={value}
        provider={provider}
        selected={mainSelected}
        expandable={expandable}
        expanded={expanded}
        onClick={handleMainItemClick}
      />

      {expandable && (
        <Collapse in={expanded}>
          {actionableResourceTypes.map((resourceType) => {
            if (getIsResourceTypeHiddenByFeatureToggle(resourceType)) {
              return null;
            }

            return (
              <LiveUsageMgmtNestedNavItem
                key={resourceType}
                resourceType={resourceType}
                selected={resourceType === selectedResourceType}
              />
            );
          })}
        </Collapse>
      )}
    </Fragment>
  );
};
