import { FC, useMemo } from "react";
import { Box } from "@mui/material";
import { getTargetMultipleResourcesGridColumns } from "./targetMultipleResourcesGridColumns";
import { taskManagementTargetResourcesSelector } from "../../../../../../../../../../store/task-management/selectors/form/target/resources/taskManagementTargetResourcesSelector";
import { useAppSelector } from "../../../../../../../../../../store/hooks";
import { AgDataGrid } from "../../../../../../../ag-grid/AgDataGrid";

export const TargetMultipleResources: FC = () => {
  const resources = useAppSelector(taskManagementTargetResourcesSelector);

  const resourceType = resources?.at(0)?.resource_type;

  const columnDefs = useMemo(() => {
    if (!resourceType) {
      return [];
    }

    return getTargetMultipleResourcesGridColumns(resourceType);
  }, [resourceType]);

  if (!resources) {
    return null;
  }

  return (
    <Box
      border={1}
      width="100%"
      minHeight={100}
      overflow="hidden"
      borderRadius={2}
      borderColor="grey.300"
    >
      <AgDataGrid
        rowHeight={60}
        suppressContextMenu
        rowData={resources}
        domLayout="autoHeight"
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
      />
    </Box>
  );
};

const defaultColDef = { suppressHeaderMenuButton: true, minWidth: 200 };
