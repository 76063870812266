import { FC, useCallback } from "react";
import {
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
} from "@mui/material";
import KeyboardArrowRightOutlinedIcon from "@mui/icons-material/KeyboardArrowRightOutlined";
import { FilterTriggerComponentProps } from "../../../../../common/select/dropdown-select/utils/types/filterTriggerComponentProps";
import { CountChipWithTooltip } from "../CountChipWithTooltip";
import { GroupingOptionIcon } from "../GroupingOptionIcon";
import { GroupingAvailableProviders } from "../GroupingAvailableProviders";

interface NestedGroupingOptionTriggerProps extends FilterTriggerComponentProps {
  needToShowCount?: boolean;
}

export const NestedGroupingOptionTrigger: FC<
  NestedGroupingOptionTriggerProps
> = ({ onClick, label, needToShowCount, selectedValues }) => {
  const clickHandler = useCallback(
    (event: any) => {
      event.preventDefault();
      event.stopPropagation();
      onClick(event);
    },
    [onClick],
  );

  return (
    <ListItemButton disableRipple onClick={clickHandler} sx={{ py: 0.5 }}>
      <ListItemIcon sx={{ minWidth: 28 }}>
        <GroupingOptionIcon grouping="cost_allocation_tag" />
      </ListItemIcon>

      <Stack direction="row" spacing={1} flex={1} alignItems="center">
        <ListItemText
          primary={label}
          primaryTypographyProps={{
            sx: { whiteSpace: "nowrap", width: "fit-content" },
            variant: "body2",
          }}
          sx={{ flexGrow: 0 }}
        />
        <GroupingAvailableProviders grouping="cost_allocation_tag" />
      </Stack>

      {needToShowCount && <CountChipWithTooltip values={selectedValues} />}

      <KeyboardArrowRightOutlinedIcon fontSize="small" />
    </ListItemButton>
  );
};
