import { ListItemText, Stack } from "@mui/material";
import { GroupingOptionIcon } from "../GroupingOptionIcon";
import {
  resourceExplorerMongoGroupings,
  resourcesExplorerGroupingSingularLabels,
} from "../../../../resource-explorer/components/resource-explorer-card/utils/constants/groupings";
import { TypographyWithTooltip } from "../../../../../common/TypographyWithTooltip";
import { DropdownSelectOption } from "../../../../../common/select/dropdown-select/utils/types/types";
import MongoSvgIcon from "../../../../../../assets/images/logos/providers/MongoSvgIcon";

export const mongoGroupingOptions: DropdownSelectOption = {
  value: "mongo_groupings",
  disableSelection: true,
  label: (
    <Stack direction="row" spacing={1} alignItems="center">
      <MongoSvgIcon fontSize="small" filter="grayscale(1)" />
      <ListItemText primary="Mongo" />
    </Stack>
  ),
  rawValue: { search: "mongo" },
  nestedOptions: resourceExplorerMongoGroupings.map((option) => ({
    value: option ?? "mongo",
    rawValue: { search: `${option} mongo` },
    label: (
      <Stack direction="row" spacing={1} alignItems="center" pl={2}>
        <GroupingOptionIcon grouping={option} />
        <TypographyWithTooltip
          variant="body2"
          title={resourcesExplorerGroupingSingularLabels.get(option) ?? option}
        />
      </Stack>
    ),
  })),
};
