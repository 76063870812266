import { FC, Fragment, useCallback, useMemo, useRef } from "react";
import { List, ListItem, ListSubheader, ListItemText } from "@mui/material";
import { useVirtual } from "react-virtual";
import {
  FilterListItemWrapper,
  FilterListItemWrapperProps,
} from "./FilterListItemWrapper";
import { calculateVirtualizationPaddings } from "../../../../../utils/virtualization/calculateVirtualizationPaddings";
import { TypographyWithTooltip } from "../../../TypographyWithTooltip";

type ExtendedProps = Omit<FilterListItemWrapperProps, "index">;

export interface FilterItemsListProps extends ExtendedProps {
  virtualized: boolean;
  nested?: boolean;
  listWidth?: number;
  listMaxHeight?: number;
}

export const FilterItemsList: FC<FilterItemsListProps> = ({
  singleSelect,
  submitHandlerOnClose,
  selectedValues,
  setSelectedValues,
  filteredOptions,
  closeHandler,
  virtualized,
  listWidth,
  renderOption,
  noItemsLabel,
  keyword,
  optionsLoading,
  nested,
  listMaxHeight,
  lastSelectedOptionDisabled,
}) => {
  const parentRef = useRef(null);

  const estimateSize = useCallback(
    (index: number) => {
      const option = filteredOptions.at(index);

      if (option?.listItemHeight) {
        return option.listItemHeight;
      }

      const groupName = option?.groupName;
      const nestedOptions = option?.nestedOptions;

      const withGroupLabel =
        groupName && groupName !== filteredOptions.at(index - 1)?.groupName;

      if (nestedOptions) {
        return listItemHeight * nestedOptions.length;
      }

      return withGroupLabel ? listItemHeight * 2 : listItemHeight;
    },
    [filteredOptions],
  );

  const { virtualItems, totalSize } = useVirtual({
    estimateSize,
    overscan: 20,
    size: virtualized ? filteredOptions.length : 0,
    parentRef: parentRef,
  });

  const { paddingTop, paddingBottom } = calculateVirtualizationPaddings(
    virtualItems,
    totalSize,
  );

  const groupName = useMemo(() => {
    const firstVirtualIndex = virtualItems?.at(0)?.index;

    if (!firstVirtualIndex && firstVirtualIndex !== 0) {
      return "";
    }

    const firstVisibleOption = filteredOptions[firstVirtualIndex];

    return firstVisibleOption?.groupName ?? "";
  }, [filteredOptions, virtualItems]);

  const maxHeight = nested ? undefined : 300;

  return (
    <List
      ref={virtualized ? parentRef : undefined}
      sx={{
        maxHeight: listMaxHeight ?? maxHeight,
        overflowY: "auto",
        py: 0,
        width: listWidth,
      }}
    >
      {!optionsLoading && !filteredOptions.length && (
        <ListItem>
          <ListItemText primaryTypographyProps={{ color: "text.secondary" }}>
            {noItemsLabel ? noItemsLabel(keyword) : "No Data"}
          </ListItemText>
        </ListItem>
      )}

      {virtualized && (
        <Fragment>
          {paddingTop > 0 && <ListItem sx={{ height: paddingTop }} />}
          {groupName && (
            <ListSubheader sx={{ whiteSpace: "nowrap" }}>
              <TypographyWithTooltip title={groupName} variant="inherit" />
            </ListSubheader>
          )}
        </Fragment>
      )}

      {virtualized
        ? virtualItems.map((virtualItem) => {
            return (
              <FilterListItemWrapper
                keyword={keyword}
                key={virtualItem.key}
                noItemsLabel={noItemsLabel}
                renderOption={renderOption}
                index={virtualItem.index}
                singleSelect={singleSelect}
                closeHandler={closeHandler}
                optionsLoading={optionsLoading}
                selectedValues={selectedValues}
                filteredOptions={filteredOptions}
                setSelectedValues={setSelectedValues}
                submitHandlerOnClose={submitHandlerOnClose}
                lastSelectedOptionDisabled={lastSelectedOptionDisabled}
              />
            );
          })
        : filteredOptions.map((option, index) => {
            return (
              <FilterListItemWrapper
                index={index}
                keyword={keyword}
                key={option.value}
                noItemsLabel={noItemsLabel}
                singleSelect={singleSelect}
                closeHandler={closeHandler}
                renderOption={renderOption}
                optionsLoading={optionsLoading}
                selectedValues={selectedValues}
                filteredOptions={filteredOptions}
                setSelectedValues={setSelectedValues}
                submitHandlerOnClose={submitHandlerOnClose}
                lastSelectedOptionDisabled={lastSelectedOptionDisabled}
              />
            );
          })}

      {virtualized && paddingBottom > 0 && (
        <ListItem sx={{ height: paddingBottom }} />
      )}
    </List>
  );
};

const listItemHeight = 47;
