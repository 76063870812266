import { createAsyncThunk } from "@reduxjs/toolkit";
import { cloudChiprApi } from "../../../services/cloudchipr.api";
import { deleteReportFixedCacheKey } from "../utils/constants/fixedCacheKeys";
import { enqueueSnackbarErrorAlert } from "../../../components/pages/common/snackbar-alert/EnqueueSnackbarErrorAlert";

export const deleteReportThunk = createAsyncThunk(
  "reports/deleteReport",
  async (reportId: string, { dispatch }) => {
    const { deleteUsersMeOrganisationsCurrentReportsByReportId } =
      cloudChiprApi.endpoints;

    try {
      await dispatch(
        deleteUsersMeOrganisationsCurrentReportsByReportId.initiate(
          {
            reportId,
          },
          {
            fixedCacheKey: deleteReportFixedCacheKey,
          },
        ),
      );
    } catch (e) {
      // @ts-expect-error todo: fix api spec;
      enqueueSnackbarErrorAlert(e?.data?.message);
    }
  },
);
