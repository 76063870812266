import { FC, useCallback } from "react";
import { Button, Stack } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { SnackbarKey, useSnackbar } from "notistack";
import { useAppDispatch, useAppSelector } from "../../../../../../store/hooks";
import { reportsSaveButtonDisabledSelector } from "../../../../../../store/reports/selector/reportsSaveButtonDisabledSelector";
import { saveReportThunk } from "../../../../../../store/reports/thunks/saveReportThunk";
import { saveReportLoadingSelector } from "../../../../../../store/reports/selector/saveReportLoadingSelector";
import { reportCurrentSourceNameBySourceSelector } from "../../../../../../store/reports/selector/reportCurrentSourceNameBySourceSelector";
import { reportsDataPropertyByKeySelector } from "../../../../../../store/reports/selector/reportsDataPropertyByKeySelector";
import { CreateReportSnackbarFooter } from "../../../../common/reports-by-source/CreateReportSnackbarFooter";
import { isReportEditModeSelector } from "../../../../../../store/reports/selector/isReportEditModeSelector";

interface ReportsDrawerActionsProps {
  onClose(): void;
  onSave?(): void;
  snackbarWithLinks?: boolean;
}

export const ReportsDrawerActions: FC<ReportsDrawerActionsProps> = ({
  onClose,
  onSave,
  snackbarWithLinks,
}) => {
  const dispatch = useAppDispatch();
  const disabled = useAppSelector(reportsSaveButtonDisabledSelector);
  const loading = useAppSelector(saveReportLoadingSelector);
  const sourceName = useAppSelector(reportCurrentSourceNameBySourceSelector);
  const sourceType = useAppSelector(
    reportsDataPropertyByKeySelector("source_type"),
  );
  const isEdit = useAppSelector(isReportEditModeSelector);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const saveHandler = useCallback(async () => {
    const response = await dispatch(
      saveReportThunk(!!snackbarWithLinks),
    ).unwrap();
    onSave?.();

    if (response && !isEdit && snackbarWithLinks) {
      const key: SnackbarKey = enqueueSnackbar(
        `Subscription created for "${sourceName}" ${sourceType}.`,
        {
          variant: "snackbarAlert",
          AlertSnackBarProps: {
            severity: "success",
            footer: (
              <CreateReportSnackbarFooter subscriptionId={response?.id ?? ""} />
            ),
            onClose: () => closeSnackbar(key),
          },
        },
      );
    }
  }, [
    dispatch,
    onSave,
    isEdit,
    enqueueSnackbar,
    closeSnackbar,
    sourceName,
    sourceType,
    snackbarWithLinks,
  ]);

  return (
    <Stack direction="row" spacing={2.5}>
      <LoadingButton
        onClick={saveHandler}
        loading={loading}
        disabled={disabled}
        variant="contained"
      >
        Save
      </LoadingButton>

      <Button color="tertiary" onClick={onClose}>
        Cancel
      </Button>
    </Stack>
  );
};
