import { FC } from "react";
import { Box, Stack } from "@mui/material";
import { LiveUsageMgmtResourceCard } from "./LiveUsageMgmtResourceCard";
import { ProviderType } from "../../../../../services/cloudchipr.api";
import { allResourcesByProvider } from "../../../../../utils/constants/resources/resourceGroupsByProvider";
import { getIsResourceTypeHiddenByFeatureToggle } from "../../utils/helpers/getIsResourceTypeHiddenByFeatureToggle";

interface LiveUsageAllResourcesProps {
  provider: ProviderType;
}

export const LiveUsageAllResources: FC<LiveUsageAllResourcesProps> = ({
  provider,
}) => {
  const allResources = allResourcesByProvider.get(provider);

  return (
    <Stack direction="row" overflow="auto" flexGrow={1}>
      <Box flexGrow={1} overflow="auto">
        <Stack gap={1} p={1}>
          {allResources?.map((resourceType) => {
            if (getIsResourceTypeHiddenByFeatureToggle(resourceType)) {
              return null;
            }

            return (
              <Box key={resourceType}>
                <LiveUsageMgmtResourceCard
                  key={resourceType}
                  resourceType={resourceType}
                />
              </Box>
            );
          })}
        </Stack>
      </Box>
    </Stack>
  );
};
