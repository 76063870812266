import { FC, useCallback } from "react";
import { Stack } from "@mui/material";
import { LoadingButton } from "@mui/lab";

import { UserInviteDialog } from "../../UserInviteDialog";
import {
  useGetV2UsersMeOrganisationsCurrentUsersQuery,
  useGetUsersMeOrganisationsCurrentInvitationRequestsQuery,
  useDeleteUsersMeOrganisationsCurrentInvitationRequestsByUserIdMutation,
} from "../../../../../../../../services/cloudchipr.api";
import { useDialog } from "../../../../../../../../utils/hooks/useDialog.hook";
import { enqueueSnackbarErrorAlert } from "../../../../../../common/snackbar-alert/EnqueueSnackbarErrorAlert";

interface UserRequestActionCellProps {
  id: string;
  email?: string;
}
export const UserRequestActionCell: FC<UserRequestActionCellProps> = ({
  id,
  email,
}) => {
  const { refetch } =
    useGetUsersMeOrganisationsCurrentInvitationRequestsQuery();

  const [deleteUserInvite, { isLoading }] =
    useDeleteUsersMeOrganisationsCurrentInvitationRequestsByUserIdMutation();

  const { refetch: refetchUsers } =
    useGetV2UsersMeOrganisationsCurrentUsersQuery({});

  const { open, openDialog, closeDialog } = useDialog();

  const denyClickHandler = useCallback(() => {
    deleteUserInvite({ userId: id })
      .unwrap()
      .then(() => {
        refetch();
      })
      .catch((e) => {
        enqueueSnackbarErrorAlert(e?.data?.message);
      });
  }, [deleteUserInvite, id, refetch]);

  const approveClickHandler = useCallback(() => {
    openDialog();
  }, [openDialog]);

  const dialogCloseSuccessHandler = useCallback(() => {
    closeDialog();
    refetchUsers();
    refetch();
  }, [closeDialog, refetch, refetchUsers]);

  return (
    <Stack direction="row" spacing={1.5}>
      <LoadingButton
        onClick={denyClickHandler}
        sx={{
          color: "text.secondary",
          textTransform: "none",
        }}
        size="small"
        loading={isLoading}
      >
        Deny
      </LoadingButton>
      <LoadingButton
        onClick={approveClickHandler}
        variant="outlined"
        size="small"
        sx={{
          textTransform: "none",
        }}
        disabled={isLoading}
      >
        Approve
      </LoadingButton>
      <UserInviteDialog
        open={open}
        onClose={closeDialog}
        onSuccess={dialogCloseSuccessHandler}
        email={email}
      />
    </Stack>
  );
};
