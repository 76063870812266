import { FC, Fragment } from "react";
import { Stack } from "@mui/material";
import { useFlag } from "@unleash/proxy-client-react";
import { useToggle } from "rooks";
import LinkIcon from "@mui/icons-material/Link";
import { DashboardAppBarNavigation } from "./breadcrumb-navigation/DashboardAppBarNavigation";
import { useAppSelector } from "../../../../../store/hooks";
import { AddingWidget } from "../adding-widget/AddingWidget";
import { dashboardWidgetsSelector } from "../../../../../store/dashboards/selectors/dashboard/dashboardWidgetsSelector";
import { PageHeader } from "../../../common/PageHeader";
import { SendReportActionItem } from "../../../common/send-report/SendReportActionItem";
import { currentDashboardIdSelector } from "../../../../../store/dashboards/selectors/dashboard/currentDashboardIdSelector";
import { ReportsBySourceActionItem } from "../../../common/reports-by-source/ReportsBySourceActionItem";
import { ReportsBySourceDrawer } from "../../../common/reports-by-source/ReportsBySourceDrawer";
import { CopyIconButton } from "../../../../common/CopyIconButton";
import { getDashboardByIdLoadingSelector } from "../../../../../store/dashboards/selectors/dashboard/loadings/getDashboardByIdLoadingSelector";

interface DashboardHeaderProps {
  loading: boolean;
}

export const DashboardHeader: FC<DashboardHeaderProps> = ({ loading }) => {
  const [reportsDrawerOpen, toggleReportsDrawerOpen] = useToggle(false);
  const enableSubscriptions = useFlag("EnableSubscriptionsPage");
  const widgetsData = useAppSelector(dashboardWidgetsSelector);
  const dashboardId = useAppSelector(currentDashboardIdSelector);
  const dashboardByIdLoading = useAppSelector((state) =>
    getDashboardByIdLoadingSelector(state, dashboardId ?? ""),
  );

  return (
    <PageHeader
      sticky
      loading={loading || dashboardByIdLoading}
      title={<DashboardAppBarNavigation loading={loading} />}
      actions={
        <Stack
          spacing={2}
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <CopyIconButton
            data={window.location.href}
            TooltipProps={{ title: "Copy URL", placement: "top" }}
          >
            <LinkIcon fontSize="small" color="primary" />
          </CopyIconButton>

          {enableSubscriptions && (
            <Fragment>
              <SendReportActionItem
                iconButton
                disabled={loading}
                sourceType="dashboard"
                sourceId={dashboardId ?? ""}
              />

              <ReportsBySourceActionItem
                sourceId={dashboardId ?? ""}
                sourceType="dashboard"
                onClick={toggleReportsDrawerOpen}
              />

              <ReportsBySourceDrawer
                sourceId={dashboardId ?? ""}
                open={reportsDrawerOpen}
                sourceType="dashboard"
                toggleOpen={toggleReportsDrawerOpen}
              />
            </Fragment>
          )}

          {!!widgetsData?.length && (
            <AddingWidget startIcon disabled={loading} />
          )}
        </Stack>
      }
    />
  );
};
