import { FC } from "react";
import { IconButton, Stack, Tooltip } from "@mui/material";
import { Link } from "react-router-dom";
import C8RSvgIcon from "../../../../assets/images/logos/c8r/C8RSvgIcon";
import C8RTextSvgIcon from "../../../../assets/images/logos/c8r/C8RTextSvgIcon";
import SidebarFilledSvgIcon from "../../../../assets/images/icons/SidebarFilledSvgIcon";
import { useAppSelector } from "../../../../store/hooks";
import { navigationCollapsedSelector } from "../../../../store/common/selectors/navigation/navigationCollapsedSelector";

interface LogoAreaProps {
  onCollapse(): void;
}

export const LogoArea: FC<LogoAreaProps> = ({ onCollapse }) => {
  const navigationCollapsed = useAppSelector(navigationCollapsedSelector);

  return (
    <Stack
      flexBasis={52}
      flexShrink={0}
      flexGrow={0}
      p={1}
      pl={navigationCollapsed ? 1 : 2}
      alignItems="center"
      justifyContent={navigationCollapsed ? "center" : "space-between"}
      direction="row"
      borderBottom={1}
      borderColor="grey.300"
    >
      <Link to="/?to=home">
        <Stack direction="row" spacing={1}>
          <C8RSvgIcon />
          {!navigationCollapsed && <C8RTextSvgIcon />}
        </Stack>
      </Link>
      {!navigationCollapsed && (
        <Tooltip arrow title="Collapse Sidebar">
          <IconButton size="small" onClick={onCollapse}>
            <SidebarFilledSvgIcon fontSize="inherit" />
          </IconButton>
        </Tooltip>
      )}
    </Stack>
  );
};
