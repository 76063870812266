import { FC, Fragment, useCallback } from "react";
import { CustomCellRendererProps } from "ag-grid-react";
import Stack from "@mui/material/Stack";
import { useNavigate } from "react-router-dom";
import { enqueueSnackbar } from "notistack";
import { ScheduleListGridActionCellFunctionality } from "./ScheduleListGridActionCellFunctionality";
import { Schedule } from "../../../../../../../../services/cloudchipr.api";
import { AutomationsActivationSwitcher } from "../../../../../common/components/grid/automation-activation-switcher/AutomationsActivationSwitcher";
import { WithoutAccountsWorkflowDialog } from "../../../../../common/components/grid/WithoutAccountsWorkflowDialog";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../store/hooks";
import { useDialog } from "../../../../../../../../utils/hooks/useDialog.hook";
import { scheduleStatusToggleLoadingSelector } from "../../../../../../../../store/schedules/selectors/scheduleStatusToggleLoadingSelector";
import { toggleScheduledJobsStatusThunk } from "../../../../../../../../store/schedules/thunks/toggleScheduledJobsStatusThunk";
import { enqueueSnackbarErrorAlert } from "../../../../../../common/snackbar-alert/EnqueueSnackbarErrorAlert";

export const WorkflowGridActionsCell: FC<CustomCellRendererProps<Schedule>> = ({
  data: schedule,
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { open, openDialog, closeDialog } = useDialog();

  const loading = useAppSelector(scheduleStatusToggleLoadingSelector);

  const editScheduleHandler = useCallback(() => {
    const withoutAccounts = schedule?.accounts?.every(
      (account) => account.status !== "connected",
    );

    if (withoutAccounts) {
      openDialog();
    } else {
      navigate(`/automations/workflow/edit/${schedule?.id}`);
    }
  }, [schedule, navigate, openDialog]);

  const deleteScheduleHandler = useCallback(async () => {
    try {
      await dispatch(
        toggleScheduledJobsStatusThunk({
          schedule: schedule as Schedule,
          status: "deleted",
        }),
      ).unwrap();

      enqueueSnackbar("Workflow successfully deleted.", {
        variant: "snackbarAlert",
        AlertSnackBarProps: { severity: "success" },
      });
    } catch (e) {
      // @ts-expect-error todo: fix api spec;
      enqueueSnackbarErrorAlert(e?.data?.message);
    }
  }, [schedule, dispatch]);

  if (!schedule) {
    return null;
  }

  return (
    <Stack
      direction="row"
      spacing={1}
      height="100%"
      alignItems="center"
      justifyContent="space-between"
    >
      <AutomationsActivationSwitcher schedule={schedule} type="schedule" />

      <Fragment>
        <ScheduleListGridActionCellFunctionality
          isLoading={!!loading}
          name={schedule.name}
          id={schedule.id}
          onEdit={editScheduleHandler}
          onDelete={deleteScheduleHandler}
          type="workflow"
        />

        {open && (
          <WithoutAccountsWorkflowDialog
            onClose={closeDialog}
            workflow={schedule}
          />
        )}
      </Fragment>
    </Stack>
  );
};
