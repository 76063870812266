import {
  AccountResourceFilter2,
  FilterItemWithType,
  ResourceType,
} from "../../../../services/cloudchipr.api";

interface AddProviderUniqueIdentifierFilterToAppliedFiltersProps {
  uniqueIds: string[];
  selectedResourceType: ResourceType;
  appliedFilters: AccountResourceFilter2;
}

export const addProviderUniqueIdentifierFilterToAppliedFilters = ({
  uniqueIds,
  selectedResourceType,
  appliedFilters,
}: AddProviderUniqueIdentifierFilterToAppliedFiltersProps): AccountResourceFilter2 => {
  const alreadyHasUniqueIdFilter = uniqueIdFilterAlreadyExists(
    appliedFilters,
    selectedResourceType,
  );

  let updatedFilterItems = [...appliedFilters.filter_items];
  const updatedFilterGroups = [...appliedFilters.filter_groups];

  if (alreadyHasUniqueIdFilter) {
    updatedFilterItems = addNewIdsToExistingOnes(
      updatedFilterItems,
      selectedResourceType,
      uniqueIds,
    );
  } else {
    updatedFilterGroups.push(updatedFilterItems.length);
    updatedFilterItems.push({
      type: selectedResourceType,
      filter: {
        key: "provider_unique_identifier",
        operator: "not_in",
        value: uniqueIds,
      },
    });
  }

  return {
    ...appliedFilters,
    filter_groups: updatedFilterGroups,
    filter_items: updatedFilterItems,
  } as AccountResourceFilter2;
};

const uniqueIdFilterAlreadyExists = (
  appliedFilters: AccountResourceFilter2,
  selectedResourceType: ResourceType,
) => {
  return appliedFilters.filter_items.some(
    (filterItem) =>
      filterItem.type === selectedResourceType &&
      filterItem.filter.key === "provider_unique_identifier" &&
      filterItem.filter.operator === "not_in",
  );
};

const addNewIdsToExistingOnes = (
  updatedFilterItems: FilterItemWithType[],
  selectedResourceType: ResourceType,
  uniqueIds: string[],
) => {
  return updatedFilterItems.map((filterItem) => {
    const shouldBeAddedToCurrentFilter =
      filterItem.type === selectedResourceType &&
      filterItem.filter.key === "provider_unique_identifier" &&
      filterItem.filter.operator === "not_in";

    if (
      shouldBeAddedToCurrentFilter &&
      Array.isArray(filterItem.filter.value)
    ) {
      return {
        ...filterItem,
        filter: {
          ...filterItem.filter,
          value: [...filterItem.filter.value, ...uniqueIds],
        },
      };
    }

    return filterItem;
  });
};
