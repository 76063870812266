import { createAsyncThunk } from "@reduxjs/toolkit";
import { getUtilizationAlertsThunk } from "./getUtilizationAlertsThunk";
import {
  cloudChiprApi,
  UtilizationAndCoverageAlert,
} from "../../../../services/cloudchipr.api";
import { createUtilizationAlertFixedCacheKey } from "../../utils/constants/fixedCacheKeys";
import { formatNotificationsFromHtmlToSlack } from "../../../../components/utils/helpers/formatNotificationsFromHtmlToSlack";
import { enqueueSnackbarErrorAlert } from "../../../../components/pages/common/snackbar-alert/EnqueueSnackbarErrorAlert";

export const duplicateUtilizationAlertThunk = createAsyncThunk(
  "alerts/duplicateUtilizationAlert",
  async (alertData: UtilizationAndCoverageAlert, { dispatch }) => {
    const { postUsersMeOrganisationsCurrentAlertsUtilizationAndCoverage } =
      cloudChiprApi.endpoints;

    try {
      const response = await dispatch(
        postUsersMeOrganisationsCurrentAlertsUtilizationAndCoverage.initiate(
          {
            body: {
              name: alertData.name,
              provider_organisation_id: alertData.provider_organisation_id,
              status: "inactive",
              notifications:
                formatNotificationsFromHtmlToSlack(alertData.notifications) ??
                null,
              thresholds: alertData.thresholds,
              emails: alertData.emails,
              based_on: alertData.based_on,
            },
          },
          { fixedCacheKey: createUtilizationAlertFixedCacheKey },
        ),
      ).unwrap();

      await dispatch(getUtilizationAlertsThunk()).unwrap();

      return response;
    } catch (e) {
      // @ts-expect-error todo: fix api spec;
      enqueueSnackbarErrorAlert(e?.data?.message);
    }
  },
);
