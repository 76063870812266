import { FC, Fragment } from "react";
import { Card, LinearProgress } from "@mui/material";
import { CostAndUsageSumWidgetSetupDataGrid } from "./data-grid/CostAndUsageSumWidgetSetupDataGrid";
import { CostAndUsageSumWidgetSetupHeader } from "./CostAndUsageSumWidgetSetupHeader";
import { CostAndUsageSumWidgetView } from "./CostAndUsageSumWidgetView";
import { useAppSelector } from "../../../../../../../../../store/hooks";
import { widgetSetupContentChartHeight } from "../../../../../../utils/constants/stylings";
import { WidgetSetupContentWrapper } from "../../common/WidgetSetupContentWrapper";
import { costAndUsageSumSetupWidgetFilteredDataSelector } from "../../../../../../../../../store/dashboards/selectors/setups/cost-and-usage/data/costAndUsageSumSetupWidgetFilteredDataSelector";
import { costAndUsageSumSetupWidgetDataLoadingSelector } from "../../../../../../../../../store/dashboards/selectors/setups/cost-and-usage/data/costAndUsageSumSetupWidgetDataLoadingSelector";
import { costAndUsageSumSetupPropertyByKeySelector } from "../../../../../../../../../store/dashboards/selectors/setups/cost-and-usage/costAndUsageSumSetupPropertyByKeySelector";
import { DashboardNotFoundWidget } from "../../../../../widgets/common/DashboardNotFoundWidget";

export const CostAndUsageSumWidgetSetupContent: FC = () => {
  const loading = useAppSelector(costAndUsageSumSetupWidgetDataLoadingSelector);
  const data = useAppSelector(costAndUsageSumSetupWidgetFilteredDataSelector);

  const dateLabel = useAppSelector(
    costAndUsageSumSetupPropertyByKeySelector("dates"),
  )?.label;

  return (
    <Fragment>
      <WidgetSetupContentWrapper>
        <Card
          elevation={3}
          sx={{
            height: widgetSetupContentChartHeight,
            display: "flex",
            flexDirection: "column",
          }}
        >
          {loading && <LinearProgress />}

          <CostAndUsageSumWidgetSetupHeader />

          {!data && !loading ? (
            <DashboardNotFoundWidget name="" label="billing report" />
          ) : (
            <CostAndUsageSumWidgetView
              data={data ?? []}
              loading={loading}
              dateLabel={dateLabel}
            />
          )}
        </Card>
      </WidgetSetupContentWrapper>

      <CostAndUsageSumWidgetSetupDataGrid />
    </Fragment>
  );
};
