import { FC } from "react";
import InsertChartOutlinedOutlinedIcon from "@mui/icons-material/InsertChartOutlinedOutlined";
import { HierarchyAllItemsFolderNameCell } from "../../../../../common/hierarchy-all-items/components/table-cells/HierarchyAllItemsFolderNameCell";
import { HierarchyAllItemsItemNameCell } from "../../../../../common/hierarchy-all-items/components/table-cells/HierarchyAllItemsItemNameCell";
import { VisibilityType } from "../../../../../common/hierarchy-all-items/utils/types/types";

interface ResourceExplorerItemNameCellProps {
  id: string;
  name: string;
  folder: boolean;
  expanded: boolean;
  visibility: VisibilityType;
  isProtected?: boolean;
  toggleExpandedHandler(): void;
}

export const ResourceExplorerFolderViewNameCell: FC<
  ResourceExplorerItemNameCellProps
> = ({
  folder,
  name,
  id,
  visibility,
  expanded,
  toggleExpandedHandler,
  isProtected,
}) => {
  if (folder) {
    return (
      <HierarchyAllItemsFolderNameCell
        name={name}
        expanded={expanded}
        visibility={visibility}
        toggleExpandedHandler={toggleExpandedHandler}
      />
    );
  }

  return (
    <HierarchyAllItemsItemNameCell
      link={`/resource-explorer/${id}`}
      name={name}
      visibility={visibility}
      isProtected={isProtected}
      icon={InsertChartOutlinedOutlinedIcon}
    />
  );
};
