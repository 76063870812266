import { FC, Fragment, useCallback } from "react";
import { ListItemIcon, MenuItem, Tooltip, Typography } from "@mui/material";
import DriveFileMoveOutlinedIcon from "@mui/icons-material/DriveFileMoveOutlined";
import ChevronRightOutlinedIcon from "@mui/icons-material/ChevronRightOutlined";
import { DashboardV2FolderMoveActionMenu } from "./DashboardV2FolderMoveActionMenu";
import { NavigationItemsVisibilityType } from "../../../../../../../store/navigation/utils/types";
import { useMenuHook } from "../../../../../../../utils/hooks/useMenu.hook";

interface DashboardV2FolderMoveActionProps {
  id: string;
  name: string;
  onClose(): void;
  containsProtectedItem?: boolean;
  visibility: NavigationItemsVisibilityType;
}

export const DashboardV2FolderMoveAction: FC<
  DashboardV2FolderMoveActionProps
> = ({ id, name, onClose, visibility, containsProtectedItem }) => {
  const { anchor, open, openMenu, closeMenu: closeMoveMenu } = useMenuHook();

  const closeMenusHandler = useCallback(() => {
    onClose();
    closeMoveMenu();
  }, [onClose, closeMoveMenu]);

  return (
    <Fragment>
      <Tooltip
        title={
          containsProtectedItem
            ? "This folder contains protected dashboard(s) and cannot be moved."
            : ""
        }
        arrow
        placement="right"
      >
        <span>
          <MenuItem
            onClick={openMenu}
            sx={menuItemSx}
            disabled={containsProtectedItem}
          >
            <ListItemIcon>
              <DriveFileMoveOutlinedIcon fontSize="small" sx={iconSx} />
            </ListItemIcon>
            <Typography variant="body2">Move</Typography>
            <ListItemIcon sx={{ justifyContent: "flex-end", ...iconSx }}>
              <ChevronRightOutlinedIcon fontSize="small" />
            </ListItemIcon>
          </MenuItem>
        </span>
      </Tooltip>

      {open && (
        <DashboardV2FolderMoveActionMenu
          open
          id={id}
          name={name}
          anchor={anchor}
          visibility={visibility}
          onClose={closeMenusHandler}
        />
      )}
    </Fragment>
  );
};

const iconSx = { minWidth: 28 };

const menuItemSx = {
  pl: 1,
  pr: 1.5,
};
