import { createDraftSafeSelector } from "@reduxjs/toolkit";
import { stateAccountsSelector } from "./slice-data/stateAccountsSelector";

// purpose of this selector is to decide redirect to account-setup flow or not
export const noProviderConnectedSelector = createDraftSafeSelector(
  [stateAccountsSelector],
  (accounts): boolean | null => {
    if (!accounts) {
      return true;
    }

    return !accounts.length;
  },
);
