import {
  nextGroupingByCurrentGrouping,
  NextGroupingByCurrentGroupingType,
} from "./nextGroupingByCurrentGrouping";
import { ResourceExplorerFilterProvider } from "../../../../../../../../services/cloudchipr.api";

export const nextGroupingByCurrentGroupingAndProvider: Record<
  ResourceExplorerFilterProvider,
  NextGroupingByCurrentGroupingType
> = {
  aws: nextGroupingByCurrentGrouping,
  gcp: nextGroupingByCurrentGrouping,
  azure: nextGroupingByCurrentGrouping,
  confluent: nextGroupingByCurrentGrouping,
  dimensions: nextGroupingByCurrentGrouping,
  global: nextGroupingByCurrentGrouping,
  eks: {
    ...nextGroupingByCurrentGrouping,
    account: "cluster",
    cluster: "namespace",
  },
  mongo: {
    ...nextGroupingByCurrentGrouping,
    account: "organisation",
    cluster: "service",
    service: "product_family",
    product_family: null,
  },
};
