import { FC } from "react";
import { CustomCellRendererProps } from "ag-grid-react";
import Stack from "@mui/material/Stack";
import { Typography } from "@mui/material";
import { SavingsOpportunity } from "../../../../../services/cloudchipr.api";
import { ProviderIcon } from "../../../../common/ProviderIcon";
import { CopyIconButton } from "../../../../common/CopyIconButton";
import { useHover } from "../../../../../utils/hooks/useHover.hook";
import { getProviderName } from "../../../../../utils/helpers/providers/getProviderName";

export const SavingsOpportunityCloudProviderCellRenderer: FC<
  CustomCellRendererProps<SavingsOpportunity>
> = ({ data }) => {
  const { ref, hovered } = useHover();

  const provider = data?.account?.provider;

  if (!provider) {
    return null;
  }

  return (
    <Stack direction="row" spacing={1} ref={ref} alignItems="center">
      <ProviderIcon provider={provider} />
      <Typography variant="body2">
        {getProviderName(provider, { title: true })}
      </Typography>

      <CopyIconButton data={provider} visible={hovered} />
    </Stack>
  );
};
