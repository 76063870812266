import { GetQuickFilterTextParams } from "ag-grid-community";
import { Role } from "../../../../../../../../services/cloudchipr.api";

export const getQuickFilterCreatedByText = (
  params: GetQuickFilterTextParams<Role>,
) => {
  const role = params.data;

  return role.created_by?.name ?? "";
};
