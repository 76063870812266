import { FC } from "react";
import { Stack } from "@mui/material";
import { GridApi } from "ag-grid-community";
import { ReportsFrequenciesFilter } from "./filters/ReportsFrequenciesFilter";
import { ReportsSourceFilter } from "./filters/ReportsSourceFilter";
import { ReportsEnabledFilter } from "./filters/ReportsEnabledFilter";
import { ReportsCreatedByFilter } from "./filters/ReportsCreatedByFilter";
import { ReportsSearch } from "./ReportsSearch";
import { AGGridColumnsToolPanel } from "../../../../../common/ag-grid/components/AGGridColumnsToolPanel";

interface ReportsTableToolbarProps {
  gridApi?: GridApi;
}

export const ReportsTableToolbar: FC<ReportsTableToolbarProps> = ({
  gridApi,
}) => {
  return (
    <Stack
      p={2}
      top={0}
      spacing={2}
      bgcolor="white"
      zIndex="appBar"
      borderBottom={1}
      position="sticky"
      borderColor="grey.300"
      justifyContent="space-between"
      direction="row"
    >
      <Stack direction="row" spacing={2}>
        <ReportsFrequenciesFilter />
        <ReportsSourceFilter />
        <ReportsCreatedByFilter />
        <ReportsEnabledFilter />
      </Stack>

      <Stack direction="row" spacing={2}>
        <ReportsSearch gridApi={gridApi} />

        <AGGridColumnsToolPanel gridApi={gridApi ?? null} />
      </Stack>
    </Stack>
  );
};
