import { FC } from "react";
import { Typography } from "@mui/material";
import { ExampleTypography } from "./ExampleTypography";
import { useAppSelector } from "../../../../../../../store/hooks";
import { reportsDataPropertyByKeySelector } from "../../../../../../../store/reports/selector/reportsDataPropertyByKeySelector";
import { getLabelByBillingSourceType } from "../../../../../../utils/helpers/getLabelByBillingSourceType";

export const ReportsCustomMessageBody: FC = () => {
  const source = useAppSelector(
    reportsDataPropertyByKeySelector("source_type"),
  );
  const sourceLabel = getLabelByBillingSourceType(source, true);

  return (
    <Typography variant="body1" lineHeight={1.5}>
      Please find the attached report, generated from the{" "}
      <ExampleTypography>{`${sourceLabel} name`}</ExampleTypography>{" "}
      {sourceLabel} and sent by <ExampleTypography>user name</ExampleTypography>{" "}
      via Cloudchipr.
    </Typography>
  );
};
