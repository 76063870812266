import { createAsyncThunk } from "@reduxjs/toolkit";
import { getCostAnomalyAlertsThunk } from "./getCostAnomalyAlertsThunk";
import {
  cloudChiprApi,
  CostAnomalyAlert,
} from "../../../../services/cloudchipr.api";
import { createCostAnomalyAlertFixedCacheKey } from "../../utils/constants/fixedCacheKeys";
import { getViewLinkByAlertData } from "../../utils/helpers/getViewLinkByAlertData";
import { formatNotificationsFromHtmlToSlack } from "../../../../components/utils/helpers/formatNotificationsFromHtmlToSlack";
import { enqueueSnackbarErrorAlert } from "../../../../components/pages/common/snackbar-alert/EnqueueSnackbarErrorAlert";

export const duplicateCostAnomalyAlertThunk = createAsyncThunk(
  "alerts/duplicateCostAnomalyAlert",
  async (alertData: CostAnomalyAlert, { dispatch }) => {
    const { postUsersMeOrganisationsCurrentAlertsCostAnomaly } =
      cloudChiprApi.endpoints;
    const viewLink = getViewLinkByAlertData(
      alertData.grouping,
      alertData.time_interval.time_interval_unit,
      alertData.time_interval.time_interval?.toString(),
    );

    try {
      const response = await dispatch(
        postUsersMeOrganisationsCurrentAlertsCostAnomaly.initiate(
          {
            body: {
              name: alertData.name,
              view_id: alertData.view_id,
              view_link: viewLink,
              status: "inactive",
              group_by: alertData.grouping,
              notifications:
                formatNotificationsFromHtmlToSlack(alertData.notifications) ??
                null,
              thresholds: alertData.thresholds,
              threshold: alertData.threshold,
              emails: alertData.emails,
              time_interval: alertData.time_interval,
            },
          },
          { fixedCacheKey: createCostAnomalyAlertFixedCacheKey },
        ),
      ).unwrap();

      await dispatch(getCostAnomalyAlertsThunk()).unwrap();

      return response;
    } catch (e) {
      // @ts-expect-error todo: fix api spec;
      enqueueSnackbarErrorAlert(e?.data?.message);
    }
  },
);
