import { SavingsOpportunity } from "../../../../../services/cloudchipr.api";

export const possibleEfforts: SavingsOpportunity["implementation_effort"][] = [
  "very_low",
  "low",
  "medium",
  "high",
  "very_high",
];

export const possibleEffortsLabels: Record<
  SavingsOpportunity["implementation_effort"],
  string
> = {
  very_low: "Very Low",
  low: "Low",
  medium: "Medium",
  high: "High",
  very_high: "Very High",
};
