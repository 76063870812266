import { FC } from "react";
import { CustomCellRendererProps } from "ag-grid-react";
import { Box, Chip } from "@mui/material";
import { SavingsOpportunity } from "../../../../../services/cloudchipr.api";
import { IconByResourceType } from "../../../common/IconByResourceType";
import { getResourceTypeName } from "../../../../../utils/helpers/resources/getResourceTypeName";
import { TypographyWithTooltip } from "../../../../common/TypographyWithTooltip";

export const SavingsOpportunityServiceCellRenderer: FC<
  CustomCellRendererProps<SavingsOpportunity>
> = (cell) => {
  const data = cell.data as SavingsOpportunity;

  if (!data) {
    return null;
  }

  if (!data.service) {
    return (
      <Chip
        sx={{ borderRadius: 2, px: 0.5 }}
        label={
          <TypographyWithTooltip variant="body2" title={data.resource_type} />
        }
      />
    );
  }

  return (
    <Chip
      sx={{ borderRadius: 2, px: 0.5 }}
      avatar={
        <Box sx={{ filter: "grayscale(1)" }}>
          <IconByResourceType resourceType={data.service} />
        </Box>
      }
      label={
        <TypographyWithTooltip
          variant="body2"
          title={getResourceTypeName(data.service, {
            type: "long",
            singular: true,
          })}
        />
      }
    />
  );
};
