import { LabelProps } from "recharts";
import { FC } from "react";
import { YAxisFormatter } from "./CustomizedYAxisTick";

interface ApproxBarLabelProps extends LabelProps {
  color: string;
  opacity: number | string;
  yAxisFormatter?: YAxisFormatter;
}

export const ApproxBarLabel: FC<ApproxBarLabelProps> = ({
  x,
  y,
  width,
  height,
  value,
  color,
  opacity,
  yAxisFormatter,
}) => {
  const barWidth = +(width ?? 0);
  const barHeight = +(height ?? 0);
  if (!value || Math.abs(+(height ?? 0)) < 12) {
    return null;
  }

  const textX = +(x ?? 0) + barWidth / 2;
  const textY = +(y ?? 0) + barHeight / 2;

  const formattedValue = yAxisFormatter ? yAxisFormatter(value) : value;
  const textWidth = formattedValue.toString().length * 6;
  const rectWidth = textWidth + padding;
  const rectHeight = textHeight + padding;

  if (barWidth < 32 + padding) {
    return null;
  }

  const rectX = textX - rectWidth / 2;
  const rectY = textY - rectHeight / 2;

  return (
    <g opacity={opacity}>
      <rect
        x={rectX}
        y={rectY}
        width={rectWidth}
        height={rectHeight}
        fill="white"
        rx={3}
        ry={3}
      />
      <text
        x={textX}
        y={textY}
        dy={4}
        fill={color}
        textAnchor="middle"
        style={{ fontSize: 10, fontWeight: "bold" }}
      >
        {formattedValue}
      </text>
    </g>
  );
};

const textHeight = 12;
const padding = 3;
