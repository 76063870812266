import { FC, Fragment, useCallback } from "react";
import { ListItemIcon, MenuItem, Tooltip, Typography } from "@mui/material";
import DriveFileMoveOutlinedIcon from "@mui/icons-material/DriveFileMoveOutlined";
import ChevronRightOutlinedIcon from "@mui/icons-material/ChevronRightOutlined";
import { DashboardV2ItemMoveActionMenu } from "./DashboardV2ItemMoveActionMenu";
import { useMenuHook } from "../../../../../../../../utils/hooks/useMenu.hook";
import { NavigationItemsVisibilityType } from "../../../../../../../../store/navigation/utils/types";

interface DashboardV2ItemMoveActionProps {
  id: string;
  name: string;
  folderId?: string;
  onClose(): void;
  isProtected: boolean;
  visibility: NavigationItemsVisibilityType;
}

export const DashboardV2ItemMoveAction: FC<DashboardV2ItemMoveActionProps> = ({
  id,
  name,
  folderId,
  onClose,
  visibility,
  isProtected,
}) => {
  const { anchor, open, openMenu, closeMenu: closeMoveMenu } = useMenuHook();

  const closeMenusHandler = useCallback(() => {
    onClose();
    closeMoveMenu();
  }, [onClose, closeMoveMenu]);

  return (
    <Fragment>
      <Tooltip
        title={isProtected ? "Protected dashboards cannot be modified." : ""}
        arrow
        placement="right"
      >
        <span>
          <MenuItem onClick={openMenu} sx={menuItemSx} disabled={isProtected}>
            <ListItemIcon>
              <DriveFileMoveOutlinedIcon fontSize="small" sx={iconSx} />
            </ListItemIcon>
            <Typography variant="body2">Move</Typography>
            <ListItemIcon sx={{ justifyContent: "flex-end", ...iconSx }}>
              <ChevronRightOutlinedIcon fontSize="small" />
            </ListItemIcon>
          </MenuItem>
        </span>
      </Tooltip>

      {open && (
        <DashboardV2ItemMoveActionMenu
          open
          id={id}
          name={name}
          anchor={anchor}
          folderId={folderId}
          visibility={visibility}
          onClose={closeMenusHandler}
        />
      )}
    </Fragment>
  );
};

const iconSx = { minWidth: 28 };

const menuItemSx = {
  pl: 1,
  pr: 1.5,
};
