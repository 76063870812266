import { FC, useCallback, useEffect, useState } from "react";
import { CustomCellRendererProps } from "ag-grid-react";
import { TaskPropCellRendererWrapper } from "./TaskPropCellRendererWrapper";
import { Task } from "../../../../../../services/cloudchipr.api";
import { useAppDispatch } from "../../../../../../store/hooks";
import { taskManagementUpdateTaskThunk } from "../../../../../../store/task-management/thunks/actions/taskManagementUpdateTaskThunk";
import { TaskPropertySelection } from "../../../../common/task-management/components/common/properties/task-property-selection/TaskPropertySelection";
import { autoGroupColumnId } from "../auto-group-column/getAutoGroupColumnDef";

export const EnvironmentCellRenderer: FC<CustomCellRendererProps<Task>> = ({
  data,
  colDef,
  column,
}) => {
  const dispatch = useAppDispatch();
  const [environmentId, setEnvironmentId] = useState<string | null>();
  const colId = column?.getColId() || colDef?.colId;
  const inGroup = colId === autoGroupColumnId;

  const changeHandler = useCallback(
    (value: string | null) => {
      if (!data?.id || environmentId === value) {
        return null;
      }

      setEnvironmentId(value);

      dispatch(
        taskManagementUpdateTaskThunk({
          task_ids: [data.id],
          environment_id: { value },
        }),
      );
    },
    [dispatch, data?.id, environmentId],
  );

  useEffect(() => {
    setEnvironmentId(data?.environment?.id ?? null);
  }, [data?.environment?.id]);

  if (!data) {
    return null;
  }

  return (
    <TaskPropCellRendererWrapper propertyType="environments" inGroup={inGroup}>
      <TaskPropertySelection
        inCell
        optionsKey="environments"
        value={environmentId}
        label="Environment"
        disableSelection={inGroup}
        onChange={changeHandler}
      />
    </TaskPropCellRendererWrapper>
  );
};
