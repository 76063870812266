import { FC, Fragment, useCallback } from "react";
import { Card, Stack, Typography } from "@mui/material";
import { DigestOption } from "./components/DigestOption";
import { PageHeader } from "../../../common/PageHeader";
import {
  useGetUsersMeCurrentDigestPreferencesQuery,
  usePatchUsersMeCurrentDigestPreferencesMutation,
} from "../../../../../services/cloudchipr.api";
import { DigestType } from "../../utils/types";
import { enqueueSnackbarErrorAlert } from "../../../common/snackbar-alert/EnqueueSnackbarErrorAlert";

export const EmailNotifications: FC = () => {
  const {
    data: digests,
    refetch,
    isLoading,
    isFetching,
  } = useGetUsersMeCurrentDigestPreferencesQuery();

  const [updateDigests, { isLoading: updateDigestsLoading }] =
    usePatchUsersMeCurrentDigestPreferencesMutation();

  const toggleDigest = useCallback(
    async (type: DigestType) => {
      if (!digests) {
        return;
      }

      try {
        await updateDigests({
          body: {
            ...digests,
            [type]: !digests[type],
          },
        }).unwrap();

        await refetch();
      } catch (e) {
        // @ts-expect-error todo: fix api spec;
        enqueueSnackbarErrorAlert(e?.data?.message);
      }
    },
    [digests, updateDigests, refetch],
  );

  const toggleWeeklyDigest = useCallback(
    () => toggleDigest("weekly_digest"),
    [toggleDigest],
  );
  const toggleMonthlyDigest = useCallback(
    () => toggleDigest("monthly_digest"),
    [toggleDigest],
  );

  const loading = isLoading || isFetching || updateDigestsLoading;

  return (
    <Stack height="100%">
      <PageHeader loading={loading} title="Email Notifications" />

      <Stack p={2} flexGrow={1}>
        <Card variant="outlined" sx={{ p: 4, flexGrow: 1 }}>
          <Stack spacing={3}>
            <Typography variant="h6">
              Select the frequency to receive cost digests.
            </Typography>

            {digests && (
              <Fragment>
                <DigestOption
                  label="Weekly digests"
                  description="Weekly digests will be sent on Mondays."
                  checked={digests.weekly_digest}
                  disabled={loading}
                  toggleDigest={toggleWeeklyDigest}
                />
                <DigestOption
                  label="Monthly digests"
                  description="Monthly digests will be sent on the second day of each month."
                  checked={digests.monthly_digest}
                  disabled={loading}
                  toggleDigest={toggleMonthlyDigest}
                />
              </Fragment>
            )}
          </Stack>
        </Card>
      </Stack>
    </Stack>
  );
};
