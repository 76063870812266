import { ColumnSetupType } from "../../../../../../../../../../../../../../storybook/data-grid/utils/types/types";
import { DynamoDbTable } from "../../../../../../../../../../../../../../services/cloudchipr.api";

export const dynamoDbTableColumns: ColumnSetupType<DynamoDbTable>[] = [
  {
    accessorKey: "name",
    header: "Name",
    type: "identifier",
    size: 210,
    meta: { sticky: "left", hideFromSettings: true },
  },
  {
    accessorKey: "price_per_month",
    header: "Monthly Price",
    type: "money",
  },
  {
    accessorKey: "account",
    header: "Account",
    type: "account",
    size: 210,
  },
  {
    accessorKey: "created_at",
    header: "Date Created",
    type: "date",
  },
  {
    accessorKey: "all_time_spent",
    header: "Total Spend",
    type: "money",
  },
  {
    accessorKey: "size",
    header: "Table Size",
    type: "byteToGib",
  },
  {
    accessorKey: "table_class",
    header: "Table Class",
    type: "stringWithNA",
  },
  {
    accessorKey: "provider_status",
    header: "Status",
  },
  {
    accessorKey: "replicas",
    header: "Replicas",
  },
  {
    accessorKey: "read_usage_value",
    header: "Read Usage",
    type: "readUsage",
    headerTooltip: "Max read usage for the last 7 days.",
  },
  {
    accessorKey: "rcu_reservation_value",
    header: "RCU Reservation",
    type: "rcuReservation",
    headerTooltip: "Current RCU reservation.",
  },
  {
    accessorKey: "write_usage_value",
    header: "Write Usage",
    type: "writeUsage",
    headerTooltip: "Max write usage for the last 7 days.",
  },
  {
    accessorKey: "wcu_reservation_value",
    header: "WCU Reservation",
    type: "wcuReservation",
    headerTooltip: "Current WCU reservation.",
  },
  {
    minSize: 200,
    type: "capacityMode",
    accessorKey: "read_capacity_mode",
    header: "Read Capacity Mode",
  },
  {
    minSize: 200,
    type: "capacityMode",
    accessorKey: "write_capacity_mode",
    header: "Write Capacity Mode",
  },
  {
    accessorKey: "creation_data",
    header: "Created By",
    type: "createdBy",
    headerTooltip:
      "The Created By value is only available for resources created within the last 90 days.",
  },
  { accessorKey: "region", header: "Region", type: "withCopy" },
  { accessorKey: "tags", header: "Tags", type: "tag", enableSorting: false },
  {
    accessorKey: "tags",
    header: "Smart Tags",
    id: "smart_tag",
    type: "smartTag",
    enableSorting: false,
  },

  {
    accessorKey: "task",
    header: "Task Status",
    type: "taskStatus",
    enableSorting: false,
  },
  {
    accessorKey: "issues",
    header: "JIRA Status",
    type: "jiraTicketStatus",
    enableSorting: false,
  },
];
