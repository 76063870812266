import { FC } from "react";
import { Typography } from "@mui/material";
import { LiveFilteredTitle } from "../../../../../../../resource-explorer/components/resource-explorer-card/components/resource-explorer-metadata/components/live-usage/LiveFilteredTitle";

export const CostAndUsageSumLiveFilteredTitle: FC<{
  fontSize: number;
  label: string;
}> = ({ fontSize, label }) => {
  return (
    <LiveFilteredTitle
      tooltipTitle="Total monthly price of live filtered resources found in Live Usage & Mgmt for the current report."
      large
      customTextComponent={
        <Typography
          variant="caption"
          color="text.secondary"
          whiteSpace="nowrap"
          fontSize={fontSize}
        >
          {label}
        </Typography>
      }
    />
  );
};
