import { capitalize } from "@mui/material";

export const getLabelByBillingSourceType = (
  sourceType: "view" | "dashboard",
  fullLabel?: boolean,
): string => {
  if (sourceType === "view") {
    if (fullLabel) {
      return "billing report";
    }
    return "Report";
  }

  return capitalize(sourceType);
};
