import moment from "moment/moment";
import { GetQuickFilterTextParams } from "ag-grid-community";
import { Schedule } from "../../../../../../../services/cloudchipr.api";
import { getTimeZoneObject } from "../../../../../../../utils/helpers/date-time/timeZones";
import { formatDate } from "../../../../../../../utils/helpers/date-time/datetime-format";

export const quickFilterNextRunText = (
  params: GetQuickFilterTextParams<Schedule>,
) => {
  const schedule = params.data;

  const status = schedule.status;
  const nextRun = schedule?.metadata.next_run_at;
  const timeZone = `${
    getTimeZoneObject(schedule.time_zone).name
  } More`.toLowerCase();

  if (status === "inactive") {
    return "";
  }

  if (!nextRun) {
    return "never";
  }

  const nextRunDate = formatDate(nextRun, {
    type: "dateWithComma",
  });
  const nextRunTime = formatDate(nextRun, {
    type: "time",
  });

  return `${moment(nextRun).format("ddd")}, ${nextRunDate} ${nextRunTime} ${timeZone}`.toLowerCase();
};
