import { FC, Fragment, useCallback } from "react";
import { Stack } from "@mui/material";
import { LargestCostChangesPriceDirection } from "./app-bar/LargestCostChangesPriceDirection";
import { LargestCostChangesSortBy } from "./app-bar/LargestCostChangesSortBy";
import { LargestCostChangesSortByItemsCount } from "./app-bar/LargestCostChangesSortByItemsCount";
import { LargestCostChangesWidgetSetupContent } from "./content/LargestCostChangesWidgetSetupContent";
import { LargestCostChangesGroupingSelect } from "./app-bar/LargestCostChangesGroupingSelect";
import { LargestCostChangesMinChange } from "./app-bar/LargestCostChangesMinChange";
import { LargestCostChangesDateRangeSelect } from "./app-bar/LargestCostChangesDateRangeSelect";
import { WidgetSetupHeader } from "../common/WidgetSetupHeader";
import { WidgetSetupLayout } from "../common/WidgetSetupLayout";
import { WidgetsViewsHierarchySelect } from "../common/widgets-views-hierarchy-select/WidgetsViewsHierarchySelect";
import { scrollPageToBottom } from "../../../../../../utils/helpers/page-scrolling-functions";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../store/hooks";
import { resourceExplorerViewByIdLoadingSelector } from "../../../../../../../../store/resource-explorer/selectors/resource-explorer-by-id/resourceExplorerViewByIdLoadingSelector";
import { largestCostChangesREViewChangeThunk } from "../../../../../../../../store/dashboards/thunks/widgets/largest-cost-changes/widget-change/largestCostChangesREViewChangeThunk";
import { largestCostChangesSetupPropertyByKeySelector } from "../../../../../../../../store/dashboards/selectors/setups/larges-cost-changes/largestCostChangesSetupPropertyByKeySelector";
import { buildLargestCostChangesWidgetThunk } from "../../../../../../../../store/dashboards/thunks/widgets/largest-cost-changes/actions/buildLargestCostChangesWidgetThunk";
import { largesCostChangesSubmitButtonDisabledSelector } from "../../../../../../../../store/dashboards/selectors/setups/larges-cost-changes/largesCostChangesSubmitButtonDisabledSelector";

interface LargestCostChangesSetupProps {
  onClose(): void;
  onSubmit(): void;
}
export const LargestCostChangesWidgetSetup: FC<
  LargestCostChangesSetupProps
> = ({ onClose, onSubmit }) => {
  const dispatch = useAppDispatch();

  const viewId = useAppSelector(
    largestCostChangesSetupPropertyByKeySelector("viewId"),
  );
  const viewGettingLoading = useAppSelector((state) =>
    resourceExplorerViewByIdLoadingSelector(state, viewId),
  );
  const setupId = useAppSelector(
    largestCostChangesSetupPropertyByKeySelector("id"),
  );
  const disabled = useAppSelector(
    largesCostChangesSubmitButtonDisabledSelector,
  );

  const viewChangeHandler = useCallback(
    (id: string) => {
      dispatch(largestCostChangesREViewChangeThunk(id));
    },
    [dispatch],
  );

  const submitHandler = useCallback(async () => {
    const responseId = await dispatch(
      buildLargestCostChangesWidgetThunk(),
    ).unwrap();
    onSubmit();

    if (responseId && !setupId) {
      scrollPageToBottom();
    }
  }, [dispatch, setupId, onSubmit]);

  return (
    <Fragment>
      <WidgetSetupHeader
        disabled={disabled}
        onAdd={submitHandler}
        onCancel={onClose}
      />

      <WidgetSetupLayout
        empty={!viewId ? "Select Billing Report to get started." : ""}
        appBar={
          <Fragment>
            <WidgetsViewsHierarchySelect
              selectedItemId={viewId}
              onChange={viewChangeHandler}
            />

            <LargestCostChangesGroupingSelect disabled={viewGettingLoading} />

            <LargestCostChangesPriceDirection />

            <LargestCostChangesDateRangeSelect />

            <Stack direction="row" spacing={2}>
              <LargestCostChangesSortBy />
              <LargestCostChangesSortByItemsCount />
            </Stack>

            <LargestCostChangesMinChange type="minCostChange" />
            <LargestCostChangesMinChange type="minPercentChange" />
          </Fragment>
        }
      >
        <LargestCostChangesWidgetSetupContent />
      </WidgetSetupLayout>
    </Fragment>
  );
};
