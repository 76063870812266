import { FC } from "react";
import * as yup from "yup";
import { useFormik } from "formik";
import { useAuth0 } from "@auth0/auth0-react";
import { Stack, TextField } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useSnackbar } from "notistack";
import {
  useGetUsersMeQuery,
  usePatchUsersMeMutation,
} from "../../../../../../services/cloudchipr.api";
import { enqueueSnackbarErrorAlert } from "../../../../common/snackbar-alert/EnqueueSnackbarErrorAlert";

export const ProfileUpdateForm: FC = () => {
  const { user: userAuth0 } = useAuth0();
  const { enqueueSnackbar } = useSnackbar();

  const [triggerPatch, { isLoading }] = usePatchUsersMeMutation();
  const { data: user, refetch } = useGetUsersMeQuery({});

  const userProfile = {
    name: user?.name ? user.name : userAuth0?.name || "",
    email: user?.email ? user.email : userAuth0?.email || "",
    company_name: user?.company_name ? user.company_name : "",
    position: user?.position ? user.position : "",
  };

  const formik = useFormik({
    initialValues: userProfile,
    validationSchema: yup.object({
      name: yup.string().required(),
      email: yup.string().trim().email().required(),
      company_name: yup.string(),
      position: yup.string(),
    }),
    onSubmit: async (values) => {
      try {
        await triggerPatch({
          body: {
            ...values,
          },
        });
        enqueueSnackbar("Profile updated successfully!", {
          variant: "snackbarAlert",
          AlertSnackBarProps: {
            severity: "success",
          },
        });
        refetch();
      } catch {
        enqueueSnackbarErrorAlert();
      }
    },
    enableReinitialize: true,
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Stack spacing={2}>
        <TextField
          type="text"
          label="Full name*"
          name="name"
          value={formik.values.name}
          margin="normal"
          size="small"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={!!(formik.errors.name && formik.touched.name)}
        />
        <TextField
          type="email"
          label="Email*"
          name="email"
          value={formik.values.email}
          margin="normal"
          size="small"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={!!(formik.errors.email && formik.touched.email)}
        />
        <TextField
          type="text"
          label="Company Name"
          name="company_name"
          value={formik.values.company_name}
          size="small"
          margin="normal"
          onChange={formik.handleChange}
        />
        <TextField
          type="text"
          label="Position"
          name="position"
          value={formik.values.position}
          size="small"
          margin="normal"
          onChange={formik.handleChange}
        />
        <LoadingButton
          type="submit"
          disabled={!(formik.isValid && formik.dirty)}
          color="primary"
          variant="contained"
          loading={isLoading}
        >
          Update profile
        </LoadingButton>
      </Stack>
    </form>
  );
};
