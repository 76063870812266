import { FC, Fragment, useMemo } from "react";
import { Stack, TextField, Tooltip } from "@mui/material";
import { TextFieldProps } from "@mui/material/TextField/TextField";
import ArrowDropUpOutlinedIcon from "@mui/icons-material/ArrowDropUpOutlined";
import ArrowDropDownOutlinedIcon from "@mui/icons-material/ArrowDropDownOutlined";
import { GroupingOptionIcon } from "../GroupingOptionIcon";
import { FilterTriggerComponentProps } from "../../../../../common/select/dropdown-select/utils/types/filterTriggerComponentProps";
import {
  ResourceExplorerGrouping,
  useGetUsersMeOrganisationsCurrentResourceExplorerFiltersByFilterProviderAndFilterTypeValuesQuery as useValuesQuery,
} from "../../../../../../services/cloudchipr.api";
import { getResourcesExplorerGroupingLabel } from "../../../../resource-explorer/components/resource-explorer-card/utils/helpers/getResourcesExplorerGroupingLabel";
import { CountChipWithTooltip } from "../CountChipWithTooltip";
import { useAppSelector } from "../../../../../../store/hooks";
import { connectedProvidersSelector } from "../../../../../../store/common/selectors/connectedProvidersSelector";
import { resourceExplorerAllGroupings } from "../../../../resource-explorer/components/resource-explorer-card/utils/constants/groupings";
import { GroupingAvailableProviders } from "../GroupingAvailableProviders";

interface GroupBySelectorTriggerProps extends FilterTriggerComponentProps {
  groupValues?: string[];
  size?: TextFieldProps["size"];
}

export const GroupBySelectorTrigger: FC<GroupBySelectorTriggerProps> = ({
  onClick,
  open,
  disabled,
  selectedValues,
  groupValues,
  size = "small",
}) => {
  const providers = useAppSelector(connectedProvidersSelector);
  const firstValue = selectedValues.at(0) as ResourceExplorerGrouping;

  const groupBy = resourceExplorerAllGroupings.includes(firstValue)
    ? firstValue
    : "category";

  const { data } = useValuesQuery(
    { filterProvider: "dimensions", filterType: "dimension_id" },
    { skip: groupBy !== "category" },
  );

  const dimensionName = useMemo(() => {
    if (groupBy !== "category") {
      return "";
    }

    const firstValue = groupValues?.at(0);
    const selected = data?.find((option) => option.value === firstValue);

    return selected?.title ?? selected?.value ?? "";
  }, [groupValues, groupBy, data]);

  const label =
    groupBy === "category"
      ? dimensionName
      : getResourcesExplorerGroupingLabel(groupBy, true, providers);

  const tooltip = dimensionName ? label : "";

  return (
    <Tooltip title={open ? "" : tooltip} arrow>
      <TextField
        size={size}
        label="Group by"
        onClick={onClick}
        disabled={disabled}
        contentEditable={false}
        value={firstValue ? label : ""}
        InputProps={{
          readOnly: true,
          inputProps: {
            style: {
              paddingLeft: 8,
              cursor: "pointer",
              caretColor: "transparent",
              textOverflow: "ellipsis",
              overflow: "hidden",
            },
          },
          sx: { pr: 1, cursor: "pointer", minWidth: 175 },
          endAdornment: (
            <TriggerEndAdornment
              open={open}
              values={
                groupBy === "cost_allocation_tag" ? groupValues : undefined
              }
              grouping={groupBy}
            />
          ),
          startAdornment: firstValue ? (
            <GroupingOptionIcon grouping={groupBy} />
          ) : undefined,
        }}
      />
    </Tooltip>
  );
};

const TriggerEndAdornment: FC<{
  open: boolean;
  values?: string[];
  grouping: ResourceExplorerGrouping;
}> = ({ open, values, grouping }) => {
  return (
    <Fragment>
      <Stack direction="row" alignItems="center" spacing={0.5}>
        <GroupingAvailableProviders size="small" grouping={grouping} />

        {!!values?.length && <CountChipWithTooltip values={values} />}
      </Stack>

      {open ? (
        <ArrowDropUpOutlinedIcon color="action" />
      ) : (
        <ArrowDropDownOutlinedIcon color="action" />
      )}
    </Fragment>
  );
};
