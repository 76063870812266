import { createAsyncThunk } from "@reduxjs/toolkit";
import { checkForOrganizationExistenceThunk } from "../checkForOrganizationExistenceThunk";
import { RootState } from "../../../../store";
import { cloudChiprApi } from "../../../../../services/cloudchipr.api";
import { reservationsRecommendationsPayloadSelector } from "../../../selectors/recommendations/reservations/reservationsRecommendationsPayloadSelector";
import { enqueueSnackbarErrorAlert } from "../../../../../components/pages/common/snackbar-alert/EnqueueSnackbarErrorAlert";

export const getReservationsRecommendationsThunk = createAsyncThunk(
  "commitments/getReservationsRecommendationsThunk",
  async (_: void, { dispatch, getState }) => {
    dispatch(checkForOrganizationExistenceThunk());

    const state = getState() as RootState;

    const { getUsersMeOrganisationsCurrentReservationsRecommendations } =
      cloudChiprApi.endpoints;

    const payload = reservationsRecommendationsPayloadSelector(state);
    try {
      await dispatch(
        getUsersMeOrganisationsCurrentReservationsRecommendations.initiate({
          ...payload,
        }),
      ).unwrap();
    } catch (e) {
      // @ts-expect-error todo: fix api spec;
      enqueueSnackbarErrorAlert(e?.data?.message);

      return null;
    }
  },
);
