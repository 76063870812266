import { createAsyncThunk } from "@reduxjs/toolkit";
import { generateGroupedRolePermissions } from "./utils/generateGroupedRolePermissions";
import { applyRolePermissionsToPermissionsList } from "./utils/applyRolePermissionsToPermissionsList";
import { cloudChiprApi } from "../../../../services/cloudchipr.api";
import { setUserRoleData } from "../../userSlice";
import { userRolePermissionsSelector } from "../../selectors/roles/userRolePermissionsSelector";
import { RootState } from "../../../store";
import { enqueueSnackbarErrorAlert } from "../../../../components/pages/common/snackbar-alert/EnqueueSnackbarErrorAlert";

export const getUserRoleByIdThunk = createAsyncThunk(
  "roles/getUserRoleById",
  async (id: string, { dispatch, getState }) => {
    const state = getState() as RootState;
    const permissionsList = userRolePermissionsSelector(state);
    try {
      const response = await dispatch(
        cloudChiprApi.endpoints.getUsersMeOrganisationsCurrentRolesByRoleId.initiate(
          { roleId: id },
          {
            forceRefetch: true,
          },
        ),
      ).unwrap();

      if (!response) {
        return;
      }
      const appliedData = applyRolePermissionsToPermissionsList(
        permissionsList ?? [],
        response.permissions ?? [],
      );
      dispatch(
        setUserRoleData({
          name: response.name,
          description: response.description ?? "",
          permissions: generateGroupedRolePermissions(appliedData),
        }),
      );
    } catch (e) {
      // @ts-expect-error todo: fix api spec;
      enqueueSnackbarErrorAlert(e?.data?.message);
    }
  },
);
