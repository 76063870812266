import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  cloudChiprApi,
  PostUsersMeOffHoursSchedulesApiArg,
} from "../../../services/cloudchipr.api";
import { offHoursDataSelector } from "../../automations/selectros/off-hours/data/offHoursDataSelector";
import { RootState } from "../../store";
import { enqueueSnackbarErrorAlert } from "../../../components/pages/common/snackbar-alert/EnqueueSnackbarErrorAlert";

interface DuplicateOffHoursThunkArgs {
  id: string;
  name: string;
}
export const duplicateOffHoursThunk = createAsyncThunk(
  "schedules/duplicateOffHours",
  async ({ id, name }: DuplicateOffHoursThunkArgs, { dispatch, getState }) => {
    const { postUsersMeOffHoursSchedules } = cloudChiprApi.endpoints;
    const state = getState() as RootState;

    const data = offHoursDataSelector(state);
    const offHours = data?.find((item) => item.id === id);

    if (!offHours) {
      return;
    }

    const {
      start_date_time,
      end_date_time,
      start_cron,
      stop_cron,
      regions,
      action,
      time_zone,
      min_threshold,
      emails,
      accounts,
      notifications,
    } = offHours;
    const accountIds = accounts.map((item) => item.id);

    const body: PostUsersMeOffHoursSchedulesApiArg = {
      body: {
        name,
        start_date_time,
        end_date_time,
        start_cron,
        stop_cron,
        regions,
        action,
        time_zone,
        min_threshold,
        emails,
        notifications,
        account_ids: accountIds ?? [],
        filter: offHours?.filter ?? [],
        status: "inactive",
      },
    };
    try {
      return await dispatch(
        postUsersMeOffHoursSchedules.initiate(body, {
          fixedCacheKey: id,
        }),
      ).unwrap();
    } catch (e) {
      // @ts-expect-error todo: fix api spec;
      enqueueSnackbarErrorAlert(e?.data?.message);
    }
  },
);
