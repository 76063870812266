import { useEffect } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useEffectOnceWhen } from "rooks";
import { accountTabs } from "./useAccountStorageMemoizedTabHook";
import { ProviderType } from "../../../../../services/cloudchipr.api";
import {
  accountIdsSearchParam,
  accountsPageTabSearchParam,
} from "../../../live-usage-mgmt/utils/constants/searchParams";
import { useAppSelector } from "../../../../../store/hooks";
import { accountOrganisationIdSelector } from "../../../../../store/accounts/selectors/slice-data/accountOrganisationIdSelector";
import { AccountTabsWithResources } from "../types/types";

export function useAccountTabInitializerHook(
  activeAccountsLength: number,
  inactiveAccountsLength: number,
) {
  const navigate = useNavigate();
  const { provider } = useParams<{ provider: ProviderType }>();

  const orgId = useAppSelector(accountOrganisationIdSelector);

  const [searchParams] = useSearchParams();
  const accountIdsFromSearchParams = searchParams.get(accountIdsSearchParam);
  const accountTypeFromSearchParams = searchParams.get(
    accountsPageTabSearchParam,
  );

  const activeTab = (
    accountIdsFromSearchParams ? "live-resources" : accountTypeFromSearchParams
  ) as AccountTabsWithResources;

  useEffect(() => {
    if (accountIdsFromSearchParams && !activeAccountsLength) {
      navigate(
        `/${provider}/${orgId}/accounts?${accountsPageTabSearchParam}=active`,
      );
    }
  }, [
    accountIdsFromSearchParams,
    orgId,
    provider,
    navigate,
    activeAccountsLength,
  ]);

  useEffect(() => {
    if (!accountTypeFromSearchParams && activeTab !== "live-resources") {
      navigate(
        `/${provider}/${orgId}/accounts?${accountsPageTabSearchParam}=active`,
      );
    }
  }, [accountTypeFromSearchParams, orgId, provider, navigate, activeTab]);

  useEffectOnceWhen(
    () => {
      navigate(
        `${location.pathname}?${accountsPageTabSearchParam}=${accountTabs.inactive}`,
        { replace: true },
      );
    },
    activeTab === "active" && !activeAccountsLength && !!inactiveAccountsLength,
  );

  useEffectOnceWhen(
    () => {
      navigate(
        `${location.pathname}?${accountsPageTabSearchParam}=${accountTabs.active}`,
        { replace: true },
      );
    },
    activeTab === "inactive" &&
      !inactiveAccountsLength &&
      !!activeAccountsLength,
  );

  return {
    provider,
    activeTab,
    activeAccountsLength,
    inactiveAccountsLength,
    renderOutlet: !(accountIdsFromSearchParams && !activeAccountsLength),
  };
}
