import { createAsyncThunk } from "@reduxjs/toolkit";
import { closeSnackbar, enqueueSnackbar, SnackbarKey } from "notistack";
import { createResourceExplorerVisibilityHierarchyItemByBodyThunk } from "./createResourceExplorerVisibilityHierarchyItemByBodyThunk";
import { getResourceExplorerVisibilityHierarchyThunk } from "./getResourceExplorerVisibilityHierarchyThunk";
import { updateResourceExplorerVisibilityHierarchyThunk } from "./updateResourceExplorerVisibilityHierarchyThunk";
import { NavigationItemsVisibilityType } from "../../../navigation/utils/types";
import {
  cloudChiprApi,
  PostV2UsersMeOrganisationsCurrentResourceExplorerViewsApiArg,
} from "../../../../services/cloudchipr.api";
import { enqueueSnackbarErrorAlert } from "../../../../components/pages/common/snackbar-alert/EnqueueSnackbarErrorAlert";

interface DuplicateResourceExplorerVisibilityHierarchyFolderOrItemThunkArg {
  name: string;
  visibility: NavigationItemsVisibilityType;
  folderId?: string;
  viewId?: string;
}

export const duplicateResourceExplorerVisibilityHierarchyItemThunk =
  createAsyncThunk(
    "resourceExplorer/duplicateResourceExplorerVisibilityHierarchyItem",
    async (
      {
        name,
        visibility,
        viewId,
        folderId,
      }: DuplicateResourceExplorerVisibilityHierarchyFolderOrItemThunkArg,
      { dispatch },
    ) => {
      if (!viewId) {
        return;
      }

      const {
        getUsersMeOrganisationsCurrentResourceExplorerViewsByResourceExplorerViewId,
      } = cloudChiprApi.endpoints;

      const currentView = await dispatch(
        getUsersMeOrganisationsCurrentResourceExplorerViewsByResourceExplorerViewId.initiate(
          { resourceExplorerViewId: viewId },
        ),
      ).unwrap();

      const viewData = currentView?.data;
      const filterTree = currentView?.filter_tree;

      const body: PostV2UsersMeOrganisationsCurrentResourceExplorerViewsApiArg["body"] =
        {
          name,
          visibility,
          filter_tree: filterTree,
          data: viewData,
        };
      let response;
      try {
        response = await dispatch(
          createResourceExplorerVisibilityHierarchyItemByBodyThunk(body),
        ).unwrap();

        if (!response?.id) {
          return;
        }

        if (folderId) {
          await dispatch(
            updateResourceExplorerVisibilityHierarchyThunk({
              folderId,
              id: response.id,
              visibility,
              type: "view",
            }),
          );
        } else {
          dispatch(getResourceExplorerVisibilityHierarchyThunk());
        }

        const snackbarId: SnackbarKey = enqueueSnackbar(
          "Report successfully duplicated.",
          {
            variant: "snackbarAlert",
            AlertSnackBarProps: {
              severity: "success",
              onClose: () => closeSnackbar(snackbarId),
            },
          },
        );
        return response;
      } catch (e) {
        // @ts-expect-error todo: fix api spec;
        enqueueSnackbarErrorAlert(e?.data?.message);
      }
    },
  );
