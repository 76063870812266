import { tasksListGridDataSelector } from "./tasksListGridDataSelector";
import { RootState } from "../../../store";

export const tasksTargetResourcesTotalMonthlyPriceSelector = (
  state: RootState,
) => {
  const tasks = tasksListGridDataSelector(state);

  return (
    tasks?.reduce((acc, task) => {
      return acc + +(task?.target?.price_per_month ?? 0);
    }, 0) ?? 0
  );
};
