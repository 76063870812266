import { createAsyncThunk } from "@reduxjs/toolkit";
import { liveFilteredTrackedResourcesFixedCacheKey } from "./utils/constants";
import {
  cloudChiprApi,
  ResourceExplorerFilterSchema,
} from "../../../../services/cloudchipr.api";
import { enqueueSnackbarErrorAlert } from "../../../../components/pages/common/snackbar-alert/EnqueueSnackbarErrorAlert";

export const getLiveFilteredTrackedResourcesForViewThunk = createAsyncThunk(
  "resourceExplorer/getLiveFilteredTrackedResourcesForView",
  async (filters: ResourceExplorerFilterSchema, { dispatch }) => {
    const { postUsersMeOrganisationsCurrentTrackedResources } =
      cloudChiprApi.endpoints;

    try {
      return await dispatch(
        postUsersMeOrganisationsCurrentTrackedResources.initiate(
          {
            body: {
              load_for: "view",
              filters,
            },
          },
          {
            fixedCacheKey: liveFilteredTrackedResourcesFixedCacheKey,
          },
        ),
      ).unwrap();
    } catch (e) {
      // @ts-expect-error todo: fix api spec;
      enqueueSnackbarErrorAlert(e?.data?.message);
    }
  },
);
