import { createAsyncThunk } from "@reduxjs/toolkit";
import { enqueueSnackbar } from "notistack";
import { taskManagementGetTasksThunk } from "./taskManagementGetTasksThunk";
import { cloudChiprApi } from "../../../../services/cloudchipr.api";
import { taskDeletionFixedCacheKey } from "../../utils/constants/fixedCacheKeys";
import { enqueueSnackbarErrorAlert } from "../../../../components/pages/common/snackbar-alert/EnqueueSnackbarErrorAlert";

export const taskManagementDeleteTasksByIdsThunk = createAsyncThunk(
  "taskManagement/taskManagementDeleteTasksByIds",
  async (ids: string[], { dispatch }) => {
    const { putUsersMeOrganisationsCurrentTasks } = cloudChiprApi.endpoints;

    try {
      await dispatch(
        putUsersMeOrganisationsCurrentTasks.initiate(
          {
            body: { task_ids: ids },
          },
          { fixedCacheKey: taskDeletionFixedCacheKey },
        ),
      ).unwrap();

      await dispatch(taskManagementGetTasksThunk()).unwrap();

      enqueueSnackbar("Task successfully deleted.", {
        variant: "snackbarAlert",
        AlertSnackBarProps: { severity: "success" },
      });
    } catch (e) {
      // @ts-expect-error todo: fix api spec;
      enqueueSnackbarErrorAlert(e?.data?.message);
    }
  },
);
