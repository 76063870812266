import { SvgIcon, SvgIconProps, useTheme } from "@mui/material";

export const GaugeSvgIcon = ({ color }: SvgIconProps) => {
  const { palette } = useTheme();

  const fillColor =
    color === "primary" ? palette.primary.main : palette.action.active;

  return (
    <SvgIcon>
      <svg
        width="20"
        height="20"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M18 13C18 9.69 15.31 7 12 7C8.69 7 6 9.69 6 13C6 15.22 7.21 17.46 9 18.5L10 16.76C8.81 16.06 8 14.48 8 13C8 10.79 9.79 9 12 9C14.21 9 16 10.79 16 13C16 14.48 15.19 15.75 14 16.45L15 18.19C16.79 17.15 18 15.22 18 13ZM12 3C6.48 3 2 7.48 2 13C2 16.7 4.01 19.92 6.99 21.65L7.99 19.92C5.61 18.53 4 15.96 4 13C4 8.58 7.58 5 12 5C16.42 5 20 8.58 20 13C20 15.96 18.39 18.53 16 19.92L17 21.65C19.99 19.92 22 16.7 22 13C22 7.48 17.52 3 12 3Z"
          fill={fillColor}
          fillOpacity="0.87"
        />
      </svg>
    </SvgIcon>
  );
};

GaugeSvgIcon.muiName = "GaugeSvgIcon";

export default GaugeSvgIcon;
