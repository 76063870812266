import { FC } from "react";
import { ListItemIcon, ListItemText, MenuItem } from "@mui/material";
import { GppBadOutlined, VerifiedUserOutlined } from "@mui/icons-material";
import { useAppAbility } from "../../../../../../../../../../../../../services/permissions";
import { ProviderAccessType } from "../../../../../../../../../../../../../services/cloudchipr.api";

interface ProtectActionItemProps {
  isProtected: boolean;
  onOpen(): void;
  accessType: ProviderAccessType;
  inProgress?: boolean;
}

export const ProtectActionItemV2: FC<ProtectActionItemProps> = ({
  onOpen,
  isProtected,
  accessType,
  inProgress,
}) => {
  const { cannot } = useAppAbility();
  const canNotCleanResource = cannot("protect", "resource");

  return (
    <MenuItem
      dense
      onClick={onOpen}
      disabled={accessType === "read" || inProgress || canNotCleanResource}
    >
      <ListItemIcon>
        {!isProtected ? (
          <VerifiedUserOutlined fontSize="small" />
        ) : (
          <GppBadOutlined fontSize="small" />
        )}
      </ListItemIcon>
      <ListItemText>{isProtected ? "Unprotect" : "Protect"}</ListItemText>
    </MenuItem>
  );
};
