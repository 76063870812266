import { createAsyncThunk } from "@reduxjs/toolkit";
import { cloudChiprApi } from "../../../../../services/cloudchipr.api";
import { RootState } from "../../../../store";
import { rightsizingRecommendationsDismissedDataSelector } from "../../../selectors/rightsizing/data/rightsizingRecommendationsDismissedDataSelector";
import { recommendationRestoreFixedCacheKey } from "../../../utils/constants/fixedCacheKeys";
import { enqueueSnackbarErrorAlert } from "../../../../../components/pages/common/snackbar-alert/EnqueueSnackbarErrorAlert";

export const restoreRightsizingRecommendationThunk = createAsyncThunk(
  "recommendations/restoreRightsizingRecommendationThunk",
  async (ids: string[], { dispatch, getState }) => {
    const state = getState() as RootState;

    const { patchUsersMeOrganisationsCurrentRecommendationsRightSizing } =
      cloudChiprApi.endpoints;

    const dismissedRecommendations =
      rightsizingRecommendationsDismissedDataSelector(state);

    const accountIds = ids.map((recommendationId) => {
      const recommendation = dismissedRecommendations?.find(
        ({ id }) => id === recommendationId,
      );

      return recommendation?.account?.id ?? "";
    });

    try {
      return await dispatch(
        patchUsersMeOrganisationsCurrentRecommendationsRightSizing.initiate(
          {
            restoreRecommendationRequest: {
              account_ids: accountIds,
              recommendation_ids: ids,
            },
          },
          {
            fixedCacheKey: recommendationRestoreFixedCacheKey,
          },
        ),
      ).unwrap();
    } catch (e: any) {
      enqueueSnackbarErrorAlert(e?.data?.message);
    }
  },
);
