import { FC } from "react";
import { Stack } from "@mui/material";
import { CellContext } from "@tanstack/react-table";
import { TypographyWithTooltip } from "../../../../../../../../../../common/TypographyWithTooltip";
import { CostAndUsageDataGridType } from "../utils/types";
import { LiveFilteredTitle } from "../../../../../../../../../resource-explorer/components/resource-explorer-card/components/resource-explorer-metadata/components/live-usage/LiveFilteredTitle";
import { RowSelectionCellCheckbox } from "../../../../../../../../../../../storybook/data-grid/common/RowSelectionCellCheckbox";

type CostAndUsageSumWidgetLabelCellProps = CellContext<
  CostAndUsageDataGridType,
  any
>;

export const CostAndUsageSumWidgetLabelCell: FC<
  CostAndUsageSumWidgetLabelCellProps
> = (cell) => {
  const { row, table } = cell;
  const { costAndUsageSumWidgetFilterType, label } = row.original;

  return (
    <Stack direction="row" spacing={0.5}>
      <RowSelectionCellCheckbox row={row} table={table} />

      {costAndUsageSumWidgetFilterType !== "live_usage_total_cost" ? (
        <TypographyWithTooltip title={label} variant="body2" maxWidth={300} />
      ) : (
        <LiveFilteredTitle
          tooltipTitle="The total monthly cost of filtered resources found in Live Usage & Mgmt for the current report."
          large
        />
      )}
    </Stack>
  );
};
