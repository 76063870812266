import { FC } from "react";
import { Stack } from "@mui/material";
import { ColumnsInnerHeaderComponent } from "../../../../common/ag-grid/components/ColumnsInnerHeaderComponent";
import { useAppSelector } from "../../../../../../store/hooks";
import { tasksTargetResourcesTotalMonthlyPriceSelector } from "../../../../../../store/task-management/selectors/data/tasksTargetResourcesTotalMonthlyPriceSelector";
import { money } from "../../../../../../utils/numeral/money";
import { TypographyWithTooltip } from "../../../../../common/TypographyWithTooltip";
import type { CustomHeaderProps } from "ag-grid-react";

export const MonthlyPriceInnerHeader: FC<CustomHeaderProps> = (props) => {
  const total = useAppSelector(tasksTargetResourcesTotalMonthlyPriceSelector);

  return (
    <Stack position="relative">
      <ColumnsInnerHeaderComponent {...props} />

      <TypographyWithTooltip title={money(total)} variant="caption" />
    </Stack>
  );
};
