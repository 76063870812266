import { FC, Fragment, useCallback } from "react";
import {
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { WidgetDuplicateActionMenuItem } from "./WidgetDuplicateActionMenuItem";
import { WidgetEditActionMenuItem } from "./WidgetEditActionMenuItem";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../store/hooks";
import { WidgetType } from "../../../../../../../../services/cloudchipr.api";
import { useAppAbility } from "../../../../../../../../services/permissions";
import { useMenuHook } from "../../../../../../../../utils/hooks/useMenu.hook";
import { useDialog } from "../../../../../../../../utils/hooks/useDialog.hook";
import { currentDashboardIdSelector } from "../../../../../../../../store/dashboards/selectors/dashboard/currentDashboardIdSelector";
import { widgetsRemovalLoadingSelector } from "../../../../../../../../store/dashboards/selectors/widgets/widgetsRemovalLoadingSelector";
import { getDashboardByIdLoadingSelector } from "../../../../../../../../store/dashboards/selectors/dashboard/loadings/getDashboardByIdLoadingSelector";
import { removeDashboardWidgetByIdThunk } from "../../../../../../../../store/dashboards/thunks/widgets/removeDashboardWidgetByIdThunk";
import { DeleteConfirmationDialog } from "../../../../../../../common/modals/DeleteConfirmationDialog";
import { patchDashboardVisibilityHierarchyByDashboardIdLoadingSelector } from "../../../../../../../../store/dashboards/selectors/dashboard-hierarchy-visibility/patchDashboardVisibilityHierarchyByDashboardIdLoadingSelector";
import { currentDashboardProtectedSelector } from "../../../../../../../../store/dashboards/selectors/dashboard-hierarchy-visibility/currentDashboardProtectedSelector";

interface WidgetActionsProps {
  widgetId: string;
  widgetType: WidgetType;
  widgetName: string;
}

export const WidgetActions: FC<WidgetActionsProps> = ({
  widgetId,
  widgetType,
  widgetName,
}) => {
  const dispatch = useAppDispatch();
  const { cannot } = useAppAbility();
  const canNotDeleteWidget = cannot("delete", "dashboard");
  const isProtected = useAppSelector(currentDashboardProtectedSelector);

  const { anchor, open, openMenu, closeMenu } = useMenuHook();
  const { open: removeDialogOpen, openDialog, closeDialog } = useDialog();

  const dashboardId = useAppSelector(currentDashboardIdSelector);

  const removeLoading = useAppSelector((state) =>
    widgetsRemovalLoadingSelector(state, widgetId),
  );
  const dashboardGetLoading = useAppSelector((state) =>
    getDashboardByIdLoadingSelector(state, dashboardId ?? ""),
  );
  const dashboardUpdateLoading = useAppSelector(
    patchDashboardVisibilityHierarchyByDashboardIdLoadingSelector,
  );
  const loading =
    removeLoading || dashboardGetLoading || dashboardUpdateLoading;

  const removeWidgetHandler = useCallback(async () => {
    await dispatch(removeDashboardWidgetByIdThunk(widgetId));
    closeMenu();
  }, [dispatch, widgetId, closeMenu]);

  return (
    <Fragment>
      <IconButton size="small" onClick={openMenu}>
        <MoreVertIcon />
      </IconButton>

      <Menu anchorEl={anchor} open={open} onClose={closeMenu}>
        <WidgetEditActionMenuItem
          widgetId={widgetId}
          widgetType={widgetType}
          onClose={closeMenu}
        />
        <WidgetDuplicateActionMenuItem
          widgetId={widgetId}
          widgetType={widgetType}
          widgetName={widgetName}
          onClose={closeMenu}
        />

        <Tooltip
          title={isProtected ? "Protected dashboards cannot be modified." : ""}
          arrow
          placement="right"
        >
          <span>
            <MenuItem
              onClick={openDialog}
              disabled={canNotDeleteWidget || isProtected}
            >
              <ListItemIcon>
                <DeleteOutlineIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>
                <Typography variant="body2">Delete</Typography>
              </ListItemText>
            </MenuItem>
          </span>
        </Tooltip>
      </Menu>

      <DeleteConfirmationDialog
        open={removeDialogOpen}
        onClose={closeDialog}
        onDelete={removeWidgetHandler}
        loading={loading}
        title="Delete Widget?"
        content={
          <Typography>
            Please confirm if you want to delete the{" "}
            <Typography component="span" fontWeight="bold">
              {widgetName}
            </Typography>{" "}
            widget.
          </Typography>
        }
      />
    </Fragment>
  );
};
