import { createAsyncThunk } from "@reduxjs/toolkit";
import { enqueueSnackbar } from "notistack";
import { cloudChiprApi } from "../../../services/cloudchipr.api";
import { currentBudgetSelector } from "../selectors/current-budget/currentBudgetSelector";
import { RootState } from "../../store";
import { formatDate } from "../../../utils/helpers/date-time/datetime-format";
import { enqueueSnackbarErrorAlert } from "../../../components/pages/common/snackbar-alert/EnqueueSnackbarErrorAlert";

export const createBudgetFromCurrentThunk = createAsyncThunk(
  "budgets/createBudgetFromCurrent",
  async (_: void, { dispatch, getState }) => {
    const state = getState() as RootState;

    const { postUsersMeOrganisationsCurrentViewsByViewIdBudgets } =
      cloudChiprApi.endpoints;

    const currentBudget = currentBudgetSelector(state);

    if (!currentBudget) {
      return;
    }

    try {
      await dispatch(
        postUsersMeOrganisationsCurrentViewsByViewIdBudgets.initiate({
          ...currentBudget,
          body: {
            ...currentBudget.body,
            start_date: formatDate(currentBudget.body.start_date, {
              type: "yearMonthDay",
            }),
          },
        }),
      ).unwrap();

      enqueueSnackbar(`${currentBudget.body.name} Budget created!`, {
        variant: "snackbarAlert",
        AlertSnackBarProps: { severity: "success" },
      });

      return "success";
    } catch (e) {
      // @ts-expect-error todo: fix api spec;
      enqueueSnackbarErrorAlert(e?.data?.message);
      return "error";
    }
  },
);
