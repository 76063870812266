import { useCallback } from "react";
import { Box, Switch, Tooltip } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../../../store/hooks";
import { liveUsageMgmtFilterTemplatesEnabledSelector } from "../../../../../store/live-usage-mgmt/selectors/store-selectors/liveUsageMgmtFilterTemplatesEnabledSelector";
import { liveUsageMgmtFilterTemplateEnabledChangeThunk } from "../../../../../store/live-usage-mgmt/thunks/liveUsageMgmtFilterTemplateEnabledChangeThunk";

export const LiveUsageFilterTemplatesSwitcher = () => {
  const dispatch = useAppDispatch();
  const filterTemplatesEnabled = useAppSelector(
    liveUsageMgmtFilterTemplatesEnabledSelector,
  );

  const switchChangeHandler = useCallback(() => {
    dispatch(liveUsageMgmtFilterTemplateEnabledChangeThunk());
  }, [dispatch]);

  return (
    <Box px={1}>
      <Tooltip
        title={`Turn ${filterTemplatesEnabled ? "Off" : "On"} Filter Template`}
        arrow
        placement="top"
      >
        <Switch
          size="small"
          checked={filterTemplatesEnabled}
          onChange={switchChangeHandler}
        />
      </Tooltip>
    </Box>
  );
};
