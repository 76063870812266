import { useCallback } from "react";
import { AccountTabsWithResources } from "../types/types";
import { useLocalStorageHook } from "../../../../../utils/hooks/useLocalStorage.hook";

export function useAccountStorageMemoizedTabHook() {
  const { getItem, setItem } = useLocalStorageHook();

  const setAccountTab = useCallback(
    (tab: AccountTabsWithResources) => {
      setItem(accountTabsKey, tab);
    },
    [setItem],
  );

  const getAccountTab = useCallback(() => {
    return (
      (getItem(accountTabsKey) as AccountTabsWithResources) ??
      accountTabs.active
    );
  }, [getItem]);

  return {
    setAccountTab,
    getAccountTab,
  };
}

const accountTabsKey = "c8r:accountsPageTab";

export const accountTabs: Record<
  AccountTabsWithResources,
  AccountTabsWithResources
> = {
  active: "active",
  inactive: "inactive",
  "live-resources": "live-resources",
};
