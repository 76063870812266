import { FC, useCallback } from "react";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../store/hooks";
import { NullableDate } from "../../../../../../../services/cloudchipr.api";
import { setOffHoursSpecificData } from "../../../../../../../store/automations/automationsSlice";
import { offHoursSchedulerSelector } from "../../../../../../../store/automations/selectros/off-hours/offHoursSchedulerSelector";
import { getDateNowByTimeZone } from "../../../../../../../utils/helpers/date-time/datetime-format";
import { AutomationDateTimePicker } from "../../../../common/components/form/schedule-content/dates/AutomationDateTimePicker";
import { automationTimeZoneSelector } from "../../../../../../../store/automations/selectros/common/fields/automationTimeZoneSelector";
import { TimePicker } from "../../../../../../common/TimePicker";

interface OffHoursStopStartTimeProps {
  value?: NullableDate;
  type: "stop" | "start";
  dateTimePicker?: boolean;
}

export const OffHoursStopStartTime: FC<OffHoursStopStartTimeProps> = ({
  value,
  type,
  dateTimePicker,
}) => {
  const dispatch = useAppDispatch();

  const scheduler = useAppSelector(offHoursSchedulerSelector);
  const timeZone = useAppSelector(automationTimeZoneSelector);

  const changeHandler = useCallback(
    (date: NullableDate) => {
      const key = type === "start" ? "startTime" : "stopTime";

      dispatch(
        setOffHoursSpecificData({
          scheduler: { ...scheduler, [key]: date },
        }),
      );
    },
    [dispatch, scheduler, type],
  );

  if (dateTimePicker) {
    return (
      <AutomationDateTimePicker
        icon
        date={value ?? ""}
        onAccept={changeHandler}
        minDate={getDateNowByTimeZone(timeZone)}
      />
    );
  }

  return <TimePicker icon value={value} onChange={changeHandler} />;
};
