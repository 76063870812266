import { FC } from "react";
import {
  ListItem,
  ListItemButton,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import { TypographyWithTooltip } from "../../../../../../../common/TypographyWithTooltip";

interface LiveUsageAccountFiltersNavigationItemProps {
  title: string;
  filtersCount: number;
  onClick(): void;
  active: boolean;
}

export const LiveUsageFiltersNavigationItem: FC<
  LiveUsageAccountFiltersNavigationItemProps
> = ({ filtersCount, title, onClick, active }) => {
  return (
    <ListItem sx={listItemSx}>
      <ListItemButton sx={listItemButtonSx} onClick={onClick} selected={active}>
        <ListItemText
          inset
          sx={{ pl: 0 }}
          primary={
            <Stack direction="row" justifyContent="space-between" spacing={1}>
              <TypographyWithTooltip
                variant="subtitle2"
                fontWeight={active ? "medium" : "normal"}
                color={active ? "primary" : undefined}
                title={title}
              />

              <Stack
                width={24}
                height={24}
                borderRadius={1}
                alignItems="center"
                justifyContent="center"
                bgcolor={filtersCount ? "primary.light" : "grey.200"}
              >
                <Typography
                  variant="caption"
                  fontWeight="medium"
                  color={filtersCount ? "primary" : "text.disabled"}
                >
                  {filtersCount}
                </Typography>
              </Stack>
            </Stack>
          }
        />
      </ListItemButton>
    </ListItem>
  );
};

const listItemSx = { p: 0 };
const listItemButtonSx = { borderRadius: 2, px: 1, py: 0.5 };
