import { FC, Fragment } from "react";
import { Box, Stack } from "@mui/material";
import { useDidMount, useIntervalWhen } from "rooks";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { AccountsGridView } from "./accounts-grid/AccountsGridView";
import { AccountsEmptyTemplate } from "./AccountsEmptyTemplate";
import { ProviderAccountCashs } from "./ProviderAccountCashs";
import { AccountsToolbar } from "./toolbar/AccountsToolbar";
import { AccountsListView } from "./accounts-list/AccountsListView";
import { Account, ProviderType } from "../../../../../services/cloudchipr.api";
import { useAppDispatch, useAppSelector } from "../../../../../store/hooks";
import { accountsFilterValueSelector } from "../../../../../store/accounts/selectors/slice-data/accountsFilterValueSelector";
import { accountsViewSelector } from "../../../../../store/accounts/selectors/slice-data/accountsViewSelector";
import { getAccountsThunk } from "../../../../../store/accounts/thunks/getAccountsThunk";
import { AccountsPageTabs } from "../../utils/types/types";
import { accountsNeededInfoLoadingSelector } from "../../../../../store/accounts/selectors/loadings/accountsNeededInfoLoadingSelector";
import { getOrganisationCurrentFilterTemplatesThunk } from "../../../../../store/live-usage-mgmt/thunks/getOrganisationCurrentFilterTemplatesThunk";
import { useAccountsDnD } from "../../../live-usage-mgmt/utils/hooks/useAccountsDnD";

interface AccountsPageBodyProps {
  accounts: Record<AccountsPageTabs, Account[]>;
  provider: ProviderType;
  activeTab: AccountsPageTabs;
  orgId: string;
}

export const AccountsPageBody: FC<AccountsPageBodyProps> = ({
  accounts,
  provider,
  activeTab,
  orgId,
}) => {
  const dispatch = useAppDispatch();
  const loading = useAppSelector((state) =>
    accountsNeededInfoLoadingSelector(state, provider, activeTab, orgId),
  );

  const filterValue = useAppSelector(accountsFilterValueSelector);
  const accountsView = useAppSelector(accountsViewSelector);

  const { sortedData, onSortingChange } = useAccountsDnD(
    provider,
    accounts[activeTab],
    activeTab,
    orgId,
  );

  useDidMount(() => {
    dispatch(getAccountsThunk());
    dispatch(getOrganisationCurrentFilterTemplatesThunk());
  });

  useIntervalWhen(
    () => {
      // TODO: Instead of using interval, we should use polling mechanism from RTK
      dispatch(getAccountsThunk());
    },
    60_000,
    true,
  );

  if (!accounts || loading) {
    return null;
  }

  const accountsByView = {
    grid: (
      <AccountsGridView
        accounts={sortedData}
        provider={provider}
        onSortingChange={onSortingChange}
      />
    ),
    list: (
      <AccountsListView
        accounts={sortedData}
        onSortingChange={onSortingChange}
        accountsType={activeTab}
        provider={provider}
      />
    ),
    bar: (
      <AccountsListView
        withBar
        accounts={sortedData}
        onSortingChange={onSortingChange}
        accountsType={activeTab}
        provider={provider}
      />
    ),
  };

  const isEmpty = !(accounts[activeTab]?.length || filterValue);

  return (
    <Stack py={isEmpty ? 0 : 2}>
      {isEmpty ? (
        <AccountsEmptyTemplate
          provider={provider}
          onlyInActive={!!accounts.inactive?.length}
        />
      ) : (
        <Fragment>
          <Stack px={1} spacing={1}>
            <AccountsToolbar provider={provider} tabValue={activeTab} />

            {activeTab === "active" && (
              <ProviderAccountCashs
                organizationId={orgId}
                provider={provider}
              />
            )}
          </Stack>

          <Box px={1} mt={accountsView === "grid" ? 0 : 1}>
            <DndProvider backend={HTML5Backend} context={window}>
              {!!sortedData && accountsView && accountsByView[accountsView]}
            </DndProvider>
          </Box>
        </Fragment>
      )}
    </Stack>
  );
};
