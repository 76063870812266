import { createAsyncThunk } from "@reduxjs/toolkit";
import { createDashboardVisibilityHierarchyItemThunk } from "./createDashboardVisibilityHierarchyItemThunk";
import { updateDashboardVisibilityHierarchyThunk } from "./updateDashboardVisibilityHierarchyThunk";
import { PostV2UsersMeOrganisationsCurrentDashboardsApiArg } from "../../../../services/cloudchipr.api";
import { enqueueSnackbarErrorAlert } from "../../../../components/pages/common/snackbar-alert/EnqueueSnackbarErrorAlert";

interface CreateDashboardVisibilityHierarchyItemByFolderIdThunkArg {
  name: string;
  folderId: string;
  visibility: PostV2UsersMeOrganisationsCurrentDashboardsApiArg["body"]["visibility"];
}

export const createDashboardVisibilityHierarchyItemByFolderIdThunk =
  createAsyncThunk(
    "dashboard/createDashboardVisibilityHierarchyItemByFolderId",
    async (
      {
        name,
        folderId,
        visibility,
      }: CreateDashboardVisibilityHierarchyItemByFolderIdThunkArg,
      { dispatch },
    ) => {
      try {
        const response = await dispatch(
          createDashboardVisibilityHierarchyItemThunk({ name, visibility }),
        ).unwrap();

        if (!response?.id) {
          return;
        }

        await dispatch(
          updateDashboardVisibilityHierarchyThunk({
            folderId,
            id: response.id,
            visibility,
            type: "dashboard",
          }),
        );

        return response;
      } catch (e) {
        // @ts-expect-error todo: fix api spec;
        enqueueSnackbarErrorAlert(e?.data?.message);
      }
    },
  );
