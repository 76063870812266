import { createAsyncThunk } from "@reduxjs/toolkit";
import { cloudChiprApi } from "../../../../../services/cloudchipr.api";
import { dismissPeriodSelector } from "../../../selectors/dismiss/dismissPeriodSelector";
import { RootState } from "../../../../store";
import { recommendationDismissFixedCacheKey } from "../../../utils/constants/fixedCacheKeys";
import { enqueueSnackbarErrorAlert } from "../../../../../components/pages/common/snackbar-alert/EnqueueSnackbarErrorAlert";

export const dismissDailyOffHoursRecommendationThunk = createAsyncThunk(
  "recommendations/dismissDailyOffHoursRecommendationThunk",
  async (ids: string[], { dispatch, getState }) => {
    const state = getState() as RootState;

    const { patchUsersMeDailyOffHoursRecommendations } =
      cloudChiprApi.endpoints;

    const dismissPeriod = dismissPeriodSelector(state);

    if (!dismissPeriod) {
      return;
    }

    try {
      return await dispatch(
        patchUsersMeDailyOffHoursRecommendations.initiate(
          {
            body: {
              recommendation_ids: ids,
              dismiss_period: dismissPeriod,
            },
          },
          {
            fixedCacheKey: recommendationDismissFixedCacheKey,
          },
        ),
      ).unwrap();
    } catch (e: any) {
      enqueueSnackbarErrorAlert(e?.data?.message);
    }
  },
);
