import {
  useCallback,
  useState,
  MouseEvent as ReactMouseEvent,
  useRef,
} from "react";
import { useLocalStorageHook } from "../../../../../../../../utils/hooks/useLocalStorage.hook";
import { getNumberByPercentage } from "../../../../../../../../utils/numeral/getPercentage";

export function useLiveUsageFiltersPopoverHeight() {
  const { getItem, setItem } = useLocalStorageHook();

  const heightFromStorage = getItem(filtersHeightKey);
  const initialHeight =
    typeof heightFromStorage === "number" ? heightFromStorage : defaultHeight;

  const heightRef = useRef(0);
  const [height, setHeight] = useState(initialHeight);

  const handleMouseDown = useCallback(
    (event: ReactMouseEvent) => {
      event.preventDefault();
      const startY = event.clientY;

      const handleMouseMove = (moveEvent: MouseEvent) => {
        const newHeight = Math.min(
          Math.max(minHeight, height + moveEvent.clientY - startY),
          maxHeight,
        );

        heightRef.current = newHeight;

        setHeight(newHeight);
      };

      const handleMouseUp = () => {
        setItem(filtersHeightKey, heightRef.current);

        document.removeEventListener("mousemove", handleMouseMove);
        document.removeEventListener("mouseup", handleMouseUp);
      };

      document.addEventListener("mousemove", handleMouseMove);
      document.addEventListener("mouseup", handleMouseUp);
    },
    [height, setItem],
  );

  return {
    handleMouseDown,
    height,
  };
}

const filtersHeightKey = "c8r:live-usage-filters-popper-height";
const defaultHeight = 450;
const minHeight = 200;
const maxHeight = Math.round(getNumberByPercentage(80, window.screen.height));
