import React, { FC, useCallback, useEffect, useMemo, useRef } from "react";
import { Payload } from "recharts/types/component/DefaultTooltipContent";
import { Stack } from "@mui/material";
import { MultiTypeChart } from "../../../../../../../storybook/charts/multi-type-chart/MultiTypeChart";
import { moneyFormatter } from "../../../../../../../utils/numeral/moneyFormatter";
import { money } from "../../../../../../../utils/numeral/money";
import {
  ChartBaseType,
  CommitmentsGroupResponse,
} from "../../../../../../../services/cloudchipr.api";
import { useMultiTypeChartContext } from "../../../../../../../storybook/charts/multi-type-chart/MultiTypeChartProvider";
import { ChartType } from "../../../../../../../storybook/charts/multi-type-chart/utils/types/types";
import { chartDataPointKey } from "../../../../../../../storybook/charts/multi-type-chart/utils/constants/constants";
import {
  getInterval,
  getXAxisTickLabelFormater,
  percentFormater,
} from "../utils/helpers/helpers";

interface CommitmentUtilizationWidgetChartProps {
  chartBase?: ChartBaseType;
  chartType?: ChartType;
  groups?: CommitmentsGroupResponse[];
}

export const CommitmentUtilizationWidgetChart: FC<
  CommitmentUtilizationWidgetChartProps
> = ({ chartBase, chartType, groups }) => {
  const ref = useRef<null | HTMLDivElement>(null);

  const {
    data: chartData,
    chartType: { type: chartTypeFromProvider, onChange: onChartTypeChange },
  } = useMultiTypeChartContext();
  const width = ref.current?.clientWidth ?? 0;
  const interval = getInterval(chartData.length);

  const yAxisFormatter = useCallback(
    (value: number | string) => {
      return chartBase === "percent"
        ? percentFormater(value)
        : moneyFormatter(+value);
    },
    [chartBase],
  );

  const xAxisTickLabelFormater = useMemo(
    () =>
      getXAxisTickLabelFormater(chartData.length, chartType, width, interval),
    [chartType, chartData.length, width, interval],
  );

  const tooltipValueFormatter = useCallback(
    (payload: Payload<any, any>) => {
      const dateKey = payload.dataKey;
      const identifier = payload.payload[chartDataPointKey];

      if (!dateKey) {
        return;
      }

      const group = groups?.find((group) => group?.name === identifier);
      const value =
        dateKey === "Utilization" ? group?.utilization : group?.waste;

      return `${money(value?.amount)} (${value?.percentage}%)`;
    },
    [groups],
  );

  useEffect(() => {
    if (chartTypeFromProvider === chartType) {
      return;
    }

    onChartTypeChange(chartType ?? "stack");
  }, [chartTypeFromProvider, chartType, onChartTypeChange]);

  return (
    <Stack ref={ref}>
      <MultiTypeChart
        zoom
        selectable
        highlight
        tooltipProps={{
          tooltipValueFormatter,
        }}
        yAxisFormatter={yAxisFormatter}
        xAxisTickProps={{
          labelFormatter: xAxisTickLabelFormater,
          interval: interval,
        }}
        chartBase={chartBase}
        yAxisProps={chartBase === "percent" ? { domain: [0, 100] } : undefined}
        showLoader={false}
        emptyText="No data by your selected date range."
      />
    </Stack>
  );
};
