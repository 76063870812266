import { createDraftSafeSelector } from "@reduxjs/toolkit";
import { liveUsageMgmtAppliedFiltersSelector } from "./liveUsageMgmtAppliedFiltersSelector";

export const liveUsageMgmtAppliedFiltersCountSelector = createDraftSafeSelector(
  [liveUsageMgmtAppliedFiltersSelector],
  (filters) => {
    return Object.values(filters).reduce((acc, filter) => {
      return acc + filter.filter_items.length;
    }, 0);
  },
);
