import { FC } from "react";
import { useTaskManagementContext } from "../../TaskManagementProvider";
import { useAppSelector } from "../../../../../../store/hooks";
import { tasksListIsEmptySelector } from "../../../../../../store/task-management/selectors/data/tasksListIsEmptySelector";
import { AGGridColumnsToolPanel } from "../../../../common/ag-grid/components/AGGridColumnsToolPanel";

export const TaskManagementColumnsToolPanel: FC = () => {
  const { gridApi } = useTaskManagementContext();
  const empty = useAppSelector(tasksListIsEmptySelector);

  return <AGGridColumnsToolPanel gridApi={gridApi} disabled={empty} />;
};
