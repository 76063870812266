import { FC, useCallback } from "react";

import { useParams } from "react-router-dom";
import { WidgetsHierarchySelectTrigger } from "./WidgetsHierarchySelectTrigger";

import { HierarchyViewSelectionWrapper } from "../../../../../../../common/hierarchy-view-selection/HierarchyViewSelectionWrapper";
import { useAppSelector } from "../../../../../../../../../store/hooks";
import { dashboardVisibilitySelectorByIdSelector } from "../../../../../../../../../store/dashboards/selectors/dashboard-hierarchy-visibility/dashboardVisibilitySelectorByIdSelector";
import { HierarchySelectVisibilityProps } from "../../../../../../../common/hierarchy-select-visibility/HierarchySelectVisibility";

interface WidgetsViewsHierarchySelectProps
  extends Omit<
    HierarchySelectVisibilityProps,
    "label" | "hierarchy" | "ItemIcon"
  > {}

export const WidgetsViewsHierarchySelect: FC<
  WidgetsViewsHierarchySelectProps
> = ({ selectedItemId, onChange }) => {
  const { dashboardId = "" } = useParams<{
    dashboardId: string;
  }>();
  const handleChange = useCallback(
    (id: string) => {
      if (id !== selectedItemId) {
        onChange(id);
      }
    },
    [selectedItemId, onChange],
  );

  const visibility = useAppSelector((state) =>
    dashboardVisibilitySelectorByIdSelector(state, dashboardId),
  );

  return (
    <HierarchyViewSelectionWrapper
      selectedItemId={selectedItemId ?? ""}
      onChange={handleChange}
      CustomTriggerComponent={WidgetsHierarchySelectTrigger}
      visibility={visibility}
    />
  );
};
