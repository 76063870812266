import { FC, useCallback, useMemo, useRef, useState } from "react";
import {
  ColDef,
  ColumnRowGroupChangedEvent,
  GetMainMenuItemsParams,
  SelectionChangedEvent,
  SortChangedEvent,
} from "ag-grid-community";
import { Box, Stack } from "@mui/material";
import { getTasksGridColumns } from "./tasksGridColumns";
import {
  autoGroupColumnId,
  getAutoGroupColumnDef,
} from "./auto-group-column/getAutoGroupColumnDef";
import { Task } from "../../../../../services/cloudchipr.api";
import { useTaskManagementContext } from "../TaskManagementProvider";
import { useAppDispatch, useAppSelector } from "../../../../../store/hooks";
import { taskAvailablePropertiesSelector } from "../../../../../store/task-management/selectors/properties/taskAvailablePropertiesSelector";
import { setTaskManagementSelectedTasks } from "../../../../../store/task-management/taskManagementSlice";
import { AgDataGrid } from "../../../common/ag-grid/AgDataGrid";
import { agGridWrapperStyles } from "../../../common/ag-grid/utils/constants/agGridStyles";

interface TasksGridProps {
  tasks: Task[];
}

const rowHeight = 53;

export const TasksGrid: FC<TasksGridProps> = ({ tasks }) => {
  const advancedFilterParentRef = useRef<HTMLDivElement | null>(null);
  const { setGridApi } = useTaskManagementContext();
  const dispatch = useAppDispatch();
  const [rowGroupCountReached, setRowGroupCountReached] = useState(false);

  const taskAvailableProperties = useAppSelector(
    taskAvailablePropertiesSelector,
  );

  const columnDefs = useMemo(() => {
    return getTasksGridColumns(taskAvailableProperties);
  }, [taskAvailableProperties]);

  const defaultColDef = useMemo<ColDef>(() => {
    return {
      minWidth: 150,
      unSortIcon: true,
      enableRowGroup: !rowGroupCountReached,
      filter: "agMultiColumnFilter",
    };
  }, [rowGroupCountReached]);

  const autoGroupColumnDef = useMemo(
    () => getAutoGroupColumnDef(taskAvailableProperties),
    [taskAvailableProperties],
  );

  const onColumnRowGroupChanged = useCallback(
    (params: ColumnRowGroupChangedEvent) => {
      const rowGroupColumns = params.api.getRowGroupColumns();

      const colId = params.column?.getColId();
      if (colId) {
        params.api.setColumnsPinned([colId], false);
      }

      setRowGroupCountReached(rowGroupColumns?.length > 2);
    },
    [],
  );

  const getMainMenuItems = useCallback(
    (params: GetMainMenuItemsParams) => {
      if (rowGroupCountReached) {
        return params.defaultItems.filter((item) => item !== "rowGroup");
      }

      return params.defaultItems;
    },
    [rowGroupCountReached],
  );

  const sortChangeHandler = useCallback(({ api }: SortChangedEvent) => {
    const sortState = api
      .getColumnState()
      .filter(({ sort }) => sort !== null)
      .map((s) => ({ colId: s.colId, sort: s.sort, sortIndex: s.sortIndex }));

    const sortedByGrouping = sortState.some(
      ({ colId }) => colId === autoGroupColumnId,
    );

    if (!sortedByGrouping) {
      sortState.push({
        colId: autoGroupColumnId,
        sort: "asc",
        sortIndex: sortState.length,
      });
    }

    api.applyColumnState({ state: sortState });
  }, []);

  const onSelectionChanged = useCallback(
    ({ api }: SelectionChangedEvent) => {
      dispatch(setTaskManagementSelectedTasks(api.getSelectedRows()));
    },
    [dispatch],
  );

  return (
    <Stack flex={1}>
      <Box
        p={2}
        ref={advancedFilterParentRef}
        sx={{ "& ": { ...agGridWrapperStyles } }}
      />

      <AgDataGrid
        // sort
        onSortChanged={sortChangeHandler}
        // columns
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        // data
        rowData={tasks}
        rowHeight={rowHeight}
        // enableness
        enableAdvancedFilter={false}
        // grouping
        rowGroupPanelShow="always"
        headerHeight={60}
        autoGroupColumnDef={autoGroupColumnDef}
        onColumnRowGroupChanged={onColumnRowGroupChanged}
        // context menu
        getMainMenuItems={getMainMenuItems}
        // miscellaneous
        gridApiSetter={setGridApi}
        wrapperStyles={wrapperStyles}
        advancedFilterParent={advancedFilterParentRef.current}
        configToRememberStateInStorage={configToRememberStateInStorage}
        // row selection
        onSelectionChanged={onSelectionChanged}
        // rowSelection={{
        //   mode: "multiRow",
        //   selectAll: "filtered",
        // }}
      />
    </Stack>
  );
};

const wrapperStyles = {
  height: "96%",
  ".ag-cell-label-container": { paddingBottom: "6px" },
};

const configToRememberStateInStorage = {
  stateByDefault: { rowGroup: { groupColIds: ["status"] } },
  uniqueNameAsKey: "tasksGrid",
};
