import { FC } from "react";
import { Stack } from "@mui/material";
import { useDidMount, useEffectOnceWhen, useWillUnmount } from "rooks";
import { useSearchParams } from "react-router-dom";
import { CreateReportButton } from "./components/CreateReportButton";
import { ReportsDrawer } from "./components/drawer/ReportsDrawer";
import { ReportsTable } from "./components/table/ReportsTable";
import { pathReportsKey } from "./utils/constants/constants";
import { PageHeader } from "../common/PageHeader";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { getAllReportsThunk } from "../../../store/reports/thunks/getAllReportsThunk";
import { getAllReportsLoadingSelector } from "../../../store/reports/selector/getAllReportsLoadingSelector";
import {
  resetReportsFilters,
  setReportsData,
  toggleReportsOpenDrawer,
} from "../../../store/reports/reportsSlice";
import { reportByIdSelector } from "../../../store/reports/selector/reportByIdSelector";

export const Reports: FC = () => {
  const dispatch = useAppDispatch();

  const [searchParams, setSearchParams] = useSearchParams();
  const pathReportId = searchParams.get(pathReportsKey);
  const report = useAppSelector((state) =>
    reportByIdSelector(state, pathReportId ?? ""),
  );

  const loading = useAppSelector(getAllReportsLoadingSelector);

  useDidMount(() => {
    dispatch(getAllReportsThunk());
  });

  useEffectOnceWhen(() => {
    dispatch(setReportsData(report ?? {}));
    dispatch(toggleReportsOpenDrawer());
    searchParams.delete(pathReportsKey);
    setSearchParams(searchParams);
  }, !!report && !!pathReportId);

  useWillUnmount(() => {
    dispatch(resetReportsFilters());
  });

  return (
    <Stack flexGrow={1} bgcolor="white" pb={1}>
      <PageHeader
        sticky
        title="Subscriptions"
        actions={<CreateReportButton />}
        loading={loading}
      />

      <ReportsTable />

      <ReportsDrawer />
    </Stack>
  );
};
