import { FC, useCallback } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
} from "@mui/material";
import { useDidMount, useWillUnmount } from "rooks";
import { LoadingButton } from "@mui/lab";

import { SendReportActionItemProps } from "./SendReportActionItem";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { sendSingleReportThunk } from "../../../../store/reports/thunks/sendSingleReportThunk";
import { sendSingleReportLoadingSelector } from "../../../../store/reports/selector/sendSingleReportLoadingSelector";
import {
  resetReportsData,
  setReportsData,
} from "../../../../store/reports/reportsSlice";
import { sendSingleReportButtonDisabledSelector } from "../../../../store/reports/selector/sendSingleReportButtonDisabledSelector";
import { getPressEnterHandler } from "../../../utils/helpers/getPressEnterHandler";
import { ReportsDrawerAttachments } from "../../reports/components/drawer/components/attachments-section/ReportsDrawerAttachments";
import { ReportsDrawerNotifications } from "../../reports/components/drawer/components/notifications-section/ReportsDrawerNotifications";
import { getLabelByBillingSourceType } from "../../../utils/helpers/getLabelByBillingSourceType";

interface SendReportDialogProps extends SendReportActionItemProps {
  onClose(): void;
}

export const SendReportDialog: FC<SendReportDialogProps> = ({
  onClose,
  sourceType,
  sourceId,
}) => {
  const dispatch = useAppDispatch();
  const loading = useAppSelector(sendSingleReportLoadingSelector);
  const disabled = useAppSelector(sendSingleReportButtonDisabledSelector);

  const handleSend = useCallback(async () => {
    await dispatch(sendSingleReportThunk());
    onClose();
  }, [dispatch, onClose]);

  const handlePressEnter = getPressEnterHandler(
    handleSend,
    disabled ?? loading,
  );

  useDidMount(() => {
    dispatch(
      setReportsData({
        source_type: sourceType,
        source_id: sourceId,
      }),
    );
  });

  useWillUnmount(() => {
    dispatch(resetReportsData());
  });

  return (
    <Dialog
      open={true}
      onClose={onClose}
      onKeyDown={handlePressEnter}
      fullWidth
      maxWidth="md"
    >
      <DialogTitle>Send {getLabelByBillingSourceType(sourceType)}</DialogTitle>

      <DialogContent sx={{ color: "text.secondary" }} dividers>
        <Stack spacing={3} p={1}>
          <ReportsDrawerAttachments />
          <ReportsDrawerNotifications />
        </Stack>
      </DialogContent>

      <DialogActions>
        <Button disabled={loading} onClick={onClose} color="tertiary">
          Cancel
        </Button>
        <LoadingButton
          variant="contained"
          color="primary"
          onClick={handleSend}
          loading={loading}
          disabled={disabled}
          sx={{ m: 1 }}
        >
          Send
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
