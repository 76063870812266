import { FC, Fragment, MouseEvent, useCallback, useRef } from "react";
import { IconButton, Stack, Tooltip, Typography } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import moment from "moment/moment";
import { Moment } from "moment";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import CloseIcon from "@mui/icons-material/Close";
import { TaskPropertyTriggerWrapper } from "./TaskPropertyTriggerWrapper";
import { formatDate } from "../../../../../../../utils/helpers/date-time/datetime-format";
import { useDialog } from "../../../../../../../utils/hooks/useDialog.hook";
import { getTaskDueDateLabel } from "../../../utils/helpers/getTaskDueDateLabel";
import { useHover } from "../../../../../../../utils/hooks/useHover.hook";

interface TaskDueDateSelectionProps {
  value?: string | null;
  inCell?: boolean;
  disableSelection?: boolean;
  onChange(value: string | null): void;
}

export const TaskDueDateSelection: FC<TaskDueDateSelectionProps> = ({
  value,
  onChange,
  disableSelection,
  inCell,
}) => {
  const anchorRef = useRef<HTMLDivElement | null>(null);
  const { ref, hovered } = useHover();

  const { open, openDialog, closeDialog } = useDialog();

  const changeHandler = useCallback(
    (date: Moment | null) => {
      closeDialog();
      onChange(date ? formatDate(date, { type: "yearMonthDay" }) : null);
    },
    [closeDialog, onChange],
  );

  const removeHandler = useCallback(
    (e: MouseEvent) => {
      e.stopPropagation();

      onChange(null);
      closeDialog();
    },
    [onChange, closeDialog],
  );

  const isToday = moment(value).isSame(moment(), "day");
  const isPastDue = moment(value).isBefore(moment(), "day");

  const date = formatDate(value ?? "", { type: "date" });
  const label = getTaskDueDateLabel(value);

  let color = isToday ? "#C77700" : "text.secondary";
  if (isPastDue) {
    color = "error.main";
  }

  return (
    <Stack alignItems="start" ref={disableSelection ? undefined : anchorRef}>
      <TaskPropertyTriggerWrapper
        width="100%"
        inCell={inCell}
        refToPass={ref}
        hovered={hovered}
        onClick={openDialog}
        disabled={disableSelection}
      >
        {value ? (
          <Fragment>
            <Tooltip title={label ? date : ""} placement="top" arrow>
              <Typography color={color} variant="body2">
                {label ?? date}
              </Typography>
            </Tooltip>

            {!disableSelection && hovered && (
              <IconButton size="small" sx={{ p: 0.25 }} onClick={removeHandler}>
                <CloseIcon fontSize="small" />
              </IconButton>
            )}
          </Fragment>
        ) : (
          <IconButton
            onClick={disableSelection ? undefined : openDialog}
            sx={{
              border: "1px dashed",
              borderColor: "grey.400",
              whiteSpace: "nowrap",
            }}
          >
            <CalendarTodayIcon color="action" sx={{ fontSize: 16 }} />
          </IconButton>
        )}
      </TaskPropertyTriggerWrapper>

      <DatePicker
        open={open}
        onClose={closeDialog}
        value={value ? moment(value) : null}
        onChange={changeHandler}
        slotProps={{
          textField: {
            error: false,
            size: "small",
            variant: "standard",
            sx: { width: 0, height: 0, p: 0 },
            InputProps: { endAdornment: null },
          },
          popper: {
            anchorEl: anchorRef.current,
            placement: "bottom-start",
          },
        }}
      />
    </Stack>
  );
};
