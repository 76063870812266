import { FC, Fragment, useCallback } from "react";
import { ListItemIcon, MenuItem, Tooltip, Typography } from "@mui/material";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { ResourceExplorerViewRenameDialog } from "./ResourceExplorerViewRenameDialog";
import { useDialog } from "../../../../../../../../utils/hooks/useDialog.hook";

interface ResourceExplorerRenameActionItemProps {
  id: string;
  name: string;
  onClose(): void;
  isProtected?: boolean;
}

export const ResourceExplorerRenameActionItem: FC<
  ResourceExplorerRenameActionItemProps
> = ({ name, id, onClose, isProtected }) => {
  const { open, openDialog, closeDialog } = useDialog();

  const closeHandler = useCallback(() => {
    closeDialog();
    onClose();
  }, [onClose, closeDialog]);

  return (
    <Fragment>
      <Tooltip
        arrow
        placement="right"
        title={isProtected ? "Protected reports cannot be modified." : ""}
      >
        <span>
          <MenuItem onClick={openDialog} disabled={isProtected}>
            <ListItemIcon>
              <EditOutlinedIcon fontSize="small" />
            </ListItemIcon>
            <Typography variant="body2">Rename</Typography>
          </MenuItem>
        </span>
      </Tooltip>

      <ResourceExplorerViewRenameDialog
        id={id}
        open={open}
        name={name}
        closeMenu={closeHandler}
      />
    </Fragment>
  );
};
