import { createAsyncThunk } from "@reduxjs/toolkit";
import { getOffHoursThunk } from "./getOffHoursThunk";
import { cloudChiprApi } from "../../../../services/cloudchipr.api";
import { automationIdSelector } from "../../selectros/common/automationIdSelector";
import { RootState } from "../../../store";
import { offHoursPayloadDataSelector } from "../../selectros/off-hours/data/offHoursPayloadDataSelector";
import {
  createAutomationFixedCacheKey,
  updateAutomationFixedCacheKey,
} from "../../utils/constants/fixedCacheKeys";
import { validateAutomationDataThunk } from "../validateAutomationDataThunk";
import { setAutomationStep } from "../../automationsSlice";
import { enqueueSnackbarErrorAlert } from "../../../../components/pages/common/snackbar-alert/EnqueueSnackbarErrorAlert";

export const saveOffHoursThunk = createAsyncThunk(
  "automation/saveOffHours",
  async (_: void, { dispatch, getState }) => {
    const state = getState() as RootState;

    const {
      postUsersMeOffHoursSchedules,
      patchUsersMeOffHoursSchedulesByScheduleId,
    } = cloudChiprApi.endpoints;

    const errors = await dispatch(validateAutomationDataThunk()).unwrap();

    if (errors) {
      dispatch(setAutomationStep("setup"));
      return;
    }

    const automationId = automationIdSelector(state);
    const payload = offHoursPayloadDataSelector(state);

    try {
      if (automationId) {
        await dispatch(
          patchUsersMeOffHoursSchedulesByScheduleId.initiate(
            {
              scheduleId: automationId,
              body: payload,
            },
            { fixedCacheKey: updateAutomationFixedCacheKey },
          ),
        ).unwrap();
      } else {
        await dispatch(
          postUsersMeOffHoursSchedules.initiate(
            {
              body: payload,
            },
            { fixedCacheKey: createAutomationFixedCacheKey },
          ),
        ).unwrap();
      }

      await dispatch(getOffHoursThunk(true)).unwrap();
    } catch (e) {
      // @ts-expect-error todo: fix api spec;
      enqueueSnackbarErrorAlert(e?.data?.message);
    }
  },
);
