import { FC } from "react";
import { Fade, IconButton, Stack } from "@mui/material";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { useAppAbility } from "../../../../../../../../../services/permissions";
import { TypographyWithTooltip } from "../../../../../../../../common/TypographyWithTooltip";
import { useAppSelector } from "../../../../../../../../../store/hooks";
import { currentDashboardProtectedSelector } from "../../../../../../../../../store/dashboards/selectors/dashboard-hierarchy-visibility/currentDashboardProtectedSelector";

interface WidgetNameDefaultViewProps {
  name: string;
  hovered: boolean;
  toggleEditMode: (action: any) => any;
}

export const WidgetNameDefaultView: FC<WidgetNameDefaultViewProps> = ({
  name,
  hovered,
  toggleEditMode,
}) => {
  const { cannot } = useAppAbility();
  const canNotEditWidget = cannot("edit", "dashboard");
  const isProtected = useAppSelector(currentDashboardProtectedSelector);

  return (
    <Stack
      alignSelf="start"
      direction="row"
      spacing={1}
      overflow="hidden"
      alignItems="center"
      flexGrow={0}
    >
      <TypographyWithTooltip variant="body1" fontWeight="bold" title={name} />

      {!canNotEditWidget && !isProtected && (
        <Fade in={hovered}>
          <IconButton size="small" onClick={toggleEditMode}>
            <EditOutlinedIcon fontSize="small" />
          </IconButton>
        </Fade>
      )}
    </Stack>
  );
};
