import { FC, RefObject, useCallback } from "react";
import { AgGridReact, CustomCellRendererProps } from "ag-grid-react";
import { IconButton, ListItemIcon, Menu, MenuItem, Stack } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useNavigate } from "react-router-dom";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { RoleDuplicationDialog } from "./components/RoleDuplicationDialog";
import { RoleDeletionDialog } from "./components/RoleDeletionDialog";
import { Role } from "../../../../../../../services/cloudchipr.api";
import { useMenuHook } from "../../../../../../../utils/hooks/useMenu.hook";
import { useDialog } from "../../../../../../../utils/hooks/useDialog.hook";

interface RolesGridActionCellProps extends CustomCellRendererProps<Role> {
  gridRef?: RefObject<AgGridReact>;
}

export const RolesGridActionCell: FC<RolesGridActionCellProps> = ({
  data: role,
  gridRef,
}) => {
  const navigate = useNavigate();
  const { anchor, open, openMenu, closeMenu } = useMenuHook();
  const { open: confirmDialogOpen, openDialog, closeDialog } = useDialog();

  const {
    open: duplicationOpen,
    openDialog: openConfirmationDialog,
    closeDialog: closeConfirmationDialog,
  } = useDialog();

  const editRoleClickHandler = useCallback(() => {
    if (!role) {
      return;
    }
    navigate({
      pathname: `/settings/roles/edit/${role.id}`,
    });
  }, [navigate, role]);

  if (!role) {
    return null;
  }

  return (
    <Stack direction="row" spacing={1} overflow="hidden">
      <IconButton onClick={openMenu}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        anchorEl={anchor}
        open={open}
        onClose={closeMenu}
        sx={{ "& .MuiPaper-root": { minWidth: 124 } }}
      >
        <MenuItem onClick={editRoleClickHandler} sx={menuItemSx}>
          <ListItemIcon>
            <EditOutlinedIcon fontSize="small" sx={iconSx} />
          </ListItemIcon>
          Edit
        </MenuItem>
        {!role.is_default && (
          <MenuItem onClick={openConfirmationDialog} sx={menuItemSx}>
            <ListItemIcon>
              <ContentCopyIcon fontSize="small" sx={iconSx} />
            </ListItemIcon>
            Duplicate
          </MenuItem>
        )}
        {!role.is_default && (
          <MenuItem
            onClick={openDialog}
            sx={menuItemSx}
            disabled={role.is_default}
          >
            <ListItemIcon>
              <DeleteOutlineIcon fontSize="small" sx={iconSx} />
            </ListItemIcon>
            Delete
          </MenuItem>
        )}
      </Menu>
      <RoleDeletionDialog
        open={confirmDialogOpen}
        onClose={closeDialog}
        role={role}
        gridRef={gridRef}
      />

      <RoleDuplicationDialog
        open={duplicationOpen}
        onClose={closeConfirmationDialog}
        id={role.id}
        name={role.name}
      />
    </Stack>
  );
};
const menuItemSx = {
  pl: 1,
  pr: 1.5,
};

const iconSx = {
  minWidth: 28,
};
