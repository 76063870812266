import { FC, useCallback } from "react";
import { ToggleButtonGroup, ToggleButton } from "@mui/material";
import GridViewIcon from "@mui/icons-material/GridView";
import ListIcon from "@mui/icons-material/List";
import BarChartIcon from "@mui/icons-material/BarChart";
import { useEffectOnceWhen } from "rooks";
import { useAppDispatch, useAppSelector } from "../../../../../../store/hooks";
import {
  AccountsViewType,
  setAccountsView,
} from "../../../../../../store/accounts/accountsSlice";
import { accountsViewSelector } from "../../../../../../store/accounts/selectors/slice-data/accountsViewSelector";
import { ProviderType } from "../../../../../../services/cloudchipr.api";
import { AccountsPageTabs } from "../../../utils/types/types";
import { useAccountsView } from "../../../../live-usage-mgmt/utils/hooks/useAccountsView.hook";

interface AccountsViewToggleProps {
  provider: ProviderType;
  tabValue: AccountsPageTabs;
}

export const AccountsViewToggle: FC<AccountsViewToggleProps> = ({
  provider,
  tabValue,
}) => {
  const view = useAppSelector(accountsViewSelector);

  const dispatch = useAppDispatch();

  const {
    accountsView,
    saveAccountsView,
    isLoading: viewLoading,
  } = useAccountsView(provider, tabValue);

  const viewChangeHandler = useCallback(
    (_: unknown, view: AccountsViewType | null) => {
      if (!view) {
        return;
      }

      dispatch(setAccountsView(view));
      saveAccountsView(view);
    },
    [dispatch, saveAccountsView],
  );

  useEffectOnceWhen(() => {
    dispatch(setAccountsView(accountsView ?? "grid"));
  }, !viewLoading);

  return (
    <ToggleButtonGroup
      exclusive
      size="small"
      value={view}
      onChange={viewChangeHandler}
    >
      <ToggleButton value="grid">
        <GridViewIcon />
      </ToggleButton>

      <ToggleButton value="list">
        <ListIcon />
      </ToggleButton>

      <ToggleButton value="bar">
        <BarChartIcon />
      </ToggleButton>
    </ToggleButtonGroup>
  );
};
