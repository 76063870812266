import { createAsyncThunk } from "@reduxjs/toolkit";
import { getLiveUsageMgmtResourcesWithOutFiltersThunk } from "./resources/getLiveUsageMgmtResourcesWithOutFiltersThunk";
import { RootState } from "../../store";
import { liveUsageMgmtFilterTemplatesEnabledSelector } from "../selectors/store-selectors/liveUsageMgmtFilterTemplatesEnabledSelector";
import { setLiveUsageMgmtFilterTemplatesEnabled } from "../liveUsageMgmtSlice";

export const liveUsageMgmtFilterTemplateEnabledChangeThunk = createAsyncThunk(
  "liveUsageMgmt/liveUsageMgmtFilterTemplateEnabledChange",
  async (_: void, { dispatch, getState }) => {
    const state = getState() as RootState;
    const filterTemplatesEnabled =
      liveUsageMgmtFilterTemplatesEnabledSelector(state);

    const value = !filterTemplatesEnabled;
    dispatch(setLiveUsageMgmtFilterTemplatesEnabled(value));

    if (!value) {
      dispatch(getLiveUsageMgmtResourcesWithOutFiltersThunk());
    }
  },
);
