import { FC, Fragment } from "react";
import { BillingData } from "./pages/common/billing/billing-data/BillingData";
import { useRefreshTokenHook } from "./utils/hooks/useRefreshTokenHook";
import { CleanV2FAB } from "./pages/common/clean-v2/CleanV2FAB";
import { TaskCreationDrawer } from "./pages/common/task-management/TaskCreationDrawer";
import { ImpersonationAlert } from "./pages/common/impersonation-alert/ImpersonationAlert";
import { useInitiateHiddenResourceTypesByFeatureToggle } from "./pages/live-usage-mgmt/utils/hooks/useInitiateHiddenResourceTypesByFeatureToggle.hook";
import { ShowCelebrationPopupWrapper } from "../ShowCelebrationPopupWrapper";

export const Common: FC = () => {
  useRefreshTokenHook();
  useInitiateHiddenResourceTypesByFeatureToggle();

  return (
    <Fragment>
      <CleanV2FAB />

      <BillingData />
      <TaskCreationDrawer />
      <ImpersonationAlert />
      <ShowCelebrationPopupWrapper />
    </Fragment>
  );
};
