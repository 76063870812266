import { FC } from "react";
import { Typography } from "@mui/material";
import { WidgetOptionCard } from "../../WidgetOptionCard";
import Cover from "../../../../utils/assets/images/widget-covers/cost_comparison.svg";

export const CostComparisonCard: FC = () => {
  return (
    <WidgetOptionCard
      title="Cost comparison"
      description="Compare multiple billing reports to identify differences and intersections between them."
      disabledAction={
        <Typography
          variant="caption"
          color="white"
          align="center"
          display="block"
        >
          Coming soon.
        </Typography>
      }
    >
      <img src={Cover} alt="ConstAndUsageCard" />
    </WidgetOptionCard>
  );
};
